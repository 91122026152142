import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";

type SessionPayloadType = {
  searchValue?: string;
  sortType?: sortValues;
  sortValue?: string;
  per_page?: number;
  page?: number;
};

export const sessionListAPI = createAsyncThunk<
  SessionAPIResponse,
  SessionPayloadType
>("session/SessionAPI", async ({ per_page = 50, ...SessionPayload }) => {
  const res = await axiosClient.get(
    `auth/session?${
      SessionPayload.searchValue
        ? `&filteredVal=${SessionPayload.searchValue}`
        : ""
    }${
      SessionPayload.sortValue !== ""
        ? SessionPayload.sortType === "ascending"
          ? `&sort=-${SessionPayload.sortValue}`
          : ``
        : ""
    }${
      SessionPayload.sortValue !== ""
        ? SessionPayload.sortType === "descending"
          ? `&sort=-${SessionPayload.sortValue}`
          : ``
        : ""
    }${
      SessionPayload.page
        ? SessionPayload.page
          ? `&page=${SessionPayload.page}`
          : ``
        : ""
    }${per_page ? `&per_page=${per_page}` : ``}`
  );
  return {
    data: res.data.data,
    page: SessionPayload.page || 1,
  };
});

export const sessionTypeAPI = createAsyncThunk<SessionTypeAPIResponse>(
  "auth/sessionType",
  async () => {
    const res = await axiosClient.get("auth/session-type");
    return res.data;
  }
);

export const sessionDeleteAPI = createAsyncThunk<
  SessionDeleteAPIResponse,
  string
>("session/delete", async (id) => {
  const res = await axiosClient.delete(`auth/session/${id}`);
  return res.data;
});

export const sessionPostAPI = createAsyncThunk<
  SessionPostAPIResponse,
  SessionPostAPIRequest,
  {}
>("session/sessionpost", async (session) => {
  const res = await axiosClient.post(`auth/session`, session);
  return res.data;
});

interface initialStateType {
  sessionList: SessionItem[];
  sortType: sortValues;
  sortValue: string;
  sessionTypeList: SessionType[];
  sessionListAPIIdle: Status;
  sessionDeleleAPIIdle: Status;
  sessionTypeAPIIdle: Status;
  sessionPostAPIIdle: Status;
  totalPages: number;
  activePage: number;
}

const initialState: initialStateType = {
  sessionList: [],
  sortType: "ascending",
  sortValue: "session",
  sessionTypeList: [],
  sessionListAPIIdle: Status.SUCCESS,
  sessionDeleleAPIIdle: Status.SUCCESS,
  sessionTypeAPIIdle: Status.SUCCESS,
  sessionPostAPIIdle: Status.SUCCESS,
  totalPages: 0,
  activePage: 1,
};

const SessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    sessionListSort: (state) => {
      return {
        ...state,
        sortType: state.sortType === "ascending" ? "descending" : "ascending",
      };
    },
    handleSort: (state, action) => {
      return {
        ...state,
        sortType: action.payload.sortType,
        sortValue: action.payload.sortValue,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(sessionListAPI.pending, (state, action) => {
        return {
          ...state,
          sessionListAPIIdle: Status.LOADING,
        };
      })
      .addCase(sessionListAPI.fulfilled, (state, action) => {
        console.log(action.payload.data, "sessionPaeg");
        return {
          ...state,
          sessionListAPIIdle: Status.SUCCESS,
          sessionList: action.payload.data.data,
          totalPages: action.payload.data.total_page,
          activePage: action.payload.page,
        };
      })
      .addCase(sessionListAPI.rejected, (state, action) => {
        return {
          ...state,
          sessionListAPIIdle: Status.ERROR,
        };
      })
      .addCase(sessionDeleteAPI.pending, (state, action) => {
        return {
          ...state,
          sessionDeleleAPIIdle: Status.LOADING,
        };
      })
      .addCase(sessionDeleteAPI.fulfilled, (state, action) => {
        return {
          ...state,
          sessionDeleleAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(sessionDeleteAPI.rejected, (state, action) => {
        return {
          ...state,
          sessionDeleleAPIIdle: Status.ERROR,
        };
      })
      .addCase(sessionTypeAPI.pending, (state, action) => {
        return {
          ...state,
          sessionTypeAPIIdle: Status.LOADING,
        };
      })
      .addCase(sessionTypeAPI.fulfilled, (state, action) => {
        return {
          ...state,
          sessionTypeAPIIdle: Status.SUCCESS,
          sessionTypeList: action.payload.data,
        };
      })
      .addCase(sessionTypeAPI.rejected, (state, action) => {
        return {
          ...state,
          sessionTypeAPIIdle: Status.ERROR,
        };
      })
      .addCase(sessionPostAPI.pending, (state, action) => {
        return {
          ...state,
          sessionPostAPIIdle: Status.LOADING,
        };
      })
      .addCase(sessionPostAPI.fulfilled, (state, action) => {
        return {
          ...state,
          sessionPostAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(sessionPostAPI.rejected, (state, action) => {
        return {
          ...state,
          sessionTypeAPIIdle: Status.ERROR,
        };
      });
  },
});

export const { sessionListSort, handleSort } = SessionSlice.actions;

export default SessionSlice.reducer;

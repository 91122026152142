import React, { useRef } from "react";
import classNames from "classnames";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";
import "tailwindcss/tailwind.css";

interface SwitchProps<TFieldValues extends FieldValues> {
    name: Path<TFieldValues>;
    register: UseFormRegister<TFieldValues>;
    className?: string;
    checked: boolean;
}

const Switch = <TFormValues extends FieldValues>({
    name,
    register,
    className,
    checked,
    ...props
}: SwitchProps<TFormValues>) => {
    const ref = useRef<HTMLInputElement>(null);
    const switchClass = classNames(
        "relative grid outline-none items-center bg-[#f9f9f9] rounded-full select-none",
        {
            "w-24": !checked,
            "w-20": checked,
        },

    );
    const toggleClass = classNames(
        "block w-6 h-6 text-orange-300 rounded-full  border-4 border-[#f9f9f9] bg-orange-400  transform transition duration-200 ease-in-out",
        { "translate-x-[3.4rem] bg-orange-400": checked, "translate-x-0": !checked }
    );

    return (
        <div className={switchClass}>
            <input
                id={name}
                type="checkbox"
                {...register(name)}
                className="toggle-checkbox text-orange-300 flex items-center z-99 absolute w-16 h-6 rounded-full appearance-none cursor-pointer"
            />
            <label className={classNames("absolute text-[#F58124] font-normal text-center transform text-sm flex justify-center items-center transition duration-200 ease-in-out ", {
                "left-2": checked,
                "left-7": !checked
            })} htmlFor={name}>{checked ? "Activity" : "Payments"}</label>
            <label
                htmlFor={name}
                className={classNames(
                    "flex items-center overflow-hidden h-6 border bg-[#f9f9f9]  text-[#f58124] border-[#F58124] rounded-full cursor-pointer transition duration-200 ease-in",
                    { "": checked, "check": !checked }
                )}
            >

                <span className={toggleClass}></span>
            </label>
        </div>
    );
};

export default Switch;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "./UserManagementSlice";
import axiosClient from "../../api/api";
import { ApiResponse } from "../../types/apiresponse";

type AppControlRequest = {
  id?: string;
  version: string;
  device_type: "ios" | "android";
  force_update: boolean;
  description: string;
  version_code: number;
};
export interface AppUpdateInfo {
  _id: string;
  version: string;
  version_code: number;
  device_type: "ios" | "android"; // Assuming the app could be for iOS or Android. Adjust as necessary.
  description: string;
  force_update: boolean;
  updated_at: string;
  created_at: string;
}

export const postAppControlList = createAsyncThunk<any, AppControlRequest>(
  "appcontrol/post",
  async (data) => {
    const res = await axiosClient.post("auth/update-app", data);
  }
);

export const getAppControlList = createAsyncThunk<ApiResponse<AppUpdateInfo[]>>(
  "appcontrol/get",
  async () => {
    const res = await axiosClient.get<ApiResponse<AppUpdateInfo[]>>("auth/update-app");
    return res.data
  }
);
export const deleteAppControl = createAsyncThunk<ApiResponse<AppUpdateInfo[]>, string>(
  "appcontrol/delete",
  async (id) => {
    const res = await axiosClient.delete<ApiResponse<AppUpdateInfo[]>>(`auth/update-app/${id}`);
    return res.data
  }
);

interface initialState {
  versionList: AppUpdateInfo[]
  getAppControlAPIIdle: Status,
  postAppControlAPIIdle: Status,
  deleteAppControlAPIIdle: Status,
}

const initialState: initialState = {
  getAppControlAPIIdle: Status.IDLE,
  postAppControlAPIIdle: Status.IDLE,
  deleteAppControlAPIIdle: Status.IDLE,
  versionList: [],
};

const AppControlSlice = createSlice({
  name: "appcontrol",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postAppControlList.pending, (state) => {
        state.postAppControlAPIIdle = Status.LOADING
      })
      .addCase(postAppControlList.fulfilled, (state) => {
        state.postAppControlAPIIdle = Status.SUCCESS
      })
      .addCase(postAppControlList.rejected, (state) => {
        state.postAppControlAPIIdle = Status.ERROR
      })
      .addCase(getAppControlList.pending, (state) => {
        state.getAppControlAPIIdle = Status.LOADING
      })
      .addCase(getAppControlList.fulfilled, (state, { payload }) => {
        state.getAppControlAPIIdle = Status.SUCCESS
        state.versionList = payload.data
      })
      .addCase(getAppControlList.rejected, (state) => {
        state.getAppControlAPIIdle = Status.ERROR
      })
      .addCase(deleteAppControl.pending, (state) => {
        state.deleteAppControlAPIIdle = Status.LOADING
      })
      .addCase(deleteAppControl.fulfilled, (state, { payload }) => {
        state.deleteAppControlAPIIdle = Status.SUCCESS
      })
      .addCase(deleteAppControl.rejected, (state) => {
        state.deleteAppControlAPIIdle = Status.ERROR
      })
  },
});

export const { } = AppControlSlice.actions;

export default AppControlSlice.reducer;

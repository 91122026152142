// import Image from "next/image";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useForm, Controller } from "react-hook-form";

// import LinkedInTeamIconWhite from "../../Icons/LinkedInTeamIconWhite";
// import MessageIconWhite from "../../Icons/MessageIconWhite";
// import { AttentionSeeker, Flip, Slide } from "react-awesome-reveal";
// import Link from "next/link";
// import { useRouter } from "next/router";

type BadgeProps = {
  onClick?: (data?: any) => void;
  CTA: string;
};
type Props = {};

export function Badge({ onClick = () => {}, CTA }: BadgeProps) {
  return (
    <span
      className="bg-orangegradient text-white  cursor-pointer px-3 py-1 text-sm rounded-full select-none"
      onClick={() => onClick()}
    >
      {CTA}
    </span>
  );
}

type SquareCard = {
  text: string;
  stat: number;
  subText: string;
};

export const SquareCardComponent: React.FC<SquareCard> = ({
  text,
  stat,
  subText,
}) => {
  return (
    <>
      <div className="min-w-[15rem] text-center max-w-full rounded shadow-lg bg-slate-50 font-semibold text-3xl">
        <h6 className="text-black text-lg">{text}</h6>
        <p className="">{stat}</p>
        <p className="text-xs">{subText}</p>
      </div>
    </>
  );
};

type TeamCardProps = {
  image: string;
  designation: string;
  email: string | null;
  linkedIn: string;
  name: string;
};

const TeamMemberCard = ({
  image,
  designation,
  email,
  linkedIn,
  name,
}: TeamCardProps) => {
  return (
    // <Flip triggerOnce={true} direction={"vertical"} duration={1500}>
    <div className="border py-4 px-3 rounded">
      <div className="flex flex-col justify-center items-center gap-4">
        <div className="">
          <img
            className="w-20 h-20 bg-black rounded-full"
            src={"./images/user.png"}
            alt={name}
          />
        </div>
        <h3 className="text-lg">{name}</h3>
        <p className="text-base">{designation}</p>
        <div className="flex gap-4">
          <span>Li</span>
          <span>EM</span>
        </div>
      </div>
    </div>
    // </Flip>
  );
};

type ListProps = {
  details: string;
  content: string;
};

const ListItem = ({ details, content }: ListProps) => {
  return (
    <div className="grid grid-cols-12 border px-4 py-3">
      <div className="col-span-10 text-start">{content}</div>
      <div className="col-span-2 text-end">{details}</div>
    </div>
  );
};

type DraftEditorProps = {
  className: string;
  label: string;
};

const DraftEditor = ({ className, label }: DraftEditorProps) => {
  const modules = {
    toolbar: [
      [{ header: ["1", "2", "3", "4", "5", "6"] }],
      ["bold", "italic", "underline"],
      ["link", "image"],
      [{ align: "left" }],
      ["clean"],
      [{ color: [] }, { background: [] }],
      [{ label: "My Custom Label" }], // custom label
    ],
  };

  const formats = [
    "header",
    "font",
    "list",
    "bold",
    "italic",
    "underline",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];
  const { control } = useForm();
  return (
    <div className={`${className} relative`}>
      <label
        className="px-2 font-medium absolute left-0 py-2"
        htmlFor="quill-editor"
      >
        {label}
      </label>
      <Controller
        name={"ss"}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <ReactQuill
            className="right-align-toolbar"
            formats={formats}
            modules={modules}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </div>
  );
};

type ItemList = {
  itemHeading: string;
  listArray: any[];
};

const ArrayData = ["Golf", "club", "Tex", "Gg"];

const ItemList = ({ itemHeading, listArray }: ItemList) => {
  return (
    <div>
      <h3>{itemHeading}</h3>
      <ul>
        {listArray.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  );
};

const Sandbox = (props: Props) => {
  return (
    <div>
      Badge Component
      <div>
        <Badge onClick={() => console.log("Badge")} CTA={"Badge"} />
        <Badge onClick={() => console.log("Badge")} CTA={"Badge"} />
        <Badge onClick={() => console.log("Badge")} CTA={"Badge"} />
        <Badge onClick={() => console.log("Badge")} CTA={"Badge"} />
        <div className="grid grid-cols-6 gap-4">
          <SquareCardComponent subText="Live" text="Text" stat={100} />
          <SquareCardComponent subText="Live" text="Text" stat={100} />
          <SquareCardComponent subText="Live" text="Text" stat={100} />
          <SquareCardComponent subText="Live" text="Text" stat={100} />
          <SquareCardComponent subText="Live" text="Text" stat={100} />
          <SquareCardComponent subText="Live" text="Text" stat={100} />
        </div>
        <div className="grid gap-4 grid-cols-6 my-4">
          <TeamMemberCard
            designation="Player"
            email={"email"}
            image=""
            linkedIn=""
            name="Kurt"
          />
          <TeamMemberCard
            designation="Player"
            email={"email"}
            image=""
            linkedIn=""
            name="Kurt"
          />
          <TeamMemberCard
            designation="Player"
            email={"email"}
            image=""
            linkedIn=""
            name="Kurt"
          />
          <TeamMemberCard
            designation="Player"
            email={"email"}
            image=""
            linkedIn=""
            name="Kurt"
          />
          <TeamMemberCard
            designation="Player"
            email={"email"}
            image=""
            linkedIn=""
            name="Kurt"
          />
          <TeamMemberCard
            designation="Player"
            email={"email"}
            image=""
            linkedIn=""
            name="Kurt"
          />
        </div>
        <ListItem
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, hic!"
          details="Aug 18,2022"
        />
        <ListItem
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, hic!"
          details="Aug 18,2022"
        />
        <ListItem
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, hic!"
          details="Aug 18,2022"
        />
        <ListItem
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, hic!"
          details="Aug 18,2022"
        />
        <ListItem
          content="Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto, hic!"
          details="Aug 18,2022"
        />
      </div>
      <DraftEditor className="C" label="DRaft" />
      <div className="grid grid-cols-12">
        <section className="col-span-10"></section>
        <aside className="col-span-2">
          <ItemList itemHeading="Catorie" listArray={ArrayData} />
        </aside>
      </div>
    </div>
  );
};

export default Sandbox;

import React, { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import EditIcon from "../../Components/Icons/EditIcon";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import moment from "moment";
import TableComponent from "../../Components/TableComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  deleteDiscountCode,
  getDiscountCodeList,
  getDiscountResponse,
  getPlans,
  handleSort,
  setPerPage,
} from "../../Redux/features/DiscountSlice";
import { manageSorting } from "../../utils/helper";
import {
  DiscountCodesTable,
  Tag1PageListTableHeader,
} from "../../utils/TableColumns";
import { Status } from "../../Redux/features/UserManagementSlice";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import PaginationComponent from "../../Components/PaginationComponent";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import Sidedrawer from "../../Components/SideDrawer";
import AddNewTagForm from "../../Containers/Tags/AddNewTagForm";
import AddNewDiscountCode from "../../Containers/DiscountCodes/DiscountCodes";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import { notifySuccess } from "../../helper";

type Props = {};

const DiscountCodesList = (props: Props) => {
  const DeleteAPIloading = useAppSelector(
    (state) => state.Discount.deleteDiscountCodeAPIStatus
  );
  const sortType = useAppSelector((state) => state.Discount.sortType);
  const sortValue = useAppSelector((state) => state.Discount.sortValue);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPlans());
  }, []);

  useEffect(() => {
    dispatch(
      getDiscountCodeList({ page: 1, searchValue: "", sortType, sortValue })
    );
  }, [sortType]);

  const getSearchValue = (d: string) => {
    setSearchValue(d);
    // dispatch(UserListAPI({ page: 1, userType: "admin", searchValue: d }));
  };

  const [searchValue, setSearchValue] = useState("");
  const setPerPageValue = (value: { label: string; value: number }) => {
    dispatch(setPerPage(value));
    dispatch(
      getDiscountCodeList({ page: 1, searchValue: "", sortType, sortValue })
    );
  };
  const [showDeleteForm, setDeleteForm] = useState(false);
  const [id, setid] = useState("0");
  const [details, setDetails] = useState<getDiscountResponse | null>(null);
  const handleEditClick = (i: getDiscountResponse) => {
    setAddFormModal(true);
    setid(i._id);
    setDetails(i);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        getDiscountCodeList({
          page: 1,
          searchValue: searchValue,
          sortType,
          sortValue,
        })
      ).then((response) => {});
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const Promo = useAppSelector((state) => state.Discount.promocodes);

  const [showAddForm, setAddFormModal] = useState(false);
  const ConfirmDelete = () => {
    dispatch(deleteDiscountCode(id)).then((res) => {
      if (deleteDiscountCode.fulfilled.match(res)) {
        notifySuccess("Discount Code Deleted");
        setDeleteForm(false);
        dispatch(
          getDiscountCodeList({
            page: 1,
            searchValue: searchValue,
            sortType,
            sortValue,
          })
        );
      }
    });
  };

  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
  };

  const loading = useAppSelector(
    (state) => state.Discount.getDiscountCodesListAPIStatus
  );

  // const [details, setDetails] = useState<getDiscountResponse | null>(null);

  return (
    <>
      <BoxLayout HeaderName="Discount Codes" sectionName="Discount List">
        <FilterSortPanel
          getSearchValue={getSearchValue}
          Function={() => {}}
          sortType={sortType}
          showAddForm={showAddForm}
        >
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[99]">
            <Sidedrawer onClose={setAddFormModal}>
              <AddNewDiscountCode
                details={details}
                setAddFormModal={() => setAddFormModal(false)}
              />
            </Sidedrawer>
          </div>
        </FilterSortPanel>
        <TableComponent
          sortIcon
          loader={loading}
          sortType={sortType}
          sortValue={sortValue}
          handleSortAction={handleSort}
          onClick={manageSorting}
          TableHeaderData={DiscountCodesTable}
        >
          {loading === Status.SUCCESS && (
            <tbody>
              {Promo?.map((i) => (
                <tr
                  key={i._id}
                  className="py-1 text-start border-y border-y-[#EAF0F3]"
                >
                  <td className="py-1 text-start font-medium">{i.code}</td>
                  <td className="py-1 text-start font-medium">{i.quantity}</td>
                  <td className="py-1 text-start font-medium">
                    {i.used_quantity}
                  </td>
                  {/* <td className="py-1 text-start font-medium">{i.plan_name}</td>
                  <td className="py-1 text-start">{i.discount}</td> */}
                  <td className="py-1 text-start">
                    {moment(i.created_at).format("DD-MM-YYYY")}
                  </td>
                  <td className="py-1 text-start items-center flex gap-4 ">
                    <button onClick={() => handleEditClick(i)}>
                      <EditIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {loading === Status.LOADING && <TableSkeleton tableColumns={5} />}
        </TableComponent>
        <PaginationComponent
          total={1}
          paginationFunction={() => {}}
          active={1}
        />
      </BoxLayout>
      <ModalComponent
        confirmAction={ConfirmDelete}
        message={
          <p className="text-[#212121] font-medium text-lg">
            Are you sure you want to delete{" "}
            <span className="text-[#684C70]">{details && details?.code}</span>{" "}
            from Discount Code List?
          </p>
        }
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
        loading={DeleteAPIloading}
      />
    </>
  );
};

export default DiscountCodesList;

import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputComponent from "../../Components/Inputs/InputComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { UserPostAPI } from "../../Redux/features/UserManagementSlice";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { AssignGroupAPI, GetGroupListAPI } from "../../Redux/features/GroupSlice";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import { notifyError } from "../../helper";

type Props = {
  setAddFormModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
  name: string;
  group?: any;
};

const AddUserForm = ({ setAddFormModal }: Props) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), ""], "Passwords must match")
      .required("Confirm Password is required"),
    name: Yup.string()
      .required("Name is required")
      .min(2, "Name must be at least 2 characters"),
  });

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  const formValues = watch();
  const dispatch = useAppDispatch();

  const onSubmit = (data: FormValues) => {
    console.log(data)
    dispatch(
      UserPostAPI({
        data: {
          email: data.email,
          password: data.password,
          name: data.name,
          user_type: "user",
        },
      })
    ).then((res: any) => {
      console.log(res)
      if (res.payload) {
        dispatch(AssignGroupAPI({
          group_id: data.group,
          user_list: [res?.payload?.data?._id]
        })).then((res) => {
          setAddFormModal(false);
        })
      } else {
        notifyError("Something went wrong")
      }
    })
  }

  useEffect(() => {
    dispatch(GetGroupListAPI({}))
  }, [])

  const groupList = useAppSelector(state => state.Group.groupList)

  const loading = useAppSelector(
    (state) => state.UserManagement.UserPostAPIIdle
  );

  return (
    <div className="p-4 flex flex-col h-full ">
      <h2 className="text-xl font-bold mb-4">Add User</h2>
      <form onSubmit={handleSubmit(onSubmit)} className=" mb-4">
        <div className="flex flex-col justify-between h-screen">
          <div className="flex flex-col  gap-3 justify-between">
            <InputComponent
              // errorname={errors.email?.message}
              inputRef="email"
              label="Email"
              name="email"
              register={register}
              value={formValues.email}
            />
            <InputComponent
              // errorname={errors.password?.message}
              inputRef="password"
              label="Password"
              name="password"
              register={register}
              value={formValues.password}
            />
            <InputComponent
              // errorname={errors.confirmPassword?.message}
              inputRef="confirmPassword"
              label="Confirm Password"
              name="confirmPassword"
              register={register}
              value={formValues.confirmPassword}
            />
            <InputComponent
              // errorname={errors.name?.message}
              inputRef="name"
              label="Name"
              name="name"
              register={register}
              value={formValues.name}
            />
            <SelectComponent label="Program" control={control} inputRef="group" options={groupList.map((i) => {
              return {
                label: i.group_name,
                value: i._id
              }
            })} />
            <p className="text-xs text-center">
              After creating user here, user have to verify his email by
              verification link which is sent on provided email to activate
              account
            </p>
          </div>

          <div className="flex h-[150px] gap-4">
            <ButtonComponent
              CTA="Save"
              loading={loading}
              buttonType="submit"
              varient="blue"
            />
            <ButtonComponent
              CTA="Cancel"
              onClick={() => setAddFormModal(false)}
              buttonType="button"
              varient="outline"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddUserForm;

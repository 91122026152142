import React from "react";
import AddIcon from "../Icons/AddIcon";

type Props = {
  children: React.ReactNode;
  showModal?: boolean;
  Function: (data?: any) => void;
};

const AddButtonComponent = ({ children, showModal, Function }: Props) => {
  return (
    <>
      <button
        className="bg-bluegradient rounded-full flex w-8 h-8 justify-center items-center"
        onClick={Function}
      >
        <AddIcon />
      </button>

      {showModal && children}
    </>
  );
};

export default AddButtonComponent;

import Switch from "../Inputs/SwitchComponent";
import { UseFormRegister, FieldValues } from "react-hook-form";
import UserPieChartComponent from "../PieChart/UserPieChartComponent";
import classNames from "classnames";
import { useAppSelector } from "../../Redux/app/hooks";

type Props = {
  data: Array<{ name: string; value: number; color?: string }>;
  register: UseFormRegister<FieldValues>;
  checked: boolean;
  COLORS: string[];
};

const UserDashboardCard = ({ data, register, checked, COLORS }: Props) => {
  const showSideBar = useAppSelector((state) => state.UIController.showSideBar);
  return (
    <div className="w-full flex flex-col justify-center p-2  bg-white rounded-lg ">
      <div className="w-full h-full flex ">
        <div className="flex w-1/2 justify-between  h-full flex-col ">
          <Switch checked={checked} name="checked" register={register} />
          <p className="text-sm my-2 flex font-medium w-full">
            {checked ? "Last Login" : "Subscription Plan"}
          </p>
        </div>
        <div
          className={classNames("w-2/3", {
            "mr-8": showSideBar && checked,
            // "mx-auto": checked,
          })}
        >
          <UserPieChartComponent
            checked={checked}
            colors={COLORS}
            className="w-36 h-36"
            data={data}
          />
        </div>
      </div>
      <div className="flex px-2  gap-1 justify-between items-center">
        {data.map((item, index) => (
          <span
            className={`flex gap-2 h-5 ${
              !checked ? "pb-7" : "pb-7"
            }  justify-center items-baseline`}
          >
            <div
              style={{ background: `${COLORS[index % COLORS.length]}` }}
              className={`w-2 h-2 bg-[${
                COLORS[index % COLORS.length]
              }] rounded-full`}
            ></div>
            <div className="text-[#8B8D97] text-xs ">{item.name}</div>
          </span>
        ))}
      </div>
    </div>
  );
};

export default UserDashboardCard;

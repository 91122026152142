import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSideBar: false,
};

const UIController = createSlice({
  name: "UIController",
  initialState,
  reducers: {
    updateSideBar: (state) => {
      return {
        ...state,
        showSideBar: !state.showSideBar,
      };
    },
  },
});

export const { updateSideBar } = UIController.actions;

export default UIController.reducer;

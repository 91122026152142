import AddGroupPage from "../../Pages/Group/AddGroupPage";
import EditGroupPage from "../../Pages/Group/EditGroupPage";
import GroupList from "../../Pages/Group/GroupList";
import AdminRoutes from "../../auth/AdminRoutes";

export const programRoutes = [
  {
    id: "29",
    name: "Program",
    module: "Program",
    path: "/contentrepository/group",
    component: GroupList,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Program",
        path: "#",
      },
    ],
  },
  {
    id: "30",
    name: "Program",
    module: "Content Repository",
    path: "/contentrepository/addgroup",
    component: AddGroupPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Program",
        path: "/contentrepository/group",
      },
      {
        name: "Add Program",
        path: "#",
      },
    ],
  },
  {
    id: "30",
    name: "Trust",
    module: "Content Repository",
    path: "/contentrepository/editgroup",
    component: EditGroupPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Program",
        path: "/contentrepository/group",
      },
      {
        name: "Edit Program",
        path: "#",
      },
    ],
  },
];

type Props = {};

const DeleteIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.605 0.5C5.08498 0.5 0.60498 4.98 0.60498 10.5C0.60498 16.02 5.08498 20.5 10.605 20.5C16.125 20.5 20.605 16.02 20.605 10.5C20.605 4.98 16.125 0.5 10.605 0.5ZM2.60498 10.5C2.60498 6.08 6.18498 2.5 10.605 2.5C12.455 2.5 14.155 3.13 15.505 4.19L4.29498 15.4C3.23498 14.05 2.60498 12.35 2.60498 10.5ZM10.605 18.5C8.75498 18.5 7.05498 17.87 5.70498 16.81L16.915 5.6C17.975 6.95 18.605 8.65 18.605 10.5C18.605 14.92 15.025 18.5 10.605 18.5Z"
        fill="#D84315"
      />
    </svg>
  );
};

export default DeleteIcon;

import React from "react";
import { Control, Controller } from "react-hook-form";

type Props = {
  control: Control<any, any>;
  inputRef: string;
  id: string;
  label: string;
  value: FileList | string;
  onClick?: Function;
  disabled?: boolean;
};

const UploadFileComponents = ({
  control,
  inputRef,
  id,
  label,
  value,
  disabled = false,
  onClick = () => {},
}: Props) => {
  return (
    <div className="flex  gap-3 items-center">
      <label
        className="flex gap-4   active:scale-95 active:shadow-md transition-transform"
        htmlFor={id}
      >
        <img src="/images/plusButton.svg" alt="" />
        {typeof value !== "string" && value ? (
          <span className="block gap-5 max-w-[250px] overflow-clip text-ellipsis whitespace-nowrap">
            {" "}
            {value[0]?.name}{" "}
          </span>
        ) : (
          <p
            className="block gap-5 max-w-[200px] overflow-clip font-semibold text-[#3c4c76]
          font-blue-950 text-sm text-ellipsis  whitespace-nowrap"
          >
            {label}
          </p>
        )}
      </label>
      <Controller
        name={inputRef}
        control={control}
        render={({ field: { onChange, ref } }) => (
          <input
            disabled={disabled}
            type="file"
            id={id}
            ref={ref}
            className="hidden"
            onChange={(e) => onChange(e.target.files)}
          />
        )}
      />
      <button
        className="text-[#9E9E9E] font-medium text-sm flex justify-center items-center gap-2"
        type="button"
        onClick={() => onClick()}
      >
        <img className="w-6" src="/images/remove.svg" alt="" />
      </button>
    </div>
  );
};

export default UploadFileComponents;

import { SessionAddFormValues } from "../../Pages/Sessions/AddSessionPage";
import { useAppSelector } from "../../Redux/app/hooks";
import { DraggableList } from "../../Components/DraggableList/ObjectDraggableList";
import { Status } from "../../Redux/features/UserManagementSlice";

type Props = {
  ObjectList: any[];
  register: any;
  errors: any;
  formValues: any;
  control: any;
  placeholder: any;
  setObjectList: React.Dispatch<React.SetStateAction<any[]>>;
  moveDrills?: number[];
  setMoveDrills?: React.Dispatch<React.SetStateAction<number[]>>;
};

function SessionObject({
  ObjectList = [],
  setObjectList,
  formValues,
  moveDrills,
  setMoveDrills,
}: Props) {
  const handleInputChange = (
    index: number,
    event: string,
    time: number,
    reps?: number
  ) => {
    console.log(reps);
    console.log(time);
    const values: any = [...ObjectList];
    values[index] = {
      ...values[index],
      object_id: event,
      time: Number.isNaN(time) ? 0 : time,
      reps: reps,
    };
    setObjectList(values);
  };

  const handlePermissionChange = (index: number, event: string) => {
    const values: any = [...ObjectList];
    values[index] = {
      ...values[index],
      permission: event,
    };
    setObjectList(values);
  };

  const handleAddInput = () => {
    setObjectList([
      ...ObjectList,
      { object_id: "", permission: "1", time: 0, reps: 0 },
    ]);
  };

  const handleRemoveInput = (index: any) => {
    const values = [...ObjectList];
    values.splice(index, 1);
    setObjectList(values);
  };
  const objectList = useAppSelector((state) => state.Object.simpleObjectList);
  const loading = useAppSelector(
    (state) => state.Object.SimpleObjectListAPIIdle
  );
  return (
    <div className="border rounded-[12px] ">
      <div className="px-5 py-5 flex gap-5">
        <span className="text-base text-[#212121] font-medium ">
          Select Session Object :
        </span>
        <span className="text-base text-[#9E9E9E]">
          {ObjectList.length} Objects
        </span>
        <span className="flex justify-center gap-2 items-center">
          <img src="/images/plusButton.svg" alt="" />
          <button
            // disabled={ObjectList.length > 9}
            type="button"
            className="text-base text-[#212121] font-medium"
            onClick={handleAddInput}
          >
            Add New Object
          </button>
        </span>
      </div>
      {loading !== Status.LOADING ? (
        <>
          {objectList.length !== 0 && (
            <div className="grid border-t py-4 items-center grid-cols-1 gap-4">
              {ObjectList.length !== 0 ? (
                <>
                  <DraggableList
                    moveDrills={moveDrills}
                    setMoveDrills={setMoveDrills}
                    setObjectList={setObjectList}
                    ObjectList={ObjectList}
                    formValues={formValues}
                    handleInputChange={handleInputChange}
                    handlePermissionChange={handlePermissionChange}
                    handleRemoveInput={handleRemoveInput}
                    objectList={objectList}
                    initialItems={ObjectList}
                  />
                </>
              ) : (
                <div className="h-[80px] col-span-2 text-center text-[#9E9E9E] m-auto w-1/2 flex flex-col">
                  <span className="text-md font-medium ">
                    You haven't chosen “Session Type”
                  </span>
                  <span className="text-sm">
                    You can add choose by clicking the “Session Type “ Tab in
                    Session Deatails
                  </span>
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          {" "}
          <>
            <span className="m-auto loader my-4 w-full flex justify-center"></span>
          </>
        </>
      )}
    </div>
  );
}

export default SessionObject;

import InputComponent from "./Inputs/InputComponent";
import * as Yup from "yup";

import TextAreaComponent from "./Inputs/TextAreaComponent";
import {
  Control,
  FieldArrayWithId,
  FieldErrors,
  UseFieldArrayReturn,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form/dist/types";
import { ObjectFormValues } from "../Pages/Objects/AddObjectsPage";
import { useAppSelector } from "../Redux/app/hooks";
import TextEditor from "./Inputs/TextEditor";
import { useState } from "react";
import { ModalData } from "../types/globle.type";
import ModalComponent from "./PopUp/ModalComponent";
import { notifyError } from "../helper";
import { MetaDataType } from "../Pages/RecallCards/AddRecallCard";
import { AnyObject, ObjectSchema } from "yup";
import { extractTextFromHTML } from "../utils/helper";

type Props = {
  register: UseFormRegister<ObjectFormValues>;
  errors: FieldErrors<ObjectFormValues>;
  formValues: ObjectFormValues;
  control: Control<ObjectFormValues>;
  objectArrayFields: FieldArrayWithId<ObjectFormValues, "link_items", "id">[];
  getValues: UseFormGetValues<ObjectFormValues>;
  setValue: UseFormSetValue<ObjectFormValues>;
  itemSchema: Yup.ObjectSchema<
    {
      link: string;
      title: string;
      description: string | undefined;
    },
    Yup.AnyObject,
    {
      link: undefined;
      title: undefined;
      description: undefined;
    },
    ""
  >;
  update: any;
  append: any;
  remove: any;
};

const ObjectMetaDetails = ({
  register,
  formValues,
  control,
  objectArrayFields,
  getValues,
  setValue,
  itemSchema,
  update,
  append,
  remove,
}: Props) => {
  const objectTypeList = useAppSelector((state) => state.Object.objectTypeList);

  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalData, setModalData] = useState<ModalData>({
    link: "",
    title: "",
    description: "",
  });
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  console.log(formValues);

  const handleModalSave = async (
    fieldArray: FieldArrayWithId<ObjectFormValues, "link_items", "id">[]
  ) => {
    const currentItems = getValues("link_items");
    console.log(currentItems);
    const isUnique = currentItems?.every(
      (item: any, index: any) =>
        item?.link !== modalData?.link || index === editingIndex
    );

    if (!isUnique && currentItems.length < 1) {
      notifyError("Enter Unique Link");
      console.error("Link must be unique");
      return;
    }

    try {
      let htmlString = getValues("description");
      console.log(htmlString);
      if (editingIndex !== null) {
        const originalLink = fieldArray[editingIndex].link;
        const anchorRegex = new RegExp(
          `<a href="${originalLink}".*?>(.*?)</a>`,
          "gi"
        );
        const replacement = `<a href="${modalData.link}">${modalData.title}</a>`; // Adjust this based on how you want the link to be displayed
        htmlString = htmlString.replace(anchorRegex, replacement);

        setValue("description", htmlString);
      }

      // Validate using Yup schema
      await itemSchema.validate(modalData);

      if (editingIndex !== null) {
        update(editingIndex, modalData);
      } else {
        append(modalData);
      }
      setModalOpen(false);
      setModalData({ link: "", title: "", description: "" });
      setEditingIndex(null);
    } catch (validationError) {
      notifyError("Enter Unique Link");
      console.error(validationError);
    }
  };

  const handleEdit = (index: number, fieldArray: MetaDataType[]) => {
    setEditingIndex(index);
    setModalData(fieldArray[index]);
    setModalOpen(true);
    setModalType(modalType);
  };

  const removeUrlAndUpdateHtml = (urlToRemove: string, htmlString: string) => {
    // Find the index of the URL to remove
    console.log(urlToRemove, htmlString, "Hello");
    const indexToRemove = objectArrayFields.findIndex(
      (field) => field.link === urlToRemove
    );

    if (indexToRemove > -1) {
      // Remove the URL from the field array
      remove(indexToRemove);

      // Remove the anchor tag from the HTML string
      const updatedHtmlString = htmlString.replace(
        new RegExp(`<a href="${urlToRemove}".*?>(.*?)</a>`, "gi"),
        "$1"
      );
      // Update the form value
      setValue("description", updatedHtmlString);
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
  };
  let isFileMandatory: any = objectTypeList.filter(
    (cate) => cate._id === formValues.objectType
  )[0]?.name;

  return (
    <>
      <div className="grid grid-cols-12 border-t p-4 gap-4">
        <InputComponent
          inputClassName=""
          className="col-span-6"
          register={register}
          inputRef="Headline"
          label="Headline"
          name="question"
          value={formValues.Headline}
        />
        <InputComponent
          inputClassName=""
          className="col-span-6"
          register={register}
          inputRef="subtitles"
          label="Subtitle"
          name="Headline"
          value={formValues.Headline}
          astric={
            isFileMandatory !== "Video" &&
            isFileMandatory !== "Self Report" &&
            isFileMandatory !== "Self Check Check"
          }
        />
        {(objectTypeList.filter((cate) => cate._id === formValues.objectType)[0]
          ?.name === "Video" ||
          objectTypeList.filter((cate) => cate._id === formValues.objectType)[0]
            ?.name === "Self Check Check" ||
          objectTypeList.filter((cate) => cate._id === formValues.objectType)[0]
            ?.name === "Self Report") && (
          <InputComponent
            className="col-span-12"
            register={register}
            inputRef="video_id"
            label="Vimeo URL"
            name="question"
            value={formValues.Headline}
          />
        )}
        <TextEditor
          control={control}
          className="col-span-12"
          register={register}
          inputRef="description"
          name="subtitles"
          values={formValues.description}
          label="Text"
        />
        {objectArrayFields.map((field, index) => (
          <div className="grid grid-cols-12 col-span-12" key={field.id}>
            <InputComponent
              className="col-span-5"
              inputRef={`link_items.${index}.link`}
              name={`link_items.${index}.link`}
              register={register}
              value={field.link}
              label="Meta Link"
              disabled
            />
            <InputComponent
              className="col-span-5"
              inputRef={`link_items.${index}.title`}
              name={`link_items.${index}.title`}
              register={register}
              value={field.title}
              label="Title"
              disabled
            />
            <button
              className="text-[#9E9E9E] font-medium text-sm flex justify-center items-center gap-2"
              type="button"
              onClick={() => handleEdit(index, objectArrayFields)}
            >
              <img className="h-6" src="/images/editicon.svg" alt="" />
            </button>
            <button
              className="text-[#9E9E9E] font-medium text-sm flex justify-center items-center gap-2"
              type="button"
              onClick={() =>
                removeUrlAndUpdateHtml(field.link, getValues("description"))
              }
            >
              <img className="w-6" src="/images/remove.svg" alt="" />
            </button>
          </div>
        ))}
        <InputComponent
          className="col-span-12"
          inputClassName=""
          register={register}
          inputRef="equipment"
          label="Equipment"
          name="question"
          value={formValues.equipment}
        />
      </div>

      <ModalComponent
        actionCTA="Save"
        isOpen={isModalOpen}
        message="Edit Link Data"
        onClose={() => {
          setModalOpen(false);
          setModalData({ link: "", title: "", description: "" });
        }}
        confirmAction={() => handleModalSave(objectArrayFields)}
      >
        <div className="flex flex-col col-span-8 gap-4">
          <div
            className={`h-[50px]  w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8] `}
          >
            <input
              className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none`}
              name="link"
              value={modalData.link}
              onChange={handleChange}
            />

            <label
              className="absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
              htmlFor={"userName"}
            >
              Link
            </label>
          </div>
          <div
            className={`h-[50px]  w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8] `}
          >
            <input
              className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none `}
              name="title"
              value={modalData.title}
              onChange={handleChange}
            />

            <label
              className="absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
              htmlFor={"title"}
            >
              Title
            </label>
          </div>
          <div
            className={`h-[50px]  w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8] `}
          >
            <textarea
              name="description"
              className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none`}
              placeholder={"Description"}
              value={modalData.description}
              onChange={handleChange}
            />

            <label
              className="absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
              htmlFor={"title"}
            >
              Description
            </label>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

export default ObjectMetaDetails;

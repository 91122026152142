import { useEffect, useState } from "react";
import ObjectDetails from "../../Containers/Objects/ObjectDetails";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import AddObjectTag from "../../Components/AddObjectTag";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import ObjectMetaDetails from "../../Components/ObjectMetaDetails";
import {
  AddObjectAPI,
  CategoryTypeListAPI,
  ObjectTypeListAPI,
} from "../../Redux/features/ObjectSlice";
import { TagListAPI } from "../../Redux/features/TagSlice";
import {
  convertToMMSS,
  convertToMinutes,
  notifyError,
  notifySuccess,
} from "../../helper";
import {
  extractUrlsAndText,
  formatTime,
  updateFieldArray,
} from "../../utils/helper";
import { MetaDataType } from "../RecallCards/AddRecallCard";
import { useIsMount } from "../../CustomHooks/useIsMount";
import { ObjectFormValues } from "./AddObjectsPage";

type Props = {};

// type ObjectFormValues = {
//   objectType: string;
//   categoryType: string;
//   objectName: string;
//   objectTime: string;
//   object_reps: number;
//   is_drill: boolean;
//   thunmbnail: string | FileList;
//   tag: Array<any>;
//   thumbnail: string;
//   Headline: string;
//   video_id: string;
//   subtitles: string;
//   description: string;
//   equipment: string;
//   objectFile: FileList | string;
//   link_items: Array<MetaDataType>;
// };export

const AddObjectsPage = (props: Props) => {
  // Navigation & Other hooks
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  console.log(location.state.object, "location");
  // Local States
  const [mimeType, setMimeType] = useState([
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ]);

  //Redux States
  const loading = useAppSelector((state) => state.Object.AddobjectAPIIdle);
  const tags = useAppSelector((state) => state.Tag.TagList);
  const objectTypeList = useAppSelector((state) => state.Object.objectTypeList);
  const tagSortType = useAppSelector((state) => state.Tag.sortType);

  // ADD OBJECT FORM VALIDATION
  const itemSchema = Yup.object().shape({
    link: Yup.string().required("Link is required"),
    title: Yup.string().required("Title is required"),
    description: Yup.string(),
  });

  const validationSchema = Yup.object().shape({
    objectType: Yup.string().required(),
    categoryType: Yup.string().required(),
    objectName: Yup.string().required(),
    thumbnail: Yup.mixed<string | FileList>().test(
      "is-string-or-filelist",
      "question_image must be a string or a FileList",
      (value) => typeof value === "string" || value instanceof FileList
    ),
    objectTime: Yup.string().matches(
      /^[0-5]?[0-9]:[0-5][0-9]$/,
      "Time must be in the format 'mm:ss'"
    ),
    video_id: Yup.string().test(
      "VimeoID",
      "invalid vimeo URL",
      (value: any) => {
        if (isFileMandatory === "Video") {
          try {
            const url = new URL(value);
            if (url) {
              if (
                url.origin === "https://vimeo.com" &&
                Number(url.pathname.split("/")[1])
              ) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } catch (e) {
            return false;
          }
        } else {
          return true;
        }
      }
    ),
    tag: Yup.array(),
    Headline: Yup.string().required(),
    subtitles: Yup.string().test(
      "subtitle",
      "subtitle is required",
      (value: any) => {
        if (
          isFileMandatory !== "Video" &&
          isFileMandatory !== "Self Report" &&
          isFileMandatory !== "Self Check Check"
        ) {
          console.log(isFileMandatory);
          if (value === "") {
            return false;
          }
          return true;
        } else {
          return true;
        }
      }
    ),
    description: Yup.string().required(),
    equipment: Yup.string().required(),
    is_drill: Yup.boolean().required(),
    object_reps: Yup.number()
      .typeError("Please enter only the number on object reps field.")
      .required(),
    objectFile: Yup.mixed().test(
      "fileType",
      "Unsupported File Format",
      (value: any) =>
        (typeof value === "string" && value !== "") ||
        isFileMandatory === "Text" ||
        isFileMandatory === "Self Report" ||
        isFileMandatory === "Self Check Check" ||
        isFileMandatory === "Quote" ||
        isFileMandatory === "Video"
          ? true
          : value && mimeType.includes(value[0].type)
    ),
  });

  const {
    getValues,
    register,
    watch,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<ObjectFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      link_items: location.state?.link_items,
      objectType: location.state?.object_type_id,
      categoryType: location.state?.category_type_id,
      objectName: location.state?.object_name,
      objectTime: convertToMMSS(location.state?.object_time),
      tag: tags.data.tags
        ?.filter((item) => location.state?.tag?.includes(item._id))
        ?.map((item) => ({ label: item.name, value: item._id })),
      Headline: location.state?.headline,
      subtitles: location.state?.subtitle,
      description: location.state?.textarea,
      equipment: location.state?.equipment,
      objectFile: location.state?.file,
      object_reps: location.state?.reps,
      is_drill: location.state?.is_drill,
      thumbnail: location.state?.thumbnail,
      video_id: `https://vimeo.com/${location.state?.video_id}`,
    },
  });

  useEffect(() => {
    setValue(
      "tag",
      tags.data.tags
        ?.filter((item) => location.state?.tag?.includes(item._id))
        ?.map((item) => ({ label: item.name, value: item._id }))
    );
  }, [tags]);

  const formValues = watch();

  let isFileMandatory: any = objectTypeList.filter(
    (cate) => cate._id === formValues.objectType
  )[0]?.name;

  type DataItem = {
    link: string;
    description: string;
    title: string;
  };

  const validateLinks = (data: DataItem[]): boolean => {
    const linksSet = new Set(data.map((item) => item.link));
    return linksSet.size === data.length;
  };
  const onSubmit = (data: ObjectFormValues) => {
    console.log(data);

    const validationResult = validateLinks(data.link_items);
    if (validationResult) {
      let formData: any = new FormData();
      formData.append("id", location.state._id);
      formData.append("object_type_id", data.objectType);
      formData.append("category_type_id", data.categoryType);
      formData.append("object_name", data.objectName);
      formData.append("headline", data.Headline);
      formData.append("subtitle", data.subtitles);
      formData.append("textarea", data.description);
      formData.append("equipment", data.equipment);
      formData.append("reps", Number(data.object_reps));
      formData.append("link_items", JSON.stringify(data.link_items));
      if (typeof data.thumbnail !== "string") {
        formData.append("thumbnail", data.thumbnail[0]);
      } else if (typeof data.thumbnail === "string" && data.thumbnail === "") {
        formData.append("thumbnail", "");
      } else if (typeof data.thumbnail === "string") {
        formData.append("thumbnail", data.thumbnail);
      }
      formData.append("is_drill", data.is_drill);

      if (
        objectTypeList.filter((cate) => cate._id === formValues.objectType)[0]
          ?.name === "Audio" ||
        objectTypeList.filter((cate) => cate._id === formValues.objectType)[0]
          ?.name === "Image"
      ) {
        formData.append("file", data.objectFile[0]);
      }
      if (
        objectTypeList.filter((cate) => cate._id === formValues.objectType)[0]
          ?.name === "Video" ||
        objectTypeList.filter((cate) => cate._id === formValues.objectType)[0]
          ?.name === "Self Report" ||
        objectTypeList.filter((cate) => cate._id === formValues.objectType)[0]
          ?.name === "Self Check Check"
      ) {
        console.log(data.video_id?.split("/")[3]);
        formData.append("video_id", data.video_id?.split("/")[3]);
      }
      const tags = data.tag.map((tag) => {
        return tag.value;
      });
      formData.append("tag", tags.join(","));
      const time = convertToMinutes(data.objectTime);
      formData.append("object_time", String(time));
      dispatch(AddObjectAPI(formData)).then((res: any) => {
        if (res.payload?.success) {
          notifySuccess(" Object added Successfully");
          navigate("/contentrepository/objects");
        }
      });
    } else {
      notifyError("Enter Unique Link");
    }
  };
  console.log(formValues, "formValues");
  const { append, update, remove, fields } = useFieldArray({
    control,
    name: "link_items",
  });

  // LIFECYCLE OF COMPONENT
  useEffect(() => {
    document.title = "FISIO | Add Object";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue(
      "tag",
      tags.data.tags
        ?.filter((item) => location.state?.tag?.includes(item._id))
        ?.map((item) => ({ label: item.name, value: item._id }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    dispatch(ObjectTypeListAPI());
    dispatch(CategoryTypeListAPI());
    dispatch(TagListAPI({ sortType: tagSortType }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (isFileMandatory) {
      case "Audio":
        setMimeType([
          "audio/mp3",
          "audio/m4a",
          "audio/mpeg",
          "audio/ogg",
          "audio/wav",
          "audio/flac",
          "audio/aac",
        ]);
        break;
      case "Image":
        setMimeType([
          "image/jpeg",
          "image/png",
          "image/bmp",
          "image/gif",
          "image/webp",
          "image/svg+xml",
          "image/heic",
        ]);
        break;
      case "Video":
        setMimeType([
          "video/mp4",
          "video/mpeg",
          "video/ogg",
          "video/webm",
          "video/quicktime",
          "video/x-msvideo", // .avi files
        ]);
        break;

      default:
        break;
    }
    if (!isMount) {
      setValue("objectFile", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.objectType]);
  const isMount = useIsMount();

  useEffect(() => {
    const currentUrls = extractUrlsAndText(formValues.description);
    updateFieldArray(currentUrls, append, fields, update, remove);
  }, [formValues.description]);

  return (
    <BoxLayout HeaderName="Content Repository" sectionName="Edit Object">
      <form
        className="flex flex-col  h-[calc(100vh-6.5rem-143.6px)] justify-between"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="grid p-4 border-t gap-4 grid-cols-12">
          <div className="col-span-8 rounded-[10px] text-base font-semibold border">
            <p className="py-4 px-4">Objects Details</p>
            <ObjectDetails
              setValues={setValue}
              control={control}
              register={register}
              errors={errors}
              objectTypeList={objectTypeList}
              formValues={formValues}
            />
          </div>

          <div className="text-base font-semibold rounded-[10px] col-span-4  border">
            <p className=" py-4 px-4 border-b">Tags</p>
            <AddObjectTag
              control={control}
              register={register}
              errors={errors}
              formValues={formValues}
            />
          </div>

          <div className="rounded-[10px] font-semibold col-span-8  border">
            <p className="py-4 px-4"> Contact Details</p>
            <ObjectMetaDetails
              remove={remove}
              append={append}
              update={update}
              getValues={getValues}
              itemSchema={itemSchema}
              setValue={setValue}
              register={register}
              errors={errors}
              formValues={formValues}
              control={control}
              objectArrayFields={fields}
            />
          </div>

          <div
            className={`rounded-[10px] col-span-4  border ${
              !objectTypeList
                .filter(
                  (category) =>
                    category.name !== "Quote" &&
                    category.name !== "Text" &&
                    category.name !== "Video" &&
                    category.name !== "Self Check Check" &&
                    category.name !== "Self Report"
                )
                .map((category) => category._id)
                .includes(formValues.objectType)
                ? "text-gray-200 opacity-50"
                : "text-black"
            }`}
          >
            <div className="py-2 text-base px-4 font-semibold border-b justify-between items-center w-full flex">
              <p className="py-2">Files</p>
              <label htmlFor="file-input" className="cursor-pointer">
                <span
                  className={`flex justify - center gap - 2 items - center`}
                >
                  <img
                    className={
                      !objectTypeList
                        .filter(
                          (category) =>
                            category.name !== "Quote" &&
                            category.name !== "Text" &&
                            category.name !== "Video" &&
                            category.name !== "Self Check Check" &&
                            category.name !== "Self Report"
                        )
                        .map((category) => category._id)
                        .includes(formValues.objectType)
                        ? "text-gray-200"
                        : "text-black"
                    }
                    src="/images/plusButton.svg"
                    alt=""
                  />
                  Add File
                </span>
              </label>
              <Controller
                control={control}
                name="objectFile"
                render={({ field: { onChange, ref } }) => (
                  <input
                    disabled={
                      !objectTypeList
                        .filter(
                          (category) =>
                            category.name !== "Quote" &&
                            category.name !== "Text"
                        )
                        .map((category) => category._id)
                        .includes(formValues.objectType)
                    }
                    id="file-input"
                    ref={ref}
                    type="file"
                    className="hidden"
                    onChange={(e) => onChange(e.target.files)} // When the file changes, update it in the RHF store
                  />
                )}
              />
            </div>

            <div>
              {!formValues.objectFile ? (
                <div className="place-items-center py-20 text-xs">
                  <p className="text-center">
                    You haven't added any attached files yet.
                  </p>
                  <p className="text-center">
                    You can add a new file by clicking the "Add New File"
                    button.
                  </p>
                </div>
              ) : (
                <p className="text-center py-4 flex items-center justify-between gap-4 px-4">
                  <div className="flex gap-4 items-center">
                    <img
                      className="h-8 bg-opacity-10"
                      alt="alt"
                      src="/images/fileicon.svg"
                    />
                    {typeof formValues?.objectFile !== "string" ? (
                      <p className="w-[150px] whitespace-nowrap block text-ellipsis overflow-hidden ">
                        {formValues?.objectFile[0].name}
                      </p>
                    ) : (
                      <p className="w-[250px] whitespace-nowrap text-ellipsis overflow-hidden">
                        {formValues?.objectFile.split("/")[4]}
                      </p>
                    )}
                  </div>
                  <button
                    onClick={() => setValue("objectFile", "")}
                    type="button"
                    className="px-4"
                  >
                    <img className="w-6" src="/images/remove.svg" alt="" />
                  </button>
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="p-4 border-t col-span-12 grid grid-cols-10">
          <button
            type="button"
            onClick={() => navigate("/contentrepository/objects")}
            className="w-full text-start col-start-1 col-span-1 flex gap-4"
          >
            <img src="/images/arrow-left.svg" alt="" />
            Back
          </button>
          <ButtonComponent
            key={1}
            className="col-span-1  col-end-11"
            CTA="Save"
            varient="orange"
            loading={loading}
          />
        </div>
      </form>
    </BoxLayout>
  );
};

export default AddObjectsPage;

import React from "react";

type Props = {};

const VideoObjectIcon = (props: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.55556 14.5H13.4444C14.3036 14.5 15 13.8036 15 12.9444V2.05556C15 1.19645 14.3036 0.5 13.4444 0.5H2.55556C1.69645 0.5 1 1.19645 1 2.05556V12.9444C1 13.8036 1.69645 14.5 2.55556 14.5ZM2.55556 14.5L11.1111 5.94444L15 9.83333M6.44444 4.77778C6.44444 5.42211 5.92211 5.94444 5.27778 5.94444C4.63345 5.94444 4.11111 5.42211 4.11111 4.77778C4.11111 4.13345 4.63345 3.61111 5.27778 3.61111C5.92211 3.61111 6.44444 4.13345 6.44444 4.77778Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoObjectIcon;

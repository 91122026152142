type Props = {};

const EditIcon = (props: Props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M4.91748 18.4492V19.3626H5.82234L14.7332 10.3681L13.8283 9.45477L4.91748 18.4492Z"
        fill="url(#paint0_linear_321_24000)"
      />
      <path
        d="M20.3692 7.48905C20.7527 7.10187 20.7527 6.47643 20.3692 6.08925L18.0677 3.76617C17.871 3.56762 17.6251 3.47827 17.3694 3.47827C17.1136 3.47827 16.8678 3.57755 16.6809 3.76617L14.881 5.58294L18.5693 9.30581L20.3692 7.48905V7.48905ZM2.95068 17.6252V21.3481H6.63896L17.5169 10.3681L13.8286 6.6452L2.95068 17.6252ZM5.82262 19.3625H4.91776V18.4492L13.8286 9.45473L14.7335 10.3681L5.82262 19.3625Z"
        fill="url(#paint1_linear_321_24000)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_321_24000"
          x1="14.8834"
          y1="20.0231"
          x2="3.127"
          y2="7.27001"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#08415C" />
          <stop offset="1" stopColor="#3F687B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_321_24000"
          x1="20.9279"
          y1="22.5394"
          x2="-0.2759"
          y2="-0.465118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#08415C" />
          <stop offset="1" stopColor="#3F687B" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default EditIcon;

import classNames from "classnames";
import { Link } from "react-router-dom";

type Props = {
  path: string;
  CTA?: string;
  children: React.ReactNode;
  type: "link" | "menu" | "";
};

const HyperLink = ({ path, CTA, children, type }: Props) => {
  return (
    <>
      <Link
        className={classNames(" ", {
          "link ": type === "menu",
          "font-medium hover:underline": type === "link",
        })}
        to={path}
      >
        {CTA}
        {children}
      </Link>
    </>
  );
};

export default HyperLink;

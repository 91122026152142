import AdminListPage from "../../Pages/Users/AdminListPage";
import UserIdDetail from "../../Pages/Users/UserDetails/UserIdDetail";
import UserListPage from "../../Pages/Users/UserListPage";
import AdminRoutes from "../../auth/AdminRoutes";

export const usermanagementRoutes = [
  {
    id: "userManage1",
    name: "Admin List",
    module: "User Management",
    path: "/usermanagement/adminlist",
    component: AdminListPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Admin Management",
        path: "#",
      },
      {
        name: "Admin List",
        path: "/usermanagement/adminlist",
      },
    ],
  },
  {
    id: "userManage2",
    name: "User List",
    module: "User Dashboard",
    path: "/usermanagement/userlist",
    component: UserListPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "User Dashboard",
        path: "#",
      },
    ],
  },
  {
    id: "usermanage3",
    name: "User List",
    module: "User Management",
    path: "/usermanagement/userlist/:userId",
    component: UserIdDetail,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "User Management",
        path: "#",
      },
      {
        name: "User List",
        path: "/usermanagement/userlist",
      },
      {
        name: "User Details",
        path: "#",
      },
    ],
  },
];

import React from "react";
import InputComponent from "./Inputs/InputComponent";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import QuestionsOptions from "../Components/QuestionsOptions";
import * as Yup from "yup";
import SelectComponent from "./Inputs/SelectComponent";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import {
  QuestionListAPI,
  UpdateQuestionAPI,
} from "../Redux/features/OnboardingQuestionsSlice";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "./Buttons/ButtonComponent";
type Props = {
  answerList: string[];
  setAnswerList: React.Dispatch<React.SetStateAction<string[]>>;
  question: string;
  questionType: string;
  question_id: string;
  closeForm: Function;
};
type FormValues = {
  question: string;
  question_type: string;
};

const UpdateQuestionForm = ({
  answerList,
  setAnswerList,
  question,
  questionType,
  question_id,
  closeForm,
}: Props) => {
  const options = [
    { label: "Radio", value: "radio" },
    { label: "Multi Choice", value: "multiple_choice" },
  ];
  const validationSchema = Yup.object().shape({
    question_type: Yup.string(),
    question: Yup.string()
      .required("Question is required")
      .min(6, "Question must be at least 6 characters"),
  });
  // const getSortValue = () => {
  //   dispatch(UserListSort());
  // };

  const {
    register,
    watch,
    handleSubmit,
    setError,
    setValue,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      question: question,
      question_type: questionType,
    },
  });
  const formValues = watch();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const sortType = useAppSelector(
    (state) => state.OnboardingQuestions.sortType
  );
  const onSubmit = (data: FormValues) => {
    let hasNoEmptyString = answerList.every((item) => item !== "");
    if (hasNoEmptyString && answerList.length > 2) {
      dispatch(
        UpdateQuestionAPI({
          text: data.question,
          answers: answerList,
          question_type: data.question_type,
          question_id: question_id,
        })
      ).then((res) => {
        closeForm();
        dispatch(QuestionListAPI({ sortType }));
      });
    } else {
      alert("Please enter");
    }
  };

  const loading = useAppSelector(
    (state) => state.OnboardingQuestions.UpdateQuestionAPIIdle
  );

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
        <InputComponent
          // errorname={errors.question?.message}
          inputRef="question"
          label="Question"
          name="Quiestion"
          register={register}
          value={formValues.question}
        />
        <SelectComponent<FormValues>
          control={control}
          inputRef="question_type"
          placeHolder="Select Question Type"
          options={options}
        />

        <QuestionsOptions
          answerList={answerList}
          setAnswerList={setAnswerList}
        />

        <ButtonComponent loading={loading} CTA="Save" varient="primary" />
      </form>
    </div>
  );
};

export default UpdateQuestionForm;

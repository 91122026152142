
import SearchBoxComponent from "../../Components/Inputs/SearchBoxComponent";
import AddButtonComponent from "../../Components/Buttons/AddButtonComponent";
import SortFilterComponent from "../../Components/SortFilterComponent";

type Props = {
  children?: React.ReactNode;
  Function?: (data: any) => void;
  showAddForm?: boolean;
  Link?: string;
  getSearchValue?: Function;
  getSortValue?: Function;
  sortType?: sortValues;
  exportButton?: React.ReactNode;
  showAddButton?: boolean;
  customClassName?: string;
};

const FilterSortPanel = ({
  children,
  Function = () => { },
  exportButton,
  showAddForm,
  sortType = "ascending",
  getSortValue = () => { },
  getSearchValue = () => { },
  showAddButton = true, // default to true
  customClassName,
}: Props) => {
  return (
    <>
      <div className={`flex p-4 border-y flex-row justify-between items-center ${customClassName}`}>
        <SearchBoxComponent getSearchValue={getSearchValue} />
        <div className="flex items-center flex-row gap-4">
          {showAddButton && (
            <AddButtonComponent Function={Function} showModal={showAddForm}>
              {children}
            </AddButtonComponent>
          )}
          {exportButton}
          {/* <SortFilterComponent
            sortType={sortType}
            getSortValue={getSortValue}
          /> */}
        </div>
      </div>
    </>
  );
};

export default FilterSortPanel;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputComponent from "../../Components/Inputs/InputComponent";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getQuoteAPI,
  postQuoteAPI,
} from "../../Redux/features/TrustShotCheckSlice";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextEditor from "../../Components/Inputs/TextEditor";
import { Status } from "../../Redux/features/UserManagementSlice";
import { notifyError, notifySuccess } from "../../helper";

type Props = {};

interface QuoteForm {
  title: string;
  quote: string;
  quote_by: string;
  shown: boolean;
}

const AddTrustQuote = (props: Props) => {
  const quote = useAppSelector((state) => state.TrustShotCheck.quote);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(),
    quote: Yup.string().required(),
    quote_by: Yup.string().required(),
    // shown: Yup.boolean().required(),
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<QuoteForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      // shown: false
    },
  });

  useEffect(() => {
    setValue("quote", quote?.quote);
    setValue("title", quote?.title);
    setValue("quote_by", quote?.quote_by);
    // setValue("shown", quote?.shown)
  }, [quote]);
  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getQuoteAPI());
  }, []);

  const [loading, setLoading] = useState<Status>(Status.SUCCESS);
  const OnSubmit = (data: any) => {
    setLoading(Status.LOADING);
    dispatch(
      postQuoteAPI({
        id: quote?._id,
        title: data.title,
        quote: data.quote,
        quote_by: data.quote_by,
      })
    ).then((res) => {
      if (res.payload.success) {
        notifySuccess("Text saved successfully");
        setLoading(Status.SUCCESS);
      }
      setLoading(Status.ERROR);
    });
  };

  return (
    <div className="mx-6 gap-3 rounded-2xl border m-2 ">
      <div className="py-2 col-span-12 font-semibold border-b p-4">
        Edit Trust Quote
      </div>
      <form
        onSubmit={handleSubmit(OnSubmit)}
        className="grid grid-cols-2 gap-3 px-4 py-2"
      >
        <InputComponent
          className="col-span-"
          label="Title"
          inputRef="title"
          register={register}
          value=""
          name=""
        />
        {/* <InputComponent className='col-span-4 px-4' label='Quote' inputRef='quote' register={register} value='' name='' /> */}
        <InputComponent
          className=""
          label="Quote By"
          inputRef="quote_by"
          register={register}
          value=""
          name=""
        />
        <TextEditor
          control={control}
          register={register}
          inputRef="quote"
          label="Trust Quote"
          values="Hello"
          className="col-span-2"
          name=""
        />
        <ButtonComponent
          buttonType="submit"
          loading={loading}
          CTA="Save"
          varient="blue"
          className="max-w-[120px]   "
        />
      </form>
    </div>
  );
};

export default AddTrustQuote;

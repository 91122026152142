import React, { Dispatch, SetStateAction, useRef } from "react";
import { useCookies } from "react-cookie";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import { useNavigate } from "react-router-dom";

type Props = {
  showPopUp: boolean;
  setPopUp: Dispatch<SetStateAction<boolean>>;
};

const PopUpComponent = ({ showPopUp, setPopUp }: Props) => {
  const [cookies, setCookies, removeCookie] = useCookies(["token", "ic"]);
  const navigate = useNavigate();

  const handleLogout = () => {
    removeCookie("token", { path: "/" });
    removeCookie("token", { path: "/usermanagement" });
    removeCookie("ic", { path: "/usermanagement" });
    removeCookie("ic", { path: "/" });
    removeCookie("ic", { path: "/coachreview/list" });
    removeCookie("ic", { path: "/coachreview" });
    localStorage.removeItem("user");
    // navigate("/");
  };

  const ModalRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(ModalRef, () => {
    setPopUp(false);
  });

  return (
    <div
      ref={ModalRef}
      className="absolute bg-white top-8 cursor-pointer right-0 mt-4 ml-auto shadow-xl rounded-xl  w-[300px]"
    >
      {showPopUp ? (
        <ul className="p-2 border rounded-xl">
          <li className="font-medium" onClick={() => handleLogout()}>
            Logout
          </li>
        </ul>
      ) : null}
    </div>
  );
};

export default PopUpComponent;

import React, { useRef, useState } from "react";
import InputComponent from "../../Components/Inputs/InputComponent";
import CenterPopUpComponent from "../../Components/PopUp/CenterPopUpComponent";
import FundamentalForm from "./FundamentalForm";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import classNames from "classnames"

type Props = {
  register: any;
  reorder: any;
  fieldArray: any;
  provided: any;
  index: any;
  field: any;
  control: any;
  formValues: any;
  setValue: any;
};

const FundamentalArray = ({
  field,
  formValues,
  setValue,
  fieldArray,
  index,
  provided,
  register,
  reorder,
  control,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleEdit = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };
  const [moveID, setMoveID] = useState(-1)

  const ParaRef = useRef<HTMLParagraphElement>(null)

  useOnClickOutside(ParaRef, () => setMoveID(-1))

  return (
    <>
      <div
        className="px-2 py-2 grid items-center grid-cols-12 gap-4"
        key={field.id}
      >
        <div
          className="flex my-auto mx-4 flex-col items-center justify-center w-10 h-10 border bg-[#EAF0F3] rounded-md  gap-1"
          {...provided.dragHandleProps}
        >
          <span className="block  w-4 h-[2px] bg-[#285A9F]"></span>
          <span className="block  w-4 h-[2px] bg-[#285A9F]"></span>
          <span className="block  w-4 h-[2px] bg-[#285A9F]"></span>
        </div>
        <InputComponent
          inputRef={`micromoves.${index}.title`}
          name={`micromoves.${index}.title`}
          register={register}
          value=""
          label="Title"
          className="col-span-2"
        />
        <InputComponent
          inputRef={`micromoves.${index}.description`}
          name={`micromoves.${index}.description`}
          register={register}
          value=""
          label="Description"
          className="col-span-6"
        />

        <div
          className="flex flex-col text-[#F58124] items-center col-span-2 border-l-2 gap-1  justify-center"
        >
          <button
            type="button"
            onClick={() => handleEdit()}
            className="items-center flex gap-2"
          >

            <img src={"/images/orangeplusbutton.svg"} alt="" />
            <p>Add Steps</p>
          </button>
          <div>{
            <div className="relative">
              <p ref={ParaRef} role="button" onClick={() => setMoveID(index)} className="text-[#1F3161] text-xs cursor-pointer relative">{`${formValues.micromoves[index].steps?.length} Steps Added`}</p>
              {
                <div className={classNames("absolute max-h-[160px] overflow-auto bg-[#EAF0F3] -right-3 rounded-xl z-20 ", {
                  "bottom-4 right-10": (index === formValues.micromoves.length - 1 || (index === formValues.micromoves.length - 2)) && index > 1
                })}>
                  {moveID === index &&
                    formValues.micromoves[index].steps.map((value: any, index: any) => (
                      <div className="  px-4 py-2 text-sm z-20 ">
                        <div className="flex w-full grid-cols-4 text-black font-medium">
                          <p className="col-span-1 px-2">{index + 1}.</p>
                          <p className="col-span-3 min-w-[10rem] max-w-[18rem] text-ellipsis overflow-hidden whitespace-nowrap block">{value.detail}</p>
                          <div>
                            {/* <img className={classNames("w-6 h-6", {

                            })} src="images/vimeolink.svg" alt="" />
                            <img className={classNames("", {

                            })} src="images/image.svg" alt="" /> */}
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              }
            </div>
          }</div>
        </div>
        <div className="flex items-center  border-l-2 gap-8 justify-center">
          <button
            type="button"
            onClick={() => handleEdit()}
            className="flex items-center  gap-4 justify-center"
          >
            <img className="h-6" src="/images/editicon.svg" alt="" />
          </button>
          <button
            type="button"
            onClick={() => fieldArray.remove(index)}
            className="flex justify-center "
          >
            <img src={"/images/remove.svg"} alt="" />
          </button>
        </div>
      </div>
      <CenterPopUpComponent
        isOpen={isModalOpen}
        message="Add Micro Moves"
        onClose={() => handleClose()}
      >
        <FundamentalForm
          setValue={setValue}
          formValue={formValues}
          fieldArray={fieldArray}
          index={index}
          control={control}
          register={register}
          closeForm={() => setIsModalOpen(false)}
        />
      </CenterPopUpComponent>
    </>
  );
};

export default FundamentalArray;

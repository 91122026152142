import React, { useState } from "react";
import {
  UseFormRegister,
  UseFieldArrayReturn,
  UseFormGetValues,
} from "react-hook-form";
import { ModalData } from "../../types/globle.type";
import InputComponent from "../../Components/Inputs/InputComponent";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import { notifyError } from "../../helper";
import { AddRecallCardForm } from "./AddRecallCard";

type Props = {
  itemSchema: any;
  getValues: UseFormGetValues<AddRecallCardForm>;
  register: UseFormRegister<any>;
  questionFieldItems: UseFieldArrayReturn<
    AddRecallCardForm,
    "question_link_items",
    "id"
  >;
  answerFieldItems: UseFieldArrayReturn<
    AddRecallCardForm,
    "answer_link_items",
    "id"
  >;
  setValue: any;
};

const RecallCardMetaDataForm: React.FC<Props> = ({
  itemSchema,
  getValues,
  register,
  answerFieldItems,
  questionFieldItems,
  setValue,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalData, setModalData] = useState<ModalData>({
    link: "",
    title: "",
    description: "",
  });
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const handleModalSave = async (
    inputRef: "question_link_items" | "answer_link_items",
    fieldArray:
      | UseFieldArrayReturn<AddRecallCardForm, "question_link_items", "id">
      | UseFieldArrayReturn<AddRecallCardForm, "answer_link_items", "id">
  ) => {
    const currentItems = getValues(inputRef);
    const isUnique = currentItems?.every(
      (item: any, index: any) =>
        item?.link !== modalData?.link || index === editingIndex
    );

    if (!isUnique) {
      notifyError("Enter Unique Link");
      console.error("Link must be unique");
      return;
    }

    try {
      const htmlStringRef =
        inputRef === "question_link_items" ? "question_text" : "answer_text";
      let htmlString = getValues(htmlStringRef);

      if (editingIndex !== null) {
        const originalLink = fieldArray.fields[editingIndex].link;
        const anchorRegex = new RegExp(
          `<a href="${originalLink}".*?>(.*?)</a>`,
          "gi"
        );
        const replacement = `<a href="${modalData.link}">${modalData.title}</a>`; // Adjust this based on how you want the link to be displayed
        htmlString = htmlString.replace(anchorRegex, replacement);

        setValue(htmlStringRef, htmlString);
      }

      // Validate using Yup schema
      await itemSchema.validate(modalData);

      if (editingIndex !== null) {
        fieldArray.update(editingIndex, modalData);
      } else {
        fieldArray.append(modalData);
      }
      setModalOpen(false);
      setModalData({ link: "", title: "", description: "" });
      setEditingIndex(null);
    } catch (validationError) {
      notifyError("Enter Unique Link");
      console.error(validationError);
    }
  };

  const removeUrlAndUpdateHtml = (
    urlToRemove: string,
    inputRef: string,
    remove: any,
    htmlString: string,
    fields: any[]
  ) => {
    // Find the index of the URL to remove
    console.log(urlToRemove, htmlString, "Hello");
    const indexToRemove = fields.findIndex(
      (field) => field.link === urlToRemove
    );

    if (indexToRemove > -1) {
      // Remove the URL from the field array
      remove(indexToRemove);

      // Remove the anchor tag from the HTML string
      const updatedHtmlString = htmlString.replace(
        new RegExp(`<a href="${urlToRemove}".*?>(.*?)</a>`, "gi"),
        "$1"
      );

      // Update the form value
      setValue(inputRef, updatedHtmlString);
    }
  };

  const handleEdit = (
    index: number,
    fieldArray: any[],
    modalType: "answerLinks" | "questionLinks"
  ) => {
    setEditingIndex(index);
    setModalData(fieldArray[index]);
    setModalOpen(true);
    setModalType(modalType);
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setModalData({ ...modalData, [e.target.name]: e.target.value });
  };

  return (
    <div className="border-2 rounded-lg">
      <div className="px-3 py-4 text-base font-medium flex justify-between">
        <span> Link </span>
        {/* <button
          type="button"
          className="text-primaryFont-dark text-sm"
          onClick={() => setModalOpen(true)}
        >
          + Add Vimio Link
        </button> */}
      </div>
      <div className="flex flex-col gap-3 border-t-2 p-4">
        {questionFieldItems.fields &&
          questionFieldItems?.fields?.map((field: any, index: any) => (
            <div className="grid grid-cols-7 gap-4" key={field.id}>
              <InputComponent
                className="col-span-3"
                label="Link"
                inputRef={`question_link_items.${index}.link`}
                name="Link"
                register={register}
                value={field.link}
                disabled
              />
              <InputComponent
                className="col-span-3"
                label="Link Title"
                inputRef={`question_link_items.${index}.title`}
                name="Link"
                register={register}
                value={field.title}
                disabled
              />
              <span className="flex gap-5">
                <button
                  className="text-[#9E9E9E] font-medium text-sm flex justify-center items-center gap-2"
                  type="button"
                  onClick={() =>
                    handleEdit(
                      index,
                      questionFieldItems.fields,
                      "questionLinks"
                    )
                  }
                >
                  <img className="h-4" src="/images/editicon.svg" alt="" />
                </button>
                <button
                  className="text-[#9E9E9E] font-medium text-sm flex justify-center items-center gap-2"
                  type="button"
                  onClick={() =>
                    removeUrlAndUpdateHtml(
                      field.link,
                      "question_text",
                      questionFieldItems.remove,
                      getValues("question_text"),
                      questionFieldItems.fields
                    )
                  }
                >
                  <img className="w-6" src="/images/remove.svg" alt="" />
                </button>
              </span>
            </div>
          ))}

        {answerFieldItems.fields &&
          answerFieldItems?.fields?.map((field: any, index: any) => (
            <div className="grid grid-cols-7 gap-4" key={field.id}>
              <InputComponent
                className="col-span-3"
                label="Link"
                inputRef={`answer_link_items.${index}.link`}
                name="Link"
                register={register}
                value={field.link}
                disabled
              />
              <InputComponent
                className="col-span-3"
                label="Link Title"
                inputRef={`answer_link_items.${index}.title`}
                name="Link"
                register={register}
                value={field.title}
                disabled
              />
              <span className="flex gap-5">
                <button
                  className="text-[#9E9E9E] font-medium text-sm flex justify-center items-center gap-2"
                  type="button"
                  onClick={() =>
                    handleEdit(index, answerFieldItems.fields, "answerLinks")
                  }
                >
                  <img className="h-4" src="/images/editicon.svg" alt="" />
                </button>
                <button
                  className="text-[#9E9E9E] font-medium text-sm flex justify-center items-center gap-2"
                  type="button"
                  onClick={() =>
                    removeUrlAndUpdateHtml(
                      field.link,
                      "answer_text",
                      answerFieldItems.remove,
                      getValues("answer_text"),
                      answerFieldItems.fields
                    )
                  }
                >
                  <img className="w-6" src="/images/remove.svg" alt="" />
                </button>
              </span>
            </div>
          ))}

        {/* <span className='text-sm font-semibold text-center'>No Meta Links</span> */}
      </div>

      <ModalComponent
        actionCTA="Save"
        isOpen={isModalOpen}
        message="Edit Link Data"
        onClose={() => {
          setModalOpen(false);
          setModalData({ link: "", title: "", description: "" });
        }}
        confirmAction={() =>
          handleModalSave(
            modalType === "questionLinks"
              ? "question_link_items"
              : "answer_link_items",
            modalType === "questionLinks"
              ? questionFieldItems
              : answerFieldItems
          )
        }
      >
        <div className="flex flex-col col-span-8 gap-4">
          <div
            className={`h-[50px]  w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8] `}
          >
            <input
              className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none`}
              name="link"
              value={modalData.link}
              onChange={handleChange}
            />

            <label
              className="absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
              htmlFor={"userName"}
            >
              Link
            </label>
          </div>
          <div
            className={`h-[50px]  w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8] `}
          >
            <input
              className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none `}
              name="title"
              value={modalData.title}
              onChange={handleChange}
            />

            <label
              className="absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
              htmlFor={"title"}
            >
              Title
            </label>
          </div>
          <div
            className={`h-[50px]  w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8] `}
          >
            <textarea
              name="description"
              className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none`}
              placeholder={"Description"}
              value={modalData.description}
              onChange={handleChange}
            />

            <label
              className="absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
              htmlFor={"title"}
            >
              Description
            </label>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default RecallCardMetaDataForm;

import Select, {
  ActionMeta,
  GroupBase,
  OptionsOrGroups,
  PropsValue,
  SingleValue,
  StylesConfig,
} from "react-select";

// Define a generic type for the option structure
export interface OptionType<T> {
  label: string;
  value: T;
}

// Use generics to make the component adaptable to various data types for value
type Props<T> = {
  customStyles: StylesConfig<OptionType<T>, false>;
  onChange:
    | ((
        newValue: SingleValue<OptionType<T>>,
        actionMeta: ActionMeta<OptionType<T>>
      ) => void)
    | undefined;
  options: OptionsOrGroups<OptionType<T>, GroupBase<OptionType<T>>>;
  selectedOption: PropsValue<OptionType<T>>;
};

// Ensure you pass the generic type parameter when using Props
const SelectInput = <T,>({
  onChange,
  options,
  selectedOption,
  customStyles,
}: Props<T>) => {
  return (
    <Select<OptionType<T>>
      styles={customStyles}
      className="mx-8"
      value={selectedOption}
      onChange={onChange}
      options={options}
      menuPlacement="top"
      isSearchable={false}
    />
  );
};

export default SelectInput;

import React, {
    useRef,
    useCallback,
} from "react";
import DeleteIcon from "../Icons/DeleteIcon";
import moment from "moment";
import EditIcon from "../Icons/EditIcon";
import { useAppDispatch } from "../../Redux/app/hooks";
import { setQuestionOrder } from "../../Redux/features/OnboardingQuestionsSlice";
import { useNavigate } from "react-router-dom";

interface DraggableListProps {
    sessionList: any,
    UnitObjectList: any,
    handleInputChange: any,
    handlePermissionChange: any,
    handleRemoveInput: any
    formValues: any
    setObjectList: any
    handleDelete: any
}

export const QuestionDraggableList: React.FC<DraggableListProps> = ({ setObjectList, handleDelete, sessionList, UnitObjectList, handleInputChange, handlePermissionChange, handleRemoveInput, formValues }) => {

    const dragItem = useRef<number | null>(null);

    const handleDragStart = useCallback((index: number) => {
        dragItem.current = index;
    }, []);

    const handleDragEnter = useCallback(
        (index: number) => {
            if (dragItem.current !== null && dragItem.current !== index) {
                const newItems = [...UnitObjectList];
                const removedItem = newItems.splice(dragItem.current, 1)[0];
                newItems.splice(index, 0, removedItem);
                dragItem.current = index;
                setObjectList(newItems);
            }
        },
        [UnitObjectList]
    );

    const dispatch = useAppDispatch()
    const handleDragEnd = useCallback(() => {
        dragItem.current = null;
        dispatch(setQuestionOrder(UnitObjectList.map((item: any) => {
            return { question_id: item._id }
        })))

    }, [UnitObjectList]);
    const navigate = useNavigate()
    const ListItems = () => (
        <>
            {UnitObjectList.map((i: any, index: any) => (
                <tr key={i._id}>
                    <td className="py-1 text-start"> <span className="w-[450px] text-ellipsis whitespace-nowrap overflow-hidden block">{i.text}</span> </td>
                    <td className="py-1 text-start">{i.question_type === "radio" ? "radio" : i.question_type === "multiple_choice" ? "multiple choice" : "Open Textbox"}</td>
                    <td className="py-1 text-start">{i.can_skip ? "Optional" : "Required"}</td>

                    <td className="py-1 text-start">
                        {moment(i.updated_at).format("DD-MM-YYYY")}
                    </td>
                    <td className="py-1 flex gap-3 text-start">
                        <button onClick={() => handleDelete(i._id, i.question_type)}>
                            <DeleteIcon />
                        </button>
                        <button
                            onClick={() => {
                                navigate(
                                    "/onboardingquestionsrepository/editonboardquestions",
                                    {
                                        state: {
                                            question_id: i._id,
                                            answerList: i.answers,
                                            question: i.text,
                                            questionType: i.question_type,
                                            can_skip: i.can_skip,
                                            subtitle: i.subtitle
                                        },
                                    }
                                );
                            }}
                        >
                            <EditIcon />
                        </button>
                    </td>
                    <td>
                        <div
                            draggable
                            onDragStart={() => handleDragStart(index)}
                            onDragEnter={() => handleDragEnter(index)}
                            onDragEnd={handleDragEnd} className="flex flex-col cursor-pointer h-fit justify-center py-[10px] w-fit px-[8px] border  rounded-md  gap-1">
                            <span className="block  w-5 h-[2px] bg-orange-600"></span>
                            <span className="block  w-5 h-[2px] bg-orange-600"></span>
                            <span className="block  w-5 h-[2px] bg-orange-600"></span>
                        </div>
                    </td>
                </tr>
            ))}
        </>
    );

    return <>{ListItems()}</>;
};

export default QuestionDraggableList;

import ProgressCard from "../../Components/CardComponents/ProgressCard";
import UserDashboardCard from "../../Components/CardComponents/UserDashboardCard";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useEffect } from "react";
import {
  UserDashboardAPI,
  UserListAPI,
} from "../../Redux/features/UserManagementSlice";
import CoachDashboardCard from "./CoachDashBoardCard";
import {
  changeFilterValue,
  getDashboard,
  getSwingCheckList,
} from "../../Redux/features/CoachReviewSlice";
import classNames from "classnames";

const CoachCardSection = () => {
  const { register, watch } = useForm();
  const checked = watch("checked");
  useEffect(() => {
    dispatch(getDashboard());
  }, []);
  const rating = useAppSelector((state) => state.UserManagement.rating);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(UserDashboardAPI());
  }, []);

  const dashboard = useAppSelector((state) => state.Coach.dashboard);

  const sortValue = useAppSelector((state) => state.Coach.sortValue);
  const sortType = useAppSelector((state) => state.Coach.sortType);

  const filterValue = useAppSelector((state) => state.Coach.filterValue);
  const FilterUserList = (filter: "Pending" | "In Progress" | "Completed") => {
    dispatch(changeFilterValue(filter));
    dispatch(
      getSwingCheckList({
        filterValue: filter,
        sortType: sortType,
        sortValue: sortValue,
        page: 1,
      })
    );
  };

  return (
    <div className={classNames("grid grid-cols-1 md:grid-cols-4  gap-4")}>
      <CoachDashboardCard
        responseTime={dashboard.avg_response_time || 0}
        data={[
          { name: "Pending", value: dashboard?.pending_swing, color: "#ffff" },
          {
            name: "In Progress",
            value: dashboard?.in_progress_swing || 0,
            color: "#FFCC91",
          },
          {
            name: "Completed ",
            value: dashboard?.reviewed_swing,
            color: "#5570F1",
          },
        ]}
        checked={!checked}
        register={register}
      />
      <ProgressCard
        bg="mask2"
        filterIcon={"filtericon9"}
        // Icon={"clock"}
        number={dashboard.pending_swing}
        text="Pending"
        cardName="Pending"
        filterValue={filterValue}
        onClick={() => FilterUserList("Pending")}
      />
      <ProgressCard
        bg="mask3"
        filterIcon={"filtericon8"}
        // Icon={"Reload"}
        filterValue={filterValue}
        number={dashboard.in_progress_swing || 0}
        text="In Progress"
        onClick={() => FilterUserList("In Progress")}
        cardName="In Progress"
      />
      <ProgressCard
        bg="mask1"
        filterValue={filterValue}
        filterIcon={"filtericon7"}
        // Icon={"filterIcon"}
        number={dashboard.reviewed_swing}
        text="Completed"
        cardName="Completed"
        onClick={() => FilterUserList("Completed")}
      />
    </div>
  );
};

export default CoachCardSection;

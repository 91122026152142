import React from "react";

type Props = {};

const NoDataBox = (props: Props) => {
  return (
    <>
      <p className="bg-[#F6f6f6] p-3 min-h-[4rem] justify-center font-semibold flex h-full text-center flex-col gap-3 rounded-lg">
        No Data Available
      </p>
    </>
  );
};

export default NoDataBox;

import AudioObjectIcon from "../../Components/Icons/Object/AudioObjectIcon";
import ImageObjectIcon from "../../Components/Icons/Object/ImageObjectIcon";
import SelfCheckIcon from "../../Components/Icons/Object/SelfCheckIcon";
import SelfReportIcon from "../../Components/Icons/Object/SelfReportIcon";
import QuoteObjectIcon from "../../Components/Icons/Object/QuoteObjectIcon";
import TextObjectIcon from "../../Components/Icons/Object/TextObjectIcon";
import VideoObjectIcon from "../../Components/Icons/Object/VideoObjectIcon";

type Props = {
  objectType: string;
};

const ObjectIcon = ({ objectType }: Props) => {
  return (
    <>
      {objectType ? (
        <div className="bg-gradient-to-l  text-white from-[#F58124] from-[-4.22%] to-[#FFC266] to-[120.23%] inline-block p-2 rounded-lg">
          {objectType === "Image" && <ImageObjectIcon />}
          {objectType === "Video" && <VideoObjectIcon />}
          {objectType === "Audio" && <AudioObjectIcon />}
          {objectType === "Text" && <TextObjectIcon />}
          {objectType === "Quote" && <QuoteObjectIcon />}
          {objectType === "Self Check Check" && <SelfCheckIcon />}
          {objectType === "Self Report" && <SelfReportIcon />}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ObjectIcon;

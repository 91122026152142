import React from "react";

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const OpenEye: React.FC<IconProps> = ({ width = 16, height = 16 }) => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00002 0C4.66669 0 1.82002 2.07333 0.666687 5C1.82002 7.92667 4.66669 10 8.00002 10C11.3334 10 14.18 7.92667 15.3334 5C14.18 2.07333 11.3334 0 8.00002 0ZM8.00002 8.33333C6.16002 8.33333 4.66669 6.84 4.66669 5C4.66669 3.16 6.16002 1.66667 8.00002 1.66667C9.84002 1.66667 11.3334 3.16 11.3334 5C11.3334 6.84 9.84002 8.33333 8.00002 8.33333ZM8.00002 3C6.89335 3 6.00002 3.89333 6.00002 5C6.00002 6.10667 6.89335 7 8.00002 7C9.10669 7 10 6.10667 10 5C10 3.89333 9.10669 3 8.00002 3Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};

export default OpenEye;

import { useState, useEffect } from "react";
import PaginationComponent from "../Components/PaginationComponent";
import TableComponent from "../Components/TableComponent";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import {
  TagListAPI,
  DeleteTagAPI,
  tagListSort,
  handleSort,
} from "../Redux/features/TagSlice";
import { Tag1PageListTableHeader } from "../utils/TableColumns";
import DeleteIcon from "../Components/Icons/DeleteIcon";
import EditIcon from "../Components/Icons/EditIcon";
import ModalComponent from "../Components/PopUp/ModalComponent";
import FilterSortPanel from "../Containers/Layout/FilterSortPanel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import BoxLayout from "../Containers/Layout/BoxLayout";
import moment from "moment";
import Sidedrawer from "../Components/SideDrawer";
import AddNewTagForm from "../Containers/Tags/AddNewTagForm";
import TableSkeleton from "../Skeleton/TableSkeleton";
import UpdateTagForm from "../Containers/Tags/UpdateTagForm";
import { manageSorting } from "../utils/helper";
import { Status } from "../Redux/features/UserManagementSlice";
import { useIsMount } from "../CustomHooks/useIsMount";

type Props = {};

export type TagFormValues = {
  username: string;
  name: string;
};

const TagListPage = (props: Props) => {
  const sortValue = useAppSelector((state) => state.Tag.sortValue);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("name is required"),
  });

  const { setValue } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: { name: "" },
  });

  const [showUpdateForm, setUpdateForm] = useState(false);
  const [showDeleteForm, setDeleteForm] = useState(false);
  const [showAddForm, setAddFormModal] = useState(false);
  const [id, setid] = useState("0");
  const [tagName, settagName] = useState("");
  const dispatch = useAppDispatch();

  const TagList = useAppSelector((state) => state.Tag.TagList.data.tags);
  const sortType = useAppSelector((state) => state.Tag.sortType);
  useEffect(() => {
    dispatch(TagListAPI({ sortType: sortType, sortValue: sortValue }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const handleDeleteClick = (id: string) => {
    setDeleteForm(true);
    setid(id);
  };

  const handleCloseUpdateModel = () => {
    setUpdateForm(false);
  };

  const handleEditClick = (name: string, user_name: string, Id: any) => {
    // setValue("name", name);
    setid(Id);
    setUpdateForm(true);
    settagName(name);
  };

  const ConfirmDelete = () => {
    dispatch(DeleteTagAPI({ id: id })).then((res: any) => {
      if (res.payload?.success) {
        setDeleteForm(false);
        dispatch(TagListAPI({ sortType: sortType }));
      }
    });
  };

  const handleCloseAddFormModal = () => {
    setAddFormModal(false);
  };

  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
  };

  const pageChange = (pageNumber: number) => {
    console.log(pageNumber);
    dispatch(
      TagListAPI({
        page: pageNumber,
        searchValue: tagSearchValue,
        sortType: sortType,
        sortValue: sortValue,
      })
    );
  };

  const active = useAppSelector((state) => state.Tag.page);
  const total = useAppSelector((state) => state.Tag.TagList.data.total_page);
  const loading = useAppSelector((state) => state.Tag.TagListAPIIdle);
  const getSearchValue = (searchString: string) => {
    setTagSearchValue(searchString);
  };
  const [tagSearchValue, setTagSearchValue] = useState<string | undefined>();
  const getSortValue = () => {
    dispatch(tagListSort());
  };

  const isMount = useIsMount();

  useEffect(() => {
    if (!isMount) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(TagListAPI({ searchValue: tagSearchValue }));
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [tagSearchValue]);

  return (
    <>
      <BoxLayout HeaderName="Tag" sectionName="Tag List">
        <FilterSortPanel
          getSortValue={getSortValue}
          getSearchValue={getSearchValue}
          sortType={sortType}
          Function={() => setAddFormModal((prev) => !prev)}
          showAddForm={showAddForm}
        >
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[99]">
            <Sidedrawer onClose={setAddFormModal}>
              <AddNewTagForm setAddFormModal={handleCloseAddFormModal} />
            </Sidedrawer>
          </div>
        </FilterSortPanel>
        <TableComponent
          loader={loading}
          sortType={sortType}
          sortValue={sortValue}
          handleSortAction={handleSort}
          onClick={manageSorting}
          TableHeaderData={Tag1PageListTableHeader}
        >
          {loading === Status.SUCCESS && (
            <tbody>
              {TagList?.map((i) => (
                <tr
                  key={i._id}
                  className="py-1 text-start border-y border-y-[#EAF0F3]"
                >
                  <td className="py-1 text-start font-medium">{i.name}</td>
                  <td className="py-1 text-start font-medium">{i.user_name}</td>
                  <td className="py-1 text-start">
                    {moment(i.created_at).format("DD-MM-YYYY HH:mm")}
                  </td>
                  <td className="py-1 text-start items-center flex gap-4 ">
                    <button
                      onClick={() =>
                        handleEditClick(i.name, i.user_name, i._id)
                      }
                    >
                      <EditIcon />
                    </button>
                    <button onClick={() => handleDeleteClick(i._id)}>
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {loading === Status.LOADING && <TableSkeleton tableColumns={4} />}
        </TableComponent>

        <PaginationComponent
          total={total}
          paginationFunction={pageChange}
          active={active}
        />
      </BoxLayout>
      <ModalComponent
        message={"Are you sure you want to disable this tag?"}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
        confirmAction={ConfirmDelete}
      />
      {showUpdateForm && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[99]">
          <Sidedrawer onClose={handleCloseUpdateModel}>
            <UpdateTagForm
              id={id}
              tagName={tagName}
              handleCloseUpdateModel={handleCloseUpdateModel}
            />
          </Sidedrawer>
        </div>
      )}
    </>
  );
};

export default TagListPage;

import { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import TableComponent from "../../Components/TableComponent";
import { Status } from "../../Redux/features/UserManagementSlice";
import { ReportPageListTableHeader } from "../../utils/TableColumns";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { clearReports, getReports, handleSort } from "../../Redux/features/ReportSlice";
import moment from "moment";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import CenterPopUpComponent from "../../Components/PopUp/CenterPopUpComponent";
import PaginationComponent from "../../Components/PaginationComponent";
import { manageSorting } from "../../utils/helper";
import { ObjectListSort } from "../../Redux/features/ObjectSlice";
import { useIsMount } from "../../CustomHooks/useIsMount";

type Props = {};

const ReportList = (props: Props) => {
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState<Report>();
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [file, setFile] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [adminListValue, setAdminListValue] = useState();

  const sortType = useAppSelector(state => state.Report.sortType)
  const sortValue = useAppSelector(state => state.Report.sortValue)

  const dispatch = useAppDispatch();

  const report = useAppSelector((state) => state.Report.reports);

  useEffect(() => {
    dispatch(getReports({ sortType: sortType, sortValue: sortValue, searchValue: adminListValue, page: 1 }));
    return () => {
      dispatch(clearReports());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const loading = useAppSelector((state) => state.Report.getReportsIdle);
  const total = useAppSelector((state) => state.Report.totalPages);
  const active = useAppSelector((state) => state.Report.activePage);

  const handleEditClick = (i: Report) => {
    setDetails(i);
    setTitle(i.title);
    setDescription(i.description);
    setFile(i.file);
    setDate(i.created_at);
    setIsOpen(true);
  };
  const handlePageination = (page: number) => {
    dispatch(getReports({ sortType: sortType, sortValue: sortValue, searchValue: adminListValue, page: page }));
  };

  const handleImageError = (e: any) => {
    e.target.src = "/images/user.png"; // Set a fallback image
  };

  const totalPage = useAppSelector(state => state.Object.totalPages)
  const IsMount = useIsMount()

  useEffect(() => {
    if (!IsMount) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(getReports({ sortType: sortType, sortValue: sortValue, searchValue: adminListValue, page: 1 }));
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [adminListValue]);

  const getSearchValue = (d: any) => {
    setAdminListValue(d);
  };

  const getSortValue = (d: any) => {
    dispatch(ObjectListSort());
  };


  return (
    <>
      <BoxLayout HeaderName="Report" sectionName="Report List">
        <FilterSortPanel
          sortType={sortType}
          getSortValue={getSortValue}
          getSearchValue={getSearchValue}
          Function={() => { }}
          showAddButton={false} />
        <TableComponent
          sortType={sortType}
          sortValue={sortValue}
          onClick={manageSorting}
          TableHeaderData={ReportPageListTableHeader}
          loader={loading}
          handleSortAction={handleSort}
        >
          {loading === Status.LOADING ? (
            <TableSkeleton tableColumns={5} />
          ) : (
            <></>
          )}
          <tbody className="">
            {loading === Status.SUCCESS ? (
              report.map((i) => (
                <tr className="border-y border-y-[#EAF0F3]" key={i._id}>
                  <td className="py-1 text-start cursor-pointer">{i.title}</td>
                  <td className="py-1 text-start">{i.type}</td>
                  <td className="py-1 text-start">{i.user_name}</td>

                  <td className="py-1 text-start">
                    {moment(i.created_at).format("DD-MM-YYYY HH:mm")}
                  </td>
                  <td className="py-1 flex gap-3 text-start">
                    <button onClick={() => handleEditClick(i)}>
                      <img className="h-7" src={"/images/actionbutton.svg"} />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </TableComponent>
        <PaginationComponent
          active={active}
          paginationFunction={handlePageination}
          total={total}
        />
        <CenterPopUpComponent
          isOpen={isOpen}
          message="Bug Report"
          onClose={() => setIsOpen(false)}
        >
          <div className=" px-4 grid grid-cols-2 h-full gap-5" style={{ minHeight: "300px", maxHeight: "400px" }}>
            <div
              className={`${file === "" ? "col-span-2 " : ""
                }`}
            >
              <div className="grid gap-2">
                <div className="flex justify-between items-center">
                  <p className="text-lg font-semibold ">
                    User Name : {details?.user_name}
                  </p>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-lg font-semibold">
                    Date : {moment(date).format("DD-MM-YYYY")}
                  </p>
                </div>
                <div className="grid gap-2">
                  <p className="text-lg font-medium">{details?.title} : </p>
                  <p className="text-justify max-w-prose">
                    {details?.description}
                  </p>
                </div>
              </div>
            </div>
            {file !== "" ? (
              <div className="border h-[500px] w-[500px] ">
                <img
                  onError={handleImageError}
                  className="h-full"
                  src={file}
                  alt="ss"
                />
              </div>
            ) : (
              <p></p>
            )}
          </div>
        </CenterPopUpComponent>
      </BoxLayout>
    </>
  );
};

export default ReportList;

import React from "react";
import classNames from "classnames";

type Props = {
  children: React.ReactNode;
  name: string;
  headerSection: any;
  className?: string;
  defaultPadding?: boolean;
};

const BoxSection = ({
  children,
  name,
  headerSection,
  className,
  defaultPadding = true,
}: Props) => {
  return (
    <div className={`border rounded-lg ${className}`}>
      <div className="px-4 py-2 font-medium flex justify-between">
        <p>{name}</p>
        {headerSection ? <div>{headerSection}</div> : <></>}
      </div>
      <div
        className={classNames("border-t ", {
          "": defaultPadding === false,
          "px-4 py-2": defaultPadding === true,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default BoxSection;

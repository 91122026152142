import axios from "axios";
import { Cookies } from "react-cookie";
import { notifyError } from "../helper";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
  },
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    console.log(error);
    let res = error.response;
    switch (res.status) {
      case 401:
        const cookies = new Cookies();
        cookies.remove("token");
        cookies.remove("ic");
        window.location.href = "/";
        break;
      case 400:
        notifyError(res.data.message);
        break;
      case 403: // Forbidden
        notifyError("Access Denied");
        break;
      case 404: // Not Found
        notifyError("Resource not found");
        break;
      case 408: // Request Timeout
        notifyError("Request Timeout");
        break;
      case 409: // Conflict
        notifyError("Conflict: " + res.data.message);
        break;
      case 422: // Unprocessable Entity
        notifyError("Unprocessable Entity: " + res.data.message);
        break;
      case 429: // Too Many Requests
        notifyError("Too many requests. Please try again later.");
        break;
      case 500: // Internal Server Error
        notifyError("Internal Server Error");
        break;
      case 502: // Bad Gateway
        notifyError("Bad Gateway");
        break;
      case 503: // Service Unavailable
        notifyError("Service Unavailable");
        break;
      case 504: // Gateway Timeout
        notifyError("Gateway Timeout");
        break;
      default:
        notifyError("An unexpected error occurred");
        break;
    }
    return Promise.reject(error);
  }
);

axiosClient.interceptors.request.use(
  function (request) {
    if (request.url !== "auth/login") {
      let cookie = new Cookies();
      const token = cookie.get("token");
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  function (error) {
    console.log(error);
    return Promise.reject(error);
  }
);

export default axiosClient;

import { useEffect, useState } from "react";
import UnitDetails from "../../Containers/Units/UnitsDetail";
import UnitObjects from "../../Containers/Units/UnitObjects";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { AddUnitAPI } from "../../Redux/features/UnitSlice";
import { sessionListAPI } from "../../Redux/features/SessionSlice";
import { CategoryTypeListAPI } from "../../Redux/features/ObjectSlice";
import { notifyError } from "../../helper";
import { UnitAddFormValues } from "./AddUnitsPage";

type Props = {};

export type UnitSession = {
  object_id: string;
  permission: 0 | 1 | "";
  time: number;
};

const EditUnitsPage = (props: Props) => {
  const location = useLocation();
  const [UnitObjectList, setUnitObjectList] = useState<Array<UnitSession>>([
    ...location.state.session?.map((i: any) => {
      return {
        permission: i.permission,
        object_id: i.id,
        time: i.time,
      };
    }),
  ]);

  const validationSchema = Yup.object().shape({
    unit_name: Yup.string().required("Unit name Necessary"),
    category_type_id: Yup.string().required("Category type Necessary"),
  });

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      unit_name: location.state.unitname,
      category_type_id: location.state.category_type_id,
      order: location.state.order,
    },
  });

  const formValues = watch();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = (data: UnitAddFormValues) => {
    let hasNoEmptyString = UnitObjectList.every(
      (item) => item.object_id !== "" && item.permission !== ""
    );
    if (hasNoEmptyString) {
      dispatch(
        AddUnitAPI({
          category_type_id: data.category_type_id,
          order: data.order,
          unit_name: data.unit_name,
          unit_object: UnitObjectList,
          id: location.state.id,
        })
      ).then(() => {
        navigate("/contentrepository/units");
      });
    }
  };

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    dispatch(sessionListAPI({}));
    dispatch(CategoryTypeListAPI());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loading = useAppSelector((state) => state.Unit.UnitPostAPIIdle);

  useEffect(() => {
    document.title = "FISIO | Edit Units";
  }, []);

  return (
    <BoxLayout HeaderName="Content Repository" sectionName="Edit Unit">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="py-3 p-4">
          <div className="rounded-[10px]  w-full border">
            <div className="py-2 text-base px-4 font-semibold border-b">
              <p className=" py-2">Unit Details</p>
            </div>
            <div className="py-2 text-start">
              <UnitDetails
                control={control}
                register={register}
                errors={errors}
                formValues={formValues}
              />
            </div>
          </div>

          <div className=" py-2 text-start">
            <div className="py-2 text-start">
              <UnitObjects
                UnitObjectList={UnitObjectList}
                setUnitObjectList={setUnitObjectList}
                control={control}
                register={register}
                errors={errors}
                formValues={formValues}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between p-4">
          <button onClick={() => navigate("/contentrepository/units")}>
            Back
          </button>
          <div className="w-[100px]">
            <ButtonComponent
              className="w-[100px]"
              CTA="Save"
              buttonType="submit"
              varient="orange"
              loading={loading}
            />
          </div>
        </div>
      </form>
    </BoxLayout>
  );
};

export default EditUnitsPage;

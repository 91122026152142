import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const SmallSavedIcon: React.FC<IconProps> = ({ width = 12, height = 17 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 17"
      fill="none"
    >
      <path
        d="M11 15.3353L6 11.4464L1 15.3353V2.89088C1 2.47832 1.15051 2.08266 1.41842 1.79094C1.68633 1.49922 2.04969 1.33533 2.42857 1.33533H9.57143C9.95031 1.33533 10.3137 1.49922 10.5816 1.79094C10.8495 2.08266 11 2.47832 11 2.89088V15.3353Z"
        stroke="#F58124"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SmallSavedIcon;

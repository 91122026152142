import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../Redux/app/hooks";
import {
  Status,
  checkAllBoxes,
} from "../../Redux/features/UserManagementSlice";
import React, { ChangeEvent } from "react";

type Props = {
  TableHeaderData?: any[];
  checkBox?: boolean;
  children: React.ReactNode;
  TableClassName?: string;
  loader: Status;
  sortValue?: string;
  sortType?: sortValues;
  onClick?: Function;
  handleSortAction?: ActionCreatorWithPayload<any, string>;
  sortIcon?: boolean;
  staticHeight?: boolean;
  headerPadding?: string;
  heightClass?: string;
  checkBoxFunction?: (event: ChangeEvent<HTMLInputElement>) => void;
};

const TableComponent = ({
  heightClass,
  TableHeaderData = [],
  children,
  TableClassName = "",
  loader,
  sortValue,
  sortType,
  handleSortAction,
  sortIcon,
  checkBox = false,
  staticHeight,
  headerPadding = "",
  checkBoxFunction = (_e) => {},
  onClick = () => {},
}: Props) => {
  const dispatch = useAppDispatch();
  return (
    <div
      className={`  example text-sm  ${
        !staticHeight ? "h-[calc(100vh-67.6px-19rem)] p-2 px-4" : ""
      } ${heightClass} border-b ${
        loader !== Status.LOADING ? "overflow-auto" : "overflow-hidden "
      }`}
    >
      <table
        className={`w-full overflow-x-hidden table-auto px-2 ${TableClassName}`}
      >
        <thead className="">
          <tr>
            {TableHeaderData.map((i) => (
              <>
                <th
                  key={i.id}
                  className={`py-1 ${headerPadding} text-[#212121]  whitespace-nowrap overflow-hidden   ${
                    i.headPosition === "start" ? "text-start" : ""
                  }  ${i.headPosition === "center" ? "text-center" : ""} ${
                    i.headPosition === "end" ? "text-end" : ""
                  }`}
                >
                  <div className="flex gap-2">
                    {i.checkbox ? (
                      <>
                        <input
                          type="checkbox"
                          checked={checkBox}
                          onChange={checkBoxFunction}
                          className="accent-orange-600"
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <span
                      onClick={
                        !i?.sortDisabled
                          ? () =>
                              onClick(
                                sortType,
                                i.value,
                                dispatch,
                                handleSortAction
                              )
                          : () => {}
                      }
                      className={`flex items-center ${
                        !staticHeight ? "" : ""
                      } ${i.headPosition === "start" ? "justify-start" : ""}  ${
                        i.headPosition === "center" ? "justify-center" : ""
                      } ${
                        i.headPosition === "end" ? "justify-end" : ""
                      } gap-1 `}
                    >
                      {i.label}
                      {sortValue === i.value && !sortIcon ? (
                        <span>
                          <img
                            className={`w-5 h-6 ${
                              sortType === "descending" && "rotate-180"
                            } ${sortType === null && "hidden"}`}
                            src="/images/arrow.svg"
                            alt="s"
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                      {sortIcon && !i?.sortDisabled ? (
                        <span className=" rounded bg-[#fffaf2]">
                          <img src="/images/cards/sorticon.svg" />
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                  </div>
                </th>
              </>
            ))}
          </tr>
        </thead>
        {children}
      </table>
    </div>
  );
};

export default React.memo(TableComponent);

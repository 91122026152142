import AddUnitsPage from "../../Pages/Units/AddUnitsPage";
import EditUnitsPage from "../../Pages/Units/EditUnitPage";
import UnitListPage from "../../Pages/Units/UnitsListPage";
import AdminRoutes from "../../auth/AdminRoutes";

export const unitRoutes = [
  {
    id: "unit1",
    name: "Unit List",
    module: "Content Repository",
    path: "/contentrepository/units",
    component: UnitListPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Unit List",
        path: "/contentrepository/units",
      },
    ],
  },
  {
    id: "unit2",
    name: "Add Unit",
    module: "Content Repository",
    path: "/contentrepository/AddUnits",
    component: AddUnitsPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Unit List",
        path: "/contentrepository/units",
      },
      {
        name: "Add Unit",
        path: "/contentrepository/AddUnits",
      },
    ],
  },
  {
    id: "unit3",
    name: "Edit Units",
    module: "Content Repository",
    path: "/contentrepository/editunit",
    component: EditUnitsPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Unit List",
        path: "/contentrepository/units",
      },
      {
        name: "Edit Unit",
        path: "/contentrepository/editunits",
      },
    ],
  },
];

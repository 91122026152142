import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const QuoteIcon: React.FC<IconProps> = ({ width = 28, height = 28 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M7.62906 0C5.8254 0 4.27229 1.27264 3.91773 3.0411L0.161474 21.7766C-0.391936 24.5369 1.71908 27.1131 4.5343 27.1131C6.77854 27.1131 8.6732 25.4455 8.95807 23.2194L11.3836 4.26565C11.6732 2.00289 9.91028 0 7.62906 0ZM23.9311 0C22.1118 0 20.5486 1.29164 20.2056 3.07835L16.6144 21.7871C16.0839 24.5503 18.2015 27.1131 21.0153 27.1131C23.287 27.1131 25.1994 25.4132 25.4656 23.1571L27.6984 4.2381C27.9646 1.98244 26.2024 0 23.9311 0Z"
        fill="url(#paint0_linear_7815_1313)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7815_1313"
          x1="28.6441"
          y1="28.9206"
          x2="-3.21709"
          y2="-8.53149"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F58124" />
          <stop offset="1" stop-color="#FFC266" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default QuoteIcon;

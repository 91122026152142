import AdminRoutes from "../../auth/AdminRoutes";
import FAQ from "../../Pages/Faq/Faq";

export const faqRoutes = [
  {
    id: "FAQ",
    name: "FAQs",
    module: "FAQs",
    path: "/faq",
    component: FAQ,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "FAQs",
        path: "#",
      },
      {
        name: "All Questions ",
        path: "#",
      },
    ],
  },
];

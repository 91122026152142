type Props = {};

const AudioObjectIcon = (props: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 7.13636V8.5C11 9.76581 10.4732 10.9798 9.53553 11.8748C8.59785 12.7699 7.32608 13.2727 6 13.2727M6 13.2727C4.67392 13.2727 3.40215 12.7699 2.46447 11.8748C1.52678 10.9798 1 9.76581 1 8.5V7.13636M6 13.2727V16M3.14286 16H8.85714M6 1C5.43168 1 4.88663 1.2155 4.48477 1.5991C4.08291 1.9827 3.85714 2.50297 3.85714 3.04545V8.5C3.85714 9.04249 4.08291 9.56276 4.48477 9.94636C4.88663 10.33 5.43168 10.5455 6 10.5455C6.56832 10.5455 7.11337 10.33 7.51523 9.94636C7.91709 9.56276 8.14286 9.04249 8.14286 8.5V3.04545C8.14286 2.50297 7.91709 1.9827 7.51523 1.5991C7.11337 1.2155 6.56832 1 6 1Z"
        stroke="white"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AudioObjectIcon;

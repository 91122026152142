import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const SmallFlagIcon: React.FC<IconProps> = ({ width = 14, height = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 14 18"
      fill="none"
    >
      <path
        d="M1 11.5853C1 11.5853 1.75 10.8353 4 10.8353C6.25 10.8353 7.75 12.3353 10 12.3353C12.25 12.3353 13 11.5853 13 11.5853V2.58533C13 2.58533 12.25 3.33533 10 3.33533C7.75 3.33533 6.25 1.83533 4 1.83533C1.75 1.83533 1 2.58533 1 2.58533V11.5853ZM1 11.5853V16.8353"
        stroke="#F58124"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SmallFlagIcon;

import React, { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import InputComponent from "../../Components/Inputs/InputComponent";
import { useForm } from "react-hook-form";
import BoxSection from "../../Components/BoxSection/BoxSection";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { Status, handleSort } from "../../Redux/features/UserManagementSlice";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import PaginationComponent from "../../Components/PaginationComponent";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import { useIsMount } from "../../CustomHooks/useIsMount";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { clearReports, getReports } from "../../Redux/features/ReportSlice";
import { ObjectListSort } from "../../Redux/features/ObjectSlice";
import TableComponent from "../../Components/TableComponent";
import { exportToExcel, manageSorting } from "../../utils/helper";
import {
  AffiliateDownloadListTableHeader,
  ReportPageListTableHeader,
} from "../../utils/TableColumns";
import SearchBoxComponent from "../../Components/Inputs/SearchBoxComponent";
import ExcelIcon from "../../Components/Icons/ExcelIcon";
import { useNavigate } from "react-router-dom";

type Props = {};

interface AffiliateForm {
  affiliateName: string;
  contactName: string;
  contactEmail: string;
  contactMobile: string;
  affiliateLink: string;
  city: string;
  state: string;
  street1: string;
  street2: string;
  zip: string;
}

const AffiliateDetails = (props: Props) => {
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState<Report>();
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [file, setFile] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [adminListValue, setAdminListValue] = useState();

  const sortType = useAppSelector((state) => state.Report.sortType);
  const sortValue = useAppSelector((state) => state.Report.sortValue);

  const dispatch = useAppDispatch();

  const report = useAppSelector((state) => state.Report.reports);

  useEffect(() => {
    dispatch(
      getReports({
        sortType: sortType,
        sortValue: sortValue,
        searchValue: adminListValue,
        page: 1,
      })
    );
    return () => {
      dispatch(clearReports());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const loading = useAppSelector((state) => state.Report.getReportsIdle);
  const total = useAppSelector((state) => state.Report.totalPages);
  const active = useAppSelector((state) => state.Report.activePage);

  const handleEditClick = (i: Report) => {
    setDetails(i);
    setTitle(i.title);
    setDescription(i.description);
    setFile(i.file);
    setDate(i.created_at);
    setIsOpen(true);
  };
  const handlePageination = (page: number) => {
    dispatch(
      getReports({
        sortType: sortType,
        sortValue: sortValue,
        searchValue: adminListValue,
        page: page,
      })
    );
  };

  const handleImageError = (e: any) => {
    e.target.src = "/images/user.png"; // Set a fallback image
  };

  const totalPage = useAppSelector((state) => state.Object.totalPages);
  const IsMount = useIsMount();

  useEffect(() => {
    if (!IsMount) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getReports({
            sortType: sortType,
            sortValue: sortValue,
            searchValue: adminListValue,
            page: 1,
          })
        );
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [adminListValue]);

  const getSearchValue = (d: any) => {
    setAdminListValue(d);
  };

  const getSortValue = (d: any) => {
    dispatch(ObjectListSort());
  };
  const { register } = useForm<AffiliateForm>();

  const navigate = useNavigate()
  return (
    <BoxLayout HeaderName="Affiliate" sectionName="Affiliate Details">
      <div className="grid grid-cols-2 px-4 py-2 gap-4  grid-rows-6">
        <BoxSection
          className="row-span-3"
          headerSection={<></>}
          name="Personal Information"
        >
          <div className="grid grid-cols-2 gap-4">
            <InputComponent
              inputRef="affiliateName"
              label="Affiliate Name"
              name=""
              register={register}
              value=""
            />
            <InputComponent
              inputRef="contactName"
              label="Contact Name"
              name=""
              register={register}
              value=""
            />
            <InputComponent
              inputRef="contactEmail"
              label="Contact Email"
              name=""
              register={register}
              value=""
            />
            <InputComponent
              inputRef="contactMobile"
              label="Contact Mobile"
              name=""
              register={register}
              value=""
            />
            <InputComponent
              className="col-span-2"
              inputRef="affiliateLink"
              label="Affiliate Link"
              name=""
              register={register}
              value=""
            />
          </div>
        </BoxSection>
        <div className="row-span-2 bg-lightbluegradianet rounded-lg grid grid-cols-2">
          <div className="grid grid-cols-2 mx-2  place-content-center justify-center items-center">
            <div className="px-2 border-l-2 flex flex-col border-l-[#5570F1E5]">
              <span className="text- font-medium">Total Downloads</span>
              <span>220</span>
            </div>
            <div className="px-2 border-l-2 flex flex-col border-l-[#5570F1E5]">
              <span className="ext-lg font-medium">Total Amount</span>
              <span>$2020</span>
            </div>
          </div>
          <div></div>
        </div>
        <BoxSection
          className="row-span-3 row-start-4"
          headerSection={<></>}
          name="Billing Information"
        >
          <div className="grid grid-cols-2 gap-4">
            <InputComponent
              inputRef="city"
              label="City"
              name=""
              register={register}
              value=""
            />
            <InputComponent
              inputRef="state"
              label="State"
              name=""
              register={register}
              value=""
            />
            <InputComponent
              label="Street 1"
              inputRef="street1"
              name=""
              register={register}
              value=""
            />
            <InputComponent
              label="Street 2"
              inputRef="street2"
              name=""
              register={register}
              value=""
            />
            <InputComponent
              inputRef="zip"
              name=""
              label="Zip"
              register={register}
              value=""
            />
          </div>
        </BoxSection>
        <BoxSection
          className="row-span-4 row-start-3"
          headerSection={<></>}
          name="Total Downloads"
        >
          <>
            <div className="flex justify-between py-2">
              <SearchBoxComponent getSearchValue={getSearchValue} />
              <button
                onClick={() =>
                  exportToExcel(
                    [1, 2, 3].map((i) => {
                      return {};
                    }),
                    `userid={}downloadlist=${active}`
                  )
                }
              >
                <ExcelIcon />
              </button>
            </div>
            <TableComponent
              sortIcon
              staticHeight
              heightClass="h-[220px] example"
              sortType={sortType}
              sortValue={sortValue}
              onClick={manageSorting}
              TableHeaderData={AffiliateDownloadListTableHeader}
              loader={loading}
              handleSortAction={handleSort}
            >
              {loading === Status.LOADING ? (
                <TableSkeleton tableColumns={5} />
              ) : (
                <></>
              )}
              <tbody className="">
                {loading === Status.SUCCESS ? (
                  [1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
                    <tr className="border-y border-y-[#EAF0F3]" key={i}>
                      <td className="py-1 text-start">User Name</td>
                      <td className="py-1 text-start">2023.11.30 10:30 pm</td>
                      <td className="py-1 text-start">Basic</td>
                    </tr>
                  ))
                ) : (
                  <></>
                )}
              </tbody>
            </TableComponent>
          </>
        </BoxSection>
      </div>
      <div className="flex px-4 justify-between">
        <button onClick={() => navigate("/affiliate")} className="flex items-center text-[#191919] font-semibold gap-2">
          <img src="/images/arrow-left.svg" alt="" /> Back
        </button>
        <div className="w-[150px]">
          <ButtonComponent
            buttonType="submit"
            className="w-[200px]"
            varient="blue"
            CTA="Save"
          />
        </div>
      </div>
    </BoxLayout>
  );
};

export default AffiliateDetails;

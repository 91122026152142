import { useEffect } from 'react'
import SelectComponent from '../../Components/Inputs/SelectComponent'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../Redux/app/hooks'
import { AssignGroupAPI, GetGroupListAPI } from '../../Redux/features/GroupSlice'
import * as Yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import ButtonComponent from '../../Components/Buttons/ButtonComponent'
import { UserListAPI } from '../../Redux/features/UserManagementSlice'
import { DropDownStyles, DropDownStylesV2, DropDownStylesV3, notifySuccess } from '../../helper'
import { useIsMount } from '../../CustomHooks/useIsMount'

type Props = {
    userID?: string,
    groupID: string,
    handleClose: () => void
}

export type AssignGroupForm = {
    group: string
}

const AssignGroupID = ({ userID = "", groupID, handleClose }: Props) => {
    const groupId = useAppSelector(state => state?.UserDashboard?.dashBoardData?.user?.group_id)
    useEffect(() => {
        dispatch(GetGroupListAPI({}))
    }, [])
    const validationSchema = Yup.object().shape({
        group: Yup.string().required().default(groupID)
    })

    const { control, register, handleSubmit, watch, setValue } = useForm<AssignGroupForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            group: groupId
        }
    })
    const groupList = useAppSelector(state => state.Group.groupList)

    const group = watch("group")
    console.log(group)
    const dispatch = useAppDispatch()



    const isMount = useIsMount()

    useEffect(() => {
        // setValue("group", groupID)
        if (!isMount) {
            dispatch(AssignGroupAPI({
                group_id: group,
                user_list: [userID]
            })).then((res: any) => {
                if (res?.payload?.success) {
                    notifySuccess(res.payload.message)
                }
                console.log(res.payload)

            }).catch()
        }
    }, [group])


    const onSubmit = (data: any) => {
        dispatch(AssignGroupAPI({
            group_id: data.group,
            user_list: [userID]
        })).then(() => {
            handleClose()
            dispatch(UserListAPI({ page: 1, userType: "user" }));
        }).catch()
    }

    const loading = useAppSelector(state => state.Group.assignGroupListAPIStatus)

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='p-4 flex flex-col gap-3 '>


            <SelectComponent<AssignGroupForm>
                selectStyle={DropDownStylesV3}
                inputRef="group"

                control={control}
                options={groupList.map((i) => {
                    return {
                        value: i._id,
                        label: i.group_name
                    }
                })} />

        </form>
    )
}

export default AssignGroupID
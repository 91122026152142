import React from "react";
import SearchIcon from "../Icons/SearchIcon";

type Props = {
  getSearchValue: Function;
};

const SearchBoxComponent = ({ getSearchValue }: Props) => {
  return (
    <div className="h-10 px-2 bg-[#FAFAFA] flex items-center gap-2 border rounded-[10px]">
      <SearchIcon />
      <input
        onChange={(e) => getSearchValue(e.target.value)}
        type="search"
        className="outline-none bg-[#FAFAFA] w-full placeholder:text-sm  h-8"
        placeholder="Search"
      />
    </div>
  );
};

export default SearchBoxComponent;

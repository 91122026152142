import { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import TableComponent from "../../Components/TableComponent";
import EditIcon from "../../Components/Icons/EditIcon";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import { RecallCardListTableHeader } from "../../utils/TableColumns";
import Sidedrawer from "../../Components/SideDrawer";
import AddCardForm from "../../Containers/RecallCards/AddCardForm";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  checkAllRecallBoxes,
  deleteRecallCardsAPI,
  getRecallCards,
  handleSort,
  RearrangeRecallCardsAPI,
  RecalladdCheckbox,
  RecallremoveCheckbox,
  unCheckAllRecallBoxes,
} from "../../Redux/features/RecallCardsSlice";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import moment from "moment";
import { manageSorting } from "../../utils/helper";
import PaginationComponent from "../../Components/PaginationComponent";
import { get } from "http";
import { useNavigate } from "react-router-dom";
import { Status } from "../../Redux/features/UserManagementSlice";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { useIsMount } from "../../CustomHooks/useIsMount";
import PreviewPanel from "../Objects/PreviewPanel";
import CenterPopUpComponent from "../../Components/PopUp/CenterPopUpComponent";
import EyeIcon from "../../Components/Icons/EyeIcon";
import PreviewRecallCard from "./PreviewRecallCard";
import Switch from "../../Components/Inputs/FormSwitchComponent";
import classnames from "classnames";
import TableInput from "../../Components/TableInput/TableInput";
import ScreenLoader from "../../Components/LoadingContainer/LoadingContainer";
type Props = {};

const RecallCard = (props: Props) => {
  const loading = useAppSelector(
    (state) => state.RecallCards.postRecallCardsAPIIdle
  );
  const sortType = useAppSelector((state) => state.RecallCards.sortType);
  const sortValue = useAppSelector((state) => state.RecallCards.sortValue);
  const [showDeleteForm, setDeleteForm] = useState(false);
  const [cardId, setCardId] = useState("");

  const handleDeleteClick = (id: string) => {
    setDeleteForm(true);
    setCardId(id);
  };

  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
  };

  const ConfirmDelete = () => {
    dispatch(deleteRecallCardsAPI(cardId)).then((res) => {
      dispatch(getRecallCards({ page: activePage, perPage: perPage.value }));
      setDeleteForm(false);
    });
  };

  const listLoader = useAppSelector((state) => state.Tag.TagListAPIIdle);
  const deleteRecallCardsAPIIdle = useAppSelector(
    (state) => state.RecallCards.deleteRecallCardsAPIIdle
  );
  useEffect(() => {
    document.title = "FISIO | Recall Cards";
  }, []);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      getRecallCards({
        sortType: sortType,
        sortValue: sortValue,
        page: activePage,
        perPage: perPage.value,
      })
    ).then(() => {
      dispatch(unCheckAllRecallBoxes());
    });
  }, [sortType]);

  const recallList = useAppSelector((state) => state.RecallCards.recallCards);

  const [recallListValue, setRecallListValue] = useState();

  const totalPage = useAppSelector((state) => state.RecallCards.totalPages);
  const activePage = useAppSelector((state) => state.RecallCards.activePage);

  const getSearchValue = (d: any) => {
    setRecallListValue(d);
    // dispatch(getRecallCards({ page: 1, userType: "admin", searchValue: d }));
  };
  const IsMount = useIsMount();
  useEffect(() => {
    if (!IsMount) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getRecallCards({
            searchValue: recallListValue,
            perPage: perPage.value,
            page: 1,
          })
        ).then(() => {
          dispatch(unCheckAllRecallBoxes());
        });
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [recallListValue]);

  const pageChange = (pageNumber: number) => {
    console.log(pageNumber);
    dispatch(
      getRecallCards({
        page: pageNumber,
        searchValue: recallListValue,
        sortType: sortType,
        sortValue: sortValue,
        perPage: perPage.value,
      })
    ).then(() => {
      dispatch(unCheckAllRecallBoxes());
    });
  };
  useEffect(() => {
    document.title = "FISIO | List Recall Cards";
  }, []);

  const [preview, setPreview] = useState(false);
  const [previewObject, setPreviewObject] = useState<RecallCard | undefined>();

  const handlePreview = (i: RecallCard) => {
    setPreview(true);
    setPreviewObject(i);
  };
  const [perPage, setPerPage] = useState<{ label: string; value: number }>({
    label: "Show 50",
    value: 50,
  });
  const setPerPageValue = (value: { label: string; value: number }) => {
    setPerPage(value);
    dispatch(
      getRecallCards({
        page: 1,
        searchValue: recallListValue,
        sortType: sortType,
        sortValue: sortValue,
        perPage: value.value,
      })
    ).then(() => {
      dispatch(unCheckAllRecallBoxes());
    });
  };
  const navigate = useNavigate();
  const getRecallCardsAPIIdle = useAppSelector(
    (state) => state.RecallCards.getRecallCardsAPIIdle
  );
  const handleAdd = (value: any) => {
    dispatch(RecalladdCheckbox(value));
  };

  const handleRemove = (value: any) => {
    dispatch(RecallremoveCheckbox(value));
  };
  const selectedRecall = useAppSelector(
    (state) => state.RecallCards.selectedRecallCard
  );
  console.log(selectedRecall);
  const isUpdating = useAppSelector(
    (state) => state.RecallCards.postRecallCardsAPIIdle
  );
  return (
    <>
      {isUpdating === Status.LOADING ? <ScreenLoader /> : <></>}
      <BoxLayout HeaderName="Recall Card" sectionName="Add Recall Card">
        <FilterSortPanel
          getSearchValue={getSearchValue}
          Function={() => navigate("/contentrepository/addrecallcard")}
        >
          <></>
        </FilterSortPanel>
        <TableComponent
          loader={listLoader}
          TableHeaderData={RecallCardListTableHeader}
          onClick={manageSorting}
          sortValue={sortValue}
          sortType={sortType}
          handleSortAction={handleSort}
          sortIcon
          checkBox={selectedRecall.length === recallList.length}
          checkBoxFunction={(e) => {
            if (e.target.checked) {
              console.log("checked");
              console.log(e.target.checked);
              dispatch(checkAllRecallBoxes());
            } else {
              console.log(e.target.checked);
              dispatch(unCheckAllRecallBoxes());
            }
          }}
        >
          <tbody>
            {getRecallCardsAPIIdle === Status.SUCCESS &&
              recallList?.map((data, i) => (
                <tr
                  key={data._id}
                  className="py-1 border-y border-y-[#EAF0F3] text-start font-weight: 500 font-size: 0.75rem"
                >
                  {/* <td className="py-1 text-center pr-1">
                    <input
                      checked={selectedRecall.some(
                        (selected) => selected._id === data._id
                      )}
                      onChange={(e) => {
                        if (e.target.checked) {
                          handleAdd(data);
                        } else {
                          handleRemove(data);
                        }
                      }}
                      type="checkbox"
                      className="accent-orange-600 mr-6"
                    /
                  </td> */}
                  <td className="py-[6px]">
                    <TableInput order={data.order} data={data} />
                  </td>
                  <td className="">
                    <div className="flex justify-start w-full">
                      <Switch
                        // key={data._id}
                        label=""
                        checked={data.show}
                        className={classnames("", {})}
                        // disabled={FreemoderLoading === Status.LOADING}
                        // register={register}
                        name={data._id}
                        onChange={(e) => {
                          // console.log(e);
                          console.log(data._id);
                          dispatch(
                            RearrangeRecallCardsAPI({
                              recall_card_ids: [{ id: data._id }],
                              show: !data.show,
                            })
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td className="py-1 text-start  ">
                    <div className="h-[20px] overflow-hidden w-fit max-w-[250px] block  whitespace-nowrap line-clamp-1  text-ellipsis">
                      {data.title}
                    </div>
                  </td>
                  <td className="py-1 text-start  ">
                    <div className="flex">
                      <div
                        dangerouslySetInnerHTML={{ __html: data.question_text }}
                        className="h-[20px] overflow-hidden w-[250px]  whitespace-nowrap line-clamp-1  text-ellipsis"
                      ></div>
                      {data.question_text.length > 27 ? "..." : ""}
                    </div>
                  </td>
                  <td className="py-1 text-start h-[16px] overflow-hidden ">
                    <div className="flex">
                      <div
                        dangerouslySetInnerHTML={{ __html: data.answer_text }}
                        className="h-[20px] overflow-hidden w-fit max-w-[250px]  whitespace-nowrap line-clamp-1  text-ellipsis"
                      ></div>
                      {data.question_text.length > 27 ? "..." : ""}
                    </div>
                  </td>
                  <td className="py-1 text-start ">
                    {data.tagName ? (
                      <div className=" w-fit flex py-1 flex-row font-medium justify-center text-xs items-center px-4 gap-1  bg-gray-300 rounded-full text-blue-900 whitespace-nowrap overflow-hidden text-ellipsis">
                        {data.tagName}
                      </div>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="py-1 text-start">
                    {moment(data.updated_at).format("DD-MM-YYYY hh:mm")}
                  </td>
                  <td className="py-1 text-start items-center flex gap-4">
                    <button tabIndex={-1} onClick={() => handlePreview(data)}>
                      <EyeIcon />
                    </button>
                    <button
                      tabIndex={-1}
                      onClick={() =>
                        navigate("/contentrepository/editrecallcard", {
                          state: data,
                        })
                      }
                    >
                      <EditIcon />
                    </button>
                    <button
                      tabIndex={-1}
                      onClick={() => handleDeleteClick(data._id)}
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
          {getRecallCardsAPIIdle === Status.LOADING && (
            <TableSkeleton tableColumns={8} />
          )}
        </TableComponent>
        <PaginationComponent
          paginationFunction={pageChange}
          active={activePage}
          total={totalPage}
          setPerPageValue={setPerPageValue}
          perPageValue={perPage}
        />
      </BoxLayout>
      <ModalComponent
        confirmAction={ConfirmDelete}
        message={"Are you sure to delete this Recall Card ?"}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
        loading={deleteRecallCardsAPIIdle}
      />
      <CenterPopUpComponent
        isOpen={preview}
        onClose={() => setPreview(false)}
        message="Object Preview"
      >
        <PreviewRecallCard data={previewObject} />
      </CenterPopUpComponent>
    </>
  );
};

export default RecallCard;

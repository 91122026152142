import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import InputComponent from "../../Components/Inputs/InputComponent";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  ResetPasswordAPI,
  VerifyPasswordLinkAPI,
} from "../../Redux/features/UserAuthSlice";
import { useEffect, useState } from "react";
import { notifyError } from "../../helper";

type FormValues = {
  password: string;
  confirmPassword: string;
};
const ResetPasswordForm = () => {
  const [view, setView] = useState(true);
  const dipatch = useAppDispatch();
  const loading = useAppSelector(
    (state) => state.UserAuth.ResetPasswordAPIIdle
  );
  const location = useParams();

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), ""],
      "Passwords must match"
    ),
  });

  const {
    register,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: { confirmPassword: "", password: "" },
  });

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  const formValues = watch();
  const [expire, setExpire] = useState(false);
  const linkVerify = async () => {
    const res: any = await dipatch(
      VerifyPasswordLinkAPI({
        token: String(location.hashedID),
        email: String(location.email),
      })
    );
    if (!res.payload?.success) {
      setExpire(true);
    }
  };
  useEffect(() => {
    linkVerify();
  }, []);

  const onSubmit = (data: FormValues) => {
    dipatch(
      ResetPasswordAPI({
        token: String(location.hashedID),
        email: String(location.email),
        password: data.password,
        confirm_password: data.confirmPassword,
      })
    )
      .then((res) => {
        if (res.payload) {
          setView(false);
        }
      })
      .catch((err) => {
        setError("confirmPassword", { message: "Email not found" });
      });
  };

  return (
    <div className="md:w-[600px] m-auto h-[calc(100vh-88px)] flex flex-col justify-center items-center">
      {expire ? (
        <>
          <div className="text-center">
            <h1 className="text-2xl font-bold text-[#F58125]">Link Expired</h1>
            <h3 className="text-base my-2 text-[#9E9E9E] font-normal">
              The Password Link has been expired. Please create it again.
            </h3>
            {/* <ButtonComponent
            CTA="Go to Login Page"
            buttonType="button"
            varient="outline"
            onClick={() => navigate("/")}
          /> */}
          </div>
        </>
      ) : (
        <div className="w-full md:w-[420px] m-auto flex flex-col justify-center items-center">
          {view ? (
            <>
              <h1 className="text-2xl font-bold text-[#1F3161]">
                Reset Password
              </h1>
              <h3 className="text-base my-2 text-[#9E9E9E] font-normal">
                Please choose your new password
              </h3>
              <form
                className="flex w-[90%] gap-4 flex-col"
                onSubmit={handleSubmit(onSubmit)}
              >
                <InputComponent
                  register={register}
                  inputRef="password"
                  // errorname={errors.password?.message}
                  label="Password"
                  name="password"
                  type="password"
                  value={formValues.password}
                />

                <InputComponent
                  register={register}
                  inputRef="confirmPassword"
                  // errorname={errors.confirmPassword?.message}
                  label="Confirm Password"
                  name="confirmPassword"
                  value={formValues.confirmPassword}
                  type="password"
                />
                <ButtonComponent
                  loading={loading}
                  CTA="Submit"
                  varient="orange"
                />
              </form>
            </>
          ) : (
            <div className="text-center">
              <h1 className="text-2xl font-bold text-[#1F3161]">
                Password Changed
              </h1>
              <h3 className="text-base my-2 text-[#9E9E9E] font-normal">
                Password Successfully Changed, Please Login Again
              </h3>
              {/* <ButtonComponent
            CTA="Go to Login Page"
            buttonType="button"
            varient="outline"
            onClick={() => navigate("/")}
          /> */}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ResetPasswordForm;

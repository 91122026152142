import BoxLayout from "../../Containers/Layout/BoxLayout";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import { useState, useEffect } from "react";
import TableComponent from "../../Components/TableComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { GroupListTableHeader } from "../../utils/TableColumns";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import EditIcon from "../../Components/Icons/EditIcon";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import { useNavigate } from "react-router";
import {
  // ObjectListAPI,
  // ObjectListSort,
  // clearObject,
  handleSort,
} from "../../Redux/features/GroupSlice";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { manageSorting } from "../../utils/helper";
import {
  DeleteGroupAPI,
  GetGroupListAPI,
} from "../../Redux/features/GroupSlice";
import moment from "moment";
import { notifyError } from "../../helper";
import { Status } from "../../Redux/features/UserManagementSlice";
import { useIsMount } from "../../CustomHooks/useIsMount";
import PaginationComponent from "../../Components/PaginationComponent";

type Props = {};

// The main component for displaying the group list.
const GroupList = (props: Props) => {
  const [groupListValue, setGroupListValue] = useState("");
  const [perPage, setPerPage] = useState<{ label: string; value: number }>({
    label: "Show 50",
    value: 50,
  });
  const setPerPageValue = (value: { label: string; value: number }) => {
    setPerPage(value);
    dispatch(
      GetGroupListAPI({
        page: 1,
        searchValue: adminListValue,
        sortType: sortType,
        sortValue: sortValue,
        // per_page: value.value,
      })
    );
  };
  // Local states for the component
  const [adminListValue, setAdminListValue] = useState<string | undefined>();
  const [showDeleteForm, setShowDeleteForm] = useState<boolean>(false);
  const [id, setId] = useState<string>("0");

  // Hooks for dispatching actions and navigating between routes.
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const totalPage = useAppSelector((state) => state.Group.totalPages);
  // Selectors for getting necessary values from the Redux store.
  const sortType = useAppSelector((state) => state.Group.sortType);
  const sortValue = useAppSelector((state) => state.Group.sortValue);
  // const userList = useAppSelector(state => state.Group.objectlist);
  const loading = useAppSelector((state) => state.Group.getGroupListAPIStatus);
  const Deleteloading = useAppSelector(
    (state) => state.Group.deleteGroupListAPIStatus
  );
  const groupList = useAppSelector((state) => state.Group.groupList);

  // Effect to set the document title.
  useEffect(() => {
    document.title = "FISIO | List of Program";
  }, []);

  const isMount = useIsMount();

  // Effect to dispatch the API call to get the object list.
  useEffect(() => {
    if (!isMount) {
      const delayDebounceFn = setTimeout(() => {
        const fetchObjects = async () => {
          try {
            await dispatch(
              GetGroupListAPI({
                sortType,
                sortValue,
                searchValue: groupListValue,
              })
            );
          } catch (error) {
            console.error("Error fetching object list:", error);
            notifyError("Failed to fetch object list. Please try again."); // Provide feedback to the user.
          }
        };

        fetchObjects();
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [sortType, dispatch, groupListValue, sortValue]);

  // Effect to dispatch the API call to get the group list.
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        await dispatch(
          GetGroupListAPI({
            page: 1,
            sortType: sortType,
            sortValue: sortValue,
          })
        );
      } catch (error) {
        console.error("Error fetching group list:", error);
        notifyError("Failed to fetch group list. Please try again."); // Provide feedback to the user.
      }
    };

    fetchGroups();
  }, [dispatch]);

  // Handler functions for various actions.
  const handleCloseDeleteModal = () => {
    setShowDeleteForm(false);
  };

  const handleDeleteClick = (id: string) => {
    setShowDeleteForm(true);
    setId(id);
  };

  const handleEditClick = (object: Group) => {
    navigate("/contentrepository/editgroup", {
      state: {
        groupName: object.group_name,
        Units: object.group_object,
        id: object._id,
        tag: object.tag,
      },
    });
  };
  const activePage = useAppSelector((state) => state.Object.activePage);

  const handlePage = (page: number) => {
    dispatch(
      GetGroupListAPI({
        sortType: sortType,
        sortValue: sortValue,
        searchValue: adminListValue,
        page: page,
        // per_page: perPage.value,
      })
    );
  };
  const ConfirmDelete = () => {
    dispatch(DeleteGroupAPI(id)).then(() => {
      setShowDeleteForm(false);
      dispatch(GetGroupListAPI({}));
    });
  };

  const getSearchValue = (d: string) => {
    setGroupListValue(d);
    dispatch(GetGroupListAPI({ page: 1, searchValue: d }));
  };
  // Render method.
  return (
    <>
      <BoxLayout HeaderName="Program" sectionName="Program List">
        <FilterSortPanel
          getSearchValue={getSearchValue}
          Function={() => navigate("/contentrepository/addgroup")}
          Link=""
        />
        <TableComponent
          sortIcon
          loader={loading}
          sortType={sortType}
          sortValue={sortValue}
          onClick={manageSorting}
          handleSortAction={handleSort}
          TableHeaderData={GroupListTableHeader}
        >
          {loading === Status.SUCCESS && (
            <tbody>
              {groupList.map((i) => (
                <tr
                  key={i._id}
                  className="font-roboto font-medium leading-16 text-gray-700 tracking-tighter"
                >
                  <td className="py-1 text-start">{i.group_name}</td>
                  <td className="py-1 text-start">
                    {i.tag_name !== "" ? (
                      <div className="flex flex-row font-medium justify-center text-base items-center py-1 px-4 gap-1 w-fit bg-gray-300 rounded-full text-blue-900">
                        {i.tag_name}
                      </div>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td className="py-1 text-start">
                    {moment(i.updated_at).format("DD-MM-yyyy  hh:mm")}
                  </td>
                  <td className="py-1 text-start flex items-center gap-4">
                    <button onClick={() => handleEditClick(i)}>
                      <EditIcon />
                    </button>
                    <button
                      className={`${
                        i.group_name !== "Fisio Foundation™ Program A"
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                      }`}
                      onClick={
                        i.group_name !== "Fisio Foundation™ Program A"
                          ? () => handleDeleteClick(i._id)
                          : () => {}
                      }
                    >
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {loading === Status.LOADING && <TableSkeleton tableColumns={4} />}
        </TableComponent>
        {/* <PaginationComponent
          paginationFunction={handlePage}
          active={activePage}
          total={totalPage}
          setPerPageValue={setPerPageValue}
          perPageValue={perPage}
        /> */}
      </BoxLayout>
      <ModalComponent
        message={"Are you sure to delete this Group?"}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModal}
        confirmAction={ConfirmDelete}
        loading={Deleteloading}
      />
    </>
  );
};

export default GroupList;

import { useEffect } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import CoachDetails from "../../Containers/Coach/CoachDetails";
import { useForm } from "react-hook-form";
import { ObjectFormValues } from "../Objects/AddObjectsPage";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import WatchVideoComp from "./WatchVideoComp";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getRepCheckByID,
  getSwingCheckByID,
} from "../../Redux/features/CoachReviewSlice";
import classNames from "classnames";
import moment from "moment";
import ReactPlayer from "react-player";
import BoxSection from "../../Components/BoxSection/BoxSection";
import RepReview from "./RepReview";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { notifyError } from "../../helper";
type Props = {};

type RepReviewType = {
  videoForRep: FileList;
  vimeo_link?: string;
};

const TicketReviewList = ({}: Props) => {
  const location = useLocation();

  const vimeoUrlRegex = /^(https?:\/\/)?(www\.)?(vimeo.com\/)(\d+)(\S+)?$/;

  const validationSchema = Yup.object().shape({
    vimeo_link: Yup.string()
      .nullable()
      .test(
        "is-vimeo-link-valid",
        "Enter a valid Vimeo link",
        function (value: any) {
          const { videoForRep } = this.parent; // Access sibling field
          if (!value && !videoForRep) {
            return this.createError({
              message: "Either a Vimeo link or a Video is required",
              path: "vimeo_link",
            });
          }
          if (value && !vimeoUrlRegex.test(value)) {
            return this.createError({
              message: "Enter a valid Vimeo link",
              path: "vimeo_link",
            });
          }
          return true;
        }
      ),

    videoForRep: Yup.mixed()
      .nullable()
      .test(
        "is-video-file",
        "Only video files are allowed",
        function (value: any) {
          const { vimeo_link } = this.parent; // Access sibling field
          if (!value && !vimeo_link) {
            return this.createError({
              message: "Either a Vimeo link or a Video is required",
              path: "videoForRep",
            });
          }
          if (value && value[0]?.type && !value[0].type.startsWith("video/")) {
            return this.createError({
              message: "Only video files are allowed",
              path: "videoForRep",
            });
          }
          return true;
        }
      ),
  });

  // Initialize the react-hook-form useForm hook
  const {
    reset,
    watch,
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RepReviewType>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      vimeo_link: location?.state?.coach_review?.vimeo_link,
    },
  });

  console.log(errors, "errors");
  const formValues = watch();
  console.log(formValues.videoForRep);
  const dispatch = useAppDispatch();

  console.log(
    formValues.videoForRep && formValues.videoForRep[0]?.name,
    "formValues"
  );

  console.log(location.state, "Location");
  // useEffect(() => {
  //   dispatch(getSwingCheckDetails(ticketid))
  // }, [])
  const info = useAppSelector((state) => state.Coach.review);

  const data = location.state as SwingVideo;
  useEffect(() => {
    if (data.type === "Rep Review") {
      dispatch(getRepCheckByID({ id: data._id }));
    } else {
      dispatch(getSwingCheckByID({ id: data._id }));
    }
  }, []);
  // Dummy user data
  const userData = [
    { heading: "User Name", value: data?.user_name },
    { heading: "Coach Name", value: data?.coach_name },
    { heading: "Date Received", value: data?.created_at },
    {
      heading: "Status",
      value: data?.status,
    },
  ];
  const userData1 = [
    { heading: "Direction", value: data?.direction },
    { heading: "Curve", value: data?.curve },
    { heading: "Face", value: data?.face },
    { heading: "Contact", value: data.contact },
  ];
  const files = [
    {
      name: "Video2.mp4",
      size: data?.down_the_line_filesize,
      extension: "mp4",
      link: data?.down_the_line,
    },
    {
      name: "Video1.mp4",
      size: data?.face_on_filesize,
      extension: "mp4",
      link: data?.face_on,
    },
  ];
  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  const navigate = useNavigate();
  return (
    <BoxLayout
      HeaderName={data.type}
      sectionName="Tickets"
      subtext={`#${data?.title}`}
    >
      <div className="flex flex-col h-[calc(100vh-10rem-5.475rem)] justify-start">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 border-t p-4 md:p-10">
          {userData.map((data, index) => (
            <div key={index} className="col-span-1">
              <p className="font-bold ">{data.heading}</p>

              <p
                className={classNames({
                  "text-[#767576]":
                    !(data.value === "Pending" && data.heading === "Status") &&
                    !(
                      data.value === "In Progress" && data.heading === "Status"
                    ) &&
                    !(data.value === "Completed" && data.heading === "Status"),
                  "text-sm": true,
                  "bg-[#FFF8E1] px-2 rounded-full text-[#FFC107] w-fit":
                    data.value === "Pending" && data.heading === "Status",
                  "bg-[#D7F1F4] px-2 rounded-full text-[#58BBCF] w-fit":
                    data.value === "In Progress" && data.heading === "Status",
                  "bg-[#B9F6CA] px-2 rounded-full text-[#00C853] w-fit":
                    data.value === "Completed" && data.heading === "Status",
                })}
              >
                {data.heading === "Date Received"
                  ? moment(data.value).format("YYYY.MM.DD hh:mm a")
                  : data.value}
              </p>
            </div>
          ))}
        </div>
        {data.type === "Coach Review" ? (
          <>
            <div className="grid p-4 gap-4 grid-cols-12">
              <div className="rounded-[10px] col-span-12 border">
                <p className="py-4 px-4 font-semibold">User Review</p>
                <div className="grid grid-cols-1 border-t py-4">
                  <div className="grid grid-cols-1 md:grid-cols-12 gap-3">
                    <div className="md:col-span-4 border-r border-[#C6D6DD] px-4 md:px-10">
                      <WatchVideoComp files={files} />
                    </div>
                    <div className="md:col-span-8 flex flex-col justify-center px-10">
                      <div className="grid grid-cols-1 md:grid-cols-4 gap-5">
                        {userData1.map((data, index) => (
                          <div key={index} className="col-span-1">
                            <span className="text-[#1F3161] font-semibold">
                              {data.heading} :{" "}
                            </span>
                            <span>{data.value}</span>
                          </div>
                        ))}
                      </div>
                      <div className="my-3 border-t border-[#C6D6DD]"></div>
                      <div className="my-3 text-start h-[5rem]">
                        {data.description}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <CoachDetails />
          </>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 p-4 gap-4">
            <BoxSection
              headerSection={<></>}
              name="User Video"
              className="overflow-auto"
            >
              <div className="w-[240px]  m-auto">
                <ReactPlayer
                  width="auto"
                  height="auto"
                  url={data.rep_video}
                  controls
                  fileConfig={{
                    attributes: {
                      style: {
                        display: "block",
                        width: "auto",
                        height: "auto",
                      },
                    },
                  }}
                />
              </div>
            </BoxSection>

            <BoxSection
              headerSection={<></>}
              name="Training Details"
              className=""
            >
              <>
                <RepReview
                  setValue={setValue}
                  repstatus={data.status}
                  reset={reset}
                  formValues={formValues}
                  register={register}
                  control={control}
                  handleSubmit={handleSubmit}
                />
              </>
            </BoxSection>
          </div>
        )}
      </div>
    </BoxLayout>
  );
};

export default TicketReviewList;

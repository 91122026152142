type Props = {};

const HomeIcon = (props: Props) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.66683 11.3333V7.33333H8.3335V11.3333H11.6668V6H13.6668L7.00016 0L0.333496 6H2.3335V11.3333H5.66683Z"
        fill="#725278"
      />
    </svg>
  );
};

export default HomeIcon;

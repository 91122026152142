import { useEffect, useRef, useState } from "react";
// import HyperLink from "../../components/Typography/HyperLink/HyperLink";
// import { sidebarRoutes } from "../../utils";
// import { useOnClickOutside } from "../../hooks/useOnClickOutSide";
import classNames from "classnames";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useOnClickOutside } from "../CustomHooks/useOnClickOutside";
import { useAppDispatch, useAppSelector } from "../Redux/app/hooks";
import { sidebarImages } from "../assets/sidebarasset";
import { navbarImages } from "../assets/navbarasset";
import HyperLink from "./HyperLink/HyperLink";
import { sidebarRoutes } from "./utils";
import { useCookies } from "react-cookie";
import { UserDetailsAPI } from "../Redux/features/UserManagementSlice";
import SideBarSection from "../Containers/Sidebar/SideBarSection";
// import { sidebarImages } from "../../../assets/sidebarasset";
// import { useAppSelector } from "../../hooks/useAppSelector";
// import { navbarImages } from "../../../assets/navbarasset";

type Props = {
  sidebarRoutes: any[];
};

const Sidebar = ({ sidebarRoutes }: Props) => {
  // const location = useParams();
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const [showid, setId] = useState("0");
  const [cookies, setCookies, removeCookie] = useCookies(["token", "ic"]);

  const handleLogout = () => {
    removeCookie("token", { path: "/" });
    removeCookie("token", { path: "/usermanagement" });
    removeCookie("ic", { path: "/usermanagement" });
    removeCookie("ic", { path: "/usermanagement" });
    removeCookie("ic", { path: "/coachreview/list" });
    removeCookie("ic", { path: "/coachreview" });
    removeCookie("ic", { path: "/" });
    // navigate("/");
  };
  const location = useLocation();
  const params = useParams();
  console.log(location);
  const sidebarRef = useRef<HTMLUListElement>(null);
  useOnClickOutside(sidebarRef, () => setId("0"));
  const showSidebar = useAppSelector((state) => state.UIController.showSideBar);
  console.log(params);
  useEffect(() => {
    if (id) {
      dispatch(UserDetailsAPI(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const navigate = useNavigate();
  const [cookie] = useCookies(["ic"]);
  const imgSrc = useAppSelector(
    (state) => state.UserDashboard.dashBoardData?.user?.profile_picture
  );
  const userdashboard = useAppSelector(
    (state) => state.UserDashboard.dashBoardData
  );
  console.log(location.pathname);
  return (
    <>
      <div
        className={classNames(
          "m-auto transition z-40 bg-white absolute  top-[70px] md:top-0 overflow-auto example h-[calc(100vh-70px)] md:relative",
          {
            "translate-x-[0px] w-screen  md:w-[270px]": showSidebar,
            "md:-translate-x-[270px] -translate-x-[400px] w-0   ": !showSidebar,
          }
        )}
      >
        {cookie.ic === "1" && (
          <h3 className="mb-5 mt-2">
            <div className="bg-[#EAF0F3] flex items-center gap-4 mx-2 p-3 rounded-lg justify-start text-[16px] font-bold text-[#1F3161]">
              <img
                src={imgSrc !== "" ? imgSrc : "/images/avatar.jpg"}
                className="rounded-full h-10 w-10"
                alt=""
              />
              <div className="flex flex-col">
                <p>The Coach</p>
                <div className="flex items-center gap-2">
                  <p className="font-normal text-[#8B8D97] text-[0.75rem] ">
                    Coach
                  </p>
                </div>
              </div>
            </div>
            <div className="border-b mx-4 block pt-4"></div>
          </h3>
        )}
        {cookie.ic === "0" ? (
          <>
            <li
              onClick={() => navigate("/usermanagement/userlist")}
              className={classNames("mx-6 cursor-pointer  my-2   flex gap-4", {
                "bg-[#EAF0F3] p-3 rounded-xl":
                  location.pathname === "/usermanagement/userlist",
              })}
            >
              <img
                src={
                  location.pathname === "/usermanagement/userlist"
                    ? sidebarImages.userActive
                    : sidebarImages.user
                }
                alt=""
              />
              <span
                className={classNames("text-sm ", {
                  "text-[#1F3161] font-normal":
                    location.pathname === "/usermanagement/userlist",
                  "text-[#767576] font-normal":
                    location.pathname !== "/usermanagement/userlist",
                })}
              >
                User Dashboard
              </span>
            </li>
          </>
        ) : (
          <></>
        )}
        <div className="px-4 py-2">
          {id ? <SideBarSection id={id} /> : <></>}
        </div>
        <ul ref={sidebarRef} className=" flex flex-col gap-4">
          <p className="mx-6  font-medium">System Management</p>
          {sidebarRoutes.map((item: any) => (
            <>
              {item.children.length ? (
                <>
                  <li
                    onClick={() => setId(item.id)}
                    className={classNames(
                      "mx-6  cursor-pointer select-none   flex gap-4",
                      {
                        "bg-[#EAF0F3] p-3 rounded-xl": item.id === showid,
                      }
                    )}
                  >
                    <img
                      src={item.id === showid ? item.activeicon : item.icon}
                      alt=""
                    />
                    <span
                      className={classNames("text-sm ", {
                        "text-[#1F3161] font-normal": item.id === showid,
                        "text-[#767576] font-normal": item.id !== showid,
                      })}
                    >
                      {item.label}
                    </span>
                  </li>
                  {item.id === showid && (
                    <ul className="border-l-[#C6D6DD] flex flex-col gap-3 text-sm ml-10 pl-4 border-l">
                      {item.children.map((i: any) => (
                        <HyperLink type="menu" path={i.path}>
                          <li
                            className={classNames("flex  items-center gap-4", {
                              "text-[#767576]": location.pathname !== i.path,
                              "text-[#1F3161]": location.pathname === i.path,
                            })}
                          >
                            <span
                              className={classNames(
                                "h-1 w-1 rounded-full  inline-block",
                                {
                                  "bg-[#767576]": location.pathname !== i.path,
                                  "bg-bluegradient":
                                    location.pathname === i.path,
                                }
                              )}
                            ></span>{" "}
                            {i.label}
                          </li>
                        </HyperLink>
                      ))}
                    </ul>
                  )}
                </>
              ) : (
                <>
                  <HyperLink type="menu" path={item.path}>
                    <li
                      onClick={() => setId(item.id)}
                      className={classNames("mx-6 flex gap-4", {
                        "bg-[#EAF0F3] p-3 rounded-xl": item.id === showid,
                      })}
                    >
                      <img src={item.icon} alt="" />
                      <span
                        className={classNames("text-sm ", {
                          "text-[#1F3161] font-normal ": item.id === showid,
                          "text-[#767576] font-normal": item.id !== showid,
                        })}
                      >
                        {item.label}
                      </span>
                    </li>
                  </HyperLink>
                </>
              )}
            </>
          ))}
        </ul>
        {
          <button
            type="button"
            onClick={() => handleLogout()}
            className=" border w-[150px] text-center border-[#1fe391] mx-4 my-4 py-3 md:hidden"
          >
            Logout
          </button>
        }
      </div>
    </>
  );
};

export default Sidebar;

import ProgressCard from "../../Components/CardComponents/ProgressCard";
import TotalProgressCard from "../../Components/CardComponents/TotalProgressCard";
import UserDashboardCard from "../../Components/CardComponents/UserDashboardCard";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useEffect, useState } from "react";
import {
  UserDashboardAPI,
  UserListAPI,
  changeFilterValue,
} from "../../Redux/features/UserManagementSlice";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { useIsMount } from "../../CustomHooks/useIsMount";

const CardSection = () => {
  const { register, watch } = useForm();
  const checked = watch("checked");

  const rating = useAppSelector((state) => state.UserManagement.rating);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(UserDashboardAPI());
  }, []);
  // const [filterValue, setFilterValue] = useState<"fourtosevenDayLoginCount"
  //     | "withinthreeDayLoginCount"
  //     | "morethansevenDayLoginCount"
  //     | "weekly_rating_count"
  //     | "session_rating_count"
  //     | "scc_rating_count" | "">("")
  const filterValue = useAppSelector(
    (state) => state.UserManagement.filterValue
  );
  const sortType = useAppSelector(
    (state) => state.UserManagement.userListSortType
  );
  const sortValue = useAppSelector(
    (state) => state.UserManagement.userListSortValue
  );
  const perPage = useAppSelector((state) => state.UserManagement.perPage);
  const FilterUserList = (
    filter:
      | "fourtosevenDayLoginCount"
      | "premium_plan_count"
      | "withinthreeDayLoginCount"
      | "pro_plan_count"
      | "basic_plan_count"
      | "morethansevenDayLoginCount"
      | "weekly_rating_count"
      | "session_rating_count"
      | "free_plan_count"
      | "scc_rating_count"
  ) => {
    dispatch(changeFilterValue(filter));
  };
  const isMount = useIsMount();
  useEffect(() => {
    if (!isMount) {
      dispatch(
        UserListAPI({
          page: 1,
          userType: "user",
          sortType: sortType,
          sortValue: sortValue,
          filter_dashboard: filterValue,
          per_page: perPage.value,
        })
      );
    }
  }, [filterValue]);

  return (
    <div
      className={classNames("grid gap-4", {
        "grid-cols-4": !checked,
        "grid-cols-5": checked,
      })}
    >
      <UserDashboardCard
        COLORS={
          !checked
            ? ["#FFCC91", "#97A5EB", "#5570F1"]
            : ["#FFF5E5", "#FFCC91", "#97A5EB", "#5570F1"]
        }
        data={
          !checked
            ? [
                {
                  name: "Within the 3 Days",
                  value: rating?.data?.login_at?.withinthreeDayLoginCount,
                  color: "#97A5EB",
                },
                {
                  name: "4-7 Days Ago",
                  value: rating?.data.login_at?.fourtosevenDayLoginCount,
                  color: "#FFCC91",
                },
                {
                  name: "7+ Days Ago ",
                  value: rating?.data.login_at?.morethansevenDayLoginCount,
                  color: "#5570F1",
                },
              ]
            : [
                {
                  name: "7 Day Trial",
                  value: rating?.data?.subscription.free_plan_count,
                  color: "#FFF5E5",
                },
                {
                  name: "Basic",
                  value: rating?.data?.subscription.basic_plan_count,
                  color: "#FFCC91",
                },
                {
                  name: "Premium",
                  value: rating?.data?.subscription?.premium_plan_count,
                  color: "#97A5EB",
                },
                {
                  name: "Ultra",
                  value: rating?.data?.subscription?.pro_plan_count,
                  color: "#5570F1",
                },
              ]
        }
        checked={!checked}
        register={register}
      />
      {checked ? (
        <ProgressCard
          cardName={!checked ? "withinthreeDayLoginCount" : "free_plan_count"}
          filterValue={filterValue}
          bg="cards/sevendaytrial"
          filterIcon={"iconfree"}
          number={rating.data.subscription.free_plan_count}
          text={"7 days Trial"}
          onClick={() => FilterUserList("free_plan_count")}
        />
      ) : (
        <></>
      )}
      <ProgressCard
        cardName={!checked ? "withinthreeDayLoginCount" : "basic_plan_count"}
        filterValue={filterValue}
        bg={!checked ? "cards/analyticsweek" : "cards/basicplan"}
        filterIcon={"Icon3"}
        number={
          !checked
            ? rating?.data?.login_at?.withinthreeDayLoginCount
            : rating?.data?.subscription.basic_plan_count
        }
        text={!checked ? "Within the 3 Days" : "Basic"}
        onClick={
          !checked
            ? () => FilterUserList("withinthreeDayLoginCount")
            : () => FilterUserList("basic_plan_count")
        }
      />
      <ProgressCard
        cardName={!checked ? "fourtosevenDayLoginCount" : "premium_plan_count"}
        filterValue={filterValue}
        bg={!checked ? "cards/analyticsweektwo" : "cards/premiumplan"}
        filterIcon={"Icon4"}
        number={
          !checked
            ? rating?.data?.login_at?.fourtosevenDayLoginCount
            : rating?.data?.subscription?.premium_plan_count
        }
        text={!checked ? "4-7 Days Ago" : "Premium"}
        onClick={
          !checked
            ? () => FilterUserList("fourtosevenDayLoginCount")
            : () => FilterUserList("premium_plan_count")
        }
      />
      <ProgressCard
        cardName={!checked ? "morethansevenDayLoginCount" : "pro_plan_count"}
        filterValue={filterValue}
        bg={!checked ? "cards/analyticstwoweeks" : "cards/proplan"}
        filterIcon={"Icon5"}
        number={
          !checked
            ? rating?.data?.login_at?.morethansevenDayLoginCount
            : rating?.data?.subscription.pro_plan_count
        }
        text={!checked ? "7+ Days Ago " : "Pro"}
        onClick={
          !checked
            ? () => FilterUserList("morethansevenDayLoginCount")
            : () => FilterUserList("pro_plan_count")
        }
      />
    </div>
  );
};

export default CardSection;

import React from "react";

type Props = {};

const CopyIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 25"
      fill="none"
    >
      <g clip-path="url(#clip0_2625_34049)">
        <path
          opacity="0.3"
          d="M14 7.5H8V21.5H19V12.5H14V7.5Z"
          fill="url(#paint0_linear_2625_34049)"
        />
        <path
          d="M16 1.5H4C2.9 1.5 2 2.4 2 3.5V17.5H4V3.5H16V1.5ZM15 5.5H8C6.9 5.5 6.01 6.4 6.01 7.5L6 21.5C6 22.6 6.89 23.5 7.99 23.5H19C20.1 23.5 21 22.6 21 21.5V11.5L15 5.5ZM19 21.5H8V7.5H14V12.5H19V21.5Z"
          fill="url(#paint1_linear_2625_34049)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2625_34049"
          x1="19.1684"
          y1="22.4333"
          x2="2.68805"
          y2="8.25498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#08415C" />
          <stop offset="1" stop-color="#3F687B" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2625_34049"
          x1="21.2908"
          y1="24.9667"
          x2="-4.85962"
          y2="0.237736"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#08415C" />
          <stop offset="1" stop-color="#3F687B" />
        </linearGradient>
        <clipPath id="clip0_2625_34049">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;

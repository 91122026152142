import { useAppSelector } from "../../Redux/app/hooks";
import { DraggableList } from "../../Components/DraggableList/UnitDraggableList";

type Props = {
    ObjectList: any[];
    formValues: any;
    setObjectList: React.Dispatch<React.SetStateAction<any[]>>;
    moveDrills?: number[];
    setMoveDrills?: React.Dispatch<React.SetStateAction<number[]>>;
};

function SelectUnit({
    ObjectList = [],
    setObjectList,
    formValues,
    moveDrills,
    setMoveDrills
}: Props) {
    const handleInputChange = (index: number, event: string, time: number, reps?: number) => {
        const values = [...ObjectList];
        values[index] = {
            ...values[index],
            object_id: event,
            time: Number.isNaN(time) ? 0 : time,
            reps: reps
        };
        setObjectList(values);
    };

    const handlePermissionChange = (index: number, event: string) => {
        const values: any = [...ObjectList];
        values[index] = {
            ...values[index],
            permission: event,
        };
        setObjectList(values);
    };

    const handleAddInput = () => {
        setObjectList([...ObjectList, { object_id: "", permission: "1", time: 0, reps: 0 }]);
    };

    const handleRemoveInput = (index: any) => {
        const values = [...ObjectList];
        values.splice(index, 1);
        setObjectList(values);
    };
    const objectList = useAppSelector((state) => state.Unit.unitList);
    return (
        <div className="border rounded-[12px] ">
            <div className="px-5 py-5 flex gap-5">
                <span className="text-base text-[#212121] font-medium ">
                    Select Program Unit:
                </span>
                <span className="text-base text-[#9E9E9E]">
                    {ObjectList.length} Units
                </span>
                <span className="flex justify-center gap-2 items-center">
                    <img src="/images/plusButton.svg" alt="" />
                    <button
                        // disabled={ObjectList.length > 9}
                        type="button"
                        className="text-base text-[#212121] font-medium"
                        onClick={handleAddInput}
                    >
                        Add New Unit
                    </button>
                </span>
            </div>
            {objectList.length !== 0 && (
                <div className="grid border-t py-4 items-center grid-cols-1 gap-4">
                    {ObjectList.length !== 0 ? (
                        <>
                            <DraggableList
                                moveDrills={moveDrills}
                                setMoveDrills={setMoveDrills}
                                setObjectList={setObjectList}
                                ObjectList={ObjectList}
                                formValues={formValues}
                                handleInputChange={handleInputChange}
                                handlePermissionChange={handlePermissionChange}
                                handleRemoveInput={handleRemoveInput}
                                objectList={objectList}
                                initialItems={ObjectList}
                            />
                        </>
                    ) : (
                        <div className="h-[80px] col-span-2 text-center text-[#9E9E9E] m-auto w-1/2 flex flex-col">
                            <span className="text-md font-medium ">
                                You haven't chosen “Session Type”
                            </span>
                            <span className="text-sm">
                                You can add new Unit by clicking the “Add New Unit“ button
                            </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default SelectUnit;

import classNames from "classnames";
import { Rating } from "../../types/apiresponse";

type Props = {
  rate: Rating[];
};

const RatingComponents = ({ rate }: Props) => {
  return (
    <>
      {rate.length ? (
        rate?.map((rating) => (
          <>
            <p className="text-right flex justify-between items-end text-[0.75rem] text-[#757575]">
              {rating.date}
            </p>
            <div className="flex ity-50opac gap-3 justify-center">
              {[1, 2, 3, 4, 5].map((value) => (
                <>
                  <img
                    className={classNames("h-10", {
                      "opacity-100": value === Number(rating.rating),
                      "opacity-40": value !== Number(rating.rating),
                    })}
                    src={`/images/cards/${value}.svg`}
                  />
                </>
              ))}
            </div>
            <div className="flex fontnunito font-bold opacity-50 text-[0.75rem] justify-between mx-10">
              <p className="fontnunito font-bold">Not Well</p>
              <p className="fontnunito font-bold">Very Well</p>
            </div>
          </>
        ))
      ) : (
        <>
          <div className="flex ity-50opac gap-3 justify-center">
            {[1, 2, 3, 4, 5].map((value) => (
              <>
                <img
                  className={classNames("h-10 opacity-50", {})}
                  src={`/images/cards/${value}.svg`}
                />
              </>
            ))}
          </div>
          <div className="flex opacity-50 fontnunito font-bold text-[0.75rem] justify-between mx-10">
            <p className="fontnunito font-bold">Not Well</p>
            <p className="fontnunito font-bold">Very Well</p>
          </div>
        </>
      )}
    </>
  );
};

export default RatingComponents;

import React from 'react'

type Props = {}

const AlignIcon = (props: Props) => {
    return (
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 10.5V11.5H7.5V10.5H10.5ZM17.5 1.5H0.5V0.5H17.5V1.5ZM14.5 6.5H3.5V5.5H14.5V6.5Z" fill="#9E9E9E" stroke="#9E9E9E"/>
        </svg>
        
    )
}

export default AlignIcon
import HomeIcon from "./Icons/HomeIcon";
import { Link, useLocation, useParams } from "react-router-dom";
import { AppRoutes } from "../routes";
import ArrowIcon from "./Icons/ArrowIcon";
import classNames from "classnames";

const BreadCrumbComponent = () => {
  const { id, ticketid } = useParams();
  const location = useLocation();
  location.pathname.split("/");

  const currentRoute = AppRoutes.find((route) => {
    if (id) {
      return (
        route.path.replace("/:id", "") ===
        location.pathname.replace(`/${id}`, "")
      );
    } else if (ticketid) {
      return (
        route.path.replace("/:ticketid", "") ===
        location.pathname.replace(`/${ticketid}`, "")
      );
    } else {
      return route.path === location.pathname;
    }
  });

  return (
    <nav aria-label="breadcrumb">
      <ul className="flex justify-center items-center gap-4">
        <HomeIcon />
        {currentRoute &&
          currentRoute?.breadCrumbRoutes?.map((route, index) => (
            <div className="flex items-center gap-3" key={route.name}>
              <li className="breadcrumb-item">
                {route.path === "#" ? (
                  <p
                    className={classNames("cursor-pointer", {
                      "text-xs text-[#9E9E9E]": index === 1,
                    })}
                  >
                    {route.name}
                  </p>
                ) : (
                  <Link to={route.path}>{route.name}</Link>
                )}
              </li>
              <ArrowIcon direction="rotate-180" />
            </div>
          ))}
      </ul>
    </nav>
  );
};

export default BreadCrumbComponent;

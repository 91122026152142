import React, { useEffect, useState } from 'react'
import BoxLayout from '../../Containers/Layout/BoxLayout'
import InputComponent from '../../Components/Inputs/InputComponent'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import MultiSelectComponent from '../../Components/Inputs/MultiSelectComponent'
import ButtonComponent from '../../Components/Buttons/ButtonComponent'
import { useAppDispatch, useAppSelector } from '../../Redux/app/hooks'
import { unitListAPI } from '../../Redux/features/UnitSlice'
import { TagListAPI } from '../../Redux/features/TagSlice'
import { PostGroupListAPI } from '../../Redux/features/GroupSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { notifyError } from '../../helper';
import AddObjectTag from '../../Components/AddObjectTag';
import SelectUnit from '../../Containers/Group/SelectUnit';

type Props = {}

type GroupForm = {
    groupName: string,
    Units: Array<any>,
    tag: Array<any>
}

const EditGroupPage = (props: Props) => {
    const location = useLocation()
    const [objectList, setObjectList] = useState<
        Array<{ object_id: string; permission: 0 | 1 | ""; time: number, title: string, description: string }>
    >(location?.state?.Units);

    const validationSchema = Yup.object().shape({
        groupName: Yup.string().required("Session Type Required"),
        Units: Yup.array().required("Category Type Required"),
        tag: Yup.array().required("Session Name Required"),
    });


    const { handleSubmit, watch, setValue, formState: { errors }, register, control } = useForm<GroupForm>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            groupName: location.state.groupName,
            // Units: location.state.Units,
            // tags: ""
        }
    })

    const AllRecallCards = useAppSelector(
        (state) => state.Unit?.unitList
    );


    useEffect(() => {
        setValue("Units", AllRecallCards.map(firstItem => {
            const matchingItem = location.state.Units.find((secondItem: any) => firstItem.id === secondItem.object_id);
            if (matchingItem) {
                return {
                    value: firstItem.id,
                    time: firstItem.time,
                    label: firstItem.unit
                };
            }
            return null;
        }).filter(item => item !== null))
    }, [AllRecallCards])

    const onSubmit = (data: any) => {
        let hasNoEmptyString = objectList.every((item) => item.object_id !== "");

        if (hasNoEmptyString && objectList.length > 0) {
            dispatch(PostGroupListAPI({
                id: location.state.id,
                group_name: data.groupName,
                tag: data.tag.map((tag: { value: string, label: string }) => {
                    return tag.value;
                }).join(','),
                group_object: objectList.map((unit) => {
                    return {
                        object_id: unit.object_id,
                        time: String(unit.time)
                    }
                }),
                // tags: data.tags.map((i: any) => i.value).toString()
            })).then(() => navigate("/contentrepository/group")).then((err) => console.log(err))
        } else {
            notifyError("Add Unit Data")
        }
    }

    const unitList = useAppSelector(state => state.Unit.unitList)
    const tagList = useAppSelector(state => state.Tag.TagList.data.tags)
    const loading = useAppSelector(state => state.Group.postGroupListAPIStatus)

    const dispatch = useAppDispatch()
    const formValues = watch()
    useEffect(() => {
        const arrayOfError = Object.values(errors);
        if (Object.keys(errors).length !== 0) {
            arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        dispatch(unitListAPI({}))
    }, [])

    useEffect(() => {
        setValue(
            "tag",
            tagList?.filter((item) => location.state?.tag?.includes(item._id))
                ?.map((item) => ({ label: item.name, value: item._id }))
        );
    }, [tagList]);
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(TagListAPI({}))
    }, [])


    return (
        <BoxLayout HeaderName='Program' sectionName='Add Program'>
            <div className='grid  border-t'>
                <form onSubmit={handleSubmit(onSubmit)} className='p-4 grid  gap-4 '>
                    <div className='grid items-center grid-cols-2 gap-5'>
                        <InputComponent value='' disabled={formValues.groupName === "Default"} register={register} inputRef='groupName' name='group' label='Program Name' />
                        <AddObjectTag
                            control={control}
                            register={register}
                            errors={errors}
                            formValues={formValues}
                        />
                    </div>
                    <SelectUnit ObjectList={objectList} formValues={formValues} setObjectList={setObjectList} />
                    <div className='grid grid-cols-10 gap-3'>
                        <ButtonComponent className='w-[200px] col-start-9' CTA='back' varient='outline' loading={loading} buttonType='button' onClick={() => navigate("/contentrepository/group")} />
                        <ButtonComponent className='w-[200px]' CTA='Save' varient='orange' loading={loading} buttonType='submit' />
                    </div>
                </form>
            </div>
        </BoxLayout >
    )
}

export default EditGroupPage
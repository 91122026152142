import React from 'react'
import StatusCard from './StatusCard'
import SearchBoxComponent from '../../../../Components/Inputs/SearchBoxComponent'

type Props = {}

const History = (props: Props) => {
    return (
        <div>
            <div className='grid grid-cols-5 gap-8'>
                {[1, 2, 3, 4, 5].map((item) => (
                    <>
                        <StatusCard category='Apply Session' number={44} />
                    </>
                ))}
            </div>
            <div className='grid grid-cols-5 my-4'>
                <SearchBoxComponent getSearchValue={() => { }} />
            </div>
            <table className='w-full my-4'>
                <thead className='font-bold '>
                    <tr>
                        <td>Order No.</td>
                        <td>Date</td>
                        <td>Price</td>
                        <td>Status</td>
                    </tr>
                </thead>
                <tbody className='text-[#757575]'>
                    {
                        [1, 2, 3].map((item, index) => (
                            <tr className='border-t '>
                                <td className='py-2'>12877227695</td>
                                <td>26th feb</td>
                                <td>$199</td>
                                <td>completed</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default History
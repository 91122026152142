import React from 'react'
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';

type Props<TFieldValues extends FieldValues> = {
    name: string;
    register: UseFormRegister<TFieldValues>;
    errorname?: string | undefined;
    inputRef: Path<TFieldValues>;
    value: string;
    className?: string;
    inputClassName?: string;
    label?: string;
    placeholder?: string;
    secondLabel?: string;
    disabled?: boolean
};

const InputComponent = <TFieldValues extends FieldValues>({
    label = "",
    register,
    errorname,
    inputRef,
    className = "",
    inputClassName = "",
    placeholder = "",
    secondLabel,
    disabled = false
}: Props<TFieldValues>) => {
    return (
        <div className={`${className} items-center pointer select-none gap-4 flex`}>
            <input
                id={inputRef}
                type='checkbox'
                {...register(inputRef)}
                disabled={disabled}
                className={` accent-orange-600 peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none ${inputClassName}`}
                placeholder={placeholder}
            />
            <label htmlFor={inputRef}>{label}</label>
        </div>
    );
}

export default InputComponent;
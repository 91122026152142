import { sidebarImages } from "../../assets/sidebarasset";

export const sidebarRoutes = [
  {
    id: "1",
    name: "Content Repository",
    label: "Content Repository",
    icon: sidebarImages.contentRepository,
    activeicon: sidebarImages.contentRepositoryActive,
    path: "#",
    children: [
      {
        name: "objects",
        label: "Objects",
        path: "/contentrepository/objects",
      },
      {
        name: "sessions",
        label: "Sessions",
        path: "/contentrepository/session",
      },
      {
        name: "units",
        label: "Units",
        path: "/contentrepository/units",
      },
      {
        name: "recallcards",
        label: "Recall Cards",
        path: "/contentrepository/recallcard",
      },
      {
        name: "program",
        label: "Program",
        path: "/contentrepository/group",
      },
      {
        name: "guide",
        label: "Guide",
        path: "/contentrepository/fundamentals",
      },
    ],
  },
  {
    id: "2",
    name: "ftue",
    label: "FTUE",
    icon: sidebarImages.FTUE,
    activeicon: sidebarImages.FTUEActive,
    path: "#",
    children: [
      {
        name: "On-Boarding Questions",
        label: "On-Boarding Questions",
        path: "/onboardingquestionsrepository",
      },
      {
        name: "Welcome Pages",
        label: "Welcome Pages",
        path: "/managewelcomescreen",
      },
      {
        name: "About Fisio",
        label: "About Fisio",
        path: "/aboutfisio",
      },
    ],
  },
  {
    id: "3",
    name: "coachreview",
    label: "Coach Review",
    icon: sidebarImages.CoachReview,
    activeicon: sidebarImages.CoachReviewActive,
    path: "#",
    children: [
      {
        name: "Tickets",
        label: "Tickets",
        path: "/coachreview/tickets",
      },
      {
        name: "Coaches",
        label: "Coaches",
        path: "/coachreview/coaches",
      },
    ],
  },
  {
    id: "4",
    name: "trust",
    label: "Trust",
    icon: sidebarImages.trust,
    activeicon: sidebarImages.trustActive,
    path: "/trust",
    children: [],
  },
  {
    id: "5",
    name: "tag",
    label: "Tag",
    icon: sidebarImages.tag,
    activeicon: sidebarImages.tagActive,
    path: "/TagsRepository/Tags",
    children: [],
  },
  {
    id: "6",
    name: "adminmanagement",
    label: "Admin Management",
    icon: sidebarImages.admin,
    activeicon: sidebarImages.adminActive,
    path: "/usermanagement/adminlist",
    children: [],
  },
  {
    id: "7",
    name: "affiliates",
    label: "Affiliate Marketing",
    icon: sidebarImages.affiliate,
    activeicon: sidebarImages.affiliateActive,
    path: "/affiliate",
    children: [],
  },
  {
    id: "11",
    name: "FAQs",
    label: "FAQs",
    icon: sidebarImages.Faq,
    activeicon: sidebarImages.FaqActive,
    path: "/faq",
    children: [],
  },
  {
    id: "8",
    name: "reports",
    label: "Reports",
    icon: sidebarImages.report,
    activeicon: sidebarImages.reportActive,
    path: "/report",
    children: [],
  },
  {
    id: "9",
    name: "appcontrol",
    label: "App Control",
    icon: sidebarImages.report,
    activeicon: sidebarImages.reportActive,
    path: "/appcontrol",
    children: [],
  },
  {
    id: "10",
    name: "discount_codes",
    label: "Discount Codes",
    icon: sidebarImages.report,
    activeicon: sidebarImages.reportActive,
    path: "/discountcodes",
    children: [],
  },
];
export const CoachSideBarRoutes = [
  {
    id: "1",
    name: "Coach Review",
    label: "Coach Review",
    icon: sidebarImages.CoachReview,
    activeicon: sidebarImages.CoachReviewActive,
    path: "/coachreview/list",
    children: [],
  },
];

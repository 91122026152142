import React, { ReactNode, useRef } from 'react'
import { useOnClickOutside } from '../../CustomHooks/useOnClickOutside';
import { Status } from '../../Redux/features/UserManagementSlice';
import className from "classnames"

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    message: string;
    confirmAction?: (data?: any) => void;
    children?: ReactNode;
    loading?: Status;
    customStyle?: React.CSSProperties;

}

const CenterPopUpComponent = ({ children, isOpen, onClose, message, confirmAction, loading, customStyle }: ModalProps) => {
    const ModalRef = useRef<HTMLDivElement>(null);

    useOnClickOutside(ModalRef, () => {
        onClose();
    });
    return (
        <>
            {isOpen && (
                <div className="w-full flex gap-4 z-50 items-center justify-center h-screen bg-black bg-opacity-30 fixed top-0 left-0">
                    <div ref={ModalRef} className={className('p-[1.68rem] z-[99] rounded-md max-h-[99%] flex gap-4 flex-col overflow-auto min-h-[8%] max-w-[80%] min-w-[40%] bg-white')} style={customStyle}>
                        <div className='px-4  py-3 flex justify-between text-center text-xl font-medium'>
                            <p className='font-semibold text-base text-[#191919]'>
                                {message}
                            </p>
                            <button type='button' onClick={() => onClose()} className='text-xl font-medium'>
                                <img className='w-5' src="/images/close.svg" alt="" />
                            </button>
                        </div>
                        {children}
                    </div>
                </div>
            )}
        </>
    )
}

export default CenterPopUpComponent
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosClient from '../../api/api';
import { Status } from './UserManagementSlice';

export const getReports = createAsyncThunk<reportAPIResponse, reportAPIRequest>("report/list", async (data) => {
    const res = await axiosClient.get(`auth/reports?${data.searchValue
        ? `&filteredVal=${data.searchValue}`
        : ""
        }${data.page
            ? `&page=${data.page}`
            : ""
        }${data.sortType === "ascending"
            ? `&sort=-${data.sortValue}`
            : ``
        }${data.sortType === "descending"
            ? `&sort=${data.sortValue}`
            : ``
        }`)
    console.log(res.data)
    return {
        data: res.data,
        active: data.page
    }
})

interface ReportSliceState {
    getReportsIdle: Status
    reports: Report[]
    totalPages: number
    activePage: number
    sortType: "ascending" | "descending" | null;
    sortValue: string
}

const initialState: ReportSliceState = {
    getReportsIdle: Status.SUCCESS,
    reports: [],
    totalPages: 0,
    activePage: 1,
    sortType: "ascending",
    sortValue: "report_date"
}

const ReportSlice = createSlice({
    name: "Report",
    initialState,
    reducers: {
        clearReports: (state) => {
            return {
                ...state,
                reports: [],
            };
        },
        ReportListSort: (state) => {
            return {
                ...state,
                sortType: state.sortType === "ascending" ? "descending" : "ascending",
            };
        },
        handleSort: (state, action) => {
            return {
                ...state,
                sortType: action.payload.sortType,
                sortValue: action.payload.sortValue,
            };
        },
        handlePagination: (state, action) => {
            return {
                ...state,
                activePage: action.payload
            }
        }

    },
    extraReducers(builder) {
        builder
            .addCase(getReports.pending, (state) => {
                state.getReportsIdle = Status.LOADING
            })
            .addCase(getReports.fulfilled, (state, action) => {
                console.log(action.payload)
                state.getReportsIdle = Status.SUCCESS
                state.reports = action.payload?.data?.data.reports?.data
                state.totalPages = action.payload?.data?.data.total_page
                state.activePage = action.payload.active
            })
            .addCase(getReports.rejected, (state) => {
                state.getReportsIdle = Status.ERROR
            })
    },
});

export const { ReportListSort, clearReports, handlePagination, handleSort } = ReportSlice.actions

export default ReportSlice.reducer
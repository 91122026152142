import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const NoteBookIcon: React.FC<IconProps> = ({ width = 17, height = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill="none"
    >
      <path
        d="M1.78949 5.73684C1.78949 3.50421 1.78949 2.38711 2.48344 1.69395C3.1766 1 4.2937 1 6.52633 1H11.2632C13.4958 1 14.6129 1 15.3061 1.69395C16 2.38711 16 3.50421 16 5.73684V12.0526C16 14.2853 16 15.4024 15.3061 16.0955C14.6129 16.7895 13.4958 16.7895 11.2632 16.7895H6.52633C4.2937 16.7895 3.1766 16.7895 2.48344 16.0955C1.78949 15.4024 1.78949 14.2853 1.78949 12.0526V5.73684Z"
        stroke="#1F3161"
      />
      <path
        d="M5.73684 1.39473V16.7895M1 8.89473H2.57895M1 12.0526H2.57895M1 5.73684H2.57895M8.5 4.55262H12.4474M8.5 7.31578H12.4474"
        stroke="#1F3161"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default NoteBookIcon;

import { useState, useEffect } from 'react';

type Props = {
  columns?: number;
  slotMachineName?: string;
};

const SlotMachineNumber = ({ columns = 0, slotMachineName }: Props) => {
  console.log(columns);

  const convertNumberToArray = (number: number) => {
    const digitArray = String(number)
      .padStart(4, '0')
      .slice(-4) // Ensure the array has only four elements
      .split('')
      .map(Number);
    return digitArray;
  };

  const resultArray = convertNumberToArray(columns);
  const createSequence = () => {
    return resultArray;
  };
  console.log(resultArray);

  const [slots, setSlots] = useState<number[]>(createSequence());
  const [isSpinning, setSpinning] = useState<boolean>(false);

  useEffect(() => {
    setSpinning(true);

    let interval: NodeJS.Timeout;

    const spin = () => {
      setSlots(createSequence());
    };

    if (isSpinning) {
      interval = setInterval(() => {
        spin();
      }, 300);
    }

    return () => {
      clearInterval(interval);
      setSpinning(true);
    };
  }, [columns]);

  return (
    <div className="flex flex-col items-center justify-center gap-2 py-1">
      <div className="flex flex-col cursor-auto w-[19.975rem] h-[3.375rem] rounded  gap-1 items-center justify-center bg-white">
        <p className="text-[0.78125rem] text-[#BDBDBD]">{slotMachineName}</p>
        <div className="flex  rounded  gap-3 items-center justify-center">
          {slots.map((number, index) => (
            <div
              key={index}
              className="flex w-[3.0625rem] h-[3.0625rem] items-center bg-stone-50 rounded-[5px] shadow-[-3px_2px_4px_0px_rgba(0,0,0,0.05)_inset,3px_-2px_4px_0px_rgba(0,0,0,0.05)_inset,0px_0px_10px_0px_rgba(0,0,0,0.02)_inset]
          justify-center text-orange-500 text-xl font-bold"
            >
              {number}
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default SlotMachineNumber;

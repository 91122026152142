import AddRecallCard from "../../Pages/RecallCards/AddRecallCard";
import EditRecallCard from "../../Pages/RecallCards/EditRecallCard";
import RecallCard from "../../Pages/RecallCards/RecallCardList";
import AdminRoutes from "../../auth/AdminRoutes";

export const recallCardRoutes = [
  {
    id: "23",
    name: "Recall Card",
    module: "Content Repository",
    path: "/contentrepository/recallcard",
    component: RecallCard,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Recall Card",
        path: "#",
      },
    ],
  },
  {
    id: "23",
    name: "Recall Card",
    module: "Content Repository",
    path: "/contentrepository/addrecallcard",
    component: AddRecallCard,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Recall Card",
        path: "/contentrepository/recallcard",
      },
      {
        name: "Add Recall Card",
        path: "#",
      },
    ],
  },
  {
    id: "23",
    name: "Recall Card",
    module: "Content Repository",
    path: "/contentrepository/editrecallcard",
    component: EditRecallCard,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Recall Card",
        path: "/contentrepository/recallcard",
      },
      {
        name: "Edit Recall Card",
        path: "#",
      },
    ],
  },
];

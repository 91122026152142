import { useAppSelector } from "../../Redux/app/hooks";
import InputField from "../../Components/Inputs/InputField";
import { Status } from "../../Redux/features/UserManagementSlice";

type Props = {
  ObjectList: any[];
  register: any;
  errors: any;
  formValues: any;
  control: any;
  placeholder: any;
  overviewName?: string;
  setObjectList: React.Dispatch<React.SetStateAction<any[]>>;
};

function OverviewDetails({
  ObjectList = [],
  setObjectList,
  formValues,
  overviewName = "Add Object Overview",
}: Props) {
  const handleTitleValue = (index: number, event: string) => {
    const values: any = [...ObjectList];
    values[index] = {
      ...values[index],
      title: event,
    };
    setObjectList(values);
  };

  const handleTextValue = (index: number, event: string) => {
    const values: any = [...ObjectList];
    values[index] = {
      ...values[index],
      description: event,
    };
    setObjectList(values);
  };

  const handleAddInput = () => {
    setObjectList([...ObjectList, { object_id: "", permission: "1", time: 0 }]);
  };

  const handleRemoveInput = (index: any) => {
    const values = [...ObjectList];
    values.splice(index, 1);
    setObjectList(values);
  };
  const objectList = useAppSelector((state) => state.Object.simpleObjectList);
  const loading = useAppSelector(
    (state) => state.Object.SimpleObjectListAPIIdle
  );

  return (
    <div className="border rounded-[12px] ">
      <div className="px-5 py-5 flex gap-5">
        <span className="text-base text-[#212121] font-medium ">
          {overviewName}
        </span>
        <span className="text-base text-[#9E9E9E]">
          {ObjectList.length} Points
        </span>
        <span className="flex justify-center gap-2 items-center">
          <img src="/images/plusButton.svg" alt="" />
          <button
            type="button"
            className="text-base text-[#212121] font-medium"
            onClick={handleAddInput}
          >
            Add Overview
          </button>
        </span>
      </div>
      {loading === Status.LOADING ? (
        <span className="m-auto loader my-4 w-full flex justify-center"></span>
      ) : (
        <>
          {objectList.length !== 0 && (
            <div className="grid border-t py-4 items-center grid-cols-1 gap-4">
              <>
                {ObjectList.length !== 0 ? (
                  <>
                    {ObjectList.map((i, index) => {
                      console.log(i);
                      return (
                        <div className="flex gap-5 px-4 items-center">
                          <p className="px-4">{index + 1}.</p>
                          <InputField
                            value={i.title}
                            label="title"
                            onChange={handleTitleValue}
                            index={index}
                          />
                          <InputField
                            value={i.description}
                            label="description"
                            onChange={handleTextValue}
                            index={index}
                          />
                          <button
                            type="button"
                            className="px-4"
                            onClick={() => handleRemoveInput(index)}
                          >
                            <img
                              className="w-14"
                              src="/images/remove.svg"
                              alt="remove"
                            />
                          </button>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className="h-[80px] col-span-2 text-center text-[#9E9E9E] m-auto w-1/2 flex flex-col">
                    <span className="text-md font-medium ">
                      You haven't Added “Overview”
                    </span>
                    <span className="text-sm">
                      You can add choose by clicking the “Add Button” Tab in
                      Object Overview
                    </span>
                  </div>
                )}
              </>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default OverviewDetails;

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

type Props = {
  tableColumns: number;
};

const TableSkeleton = ({ tableColumns }: Props) => {
  return (
    <tbody className=" overflow-hidden">
      <tr>
        {[...Array(tableColumns)].map((_column, index: number) => (
          <td key={index}>
            <Skeleton count={240} style={{ width: "100%" }} />
          </td>
        ))}
      </tr>
    </tbody>
  );
};

export default TableSkeleton;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";
import { ApiResponse, getPlansResponse } from "../../types/apiresponse";

export const getPlans = createAsyncThunk<ApiResponse<getPlansResponse[]>, void>(
  "disocunt/getPlans",
  async () => {
    const res = await axiosClient.get<ApiResponse<getPlansResponse[]>>(
      "auth/promo-code?filteredVal=ASDAS54345345"
    );
    return res.data;
  }
);

export const getPromo = createAsyncThunk<ApiResponse<getPlansResponse[]>, void>(
  "disocunt/getPropmo",
  async () => {
    const res = await axiosClient.get<ApiResponse<getPlansResponse[]>>(
      "auth/promo"
    );
    return res.data;
  }
);

type getDiscountPayload = {
  searchValue?: string;
  sortType?: sortValues;
  sortValue?: string;
  page?: number;
};

export type getDiscountResponse = {
  _id: string;
  code: string;
  discount: number;
  plan_id: string;
  updated_at: string;
  created_at: string;
  plan_name: string;
  quantity: string;
  used_quantity: string;
};

export const getDiscountCodeList = createAsyncThunk<
  ApiResponse<{
    total_pages: number;
    total_tags: number;
    promo_codes: getDiscountResponse[];
  }>,
  getDiscountPayload
>("disocunt/getCodeList", async (payload) => {
  const res = await axiosClient.get<
    ApiResponse<{
      total_pages: number;
      total_tags: number;
      promo_codes: getDiscountResponse[];
    }>
  >(
    `auth/promo-code?${payload.page ? `page=${payload.page}` : ""}${
      payload.searchValue ? `&filteredVal=${payload.searchValue}` : ""
    }${
      payload.sortType === "ascending"
        ? `&sort=-${payload.sortValue}`
        : `&sort=${payload.sortValue}`
    }`
  );
  return res.data;
});

export const postDiscountCodeList = createAsyncThunk<
  ApiResponse<getDiscountResponse>,
  { code: string; plan_id: string; discount: number; id?: string }
>("disocunt/postDiscountCode", async (data) => {
  const res = axiosClient.post<ApiResponse<getDiscountResponse>>(
    "auth/promo-code",
    data
  );
  return (await res).data;
});

export const deleteDiscountCode = createAsyncThunk<any, any>(
  "disocunt/deleteDiscountCode",
  async (id) => {
    const res = await axiosClient.delete(`auth/promo-code/${id}`);
    return res.data;
  }
);

interface Discountslice {
  getPlanAPIStatus: Status;
  getDiscountCodesListAPIStatus: Status;
  postDiscountCodesAPIStatus: Status;
  deleteDiscountCodeAPIStatus: Status;
  sortType: sortValues;
  sortValue: string;
  plans: getPlansResponse[];
  perPage: number;
  promocodes: getDiscountResponse[];
}

const initialState: Discountslice = {
  deleteDiscountCodeAPIStatus: Status.IDLE,
  getDiscountCodesListAPIStatus: Status.SUCCESS,
  getPlanAPIStatus: Status.IDLE,
  postDiscountCodesAPIStatus: Status.IDLE,
  sortType: "ascending",
  sortValue: "code",
  plans: [],
  perPage: 10,
  promocodes: [],
};

const DiscountSlice = createSlice({
  name: "discount",
  initialState,
  reducers: {
    handleSort: (state, action) => {
      return {
        ...state,
        sortType: action.payload.sortType,
        sortValue: action.payload.sortValue,
      };
    },
    setPerPage: (state, action) => {
      return {
        ...state,
        perPage: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPlans.pending, (state) => {
        state.getPlanAPIStatus = Status.LOADING;
      })
      .addCase(getPlans.fulfilled, (state, payload) => {
        state.getPlanAPIStatus = Status.SUCCESS;
        state.plans = payload.payload.data;
      })
      .addCase(getPlans.rejected, (state) => {
        state.getPlanAPIStatus = Status.ERROR;
      })
      .addCase(getDiscountCodeList.pending, (state) => {
        state.getDiscountCodesListAPIStatus = Status.LOADING;
      })
      .addCase(getDiscountCodeList.fulfilled, (state, action) => {
        state.getDiscountCodesListAPIStatus = Status.SUCCESS;
        state.promocodes = action.payload.data.promo_codes;
      })
      .addCase(getDiscountCodeList.rejected, (state) => {
        state.postDiscountCodesAPIStatus = Status.ERROR;
      })
      .addCase(postDiscountCodeList.pending, (state) => {
        state.postDiscountCodesAPIStatus = Status.LOADING;
      })
      .addCase(postDiscountCodeList.fulfilled, (state) => {
        state.postDiscountCodesAPIStatus = Status.SUCCESS;
      })
      .addCase(postDiscountCodeList.rejected, (state) => {
        state.postDiscountCodesAPIStatus = Status.ERROR;
      })
      .addCase(deleteDiscountCode.pending, (state) => {
        state.deleteDiscountCodeAPIStatus = Status.LOADING;
      })
      .addCase(deleteDiscountCode.fulfilled, (state) => {
        state.deleteDiscountCodeAPIStatus = Status.SUCCESS;
      })
      .addCase(deleteDiscountCode.rejected, (state) => {
        state.deleteDiscountCodeAPIStatus = Status.ERROR;
      });
  },
});

export const { handleSort, setPerPage } = DiscountSlice.actions;

export default DiscountSlice.reducer;

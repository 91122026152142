import React, { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import InputComponent from "../../Components/Inputs/InputComponent";
import TextEditor from "../../Components/Inputs/TextEditor";
import { useForm } from "react-hook-form";
import UploadFileComponents from "../../Components/Inputs/UploadFileComponents";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { id } from "date-fns/locale";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import EditIcon from "../../Components/Icons/EditIcon";
import TextAreaComponent from "../../Components/Inputs/TextAreaComponent";
import { notifyError, notifySuccess } from "../../helper";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  deleteFaq,
  faqreorderItems,
  faqresponse,
  getfaq,
  orderfaq,
  postFaq,
} from "../../Redux/features/FaqSlice";
import className from "classnames";
import uploadedFaqimage from "../../assets/uploadedFaqImage.svg";
import ModalComponent from "../../Components/PopUp/ModalComponent";

type Props = {};

type FaqInterface = {
  Questionheadline: string;
  text: string;
  img: string | FileList;
  vimeo_link: string;
};
const faqSchema = yup.object().shape({
  Questionheadline: yup.string().required("Headline is required"),
  text: yup.string().required("Answer is required"),
  // vimeo_link: yup.string().nullable(),
  // img: yup.mixed().nullable(),
});
const FAQ = (props: Props) => {
  const {
    register,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<FaqInterface>({
    resolver: yupResolver(faqSchema),

    defaultValues: {
      img: "",
    },
  });
  const [id, setID] = useState<string | undefined>("");
  const [order, setOrder] = useState<string | undefined>();
  const [fileType, setFileType] = useState(false);
  const [showDeleteForm, setDeleteForm] = useState(false);
  const dispatch = useAppDispatch();
  const getfaqResponse = useAppSelector((state) => state.faq.postFaqResponse);
  const reOrderAPIIdle = useAppSelector((state) => state.faq.orderStatus);
  const formValues = watch();

  const handleChange = (event: any) => {
    if (event?.target?.value === "true") {
      setFileType(true);
      setValue("vimeo_link", "");
    } else {
      setFileType(false);
      setValue("img", "");
    }
  };

  useEffect(() => {
    dispatch(getfaq());
  }, []);

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
  }, [errors]);

  const onSubmit = (data: FaqInterface) => {
    const formData = new FormData();
    if (id && order) {
      formData.append("id", id);
      formData.append("order", order);
    }
    formData.append("question", data.Questionheadline);
    formData.append("answer", data.text);
    if (data.img !== "" && data.img) {
      if (typeof data.img === "object") {
        formData.append("image", data.img[0]);
        formData.append("vimeo_link", "");
      } else {
        formData.append("image", data.img);
        formData.append("vimeo_link", "");
      }
    } else {
      formData.append("vimeo_link", data.vimeo_link);
      formData.append("image", "");
    }
    dispatch(postFaq(formData))
      .then((res: any) => {
        if (res?.payload?.success) {
          if (id) {
            notifySuccess("Question Updated Successfully");
            reset();
            setID(undefined);
          } else {
            notifySuccess("Question Created Successfully");
            reset();
          }
          dispatch(getfaq());
        } else {
          notifyError(res.payload.message);
        }
      })
      .catch((err) => notifyError(err));
  };

  const handleEditfaq = (data: faqresponse, index: number) => {
    setID(data._id);
    setOrder(String(index));
    setValue("Questionheadline", data.question);
    setValue("img", data.image);
    setValue("text", data.answer);
    if (data.vimeo_link) {
      setValue("vimeo_link", data.vimeo_link);
    }
    if (data.vimeo_link && data.vimeo_link !== "") {
      setFileType(false);
    } else {
      setFileType(true);
    }
  };
  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    dispatch(
      faqreorderItems({
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })
    );
  };

  const reOrderDetails = () => {
    dispatch(
      orderfaq({
        faq_list: JSON.stringify(
          getfaqResponse.map((article, index) => {
            return {
              id: article._id,
              order: String(index),
            };
          })
        ),
      })
    ).then((res: any) => {
      if (res.payload?.success) {
        notifySuccess("Reordererd SuccessFully");
      }
    });
  };
  const handleDeleteClick = (id: string) => {
    setDeleteForm(true);
    setID(id);
  };

  const confirmDeleteAction = () => {
    if (id) {
      dispatch(deleteFaq(id)).then((res: any) => {
        if (res.payload?.success) setDeleteForm(false);
        notifySuccess("Screen Delete SuccessFully!");
        dispatch(getfaq());
      });
    }
  };

  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
    setID("");
  };

  return (
    <BoxLayout HeaderName="FAQs" sectionName="FAQs">
      {/* <hr className="w-full border border-solid border-primary-dark" /> */}
      <div className=" border-t border-solid">
        <div className=" my-4 mx-4 gap-4  grid grid-cols-12 pb-7">
          <div className="col-span-8  rounded-lg">
            <div className="border rounded-lg">
              <div className="py-2 px-2 text-base font-medium flex justify-between ">
                <p>{"Question Details"}</p>
              </div>
              <div className="border-t">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col p-4 gap-4"
                >
                  <InputComponent
                    inputRef="Questionheadline"
                    label="Question Headline"
                    register={register}
                    value=""
                  />
                  <TextEditor
                    control={control}
                    register={register}
                    values=""
                    name="text"
                    className=""
                    inputRef="text"
                    label="Text"
                  />
                  {/* <div className="grid items-center grid-cols-4 gap-1">
                  <div className="font-semibold text-[#3c4c76] font-blue-950 text-sm col-span-2 flex gap-3">
                    Choose Cover Media :
                    <div className="">
                      <label className="flex font-semibold text-[#3c4c76] font-blue-950 text-sm items-center gap-2">
                        Image
                        <input
                          type="radio"
                          name="toggle"
                          value="true"
                          checked={fileType}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    <div>
                      <label className="flex font-semibold text-[#3c4c76] font-blue-950 text-sm items-center gap-2">
                        Video
                        <input
                          type="radio"
                          name="toggle"
                          value="false"
                          checked={!fileType}
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  </div>
                  {fileType ? (
                    <UploadFileComponents
                      onClick={() => setValue("img", "")}
                      control={control}
                      id="img"
                      inputRef="img"
                      label={
                        typeof formValues.img !== "string"
                          ? formValues?.img[0].name
                          : formValues.img === ""
                          ? "Upload Image"
                          : formValues.img.split("/")[0]
                      }
                      value={formValues.img}
                    />
                  ) : (
                    <>
                      <InputComponent
                        inputRef="vimeo_link"
                        className="col-span-2"
                        label="Vimeo Link"
                        name="s"
                        register={register}
                        value=""
                      />
                    </>
                  )} 
                </div> */}
                  <span className="w-[200px] ml-auto">
                    <ButtonComponent
                      className=""
                      // loading={postAPIIdle}
                      CTA="Add"
                      buttonType="submit"
                      varient="blue"
                    />
                  </span>
                </form>
              </div>
            </div>
          </div>
          <div className="col-span-4 flex flex-col gap-5">
            <div className="col-span-4 border rounded-lg">
              <div className="py-2 px-2 text-base font-medium">
                FAQ Questions
              </div>
              <div className="border-t w-full flex flex-col">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="droppableArticleas">
                    {(provided, snapshot) => (
                      <ul ref={provided.innerRef} {...provided.droppableProps}>
                        {getfaqResponse.map((article, index) => (
                          <Draggable
                            draggableId={article._id}
                            key={article._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="list-none flex items-center py-2 justify-between"
                              >
                                <div
                                  draggable
                                  className="flex flex-col bg-[#EAF0F3]  h-full py-[10px] mx-10 px-[8px] border  rounded-md  gap-1"
                                >
                                  <span className="block  w-6 h-[2px] bg-[#285A9f]"></span>
                                  <span className="block  w-6 h-[2px] bg-[#285A9f]"></span>
                                  <span className="block  w-6 h-[2px] bg-[#285A9f]"></span>
                                </div>
                                {/* <div>{index + 1}</div> */}
                                <div className="w-[200px] text-ellipsis whitespace-nowrap overflow-hidden block">
                                  {article.question}
                                </div>
                                <div className="flex gap-3 p-2 items-center">
                                  {/* {article.image_url ||
                                  article.vimeo_link !== "" ? (
                                    <img src={uploadedFaqimage} alt="" />
                                  ) : (
                                    <div className="h-[19px] w-[19px]"></div>
                                  )} */}
                                  <span className="block  h-4 w-[1px] bg-[#285A9f]"></span>
                                  <button
                                    className={className("", {
                                      "cursor-not-allowed": id === article._id,
                                    })}
                                    disabled={id === article._id}
                                    onClick={() =>
                                      handleEditfaq(article, index)
                                    }
                                  >
                                    <EditIcon />
                                  </button>
                                  <button
                                    disabled={getfaqResponse.length === 1}
                                    onClick={() =>
                                      handleDeleteClick(article._id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </button>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ))}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            <ButtonComponent
              CTA="Save Question Order"
              buttonType="button"
              varient="blue"
              onClick={() => reOrderDetails()}
              loading={reOrderAPIIdle}
            />
          </div>
        </div>
      </div>
      <ModalComponent
        confirmAction={confirmDeleteAction}
        message={"Are you sure to delete this Question ?"}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
        loading={reOrderAPIIdle}
      />
    </BoxLayout>
  );
};

export default FAQ;

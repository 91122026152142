import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";

export const LoginAdmin = createAsyncThunk<LoginAdminResponse, LoginAPIRequest>(
  "UserAuth/Login",
  async (LoginAPIRequest) => {
    const res = await axiosClient.post<LoginAdminResponse>(
      "auth/login",
      LoginAPIRequest
    );
    return res.data;
  }
);

export const ForgetPasswordAPI = createAsyncThunk<
  any,
  ForgetPasswordAPIRequest
>("UserAuth/ForgetPassword", async (data) => {
  const res = await axiosClient.post("/auth/forgotpassword", data);
  return res.data;
});

type PasswordRequest = {
  token: string;
  email: string;
  password: string;
  confirm_password: string;
};
type PasswordRequestLink = {
  token: string;
  email: string;
};

export const ResetPasswordAPI = createAsyncThunk<
  PayloadAction,
  PasswordRequest
>("UserAuth/ResetPassword", async (data) => {
  const res = await axiosClient.post("auth/confirm-password", data);
  return res.data;
});
export const VerifyPasswordLinkAPI = createAsyncThunk<
  PayloadAction,
  PasswordRequestLink
>("UserAuth/ResetPasswordLink", async (data) => {
  const res = await axiosClient.post("auth/verify-password-link", data);
  return res.data;
});

interface UserAuthInitialState {
  LoginAdminIdle: Status;
  ForgetPasswordAPIIdle: Status;
  ResetPasswordAPIIdle: Status;
}

const initialState: UserAuthInitialState = {
  ForgetPasswordAPIIdle: Status.SUCCESS,
  ResetPasswordAPIIdle: Status.SUCCESS,
  LoginAdminIdle: Status.SUCCESS,
};

const UserAuthSlice = createSlice({
  name: "UserAuth",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(LoginAdmin.pending, (state, { payload }) => {
        return {
          ...state,
          LoginAdminIdle: Status.LOADING,
        };
      })
      .addCase(
        LoginAdmin.fulfilled,
        (state, action: PayloadAction<LoginAdminResponse>) => {
          return {
            ...state,
            LoginAdminIdle: Status.SUCCESS,
          };
        }
      )
      .addCase(LoginAdmin.rejected, (state, { payload }) => {
        return {
          ...state,
          LoginAdminIdle: Status.ERROR,
        };
      })
      .addCase(ForgetPasswordAPI.pending, (state, { payload }) => {
        return {
          ...state,
          ForgetPasswordAPIIdle: Status.LOADING,
        };
      })
      .addCase(ForgetPasswordAPI.fulfilled, (state) => {
        return {
          ...state,
          ForgetPasswordAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(ForgetPasswordAPI.rejected, (state, { payload }) => {
        return {
          ...state,
          ForgetPasswordAPIIdle: Status.ERROR,
        };
      })
      .addCase(ResetPasswordAPI.pending, (state, { payload }) => {
        return {
          ...state,
          ResetPasswordAPIIdle: Status.LOADING,
        };
      })
      .addCase(ResetPasswordAPI.fulfilled, (state, action) => {
        return {
          ...state,
          ResetPasswordAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(ResetPasswordAPI.rejected, (state, action) => {
        return {
          ...state,
          ResetPasswordAPIIdle: Status.ERROR,
        };
      });
  },
});

export const {} = UserAuthSlice.actions;

export default UserAuthSlice.reducer;

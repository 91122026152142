import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import InputComponent from "../../Components/Inputs/InputComponent";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useCookies } from "react-cookie";
import { LoginAdmin } from "../../Redux/features/UserAuthSlice";
import { useState } from "react";
import InputComponent2 from "../../Components/Inputs/InputComponent2";

type FormValues = {
  email: string;
  password: string;
};

const LoginForm = () => {
  const dipatch = useAppDispatch();
  const loading = useAppSelector((state) => state.UserAuth.LoginAdminIdle);
  const navigate = useNavigate();
  const [_cookie, setCookie] = useCookies(["token", "ic"]);
  const [coachLogin, setCoachLogin] = useState(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: "", password: "" },
  });

  const formValues = watch();
  const [Remember, setRemember] = useState(false);

  const handleRememberMe = () => {
    setRemember((prev) => !prev);
  };
  const handleCoachLogin = () => {
    setCoachLogin((prev) => !prev);
  };

  const onSubmit = (data: FormValues) => {
    if (data.email !== "" && data.password !== "") {
      dipatch(
        LoginAdmin({
          email: data.email,
          password: data.password,
          user_type: !coachLogin ? "admin" : "coach",
        })
      ).then((res) => {
        if (LoginAdmin.fulfilled.match(res)) {
          if (res.payload.success) {
            Remember
              ? setCookie("token", res.payload.token, {
                  maxAge: 72 * 60 * 60,
                  path: "/",
                })
              : setCookie("token", res.payload.token, { path: "/" });
            if (res.payload.user.user_type === "coach") {
              Remember
                ? setCookie("ic", "1", {
                    maxAge: 72 * 60 * 60,
                    path: "/",
                  })
                : setCookie("ic", "1");
              navigate("/usermanagement/userlist");
            } else {
              Remember
                ? setCookie("ic", "0", {
                    maxAge: 72 * 60 * 60,
                    path: "/",
                  })
                : setCookie("ic", "0");
              navigate("coach/swingcheck");
            }
            localStorage.setItem("user", res.payload.user.full_name);
          }
        }
      });
    }
  };

  return (
    <div className="md:w-[420px] text-center  m-auto h-[calc(100vh-88px)]  flex flex-col justify-center items-center">
      <div className="  rounded-[12px] w-full py-4 ">
        <h1 className="text-2xl font-bold text-[#1F3161]">Hi, Welcome back</h1>
        <h3 className="text-base my-2 text-[#9E9E9E] font-normal">
          Login in to your account
        </h3>
        <form
          className="flex w-[90%] m-auto gap-4 flex-col"
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputComponent2
            register={register}
            inputRef="email"
            errorname={errors.email?.message}
            label="Email"
            name="email"
            value={formValues.email}
          />

          <InputComponent2
            register={register}
            inputRef="password"
            errorname={errors.password?.message}
            label="Password"
            name="password"
            type="password"
            value={formValues.password}
          />

          <div className="text-start outline-none text-[#1F3161] flex flex-col gap-4 justify-between">
            <label
              className="flex select-none cursor-pointer gap-2 text-sm font-medium "
              htmlFor="label"
            >
              <input
                className=" accent-[#e36600]"
                onChange={() => handleCoachLogin()}
                id="label"
                type="checkbox"
              />
              Login in Coach Portal
            </label>
            <div className="flex justify-between">
              <label
                className="flex select-none accent-[#e36600] gap-2 cursor-pointer text-sm font-medium"
                htmlFor="label2"
              >
                <input
                  onChange={() => handleRememberMe()}
                  id="label2"
                  type="checkbox"
                />
                Remember Me
              </label>
              <Link
                className="flex gap-2 text-sm font-medium"
                to={"/forgetpassword"}
              >
                Forget Password?
              </Link>
            </div>
          </div>
          <ButtonComponent loading={loading} CTA="Submit" varient="orange" />
        </form>
      </div>
    </div>
  );
};

export default LoginForm;

import React from "react";

type Props = {
  title: string;
  status?: string;
  date: string;
  children: React.ReactNode;
};

const StatusCard = ({ status, title, date, children }: Props) => {
  return (
    <div className="bg-[#F6F6F6] flex flex-col gap-3  p-[0.62rem] rounded-[10px]">
      <div className="flex items-center justify-between text-[0.875rem] font-medium  ">
        <span>{title}</span>
        {status && (
          <span className="py-1 px-4 rounded-full text-[#58BBCF] bg-[#D7F1F4] text-[0.75rem] ">
            {status}
          </span>
        )}
      </div>
      <div className="text-right flex justify-between items-end text-[0.75rem] text-[#757575]">
        {children}
        {date}
      </div>
    </div>
  );
};

export default StatusCard;

import React from "react";
import InputComponent from "../../Components/Inputs/InputComponent";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  Control,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form/dist/types";
import { UnitAddFormValues } from "../../Pages/Units/AddUnitsPage";

type Props = {
  register: UseFormRegister<UnitAddFormValues>;
  errors: FieldErrors<UnitAddFormValues>;
  formValues: UnitAddFormValues;
  control: Control<UnitAddFormValues>;
};

const UnitDetails = ({ register, errors, formValues, control }: Props) => {
  const categoryList = useAppSelector((state) => state.Object.categoryTypeList);

  return (
    <div className="py-3 p-4  ">
      <form>
        <div className="grid px-4 gap-4 grid-cols-2">
          <InputComponent
            register={register}
            inputRef="unit_name"
            // errorname={errors.unit_name?.message}
            label="Unit Name"
            name="question"
            value={formValues.unit_name}
          />
          <InputComponent
            register={register}
            inputRef="order"
            // errorname={errors.unit_name?.message}
            label="Order"
            name="question"
            value={formValues.unit_name}
          />

          <SelectComponent<UnitAddFormValues>
            control={control}
            inputRef="category_type_id"
            options={categoryList.map((i) => {
              return { value: i._id, label: i.name };
            })}
            label="CategoryType"
          // errorname={errors.category_type_id?.message}
          />
        </div>
      </form>
    </div>
  );
};

export default UnitDetails;

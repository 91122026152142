import Collapsible from "../../../../../Components/Collapsible/Collapsible";
import CollapsibleWithDropDown from "../../../../../Components/CollapsibleWithDropDown/CollapsibleWithDropDown";
import RatingComponents from "../../../../../Components/RatingComponents/RatingComponents";
import {
  ActivityItem,
  FeedbackData,
  FriendSupport,
  Rating,
} from "../../../../../types/apiresponse";
import StatusCard from "../../../../../Components/Cards/StatusCard";
import HorizontalBar from "../../../../../Components/Charts/HorizontalBar";
import { useRef, useState } from "react";
import { useOnClickOutside } from "../../../../../CustomHooks/useOnClickOutside";
import PopUpTable from "../../../../../Components/PopUpTable/PopUpTable";
import CenterPopUpComponent from "../../../../../Components/PopUp/CenterPopUpComponent";
import CustomPlayer from "../../../../../Components/VideoPlayer/VideoPlayer";
import NoDataBox from "../../../../../Components/NodataBox/NoDataBox";

type Props = {
  weeklyRatings: Rating[];
  sessionRatings: Rating[];
  sccRatings: Rating[];
  contacts: FriendSupport[];
  feedBackData: FeedbackData;
  applyData: ActivityItem[];
  trainData: ActivityItem[];
  trustData: ActivityItem[];
};

const ProfileSummary = ({
  sccRatings,
  applyData,
  trainData,
  trustData,
  sessionRatings,
  weeklyRatings,
  contacts,
  feedBackData,
}: Props) => {
  const [showsummary, setShowSummary] = useState("");

  const [showComment, setShowComment] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showReview, setShowReview] = useState(false);

  const [Comment, setComment] = useState("");
  const [video, setVideo] = useState("");
  const [review, setreview] = useState("");

  const summaryBox = useRef<HTMLDivElement>(null);

  useOnClickOutside(summaryBox, () => setShowSummary(""));

  const handleCommentCheck = (comment: string) => {
    if (comment !== "") {
      setShowComment(true);
      setComment(comment);
    }
  };

  const handleVideoCheck = (link: string) => {
    console.log(link);
    console.log(link);
    if (link !== "") {
      setShowVideo(true);
      setVideo(link);
    }
  };
  const handleReviewVideoCheck = (link: string) => {
    console.log(link);
    if (link !== "") {
      setShowReview(true);
      setreview(link);
    }
  };
  return (
    <>
      <div className="h-fit border shadow-lg border-[#C6D6DD] rounded-lg">
        <CollapsibleWithDropDown
          disabled
          defaultValues={{ activity: "Apply" }}
          title="Activity"
          inputRef="activity"
          options={[
            { label: "Apply", value: "Apply" },
            { label: "Train", value: "Train" },
            { label: "Trust", value: "Trust" },
          ]}
          defaultFragment={
            <>
              <RatingComponents rate={[]} />
            </>
          }
          data={[
            {
              id: "Apply",
              summaryFragment: (
                <>
                  {applyData.length ? (
                    applyData.slice(0, 1).map((data) => (
                      <StatusCard date={data.date} title={data.session_object}>
                        <div className="font-medium text-[0.75rem] flex gap-2 ">
                          {data.unit_name}
                        </div>
                      </StatusCard>
                    ))
                  ) : (
                    <p className="bg-[#F6f6f6] p-3 min-h-[4rem] justify-center font-semibold flex h-full text-center flex-col gap-3 rounded-lg">
                      No Data Available
                    </p>
                  )}
                </>
              ),
              detailsFragment: <></>,
            },
            {
              id: "Train",
              summaryFragment: (
                <>
                  {trainData.length ? (
                    trainData.slice(0, 1).map((data) => (
                      <StatusCard date={data.date} title={data.name}>
                        <div className="font-medium text-[0.75rem] flex gap-2">
                          {data.unit_name}
                        </div>
                      </StatusCard>
                    ))
                  ) : (
                    <p className="bg-[#F6f6f6] p-3 min-h-[4rem] justify-center font-semibold flex h-full text-center flex-col gap-3 rounded-lg">
                      No Data Available
                    </p>
                  )}
                </>
              ),
              detailsFragment: <></>,
            },
            {
              id: "Trust",
              summaryFragment: (
                <>
                  {trustData.length ? (
                    trustData.slice(0, 1).map((data) => (
                      <StatusCard date={data.date} title="Trust">
                        <ul className="text-start font-medium flex-col list-none text-[0.75rem] text-[#757575] flex gap-2">
                          <li>Trust Key: {data.trust_key_percent}</li>
                          <li>Pre-Shot Routine: {data.pre_shot_percent}</li>
                          <li>Post-Shot Routine: {data.post_shot_percent}</li>
                        </ul>
                      </StatusCard>
                    ))
                  ) : (
                    <p className="bg-[#F6f6f6] p-3 min-h-[4rem] justify-center font-semibold flex h-full text-center flex-col gap-3 rounded-lg">
                      No Data Available
                    </p>
                  )}
                </>
              ),
              detailsFragment: <></>,
            },
          ]}
        />
      </div>
      <div className="h-full border shadow-section border-[#C6D6DD] rounded-xl">
        <CollapsibleWithDropDown
          title="Rating"
          defaultValues={{ rating: "Weekly Rating" }}
          inputRef="rating"
          options={[
            { label: "Weekly Rating", value: "Weekly Rating" },
            { label: "Session rating", value: "Session Rating" },
            { label: "SCC Checking", value: "Self Check Audit" },
          ]}
          defaultFragment={
            <>
              <RatingComponents rate={[]} />
            </>
          }
          data={[
            {
              id: "Weekly Rating",
              summaryFragment: (
                <>
                  {weeklyRatings.length ? (
                    <RatingComponents rate={weeklyRatings.slice(0, 1)} />
                  ) : (
                    <NoDataBox />
                  )}
                </>
              ),
              detailsFragment: (
                <>
                  {weeklyRatings.length ? (
                    <>
                      <RatingComponents rate={weeklyRatings.slice(0, 1)} />
                      <p className="text-[0.875rem] text-[#757575]">
                        {weeklyRatings[0]?.text}
                      </p>
                    </>
                  ) : (
                    <NoDataBox />
                  )}
                </>
              ),
            },
            {
              id: "Session Rating",
              summaryFragment: (
                <>
                  {sessionRatings.length ? (
                    <RatingComponents rate={sessionRatings.slice(0, 1)} />
                  ) : (
                    <NoDataBox />
                  )}
                </>
              ),
              detailsFragment: (
                <>
                  {sessionRatings.length ? (
                    <>
                      <RatingComponents rate={sessionRatings.slice(0, 1)} />
                      <p className="text-[0.875rem] text-[#757575]">
                        {sessionRatings[0]?.text}
                      </p>
                    </>
                  ) : (
                    <NoDataBox />
                  )}
                </>
              ),
            },
            {
              id: "Self Check Audit",
              summaryFragment: (
                <>
                  {sccRatings.length ? (
                    sccRatings?.slice(0, 1).map((i) => (
                      <div className="bg-[#F6f6f6] p-3 flex flex-col gap-3 rounded-lg">
                        <div className="text-[0.875rem] flex justify-between font-semibold">
                          <div>{i.rating}</div>
                          <div className="text-[#757575] text-[0.75rem] font-normal">
                            {i.date}
                          </div>
                        </div>
                        <div className="text-[0.75rem]">{i.text}</div>
                      </div>
                    ))
                  ) : (
                    <NoDataBox />
                  )}
                </>
              ),
              detailsFragment: (
                <>
                  {sccRatings.length ? (
                    sccRatings?.slice(0, 1).map((i) => (
                      <div className="bg-[#F6f6f6] p-3 flex flex-col gap-3 rounded-lg">
                        <div className="text-[0.875rem] flex justify-between font-semibold">
                          <div>{i.rating}</div>
                          <div className="text-[#757575] text-[0.75rem] font-normal">
                            {i.date}
                          </div>
                        </div>
                        <div className="text-[0.75rem]">{i.text}</div>
                      </div>
                    ))
                  ) : (
                    <NoDataBox />
                  )}
                </>
              ),
            },
          ]}
        />
      </div>

      <div className="h-full border shadow-section border-[#C6D6DD] rounded-lg">
        <CollapsibleWithDropDown
          disabled
          defaultValues={{ feedback: "All" }}
          title="Feedback"
          inputRef="feedback"
          options={[
            { label: "Shot Check", value: "Shot Check" },
            { label: "Swing Videos", value: "Swing Videos" },
            { label: "Coach Reviews", value: "Coach Reviews" },
            { label: "All", value: "All" },
          ]}
          defaultFragment={<></>}
          data={[
            {
              id: "Coach Reviews",
              summaryFragment: (
                <>
                  {feedBackData?.coach_reviews.length ? (
                    feedBackData?.coach_reviews?.slice(0, 1).map((review) => (
                      <StatusCard
                        date={review.date}
                        status={review.status}
                        title="Coach Reviews"
                      >
                        <button
                          className="text-[0.75rem] flex gap-3 items-center"
                          onClick={() => handleCommentCheck(review.comment)}
                        >
                          Coach Feedback{" "}
                          <img src="/images/comment.svg" alt="" />
                        </button>
                        <div
                          className="text-[0.75rem] text-[#757575] flex gap-2 "
                          onClick={() => handleReviewVideoCheck(review.file)}
                        >
                          User Video:
                          <img src="/images/cards/linkicon.svg" alt="" /> Link
                        </div>
                      </StatusCard>
                    ))
                  ) : (
                    <NoDataBox />
                  )}
                </>
              ),
              detailsFragment: <></>,
            },
            {
              id: "All",
              summaryFragment: (
                <>
                  <>
                    <HorizontalBar
                      data={[
                        {
                          name: "Shot Check",
                          pv: feedBackData.shot_check_count,
                        },
                        { name: "Swing Videos", pv: feedBackData.swing_count },
                        {
                          name: "Coach Reviews",
                          pv: feedBackData.coach_review_count,
                        },
                      ]}
                    />
                  </>
                </>
              ),
              detailsFragment: <></>,
            },
            {
              id: "Shot Check",
              summaryFragment: (
                <>
                  {feedBackData?.shot_checks.length ? (
                    feedBackData?.shot_checks?.slice(0, 1).map((check) => (
                      <StatusCard date={check.date} title="Shot Check">
                        <div className="text-[#757575] text-[0.75rem] flex gap-2">
                          Coach FeedBack
                          <div
                            onClick={() => setShowSummary(check.date)}
                            className="relative"
                          >
                            <img src="/images/cards/calender.svg" alt="" />
                            {showsummary === check.date && (
                              <PopUpTable
                                check={check}
                                summaryBox={summaryBox}
                              />
                            )}
                          </div>
                        </div>
                      </StatusCard>
                    ))
                  ) : (
                    <p className="bg-[#F6f6f6] p-3 min-h-[5rem] justify-center font-semibold flex h-full text-center flex-col gap-3 rounded-lg">
                      No Data Available
                    </p>
                  )}
                </>
              ),
              detailsFragment: <></>,
            },
            {
              id: "Swing Videos",
              summaryFragment: (
                <>
                  {feedBackData?.swings.length ? (
                    feedBackData?.swings?.slice(0, 1).map((swing) => (
                      <StatusCard date={swing.date} title="Swing Video">
                        <div
                          className="text-[0.75rem] text-[#757575] flex gap-2"
                          onClick={() => handleVideoCheck(swing.video_link)}
                        >
                          User Video:
                          <img src="/images/cards/linkicon.svg" alt="" /> Link
                        </div>
                      </StatusCard>
                    ))
                  ) : (
                    <p className="bg-[#F6f6f6] p-3 min-h-[5rem] justify-center font-semibold flex h-full text-center flex-col gap-3 rounded-lg">
                      No Data Available
                    </p>
                  )}
                </>
              ),
              detailsFragment: (
                <>
                  <RatingComponents rate={sccRatings.slice(0, 3)} />
                </>
              ),
            },
          ]}
        />
      </div>

      <div className="h-full borde shadow-section border-[#C6D6DD] rounded-lg">
        <Collapsible
          title="Support People "
          summaryFragment={
            <>
              {contacts.length ? (
                <>
                  {contacts.slice(0, 1).map((contact) => (
                    <div className="flex items-center gap-8 py-4 text-sm">
                      <span className="h-10 w-10 bg-[#fff5e5] rounded-full shadow-xl">
                        <img
                          src="/images/Finance.svg"
                          className="h-8 w-8 m-auto"
                          alt=""
                        />
                      </span>
                      <div>
                        <div className="font-medium ">Support People </div>
                        <div className="text-[#]">
                          {contact.name} | {contact.phone}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <NoDataBox />
                </>
              )}
            </>
          }
          detailsFragment={
            <>
              {contacts.length ? (
                <>
                  {contacts.map((contact) => (
                    <div className="flex items-center gap-8 py-4 text-sm">
                      <span className="h-10 w-10 bg-[#fff5e5] rounded-full shadow-xl">
                        <img
                          src="/images/Finance.svg"
                          className="h-8 w-8 m-auto"
                          alt=""
                        />
                      </span>
                      <div>
                        <div className="font-medium ">Support People </div>
                        <div className="text-[#]">
                          {contact.name} | {contact.phone}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <NoDataBox />
              )}
            </>
          }
        />
      </div>

      {showComment ? (
        <CenterPopUpComponent
          onClose={() => setShowComment(false)}
          isOpen={showComment}
          message="Coach Reviews"
        >
          <div className="mx-8  pb-3 flex flex-col gap-3">
            <h1 className="text-[#191919] font-medium text-[0.875rem]">
              Coach Comment
            </h1>
            <p className="text-[#9e9e9e] font-medium text-[0.875rem]">
              {Comment}
            </p>
            <p className="flex items-center gap-2">
              <button>
                <img src="/images/cards/linkicon.svg" alt="" />
              </button>
              <span className="text-sm text-[#9e9e9e]">Link name</span>
            </p>
          </div>
        </CenterPopUpComponent>
      ) : (
        <></>
      )}
      {showReview ? (
        <CenterPopUpComponent
          onClose={() => setShowReview(false)}
          isOpen={showReview}
          message="Coach Reviews"
        >
          <div className=" pb-3 w-[800px] h-[500px] flex flex-col gap-3">
            <CustomPlayer url={review} />
          </div>
        </CenterPopUpComponent>
      ) : (
        <></>
      )}
      {showVideo ? (
        <CenterPopUpComponent
          onClose={() => setShowVideo(false)}
          isOpen={showVideo}
          message="Swing Video"
        >
          <div className=" pb-3 w-[800px] h-[500px] flex flex-col gap-3">
            <CustomPlayer url={video} />
          </div>
        </CenterPopUpComponent>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProfileSummary;

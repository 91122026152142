import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const DisableSmallLeftIcon: React.FC<IconProps> = ({
  width = 6,
  height = 11,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 6 11"
      fill="none"
    >
      <path
        d="M5.5 1L0.5 5.5L5.5 10"
        stroke="#DADADA"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DisableSmallLeftIcon;

import React, { ReactNode, useEffect } from "react";
import NavBarComponent from "../Components/NavBarComponent";
import SidebarComponent from "../Components/SidebarComponent";
import { useAppSelector } from "../Redux/app/hooks";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
// import { CoachSideBarRoutes } from "../utils/SidebarRoutes";
import { coachRoutes } from "../routes/coach/coachRoutes";
import { CoachSideBarRoutes } from "../Components/utils";

type Props = {
  children: ReactNode;
};

const CoachRoutes = ({ children }: Props) => {
  const showSideBar = useAppSelector((state) => state.UIController.showSideBar);
  const [cookies, _setCookies, remove] = useCookies(["token", "ic"]);
  useEffect(() => {
    if (!cookies.token) {
      remove("ic", {
        path: "/usermanagement",
      });
      remove("token", {
        path: "/usermanagement",
      });
      remove("ic");
      remove("token");
    }
    if (!cookies.ic) {
      console.log("Cookie Missing");
      remove("ic", {
        path: "/usermanagement",
      });
      remove("token", {
        path: "/usermanagement",
      });
      remove("ic");
      remove("token", { path: "/" });
    }
  }, [cookies]);
  return (
    <>
      {cookies.token && cookies.ic === "1" ? (
        <>
          <NavBarComponent />
          <div className="flex flex-row grid-cols-12 h-[calc(100vh-70px)]">
            <SidebarComponent sidebarRoutes={CoachSideBarRoutes} />
            <div className={`w-screen transition rounded-[10px] bg-white`}>
              {children}
            </div>
          </div>
        </>
      ) : (
        <Navigate to={"/"} />
      )}
    </>
  );
};

export default CoachRoutes;

import ForgetPasswordPage from "../../Pages/Auth/ForgetPasswordPage";
import LoginPage from "../../Pages/Auth/LoginPage";
import ResetPasswordPage from "../../Pages/Auth/ResetPasswordPage";
import OpenRoutes from "../../auth/OpenRoutes";
import PublicRoutes from "../../auth/PublicRoutes";

export const authRoutes = [
  {
    id: "auth1",
    path: "/",
    name: "Login",
    module: "Auth",
    component: LoginPage,
    auth: PublicRoutes,
    breadCrumbRoutes: [
      {
        name: "Login",
        path: "/",
      },
    ],
  },
  {
    id: "auth2",
    path: "/forgetpassword",
    name: "Forget Password",
    module: "Auth",
    component: ForgetPasswordPage,
    auth: OpenRoutes,
    breadCrumbRoutes: [
      {
        name: "Forget Password",
        path: "#",
      },
    ],
  },
  {
    id: "auth3",
    path: "/forgetpassword/:hashedID/:email",
    name: "Reset Password",
    module: "Auth",
    component: ResetPasswordPage,
    auth: OpenRoutes,
    breadCrumbRoutes: [
      {
        name: "Reset Password",
        path: "/forgetpassword/:hashedID",
      },
    ],
  },
];

import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputComponent from "../../Components/Inputs/InputComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { AddTagAPI, TagListAPI } from "../../Redux/features/TagSlice";
import { Status } from "../../Redux/features/UserManagementSlice";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import {
  getDiscountCodeList,
  getDiscountResponse,
  postDiscountCodeList,
} from "../../Redux/features/DiscountSlice";
import { notifyError, notifySuccess } from "../../helper";

type Props = {
  setAddFormModal: React.Dispatch<React.SetStateAction<boolean>>;
  details: getDiscountResponse | null;
};

type FormValues = {
  plan_id: string;
  code: string;
  discount: number;
  quantity: number;
};

const AddNewDiscountCode = ({ setAddFormModal, details = null }: Props) => {
  const validationSchema = Yup.object().shape({
    plan_id: Yup.string().required("Plan is required"),
    quantity: Yup.number().required("Plan is required"),
    code: Yup.string()
      .required("Code is required")
      .min(4, "Code must be at least 4 characters"),
    discount: Yup.number().required("Discount Value is required"),
  });

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      plan_id: details?.plan_id,
      code: details?.code,
      quantity: Number(details?.quantity) || 0,
      discount: details?.discount || 0,
    },
  });

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  console.log(errors);
  const dispatch = useAppDispatch();
  const sortType = useAppSelector((state) => state.Discount.sortType);
  const sortValue = useAppSelector((state) => state.Discount.sortValue);

  const tagSortType = useAppSelector((state) => state.Tag.sortType);
  const onSubmit = async (data: FormValues) => {
    dispatch(postDiscountCodeList({ ...data, id: details?._id })).then(
      (res) => {
        if (postDiscountCodeList.fulfilled.match(res)) {
          notifySuccess("Discount Code Generated");
          setAddFormModal(false);
          dispatch(
            getDiscountCodeList({
              page: 1,
              sortType,
              sortValue,
            })
          );
        }
      }
    );
  };

  const formValues = watch();
  // const plans = useAppSelector((state) => state.Discount.plans);

  const options = [
    {
      label: "",
      value: "",
    },
  ];

  const loading = useAppSelector(
    (state) => state.Discount.postDiscountCodesAPIStatus
  );

  return (
    <div className="p-4 flex flex-col h-full">
      <h2 className="text-xl font-bold mb-4">Generate Discount Code</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
        <div className="mt-auto">
          <div className="flex flex-col gap-3 justify-end">
            {/* <SelectComponent<FormValues>
              asterisk
              control={control}
              inputRef="plan_id"
              options={plans?.map((plan) => {
                return {
                  label: plan.name.toUpperCase(),
                  value: plan._id,
                };
              })}
              label="Plan"
            /> */}
            <InputComponent<FormValues>
              register={register}
              inputRef="quantity"
              label="Quantity"
              name="Quantity"
              value={String(formValues.quantity)}
            />
            <InputComponent
              register={register}
              inputRef="code"
              label="Code"
              name="question"
              disabled
              value={String(formValues.code)}
            />
            <div className="flex rounded-none col-end-3 ">
              <button
                disabled={loading === Status.LOADING}
                className="bg-bluegradient text-white rounded-md px-4 py-2 mr-2 w-1/2"
              >
                {loading === Status.LOADING ? "Submitting" : "Save"}
              </button>
              <button
                className=" text-red-400 px-4 py-2 rounded w-1/2"
                onClick={() => setAddFormModal((prev) => !prev)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewDiscountCode;

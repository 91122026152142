import React, { useState } from "react";
import TabItem from "./TabItem";

const Tabs: React.FC<TabsProps> = ({ items }) => {
  const [activeTab, setActiveTab] = useState<string>(items[0].label);

  return (
    <div className="grid gap-4 grid-cols-10 bg-secondary-dark">
      <div className="h-[calc(100vh-11rem)]  example overflow-auto col-span-2 justify-start items-start">
        <ul className="gap-4 py-4 flex flex-col font-medium  ">
          {items.map((item) => (
            <TabItem
              key={item.label}
              isActive={item.label === activeTab}
              label={item.label}
              onSelect={() => setActiveTab(item.label)}
            />
          ))}
        </ul>
      </div>
      <div className="col-span-8 px-2  rounded-md">
        {items.map((item) => {
          if (item.label === activeTab) {
            return (
              <div
                className="w-full h-[calc(100vh-11rem)] example overflow-auto p-4 bg-white rounded-md"
                key={item.label}
              >
                {item.content}
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default Tabs;

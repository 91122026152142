import React from 'react';
import classnames from 'classnames';

interface TabProps {
    isActive: boolean;
    label: string;
    onSelect: () => void;
}

const TabItem: React.FC<TabProps> = ({ isActive, label, onSelect }) => {

    return (
        <li className={classnames("px-3 font-semibold rounded-2xl  py-4 w-full  cursor-pointer bg-[#F9FAFB] text-[#9E9E9E]", {
            'border-l-8 border-l-[#FC8A2E]  text-[#FC8A2E]': isActive
        })} onClick={onSelect}>
            {label}
        </li>
    );
};

export default TabItem;

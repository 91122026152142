import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";
import { ApiResponse } from "../../types/apiresponse";

export const getSwingCheckList = createAsyncThunk(
  "coach/swingcheck",
  async (data?: any) => {
    const res = await axiosClient.get(
      `auth/swing?per_page=10&page=${data?.page}${
        data.searchValue ? `&filteredVal=${data.searchValue}` : ""
      }${data.sortType === "ascending" ? `&sort=-${data.sortValue}` : ``}${
        data.sortType === "descending" ? `&sort=${data.sortValue}` : ``
      }${data.filterValue ? `&filteredVal=${data.filterValue}` : ""}`
    );
    return {
      data: res.data,
      active: data.page,
    };
  }
);

export const getSwingCheckByID = createAsyncThunk(
  "coach/swingcheckbyid",
  async (data?: any) => {
    const res = await axiosClient.get(
      `auth/coach_review_detail_by_swing/${data?.id}`
    );
    console.log(res.data);
    return res.data;
  }
);
export const getRepCheckByID = createAsyncThunk(
  "coach/repcheckbyid",
  async (data?: any) => {
    const res = await axiosClient.get(
      `auth/coach_review_detail_by_rep_video/${data?.id}`
    );
    console.log(res.data);
    return res.data;
  }
);

export const getSwingCheckDetails = createAsyncThunk(
  "coach/swingcheckDetails",
  async (data?: any) => {
    const res = await axiosClient.get(`auth/coach_review/${data}`);
    return {
      data: res.data,
      // active: data.page
    };
  }
);

export interface submitCoachReviewRequest {
  id?: string;
  swing_id: string;
  status: "In Progress" | "Completed" | "Pending";
  description: string;
  vimeo_link: string;
  drills: string;
}
export interface submitRepReviewRequest {
  id?: string;
  swing_id: string;
  status: "In Progress" | "Completed" | "Pending";
  description: string;
  video: File;
}

//
export interface CoachReview {
  success: boolean;
  message: string;
  data: CoachReviewData;
}

export interface CoachReviewData {
  id: string;
  swing_id: string;
  description: string;
  drills: DrillObjectId[];
  drill_list: DrillDetail[];
  status: string;
  coach_id: string;
  coach_name: string;
  coach_img: string;
  vimeo_link: string;
  created_at: string;
  last_update: string;
}

export interface DrillObjectId {
  object_id: string;
}

export interface DrillDetail {
  object_id: string;
  tag_id: string;
  object_name: string;
  tag_name: string;
  headline: string;
  subtitle: string;
  description: string;
  file: string;
  type: string;
  video_id: string;
  link_items: LinkItem[];
}

export interface LinkItem {
  title: string;
  link: string;
  description: string;
}
//

export const submitCoachReview = createAsyncThunk<
  any,
  submitCoachReviewRequest
>("coach/review", async (data) => {
  const res = await axiosClient.post("auth/admin_coach_review", data);
  return res.data;
});

export const submitRepReview = createAsyncThunk<any, FormData>(
  "coach/repreview",
  async (data) => {
    const res = await axiosClient.post("auth/admin_coach_rep_review", data);
    return res.data;
  }
);

export const getReview = createAsyncThunk<getReviewResponse, string>(
  "coach/getreview",
  async (data) => {
    const res = await axiosClient.get(`auth/coach_review/${data}`);
    return res.data;
  }
);

export const getDashboard = createAsyncThunk<getDashboardResponse, void>(
  "coach/Dashboard",
  async () => {
    const res = await axiosClient.get("auth/dashboard");
    return res.data;
  }
);
export const getDrillList = createAsyncThunk<ApiResponse<ObjectItem[]>, any>(
  "coach/drills",
  async (data) => {
    const res = await axiosClient.get(
      `auth/drills?${
        data.searchValue ? `&filteredVal=${data.searchValue}` : ""
      }${data.sortType === "ascending" ? `&sort=-${data.sortValue}` : ``}${
        data.sortType === "descending" ? `&sort=${data.sortValue}` : ``
      }`
    );
    return res.data;
  }
);

interface getTicketsResponse {}

interface postTicketsResponse {}
interface deleteTicketsResponse {}

export const getTickets = createAsyncThunk<getTicketsResponse, void>(
  "coach/gettickets",
  async () => {}
);
export const postTicket = createAsyncThunk<postTicketsResponse, void>(
  "coach/posttickets",
  async () => {}
);
export const deletetTickets = createAsyncThunk<getTicketsResponse, void>(
  "coach/deletetickets",
  async () => {}
);

interface getCoachListAPI extends Pagination {
  coaches: UserType[];
}

export const getCoachListAPI = createAsyncThunk<
  ApiResponse<getCoachListAPI>,
  UserTypePayload
>("UserManagement/coachList", async (UserTypePayload) => {
  const res = await axiosClient.get<ApiResponse<getCoachListAPI>>(
    `auth/get-coach-list?page=${UserTypePayload.page}&type=${
      UserTypePayload.userType
    }${
      UserTypePayload.searchValue
        ? `&filteredVal=${UserTypePayload.searchValue}`
        : ""
    }${
      UserTypePayload.sortType === "ascending"
        ? `&sort=-${UserTypePayload.sortValue}`
        : ``
    }${
      UserTypePayload.sortType === "descending"
        ? `&sort=${UserTypePayload.sortValue}`
        : ``
    }
    ${
      UserTypePayload?.filter_dashboard !== "" &&
      UserTypePayload.filter_dashboard
        ? `&filter_dashboard=${UserTypePayload.filter_dashboard}`
        : ``
    }
    `
  );
  return res.data;
});

interface IinitialState {
  getDrillListAPIIdle: Status;
  reviewDetails: CoachReviewData;
  drillList: ObjectItem[];
  ticketListsortType: sortValues;
  ticketListsortValue: string;
  getCoachListAPIIdle: Status;
  coachList: UserType[];
  getSwingCheckList: Status;
  submitCoachReview: Status;
  getDashboard: Status;
  swingList: SwingVideo[];
  count: number;
  total: number;
  active: number;
  getReview: Status;
  review: SwingReview;
  dashboard: coachDashBoard;
  sortType: sortValues;
  drillSortType: sortValues;
  sortValue: string;
  drillSortValue: string;
  getSwingCheckByIDIdle: Status;
  getRepCheckByIDIdle: Status;
  submitRepReviewIdle: Status;
  filterValue: "Completed" | "Pending" | "In Progress" | "";
}

const initialState: IinitialState = {
  getRepCheckByIDIdle: Status.IDLE,
  submitRepReviewIdle: Status.IDLE,
  reviewDetails: {
    coach_id: "",
    coach_img: "",
    coach_name: "",
    created_at: "",
    description: "",
    drill_list: [],
    drills: [],
    id: "",
    last_update: "",
    status: "",
    swing_id: "",
    vimeo_link: "",
  },
  drillList: [],
  filterValue: "",
  getSwingCheckByIDIdle: Status.SUCCESS,
  getDrillListAPIIdle: Status.SUCCESS,
  getCoachListAPIIdle: Status.SUCCESS,
  coachList: [],
  getDashboard: Status.SUCCESS,
  sortType: "descending",
  drillSortType: "descending",
  drillSortValue: "object_name",
  ticketListsortType: "ascending",
  sortValue: "title",
  ticketListsortValue: "created_at",
  getSwingCheckList: Status.SUCCESS,
  submitCoachReview: Status.SUCCESS,
  getReview: Status.SUCCESS,
  review: {
    coach_id: "",
    coach_name: "",
    comment: "",
    created_at: "",
    file: "",
    id: "",
    last_update: "",
    rating: 0,
    status: "",
    swing_id: "",
    title: "",
    user_id: "",
    user_name: "",
  },
  swingList: [],
  count: 0,
  total: 0,
  active: 1,
  dashboard: {
    total_swing: 0,
    in_progress_swing: 0,
    reviewed_swing: 0,
    pending_swing: 0,
    avg_response_time: 0,
  },
};

const CoachReviewSlice = createSlice({
  name: "coach",
  initialState,
  reducers: {
    changeFilterValue: (
      state,
      action: PayloadAction<"Completed" | "In Progress" | "Pending" | "">
    ) => {
      state.filterValue = action.payload;
    },
    drillListHandleSort: (state, action) => {
      return {
        ...state,
        drillSortType: action.payload.sortType,
        drillSortValue: action.payload.sortValue,
      };
    },
    ticketListHandleSort: (state, action) => {
      return {
        ...state,
        ticketListsortType: action.payload.sortType,
        ticketListsortValue: action.payload.sortValue,
      };
    },
    handleSort: (state, action) => {
      return {
        ...state,
        sortType: action.payload.sortType,
        sortValue: action.payload.sortValue,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getSwingCheckList.pending, (state) => {
        state.getSwingCheckList = Status.LOADING;
      })
      .addCase(getSwingCheckList.fulfilled, (state, action) => {
        state.getSwingCheckList = Status.SUCCESS;
        state.swingList = action.payload.data.data?.swings;
        state.count = action.payload.data.data?.swings_count;
        state.total = action.payload.data.data?.total_page;
        state.active = action.payload?.active;
      })
      .addCase(getSwingCheckList.rejected, (state) => {
        state.getSwingCheckList = Status.ERROR;
      })
      .addCase(submitCoachReview.pending, (state) => {
        state.submitCoachReview = Status.LOADING;
      })
      .addCase(submitCoachReview.fulfilled, (state, action) => {
        state.submitCoachReview = Status.SUCCESS;
      })
      .addCase(submitCoachReview.rejected, (state) => {
        state.submitCoachReview = Status.ERROR;
      })
      .addCase(getSwingCheckByID.pending, (state) => {
        state.getSwingCheckByIDIdle = Status.LOADING;
      })
      .addCase(getSwingCheckByID.fulfilled, (state, action) => {
        state.getSwingCheckByIDIdle = Status.SUCCESS;
        state.reviewDetails = action.payload.data;
      })
      .addCase(getSwingCheckByID.rejected, (state) => {
        state.getSwingCheckByIDIdle = Status.ERROR;
      })
      .addCase(getRepCheckByID.pending, (state) => {
        state.getRepCheckByIDIdle = Status.LOADING;
      })
      .addCase(getRepCheckByID.fulfilled, (state, action) => {
        state.getRepCheckByIDIdle = Status.SUCCESS;
        state.reviewDetails = action.payload.data;
      })
      .addCase(getRepCheckByID.rejected, (state) => {
        state.getRepCheckByIDIdle = Status.ERROR;
      })
      .addCase(getReview.pending, (state) => {
        state.getReview = Status.LOADING;
      })
      .addCase(getReview.fulfilled, (state, action) => {
        state.getReview = Status.SUCCESS;
        state.review = action.payload.data[0];
      })
      .addCase(getReview.rejected, (state) => {
        state.getReview = Status.ERROR;
      })
      .addCase(getDashboard.pending, (state) => {
        state.getDashboard = Status.LOADING;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.getDashboard = Status.SUCCESS;
        state.dashboard = action.payload.data;
      })
      .addCase(getDashboard.rejected, (state) => {
        state.getDashboard = Status.ERROR;
      })
      .addCase(getDrillList.pending, (state) => {
        state.getDrillListAPIIdle = Status.LOADING;
      })
      .addCase(getDrillList.fulfilled, (state, action) => {
        state.getDrillListAPIIdle = Status.SUCCESS;
        state.drillList = action.payload.data;
      })
      .addCase(getDrillList.rejected, (state) => {
        state.getDrillListAPIIdle = Status.ERROR;
      })
      .addCase(submitRepReview.pending, (state) => {
        state.submitRepReviewIdle = Status.LOADING;
      })
      .addCase(submitRepReview.fulfilled, (state, action) => {
        state.submitRepReviewIdle = Status.SUCCESS;
        state.drillList = action.payload.data;
      })
      .addCase(submitRepReview.rejected, (state) => {
        state.submitRepReviewIdle = Status.ERROR;
      })
      .addCase(getCoachListAPI.pending, (state) => {
        state.getCoachListAPIIdle = Status.LOADING;
      })
      .addCase(getCoachListAPI.fulfilled, (state, action) => {
        state.getCoachListAPIIdle = Status.SUCCESS;
        state.coachList = action.payload.data.coaches;
        state.active = action.payload.data.current_page;
        state.total = action.payload.data.last_page;
      })
      .addCase(getCoachListAPI.rejected, (state) => {
        state.getCoachListAPIIdle = Status.ERROR;
      });
  },
});

export default CoachReviewSlice.reducer;

export const {
  handleSort,
  changeFilterValue,
  ticketListHandleSort,
  drillListHandleSort,
} = CoachReviewSlice.actions;

import React, { useEffect, useState } from "react";
import {
  Control,
  SetFieldValue,
  UseFieldArrayReturn,
  UseFormRegister,
  useFieldArray,
} from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import InputComponent from "../../Components/Inputs/InputComponent";
import { AddFundamentalForm } from "./AddFundamentalPage";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import UploadFileComponents from "../../Components/Inputs/UploadFileComponents";

// const MemoizedInputComponent = React.memo(InputComponent);

let renderCount = 0;

type Props = {
  control: Control<AddFundamentalForm>;
  register: UseFormRegister<AddFundamentalForm>;
  index: number;
  fieldArray: UseFieldArrayReturn<AddFundamentalForm, "micromoves", "id">;
  closeForm?: () => void;
  cancelForm?: () => void;
  formValue: AddFundamentalForm;
  setValue: any;
};

export default function FundamentalForm({
  cancelForm,
  formValue,
  setValue,
  control,
  register,
  index,
  fieldArray,
  closeForm = () => { },
}: Props) {
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: `micromoves.${index}.steps`,
  });

  const childrenRef = React.useRef<any>(null);

  const reorder = (result: any) => {
    console.log(result);
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    const sourceIndex = source.index;
    const destIndex = destination.index;

    if (type === "parentContainer") {
      move(sourceIndex, destIndex);
    } else if (type === "childContainer" && source.droppableId) {
      const reorderChild = childrenRef.current[source.droppableId];
      if (reorderChild) {
        reorderChild(sourceIndex, destIndex);
      }
    }
  };
  renderCount++;
  const handleClose = () => {
    if (cancelForm) {
      cancelForm();
    } else {
      // fieldArray.remove(index);
    }
    closeForm();
  };
  console.log(renderCount, "renderCount");
  return (
    <>
      <DragDropContext onDragEnd={reorder}>
        <Droppable droppableId="parent" type="parentContainer">
          {(provided) => (
            <ul
              className="flex flex-col gap-5 px-5"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className="bg-[#EAF0F3] rounded-lg px-8 py-4 flex flex-col gap-3">
                <p className="text-[#1F3161] text-[14px] font-semibold">
                  Micro Move Details
                </p>
                <h3 className="font-normal">
                  <span className="text-[#767576] font-normal">Name :</span> {formValue.micromoves[index].title}
                </h3>
                <h3 className="font-normal">
                  <span className="text-[#767576] font-normal">Description :</span>{" "}
                  {formValue.micromoves[index].description}
                </h3>
              </div>
              <div className="flex justify-between">
                {fields.length ? "Step Lists :" : ""}
                <button
                  type="button"
                  className=""
                  onClick={() =>
                    append({ detail: "", vimeo_link: "", image: "" })
                  }
                >
                  <span className={`flex justify-center gap-2 items-center`}>
                    <img src="/images/plusButton.svg" alt="" />
                    Add Step
                  </span>
                </button>
              </div>
              <div className="max-h-[30vh] flex flex-col gap-5 py-3  overflow-auto">
                {fields.map((item, indexPoint) => {
                  console.log(item, "SD");

                  return (
                    <>
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={indexPoint}
                      >
                        {(provided) => (
                          <li
                            key={item.id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className="grid grid-cols-10 gap-4 justify-between">
                              <FundamentalFormArray
                                control={control}
                                formValue={formValue}
                                index={index}
                                indexPoint={indexPoint}
                                provided={provided}
                                register={register}
                                remove={remove}
                                setValue={setValue}
                              />
                            </div>
                          </li>
                        )}
                      </Draggable>
                    </>
                  );
                })}
              </div>

              {provided.placeholder}
              <div className="grid grid-cols-7 gap-3">
                <ButtonComponent
                  CTA="Cancel"
                  onClick={() => handleClose()}
                  buttonType="button"
                  className="col-start-6"
                  varient="outline"
                />
                <ButtonComponent
                  CTA="Save"
                  onClick={() => closeForm()}
                  buttonType="button"
                  varient="blue"
                />
              </div>
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

type FundamentalFormArrayProps = {
  provided: any;
  index: any;
  indexPoint: any;
  register: any;
  setValue: any;
  control: any;
  formValue: any;
  remove: any;
};

const FundamentalFormArray = ({
  provided,
  index,
  indexPoint,
  register,
  setValue,
  control,
  formValue,
  remove,
}: FundamentalFormArrayProps) => {
  const [fileType, setFileType] = useState(false);
  const handleChange = (event: any) => {
    console.log(event?.target?.value);
    if (event?.target?.value === "true") {
      setFileType(true);
      setValue(`micromoves.${index}.steps.${indexPoint}.vimeo_link`, "");
    } else {
      setFileType(false);
      setValue(`micromoves.${index}.steps.${indexPoint}.image`, "");
    }
  };
  console.log(formValue?.micromoves[index]?.steps[indexPoint]?.image)
  useEffect(() => {
    if (formValue?.micromoves[index]?.steps[indexPoint]?.image !== "") {
      setFileType(true);
    }

  }, [])



  return (
    <>
      <div
        className="flex col-span-1 my-auto flex-col items-center justify-center h-full py-[10px] mx- px-[8px] border bg-[#EAF0F3]  rounded-md  gap-1"
        {...provided.dragHandleProps}
      >
        <span className="block  w-6 h-[2px] bg-[#285A9F]"></span>
        <span className="block  w-6 h-[2px] bg-[#285A9F]"></span>
        <span className="block  w-6 h-[2px] bg-[#285A9F]"></span>
      </div>
      <div className="col-span-3">
        <InputComponent
          label="Step Name and Description"
          inputRef={`micromoves.${index}.steps.${indexPoint}.detail`}
          register={register}
          name={`micromoves.${index}.steps.${indexPoint}.detail`}
          value=""
        />
      </div>
      {!fileType ? (
        <div className="col-span-3">
          <InputComponent
            label="Pastes link of Vimeo"
            inputRef={`micromoves.${index}.steps.${indexPoint}.vimeo_link`}
            register={register}
            name={`micromoves.${index}.steps.${indexPoint}.vimeo_link`}
            value=""
          />
        </div>
      ) : (
        <div className="col-span-3 my-auto">
          <UploadFileComponents
            onClick={() =>
              setValue(`micromoves.${index}.steps.${indexPoint}.image`, "")
            }
            control={control}
            id={`micromoves.${index}.steps.${indexPoint}.image`}
            inputRef={`micromoves.${index}.steps.${indexPoint}.image`}
            label={
              typeof formValue?.micromoves[index]?.steps[indexPoint]?.image !== "string"
                ? formValue?.micromoves[index]?.steps[indexPoint]?.image[0]?.name
                : formValue?.micromoves[index]?.steps[indexPoint]?.image === ""
                  ? "Upload Image"
                  : formValue?.micromoves[index]?.steps[indexPoint]?.image.split("/")[4]
            }
            value={formValue?.micromoves[index]?.steps[indexPoint]?.image}
          />
        </div>
      )}
      <div
        className="col-span-2 font-semibold text-[#3c4c76]
          font-blue-950 text-sm  flex flex-col gap-1"
      >
        Step Media :
        <div className="flex gap-3">
          <div className="">
            <label
              className="flex font-semibold text-[#3c4c76]
          font-blue-950 text-sm items-center gap-2"
            >
              Image
              <input
                type="radio"
                name={`toggle${indexPoint}`}
                value="true"
                checked={fileType}
                onChange={handleChange}
              />
            </label>
          </div>
          <div>
            <label
              className="flex font-semibold text-[#3c4c76]
          font-blue-950 text-sm items-center gap-2"
            >
              Video
              <input
                type="radio"
                name={`toggle${indexPoint}`}
                value="false"
                checked={!fileType}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
      </div>

      <button type="button" onClick={() => remove(indexPoint)}>
        <img className="mx-4 w-6" src="/images/remove.svg" alt="remove" />
      </button>
    </>
  );
};

import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const QuestionIcon: React.FC<IconProps> = ({ width = 10, height = 18 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10 18" fill="none">
  <path d="M1 5.0607C1 -0.202524 9 -0.202524 9 5.0607C9 8.81982 5.36373 8.06778 5.36373 12.579M5.37333 17.1133L5.384 17.1012" stroke="#1F3161" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
  );
};

export default QuestionIcon;

import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const FlipIcon: React.FC<IconProps> = ({ width = 22, height = 22 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M14.75 2.36328L17.75 5.36328M17.75 5.36328L14.75 8.36328M17.75 5.36328H7.25C6.45435 5.36328 5.69129 5.67935 5.12868 6.24196C4.56607 6.80457 4.25 7.56763 4.25 8.36328V9.86328M7.25 18.8633L4.25 15.8633M4.25 15.8633L7.25 12.8633M4.25 15.8633H14.75C15.5456 15.8633 16.3087 15.5472 16.8713 14.9846C17.4339 14.422 17.75 13.6589 17.75 12.8633V11.3633"
        stroke="#1F3161"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FlipIcon;

import { useRef, useState } from 'react'
// import { PreviewHeader } from './Preivew/PreviewHeader'
import ButtonComponent from '../../Components/Buttons/ButtonComponent'
import SmallSavedIcon from '../../Components/Icons/SmallSavedIcon'
import SmallFlagIcon from '../../Components/Icons/SmallFlagIcon'
import QuoteIcon from '../../Components/Icons/QuoteIcon'
import CustomPlayer from '../../Components/VideoPlayer/VideoPlayer'
import PreviewPlayer from '../../Components/VideoPlayer/PreviewPlayer'
import QuestionIcon from '../../Components/Icons/QuestionComp'
import SlotMachineNumber from '../../Components/SlotMachine/SlotMachine'
import OrangeFlipIcon from '../../Components/Icons/OrangeFlipIcon'
import FlipIcon from '../../Components/Icons/FlipIcon'
import Card2 from "../../Components/assets/Card2.svg"
import Card from "../../Components/assets/Card.svg"
type Props = {
    data: RecallCard | undefined
}

const PreviewRecallCard = ({ data }: Props) => {
    const selectedOption = "false"
    const index = "1"
    const currentQuestionIndex = "1"
    const audioRef = useRef<HTMLAudioElement>(null);
    const [Flipped, setFlipped] = useState(false)
    // const renderComponent = () => {
    //     switch (data?.object_type_name) {
    //         case "Audio":
    //             return (
    //                 <div className='px-4'>
    //                     <img src={"/images/AudioBlueImg.svg"} alt="" />
    //                     <div className="flex flex-col py-5 px-3">
    //                         <p
    //                             className={classNames('text-start')}
    //                         >
    //                             {data.headline}
    //                         </p>
    //                         <p
    //                             className={classNames('text-start',)}
    //                             dangerouslySetInnerHTML={{
    //                                 __html: data?.textarea?.replace(/<p>/g, '<br />'),
    //                             }}
    //                         ></p>
    //                     </div>
    //                 </div>

    //             )
    //             break;
    //         case "Video":
    //             return (
    //                 <div><PreviewPlayer
    //                     url={`https://vimeo.com/${data.video_id}`}
    //                 />
    //                     <p
    //                         style={{ padding: '10px' }}
    //                         className="text-start text-primaryBlue text-[1.125rem]"
    //                         dangerouslySetInnerHTML={{
    //                             __html: data.textarea.replace(
    //                                 /<p>/g,
    //                                 '<br />',
    //                             ),
    //                         }}
    //                     ></p>
    //                 </div>
    //             )
    //             break;
    //         case "Image":
    //             return (
    //                 <div> <div className="flex flex-col justify-center items-center gap-2">

    //                 </div> <img src={data.file} alt="" className="aspect-square" />
    //                     <p
    //                         style={{ padding: '10px' }}
    //                         className="text-start text-primaryBlue text-[1.125rem]"
    //                         dangerouslySetInnerHTML={{
    //                             __html: data.textarea.replace(
    //                                 /<p>/g,
    //                                 '<br />',
    //                             ),
    //                         }}
    //                     ></p>
    //                 </div>
    //             )
    //             break;
    //         case "Quote":
    //             return (
    //                 <>   <div>
    //                     <div className="flex flex-col justify-center items-center gap-2">
    //                         <p className="text-[#F58124] text-[1.625rem] font-bold">
    //                             {data.headline}
    //                         </p>
    //                         <p className="text-[#F58124] text-[1.125rem] ">
    //                             {data.subtitle}
    //                         </p>
    //                     </div>
    //                     <div className="bg-white w-full h-fit rounded-[0.625rem] shadow-[0px_4px_4px_0px_rgba(50,50,71,0.05)] px-3 py-2 relative">
    //                         <div className="absolute top-8 left-5">
    //                             <QuoteIcon />
    //                         </div>
    //                         <p
    //                             style={{ padding: '10px', color: '#1F3161' }}
    //                             className="text-center text-primaryBlue text-[1.125rem]"
    //                             dangerouslySetInnerHTML={{
    //                                 __html: data.textarea.replace(
    //                                     /<p>/g,
    //                                     '<br />',
    //                                 ),
    //                             }}
    //                         ></p>
    //                         <div className="flex justify-end w-full">
    //                             <QuoteIcon />
    //                         </div>

    //                         <div className="w-full flex justify-end py-2">
    //                             <button

    //                             >

    //                             </button>
    //                         </div>
    //                     </div>
    //                 </div></>
    //             )
    //             break;
    //         case "Self Check Check":
    //             return (
    //                 <div><PreviewPlayer
    //                     url={`https://vimeo.com/${data.video_id}`}
    //                 />
    //                     <p
    //                         style={{ padding: '10px' }}
    //                         className="text-start text-primaryBlue text-[1.125rem]"
    //                         dangerouslySetInnerHTML={{
    //                             __html: data.textarea.replace(
    //                                 /<p>/g,
    //                                 '<br />',
    //                             ),
    //                         }}
    //                     ></p></div>
    //             )
    //             break;
    //         case "Self Report":
    //             return (
    //                 <div><PreviewPlayer
    //                     url={`https://vimeo.com/${data.video_id}`}
    //                 />
    //                     <p
    //                         style={{ padding: '10px' }}
    //                         className="text-start text-primaryBlue text-[1.125rem]"
    //                         dangerouslySetInnerHTML={{
    //                             __html: data.textarea.replace(
    //                                 /<p>/g,
    //                                 '<br />',
    //                             ),
    //                         }}
    //                     ></p></div>
    //             )
    //             break;
    //         case "Text":
    //             return (
    //                 <>
    //                     <div className='h-full flex flex-col overflow-auto example justify-start items-center'>
    //                         <div className="flex flex-col justify-center items-center gap-2">
    //                             <p className="text-[#F58124] text-[1.625rem] font-bold">
    //                                 {data.headline}
    //                             </p>
    //                             <p className="text-[#F58124] text-[1.125rem] ">
    //                                 {data.subtitle}
    //                             </p>
    //                         </div>
    //                         <div className="bg-white w-full h-fit rounded-[0.625rem] shadow-[0px_4px_4px_0px_rgba(50,50,71,0.05)] px-3 py-2">
    //                             <p
    //                                 style={{ padding: '10px' }}
    //                                 className="text-start text-primaryBlue text-[1.125rem]"
    //                                 dangerouslySetInnerHTML={{
    //                                     __html: data.textarea.replace(
    //                                         /<p>/g,
    //                                         '<br />',
    //                                     ),
    //                                 }}
    //                             ></p>
    //                             <div className="w-full flex justify-end">
    //                                 <button

    //                                 >

    //                                     <img src={"/images/SmallAudioOff.svg"} alt="AudioOn" />
    //                                 </button>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </>
    //             )
    //             break;

    //         default:
    //             return <></>
    //             break;
    //     }
    // }
    return (
        <>
            <div className='w-[21.375rem] rounded-lg pt-4 px-4 flex flex-col justify-between h-[40.75rem] relative overflow-auto example m-auto  shadow-[3px_3px_20px_20px_rgba(172,172,172,0.25)]'>
                <div>

                    <div className="flex text-base justify-between ">
                        Exit
                        <p className="text-center text-blue-950 text- font-semibold leading-normal tracking-wide">
                            Recall Cards
                        </p>
                        <div className="flex justify-center items-center gap-6">
                            <button>
                                <QuestionIcon />
                            </button>
                            <button>
                                <SmallFlagIcon />
                            </button>
                        </div>
                    </div>
                    <SlotMachineNumber columns={13} />

                    <div
                        className={`items-center flex flex-col  relative ${index === currentQuestionIndex ? '' : 'hidden'
                            }`}

                    >
                        <div>
                            <img className='h-[22rem]' src={Card2} alt="" />
                        </div>
                        <div className="  absolute py-6">
                            <img className='h-[23rem]' src={Card} alt="" />
                        </div>
                        {Flipped ? <div className="absolute pt-10 w-[98%]   ">
                            <div
                                // className
                                style={{
                                    backgroundImage: `url(${data?.answer_img})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                }}

                                className="  h-[23.5rem] items-center w-full  m-auto  bg-white rounded-lg shadow-xl border   ">
                                <div className='flex flex-col h-full w-full justify-between items-center'>
                                    <div className='flex px-4 flex-col w-full gap-4 items-center justify-between h-full'>
                                        <p className=" w-full text-base text-center rounded-lg bg-white opacity-70 text-blue-800  py-3 mx-4 leading-[18.67px] tracking-wide ">
                                            Answer
                                        </p>
                                        <div className="bg-white rounded-lg
                                         w-full bg-opacity-70  gap-2 overflow-y-scroll flex flex-col justify-between items-center example">
                                            <div className=' max-h-[10rem] flex flex-col gap-4 example overflow-auto'>

                                                <p
                                                    className=" text-start text-base font-semibold px-2  leading-[18.67px] tracking-wide  "
                                                    dangerouslySetInnerHTML={{
                                                        __html: data?.question_text || "<div></div>",
                                                    }}
                                                ></p>
                                                <p
                                                    className=" text-start text-base font-semibold px-2 leading-[18.67px] tracking-wide  "
                                                    dangerouslySetInnerHTML={{
                                                        __html: data?.answer_text || "<div></div>",
                                                    }}
                                                ></p>
                                            </div>

                                            <div className="pt-2  rounded-lg w-full m-auto text-center">
                                                <div className="   shadow-xl rounded-lg w-full flex mx-auto flex-col h-[90px] py-2 ">
                                                    <p>Evaluate your answer</p>
                                                    <div className="flex w-full items-center  justify-center gap-7">
                                                        <button
                                                            className={`bg-white w-full py-2 rounded-[0.3125rem] text-[#FF790D] border-solid border-[1.5px] border-[#FF790D]   shadow-[0px_4px_8px_0px_rgba(50,50,71,0.06),0px_4px_4px_0px_rgba(50,50,71,0.08)] ${selectedOption === 'false'
                                                                ? 'text-orangegradient'
                                                                : ''
                                                                }`}

                                                        >
                                                            Incorrect
                                                        </button>
                                                        <button
                                                            className={`w-full bg-white py-2 rounded-[0.3125rem]  border-solid border-[1.5px] border-[#FF790D]  shadow-[0px_4px_8px_0px_rgba(50,50,71,0.06),0px_4px_4px_0px_rgba(50,50,71,0.08)] text-[#FF790D]`}
                                                            onClick={() => { }
                                                            }
                                                        >
                                                            Correct
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" flex w-full  pt-5 pb-2 items-end justify-end">
                                                <div
                                                    onClick={() => setFlipped((prev) => !prev)}
                                                    className=" flex rounded-full cursor-pointer shadow-[0px_4px_8px_0px_rgba(50,50,71,0.06),0px_4px_4px_0px_rgba(50,50,71,0.08)] border items-center justify-center w-[40px] h-[40px] "
                                                >
                                                    <FlipIcon />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                {/* {Flipped && (
                                  
                                )} */}
                            </div>
                        </div>
                            :
                            <div className="absolute pt-10 w-[98%] ">
                                <div className=" flex   flex-col h-[23.5rem] items-center  bg-white rounded-lg shadow-xl border   ">
                                    <p className=" text-base text-blue-800 py-3 leading-[18.67px] tracking-wide ">
                                        Question
                                    </p>
                                    <div className=" gap-2 overflow-y-scroll flex flex-col h-full justify-between items-center example">
                                        <p
                                            className="custom-a w-[325px] text-start text-base font-semibold px-6 leading-[18.67px] tracking-wide  "
                                            dangerouslySetInnerHTML={{
                                                __html: data?.question_text || "<div></div>",
                                            }}
                                        ></p>
                                        {typeof data?.question_img === "string" && data?.question_img !== '' ? (
                                            <div className="flex-shrink-0 w-[280px] h-[220px]">
                                                <img
                                                    className="py-3 object-contain w-full h-full"
                                                    src={data?.question_img}
                                                    alt="zx"
                                                />
                                            </div>
                                        ) : (
                                            <></>
                                            // <div className="flex-shrink-0 w-[289px] h-[225px] bg-white"></div>
                                        )}
                                    </div>

                                    <div className=" flex w-full px-3 pt-5 pb-2 items-end justify-end">
                                        <div
                                            onClick={() => setFlipped((prev) => !prev)}
                                            className=" flex rounded-full cursor-pointer shadow-[0px_4px_8px_0px_rgba(50,50,71,0.06),0px_4px_4px_0px_rgba(50,50,71,0.08)] border items-center justify-center w-[40px] h-[40px] "
                                        >
                                            <FlipIcon />
                                        </div>
                                    </div>

                                    {/* {Flipped && (
                                  
                                )} */}
                                </div>
                            </div>
                        }

                    </div>
                </div >
                <div className="mx-auto justify-center items-center mb-4  w-[200px] gap-2">
                    <ButtonComponent
                        className={`w-[100px] ] py-[17px] bg-gradient-to-tl from-orange-500 to-orange-300'
                     rounded-[5px] shadow justify-center items-center text-white text-lg inline-flex`}
                        CTA={`Next Card`}
                        varient='orange'

                    />
                </div>
            </div >

        </ >
    )
}

export default PreviewRecallCard
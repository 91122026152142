import { ReactNode, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";

type Props = {
  children: ReactNode;
};

const PublicRoutes = ({ children }: Props) => {
  const [cookies, _, remove] = useCookies(["token", "ic"]);
  useEffect(() => {
    if (!cookies.token) {
      remove("ic", {
        path: "/usermanagement",
      });
      remove("token", {
        path: "/usermanagement",
      });
      remove("ic");
      remove("token");
    }
    if (!cookies.ic) {
      remove("ic", {
        path: "/usermanagement",
      });
      remove("token", {
        path: "/usermanagement",
      });
      remove("ic");
      remove("token");
    }
  }, [cookies]);

  return (
    <>
      {!cookies.token ? (
        <>{children}</>
      ) : (
        <>
          {cookies.ic === "0" ? (
            <Navigate to={"/usermanagement/userlist"} />
          ) : cookies.ic === "1" ? (
            <Navigate to={"/coachreview/list"} />
          ) : (
            <Navigate to={"/"} />
          )}
        </>
      )}
    </>
  );
};

export default PublicRoutes;

import React from "react";
import HeaderPanel from "./HeaderPanel";
import { useAppSelector } from "../../Redux/app/hooks";
import classnames from "classnames";

type Props = {
  children: React.ReactNode;
  sectionName: string;
  HeaderName: string;
  isCardView?: boolean;
  cards?: React.ReactNode;
  headerButton?: React.ReactNode;
  headerSection?: React.ReactNode;
  headerBgColor?: string;
  subtext?: string;
};

const BoxLayout: React.FC<Props> = ({
  subtext,
  children,
  cards,
  sectionName,
  HeaderName,
  isCardView = false,
  headerButton,
  headerSection,
  headerBgColor,
}) => {
  const dashboard = useAppSelector((state) => state.Coach.dashboard);
  console.log(cards);
  return (
    <div className="rounded-[10px] mx-4 p-4 bg-secondary-dark">
      {/* {renderCount} */}
      <div className="flex w-full flex-col gap-4">
        {HeaderName !== "" ? (
          <HeaderPanel
            sectionName={HeaderName}
            headerSection={headerSection}
            bgColor={headerBgColor}
          />
        ) : (
          <></>
        )}
        {cards}
        <div
          className={classnames(
            "rounded-[10px] h-[calc(100vh-6.5rem-5.475rem)] w-full bg-white overflow-auto",
            {}
          )}
        >
          <div className="flex justify-between">
            {sectionName !== "" ? (
              <span className="py-4 flex items-center gap-3 px-5 font-semibold">
                {sectionName}
                <span className="text-sm font-normal text-[#757575]">
                  {subtext}
                </span>
              </span>
            ) : (
              <></>
            )}
            {headerButton}
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default React.memo(BoxLayout);

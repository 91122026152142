import AddSessionPage from "../../Pages/Sessions/AddSessionPage";
import EditSessionPage from "../../Pages/Sessions/EditSessionPage";
import SessionListPage from "../../Pages/Sessions/SessionListPage";
import AdminRoutes from "../../auth/AdminRoutes";

export const sessionRoutes = [
  {
    id: "session1",
    name: "Session List",
    module: "Content Repository",
    path: "/contentrepository/session",
    component: SessionListPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Content Repository",
        path: "/contentrepository/session",
      },
      {
        name: "Session List",
        path: "#",
      },
    ],
  },
  {
    id: "session2",
    name: "Add Session",
    module: "Content Repository",
    path: "/contentrepository/addsession",
    component: AddSessionPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Content Repository",
        path: "/contentrepository/session",
      },
      {
        name: "Add Session",
        path: "#",
      },
    ],
  },
  {
    id: "session3",
    name: "Edit Session",
    module: "Content Repository",
    path: "/contentrepository/editsession",
    component: EditSessionPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Content Repository",
        path: "/contentrepository/session",
      },
      {
        name: "Edit Session",
        path: "#",
      },
    ],
  },
];

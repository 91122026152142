import AddOnBoardingQuestionPage from "../../Pages/OnBoardingQuestion/AddOnBoardingQuestionPage";
import EditOnBoardingQuestionPage from "../../Pages/OnBoardingQuestion/EditOnBoardingQuestionPage";
import OnboardingQuestionsPage from "../../Pages/OnBoardingQuestion/OnboardingQuestionsPage";
import AboutFisio from "../../Pages/WelcomeMarketing/AboutFisio/AboutFisio";
import WelcomeMarketing from "../../Pages/WelcomeMarketing/AddWelcomeMarketing";
import AdminRoutes from "../../auth/AdminRoutes";

export const onBoardingRoutes = [
  {
    id: "onboarding1",
    name: "onboardingquestionsrepository",
    module: "On-Boarding Questions",
    path: "/onboardingquestionsrepository",
    component: OnboardingQuestionsPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "OnBoarding Questions",
        path: "/onboardingquestionsrepository",
      },
    ],
  },
  {
    id: "onboarding2",
    name: "Edit Questions List",
    module: "On-Boarding Questions",
    path: "/onboardingquestionsrepository/editonboardquestions",
    component: EditOnBoardingQuestionPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "OnBoarding Questions",
        path: "/onboardingquestionsrepository",
      },
      {
        name: "Edit Questions",
        path: "/onboardingquestionsrepository/editonboardingquestions",
      },
    ],
  },
  {
    id: "onboarding3",
    name: "Add Question",
    module: "On-Boarding Questions",
    path: "/onboardingquestionsrepository/addonboardquestions",
    component: AddOnBoardingQuestionPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "OnBoarding Questions",
        path: "/onboardingquestionsrepository",
      },
      {
        name: "Add Question",
        path: "/onboardingquestionsrepository/addonboardquestions",
      },
    ],
  },
  {
    id: "onboarding4",
    name: "Manage Welcome Screen",
    module: "On-Boarding Questions",
    path: "/managewelcomescreen",
    component: WelcomeMarketing,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Manage Welcome Screen",
        path: "#",
      },
    ],
  },
  {
    id: "onboarding4",
    name: "Manage Welcome Screen",
    module: "On-Boarding Questions",
    path: "/aboutfisio",
    component: AboutFisio,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "FTUE",
        path: "#",
      },
      {
        name: "About Fisio",
        path: "#",
      },
    ],
  },
];

import { useEffect, useState } from "react";
import BoxLayout from "../../../../Containers/Layout/BoxLayout";
import SearchBoxComponent from "../../../../Components/Inputs/SearchBoxComponent";
import ExcelIcon from "../../../../Components/Icons/ExcelIcon";
import TableComponent from "../../../../Components/TableComponent";
import { ActivityTableHeader } from "../../../../utils/TableColumns";
import PaginationComponent from "../../../../Components/PaginationComponent";
import {
  activityListHandleSort,
  activityListSort,
  clearActivities,
  clearFeedBackData,
  getActivties,
  ratingListHandleSort,
} from "../../../../Redux/features/UserDashboardSlice";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import { useForm } from "react-hook-form";
import SelectComponent from "../../../../Components/Inputs/SelectComponent";
import { DropDownStylesV2 } from "../../../../helper";
import { Status } from "../../../../Redux/features/UserManagementSlice";
import TableSkeleton from "../../../../Skeleton/TableSkeleton";
import { exportToExcel } from "../../../../utils/helper";
type Props = {};

const ActivityPage = (props: Props) => {
  const currentPage = useAppSelector(
    (state) => state.UserDashboard.currentActivtyPage
  );
  const totalPages = useAppSelector(
    (state) => state.UserDashboard.activityListPage
  );
  const Activities = useAppSelector(
    (state) => state.UserDashboard.userActivityData
  );
  const getActivitesAPI = useAppSelector(
    (state) => state.UserDashboard.getActivtiesAPIStatus
  );
  const sortType = useAppSelector(
    (state) => state.UserDashboard.activitesSortType
  );
  const sortValue = useAppSelector(
    (state) => state.UserDashboard.activitesSortValue
  );
  const pageChange = (pageNumber: number) => {
    console.log(pageNumber);
    dispatch(
      getActivties({
        page: pageNumber,
        sortType: sortType,
        sortValue: sortValue,
        type: ratingType.ratingType,
        filteredValue: userListValue,
        perPage: 10,
        userId: id || "",
      })
    ).then((response) => {});
  };
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { control, watch } = useForm({
    defaultValues: {
      ratingType: "",
    },
  });

  useEffect(() => {
    return () => {
      dispatch(clearActivities());
    };
  }, []);

  const ratingType = watch();
  console.log(ratingType);
  useEffect(() => {
    if (id && ratingType.ratingType !== "") {
      dispatch(
        getActivties({
          filteredValue: "",
          page: 1,
          sortType: sortType,
          sortValue: sortValue,
          perPage: 10,
          type: ratingType.ratingType,
          userId: id,
        })
      );
    }
  }, [ratingType.ratingType, sortType]);

  const [userListValue, setUserListValue] = useState("");

  const getSearchValue = (d: string) => {
    setUserListValue(d);
    // dispatch(UserListAPI({ page: 1, userType: "admin", searchValue: d }));
  };

  useEffect(() => {
    if (ratingType.ratingType !== "") {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getActivties({
            page: 1,
            type: ratingType.ratingType,
            filteredValue: userListValue,
            perPage: 10,
            userId: id || "",
          })
        ).then((response) => {});
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListValue]);

  const manageSorting = (type: sortValues, value: string) => {
    console.log("S");
    switch (type) {
      case "ascending":
        dispatch(
          activityListHandleSort({ sortType: "descending", sortValue: value })
        );
        break;
      case "descending":
        dispatch(activityListHandleSort({ sortType: null, sortValue: "" }));
        break;
      default:
        dispatch(
          activityListHandleSort({ sortType: "ascending", sortValue: value })
        );
        break;
    }
  };
  return (
    <BoxLayout HeaderName="User Dashboard" sectionName="">
      <div className="h-[calc(100vh-87.6px-7.5rem)] flex flex-col justify-between rounded-md border m-2">
        <div>
          <div className="flex gap-4 px-4 py-2 justify-between">
            <div className="font-semibold items-center h-[3.5rem] justify-start w-full  flex gap-2">
              <p className="w-36">Activity List</p>
              {/* <div className='w-full'> */}
              <SelectComponent
                selectStyle={DropDownStylesV2}
                control={control}
                inputRef="ratingType"
                options={[
                  // { label: "All", value: "All" },
                  {
                    label: "Train",
                    value: "Train",
                  },
                  {
                    label: "Apply",
                    value: "Apply",
                  },
                ]}
              />
              {/* </div> */}
            </div>
            <div className="flex gap-3 items-center justify-center">
              <SearchBoxComponent getSearchValue={getSearchValue} />{" "}
              <button
                onClick={() =>
                  exportToExcel(
                    Activities.map((i) => {
                      return {
                        category_type: i.category_type,
                        unit_name: i.unit_name,
                        session_object: i.session_object,
                        name: i.name,
                        date: i.date,
                      };
                    }),
                    `activity_pageno=${currentPage}`
                  )
                }
              >
                <ExcelIcon />
              </button>
            </div>
          </div>

          <TableComponent
            sortType={sortType}
            sortValue={sortValue}
            handleSortAction={activityListHandleSort}
            onClick={manageSorting}
            sortIcon
            staticHeight
            heightClass={
              getActivitesAPI === Status.SUCCESS && Activities.length !== 0
                ? "h-[calc(100vh-87.6px-15rem)]"
                : ""
            }
            loader={getActivitesAPI}
            TableHeaderData={ActivityTableHeader}
          >
            {getActivitesAPI === Status.SUCCESS ? (
              <tbody>
                {Activities?.map((i) => (
                  <tr className="text-[#767576] text-sm px-2 border-t">
                    <td className="font-semibold px-2 text-[#212121]">
                      {i.category_type}
                    </td>
                    <td className="font-medium py-4 text-[#212121]">
                      {i.unit_name}
                    </td>
                    <td>{i.session_object}</td>
                    <td className="font-medium py-4 text-[#212121]">
                      {i.name}
                    </td>
                    <td className="">{i.date}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <></>
            )}
            {getActivitesAPI === Status.LOADING ? (
              <TableSkeleton tableColumns={5} />
            ) : (
              <></>
            )}
          </TableComponent>
          {ratingType.ratingType !== "" &&
          getActivitesAPI === Status.SUCCESS &&
          Activities?.length === 0 ? (
            <div className="h-[calc(100vh-87.6px-17rem)] flex flex-col justify-center">
              <p className=" flex justify-center text-[1.25rem]  font-black text-[#757575]">
                No Data Available
              </p>
              <p className="font-medium flex justify-center text-[0.875rem] text-[#757575]">
                You can choose Category by clicking the “choose Category “
                Dropdown at the left side of the screen
              </p>
            </div>
          ) : (
            <></>
          )}
          {ratingType.ratingType === "" ? (
            <div className=" h-[calc(100vh-87.6px-17rem)] flex flex-col justify-center">
              <p className="  flex justify-center text-[1.25rem]  font-black text-[#757575]">
                You’ve not Choose Category
              </p>
              <p className="font-medium flex justify-center text-[0.875rem] text-[#757575]">
                You can choose Category by clicking the “choose Category “
                Dropdown at the left side of the screen
              </p>
            </div>
          ) : (
            <></>
          )}
          <PaginationComponent
            active={Number(currentPage)}
            total={totalPages}
            paginationFunction={pageChange}
          />
        </div>
      </div>
    </BoxLayout>
  );
};

export default ActivityPage;

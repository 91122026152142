type Props = {};

const ScreenLoader = ({}: Props) => {
  return (
    <div className="w-screen fixed top-0 left-0 h-screen overflow-hidden bg-opacity-50 bg-[#FFF] flex justify-center items-center">
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default ScreenLoader;

import { GroupBase, StylesConfig } from "react-select";
import { toast } from "react-toastify";
import { Status } from "../Redux/features/UserManagementSlice";

export type state = {
  value: string | number;
  label: string;
};

//StylesConfig<state, false, GroupBase<state>>
export const DropDownStyles: StylesConfig<state, boolean, GroupBase<state>> = {
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.0509803922)",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected
      ? "var(--color-white)"
      : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "10px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.2",
    color: "var(--brand-primary)",
    borderRadius: 0,
    border: "0",
    cursor: "pointer",
  }),
  placeholder: (base, state) => ({
    ...base,
    width: "100%",
    justifyContent: "center",
    top: state.isFocused ? "12px" : "6px",
    right: "0px",
    padding: "0px",
  }),
  container: (base) => ({
    ...base,
    width: "100%",
    position: "relative",
    border: "1px solid #B8B8B8",
    borderRadius: "12px",
    padding: "5px 10px",
    background: "#fafafa",
  }),
  menu: (base) => ({
    ...base,
    width: "100%",
    minWidth: "100%",
    borderRadius: "10px",
    border: "0",
    cursor: "pointer",
    margin: "10px 0 0",
    zIndex: "99",
    boxShadow: "0px 3px 6px rgba(var(--black-rgb), 0.25) !important",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    border: "none",
    color: "var(--brand-primary)",
    borderRadius: 0,
    cursor: "pointer",
    zIndex: "99",
  }),
  control: (base) => ({
    ...base,
    backgroundColor: "transparent !important",
    border: "none !important",
    padding: "0",
    minHeight: "25px",
    flexDirection: "row",
    outline: "none !important",
    borderRadius: "10px !important",
    boxShadow: "none !important",
    " &:hover, &:focus ": {
      backgroundColor: "transparent !important",
      border: "none !important;",
      boxShadow: "none !important;",
      outline: "none !important;",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px",
    width: "100% !important",
  }),
  input: (base) => ({
    ...base,
    padding: "10px 0",
    margin: "0px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: " none !important",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: "1.5rem",
    margin: "auto",
    color: "var(--brand-primary)",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "black",
    width: "2rem",
  }),
};

export const DropDownStylesV3: StylesConfig<
  state,
  boolean,
  GroupBase<state>
> = {
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.0509803922)",
      color: "#FC8A2E",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected
      ? "var(--color-white)"
      : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "10px",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "1.2",
    color: "var(--brand-primary)",
    borderRadius: 0,
    border: "0",
    cursor: "pointer",
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.hasValue ? "#FC8A2E" : "black",
  }),

  placeholder: (base, state) => ({
    ...base,
    width: "100%",
    justifyContent: "center",
    top: state.isFocused ? "12px" : "6px",
    right: "0px",
    padding: "0px",
  }),
  container: (base, state) => ({
    ...base,
    width: "fit-content",
    position: "relative",
    border: "1px solid #FC8A2E",
    borderRadius: "6px",
    padding: "0 5px",
    margin: "0 20px",
    background: state.hasValue ? "#FFF5E5" : "#fafafa",
  }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    // minWidth: "100%",
    borderRadius: "10px",
    border: "0",
    cursor: "pointer",
    margin: "10px 0 0",
    zIndex: "99",
    boxShadow: "0px 3px 6px rgba(var(--black-rgb), 0.25) !important",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    border: "none",
    color: "var(--brand-primary)",
    borderRadius: 0,
    cursor: "pointer",
    zIndex: "99",
    width: "100%",
  }),
  control: (base, state) => ({
    ...base,
    backgroundColor: "transparent !important",
    border: "none !important",
    padding: "0",
    color: state.hasValue ? "FC8A2E" : "black",
    minHeight: "25px",
    flexDirection: "row",
    outline: "none !important",
    borderRadius: "10px !important",
    boxShadow: "none !important",
    " &:hover, &:focus ": {
      backgroundColor: "transparent !important",
      border: "none !important;",
      boxShadow: "none !important;",
      outline: "none !important;",
    },
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: "0px",
    width: "100% !important",
    fontSize: "12px",
    color: state.hasValue ? "FC8A2E" : "black",
  }),

  input: (base) => ({
    ...base,
    // padding: "10px 0",
    margin: "0px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: " none !important",
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: "1.5rem",

    margin: "auto",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#FC8A2E",
    width: "2rem",
  }),
};

export const DropDownStylesV2: StylesConfig<
  state,
  boolean,
  GroupBase<state>
> = {
  option: (provided, state) => ({
    ...provided,
    background: state.isFocused ? "rgba(0, 0, 0, 0.0509803922)" : "inherit",
    color: state.isFocused ? "#FC8A2E" : "inherit",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.0509803922)",
      color: "#FC8A2E",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected
      ? "var(--color-white)"
      : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "10px",
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "1.2",
    borderRadius: 0,
    border: "0",
    cursor: "pointer",
  }),
  placeholder: (base, state) => ({
    ...base,
    width: "100%",
    justifyContent: "center",
    top: state.isFocused ? "12px" : "6px",
    right: "0px",
    padding: "0px",
  }),
  singleValue: (base, state) => ({
    ...base,
    color: state.hasValue ? "#1F3161" : "#767576",
    fontWeight: "normal",
  }),
  container: (base, state) => ({
    ...base,
    width: "110px",
    position: "relative",
    border: "1px solid  #C6D6DD",

    borderRadius: "6px",
    padding: "0 5px",
    background: state.hasValue ? "rgb(234,240,243)" : "#F6f6f6",
  }),

  menu: (base) => ({
    ...base,
    width: "max-content",
    // minWidth: "100%",
    borderRadius: "10px",
    border: "0",
    cursor: "pointer",
    margin: "10px 0 0",
    zIndex: "99",
    boxShadow: "0px 3px 6px rgba(var(--black-rgb), 0.25) !important",
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: "var(--color-white)",
    border: "none",
    color: "var(--brand-primary)",
    borderRadius: 0,
    cursor: "pointer",
    zIndex: "99",
    width: "100%",
  }),
  control: (base) => ({
    ...base,
    backgroundColor: "transparent !important",
    border: "none !important",
    padding: "0",
    minHeight: "25px",
    flexDirection: "row",
    outline: "none !important",
    borderRadius: "10px !important",
    boxShadow: "none !important",
    " &:hover, &:focus ": {
      backgroundColor: "transparent !important",
      border: "none !important;",
      boxShadow: "none !important;",
      outline: "none !important;",
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: "0px",
    width: "100% !important",
    fontSize: "12px",
  }),
  input: (base) => ({
    ...base,
    // padding: "10px 0",
    margin: "0px",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: " none !important",
  }),
  indicatorsContainer: (base) => ({
    ...base,
    height: "1.5rem",
    color: "var(--brand-primary)",
    margin: "auto",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#C6D6DD",
    width: "2rem",
  }),
};

export const notifySuccess = (message: string) =>
  toast(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    type: Status.SUCCESS,
  });

export const notifyError = (message: string = Status.ERROR) =>
  toast(message, {
    position: "top-right",
    autoClose: 2500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    type: Status.ERROR,
  });

export const convertToMinutes = (timeString: string): number => {
  const timeComponents = timeString.split(":");

  if (timeComponents.length !== 2) {
    return 0;
  }

  const minutes = parseInt(timeComponents[0]);
  const seconds = parseInt(timeComponents[1]);

  if (isNaN(minutes) || isNaN(seconds)) {
    return 0;
  }

  return Number((minutes + seconds / 60).toFixed(2));
};

export const convertToMMSS = (minutes: number): string => {
  let mins = Math.floor(minutes);
  let secs = Math.round((minutes - mins) * 60);
  return `${mins.toString().padStart(2, "0")}:${secs
    .toString()
    .padStart(2, "0")}`;
};

interface OptionType<T> {
  value: T;
  label: string;
}

export const customStyles: StylesConfig<OptionType<number>, false> = {
  indicatorsContainer: (base) => ({
    ...base,
    height: "1.5rem",
    color: "var(--brand-primary)",
    margin: "auto",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#1F3161",
    width: "2rem",
  }),
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.0509803922)",
      color: "#FC8A2E",
      cursor: "pointer",
    },
    backgroundColor: state.isSelected
      ? "var(--color-white)"
      : "var(--color-white)",
    minHeight: "auto",
    maxHeight: "auto",
    padding: "10px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.2",
    color: "var(--brand-primary)",
    borderRadius: 0,
    border: "0",
    cursor: "pointer",
  }),
  control: (base) => ({
    ...base,
    backgroundColor: "transparent !important",
    border: "none !important",
    padding: "0",
    minHeight: "25px",
    flexDirection: "row",
    outline: "none !important",
    borderRadius: "10px !important",
    fontSize: "14px",
    fontWeight: "500",
    boxShadow: "none !important",
    " &:hover, &:focus ": {
      backgroundColor: "transparent !important",
      border: "none !important;",
      boxShadow: "none !important;",
      outline: "none !important;",
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: "none",
  }),
};

import React, { useRef } from "react";
import classNames from "classnames";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";
import "tailwindcss/tailwind.css";

type SwitchProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  register?: UseFormRegister<TFieldValues>;
  className?: string;
  checked: boolean;
  label: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "type">;

const Switch = <TFormValues extends FieldValues>({
  name,
  register,
  className,
  checked,
  label,
  ...props
}: SwitchProps<TFormValues>) => {
  const ref = useRef<HTMLInputElement>(null);
  const switchClass = classNames(
    "relative inline-block w-10 mr-2 align-middle select-none",
    className
  );
  const toggleClass = classNames(
    "block w-6 h-6 bg-white rounded-full  border-4 shadow transform transition duration-200 ease-in-out",
    {
      "translate-x-4 bg-white": checked,
      "translate-x-0 cursor-pointer": !checked,
    }
  );

  return (
    <div className="flex gap-4 justify-start items-start">
      <label htmlFor={name} className="font-medium">
        {label}
      </label>

      <div className={switchClass}>
        <input
          id={name}
          type="checkbox"
          tabIndex={-1}
          {...(register !== undefined ? { ...register(name) } : {})}
          checked={checked}
          {...props}
          className={`toggle-checkbox z-99 absolute block w-6 h-6 rounded-full appearance-none cursor-pointer ${className}`}
        />
        <label
          htmlFor={name}
          className={classNames(
            `toggle-label block cursor-pointer overflow-hidden h-6 rounded-full  transition duration-200 ease-in ${className}`,

            { "bg-orange-500": checked, "bg-gray-300": !checked }
          )}
        >
          <span className={toggleClass}></span>
        </label>
      </div>
    </div>
  );
};

export default Switch;

type Props = {};

const QuoteObjectIcon = (props: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 11C20.0034 12.3199 19.6951 13.6219 19.1 14.8C18.3944 16.2118 17.3098 17.3992 15.9674 18.2293C14.6251 19.0594 13.0782 19.4994 11.5 19.5C10.1801 19.5035 8.87812 19.1951 7.7 18.6L2 20.5L3.9 14.8C3.30493 13.6219 2.99656 12.3199 3 11C3.00061 9.42179 3.44061 7.87488 4.27072 6.53258C5.10083 5.19028 6.28825 4.1056 7.7 3.40003C8.87812 2.80496 10.1801 2.49659 11.5 2.50003H12C14.0843 2.61502 16.053 3.49479 17.5291 4.97089C19.0052 6.44699 19.885 8.41568 20 10.5V11Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8531 10.1115C10.6718 9.86911 10.4039 9.68381 10.0894 9.58342C9.77498 9.48303 9.43109 9.47297 9.10942 9.55476C8.78775 9.63655 8.5057 9.80576 8.30564 10.037C8.10558 10.2682 7.99834 10.5489 8.00002 10.8369C8.00019 11.0754 8.07495 11.3095 8.21651 11.5148C8.35806 11.72 8.56121 11.8888 8.80473 12.0036C9.04825 12.1184 9.3232 12.1749 9.60086 12.1673C9.87853 12.1596 10.1487 12.0881 10.3832 11.9602C10.2613 12.271 10.0342 12.6026 9.66014 12.9349C9.58857 12.9985 9.54934 13.0839 9.55108 13.1723C9.55283 13.2607 9.5954 13.3449 9.66944 13.4063C9.74348 13.4678 9.84292 13.5015 9.94589 13.5C10.0489 13.4985 10.1469 13.4619 10.2185 13.3983C11.6013 12.168 11.4217 10.8305 10.8531 10.1131V10.1115ZM14.5754 10.1115C14.3941 9.86911 14.1261 9.68381 13.8117 9.58342C13.4972 9.48303 13.1533 9.47297 12.8317 9.55476C12.51 9.63655 12.2279 9.80576 12.0279 10.037C11.8278 10.2682 11.7206 10.5489 11.7223 10.8369C11.7224 11.0754 11.7972 11.3095 11.9387 11.5148C12.0803 11.72 12.2835 11.8888 12.527 12.0036C12.7705 12.1184 13.0454 12.1749 13.3231 12.1673C13.6008 12.1596 13.8709 12.0881 14.1054 11.9602C13.9835 12.271 13.7565 12.6026 13.3824 12.9349C13.3108 12.9985 13.2716 13.0839 13.2733 13.1723C13.2751 13.2607 13.3176 13.3449 13.3917 13.4063C13.4657 13.4678 13.5652 13.5015 13.6681 13.5C13.7711 13.4985 13.8691 13.4619 13.9407 13.3983C15.3235 12.168 15.1439 10.8305 14.5754 10.1131V10.1115Z"
        fill="white"
      />
    </svg>
  );
};

export default QuoteObjectIcon;

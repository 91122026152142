import { Bar, BarChart, LabelList, XAxis, YAxis } from "recharts";

type Props = {
  data: Array<{
    name: string;
    pv: number;
  }>;
};

const HorizontalBar = ({ data }: Props) => {
  function getMaxPlusExtra(data: any[], extra: any) {
    const maxVal = Math.max(...data.map((item) => Math.max(item.pv)));
    return maxVal + extra;
  }
  const extraValue = 6;
  const maxDomainValue = getMaxPlusExtra(data, extraValue);
  return (
    <BarChart width={350} height={100} data={data} layout="vertical">
      <defs>
        <linearGradient id="gradientColor" x1="0%" y1="100%" x2="100%" y2="0%">
          <stop offset="-4.22%" stopColor="#F58124" stopOpacity={1} />
          <stop offset="120.23%" stopColor="#FFC266" stopOpacity={1} />
        </linearGradient>
      </defs>

      <XAxis hide dataKey="pv" type="number" domain={[0, maxDomainValue]} />
      <YAxis hide dataKey="name" type="category" />
      <Bar
        dataKey="pv"
        // minPointSize={25}
        // maxBarSize={80}
        fill="url(#gradientColor)"
        radius={[10, 10, 10, 10]}
      >
        <LabelList
          dataKey="pv"
          position="insideLeft"
          style={{ fill: "#FFFFFF" }}
          color="#ffffff"
        />
        <LabelList
          dataKey="name"
          fontSize={14}
          position="right"
          style={{ fill: "#1F3161" }}
          fontWeight={500}
        />
      </Bar>
    </BarChart>
  );
};

export default HorizontalBar;

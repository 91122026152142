import React, { useState } from "react";
import { Controller, Path, FieldValues, UseFormRegister } from "react-hook-form";


type Props<TFieldValues extends FieldValues> = {
  name: string;
  register: UseFormRegister<TFieldValues>;
  errorname?: string | undefined;
  inputRef: Path<TFieldValues>;
  value: string;
  className?: string;
  inputClassName?: string;
  label?: string;
  placeholder?: string;
  rows?: number;
};

const TextAreaComponent = <TFieldValues extends FieldValues>({
  label = "",
  name,
  rows = 2,
  register,
  errorname,
  inputRef,
  value,
  className = "",
  inputClassName = "",
  placeholder = "",
}: Props<TFieldValues>) => {
  return (
    <div className={`${className}`}>
      <div
        className={` w-full rounded-[10px] justify-end flex relative border border-[#B8B8B8]`}
      >
        <textarea
          rows={rows}
          {...register(inputRef)}
          placeholder={placeholder}
          className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none ${inputClassName}`}
        />

        <label
          className="absolute left-4 text-xs font-medium -top-2 bg-gradient-to-b text-[#b8b8b8] from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2"
          htmlFor={name}
        >
          {label}
        </label>
      </div>
      {errorname && (
        <span className="text-red-500 text-sm px-2 text-left">{errorname}</span>
      )}
    </div>
  );
}

export default TextAreaComponent;

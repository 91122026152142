import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";

interface TagTypePayload {
  page: number;
  TagType: string;
}

interface TagsState {
  TagList: {
    data: {
      tags: Tag[],
      total_page: number,
      total_tags: number,
    }
  };
  DeleteAPIIdle: Status;
  sortType: sortValues;
  sortValue: string;
  AddTagAPIIdle: Status;
  UpdateTagAPIIdle: Status;
  TagListAPIIdle: Status;
  page: number;
}

const initialState: TagsState = {
  sortType: "ascending",
  DeleteAPIIdle: Status.SUCCESS,
  AddTagAPIIdle: Status.SUCCESS,
  UpdateTagAPIIdle: Status.SUCCESS,
  TagListAPIIdle: Status.SUCCESS,
  sortValue: "",
  TagList: {
    data: {
      tags: [],
      total_page: 0,
      total_tags: 0,
    }
  },
  page: 1
};


type TagPayloadType = {
  searchValue?: string,
  sortType?: sortValues,
  sortValue?: string
  page?: number

}

export const TagListAPI = createAsyncThunk<getTagsAPIResponse, TagPayloadType>(
  "/auth/tags",
  async (TagPayload) => {
    const res = await axiosClient.get(
      `/auth/tags?per_page=10${TagPayload.page
        ? `&page=${TagPayload.page}`
        : ""
      }
      ${TagPayload.searchValue
        ? `&filteredVal=${TagPayload.searchValue}`
        : ""
      }${TagPayload.sortType === "ascending"
        ? `&sort=-${TagPayload.sortValue}`
        : `&sort=${TagPayload.sortValue}`
      }`
    );
    return {
      data: res.data,
      page: TagPayload.page || 1
    };
  }
);

export const DeleteTagAPI = createAsyncThunk<
  DeleteTagListAPIResponse,
  { id: string }
>("/auth/tags/", async (data) => {
  const res = await axiosClient.delete<DeleteTagListAPIResponse>(
    `/auth/tags/${data.id}`
  );
  return res.data;
});

export const AddTagAPI = createAsyncThunk<PayloadType, AddTagAPIRequest>("tag/AddTag", async (data) => {
  const res = await axiosClient.post(`/auth/tags`, data);
  return res.data;
});

const TagSlice = createSlice({
  name: "tag",
  initialState,
  reducers: {
    clearTagList: (state) => {
      return {
        ...state,
        TagList: {
          data: {
            tags: [],
            total_page: 0,
            total_tags: 0,
          }
        }
      };
    },
    tagListSort: (state) => {
      return {
        ...state,
        sortType: state.sortType === "ascending" ? "descending" : "ascending",
      };
    },
    handleSort: (state, action) => {
      return {
        ...state,
        sortType: action.payload.sortType,
        sortValue: action.payload.sortValue,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // ... other cases ...

      // Remove the AddTagAPI cases from extraReducers section
      .addCase(TagListAPI.pending, (state) => {
        return {
          ...state,
          TagListAPIIdle: Status.LOADING,
        };
      })
      .addCase(TagListAPI.fulfilled, (state, action) => {

        return {
          ...state,
          TagListAPIIdle: Status.SUCCESS,
          TagList: {
            ...state.TagList,
            data: action.payload.data.data
            // page: action.payload.page
          },
          page: action.payload.page
        };
      })
      .addCase(TagListAPI.rejected, (state) => {
        return {
          ...state,
          TagListAPIIdle: Status.ERROR,
        };
      })
      .addCase(DeleteTagAPI.pending, (state) => {
        return {
          ...state,
          DeleteAPIIdle: Status.LOADING,
        };
      })
      .addCase(DeleteTagAPI.fulfilled, (state) => {
        return {
          ...state,
          DeleteAPIIdle: Status.LOADING,
        };
      })
      .addCase(DeleteTagAPI.rejected, (state) => {
        return {
          ...state,
          DeleteAPIIdle: Status.ERROR,
        };
      })
      .addCase(AddTagAPI.pending, (state) => {
        return {
          ...state,
          AddTagAPIIdle: Status.LOADING,
        };
      })
      .addCase(AddTagAPI.fulfilled, (state) => {
        return {
          ...state,
          AddTagAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(AddTagAPI.rejected, (state) => {
        return {
          ...state,
          AddTagAPIIdle: Status.ERROR,
        };
      });
  },
});

export const { clearTagList, tagListSort, handleSort } = TagSlice.actions;

export default TagSlice.reducer;

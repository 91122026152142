import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const ActiveSmallCircleIcon: React.FC<IconProps> = ({
  width = 16,
  height = 15,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
    >
      <circle cx="8" cy="7.5" r="7.5" fill="url(#paint0_linear_7494_74968)" />
      <defs>
        <linearGradient
          id="paint0_linear_7494_74968"
          x1="15.7296"
          y1="16"
          x2="-2.05489"
          y2="-3.47317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F58124" />
          <stop offset="1" stop-color="#FFC266" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ActiveSmallCircleIcon;

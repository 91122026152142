import { ReactNode, useEffect } from "react";
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";

type Props = {
    children: ReactNode;
};

const OpenRoutes = ({ children }: Props) => {
    return (
        <>
            {children}
        </>
    );
};

export default OpenRoutes;

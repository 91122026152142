import { useState, useEffect } from "react";
import TableComponent from "../../Components/TableComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { ObjectTypeListTableHeader } from "../../utils/TableColumns";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import EditIcon from "../../Components/Icons/EditIcon";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import { useNavigate } from "react-router";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import {
  ObjectDeleteAPI,
  ObjectListAPI,
  ObjectListSort,
  clearObject,
  handlePagination,
  handleSort,
} from "../../Redux/features/ObjectSlice";
import ObjectIcon from "../../Containers/Objects/ObjectIcon";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { formatTime, manageSorting } from "../../utils/helper";
import CopyIcon from "../../Components/Icons/CopyIcon";
import { Status } from "../../Redux/features/UserManagementSlice";
import PaginationComponent from "../../Components/PaginationComponent";
import { useIsMount } from "../../CustomHooks/useIsMount";
import EyeIcon from "../../Components/Icons/EyeIcon";
import CenterPopUpComponent from "../../Components/PopUp/CenterPopUpComponent";
import PreviewPanel from "./PreviewPanel";
import AlignIcon from "../../Components/Icons/AlignIcon";

type Props = {};

const ObjectPage = (props: Props) => {
  const [adminListValue, setAdminListValue] = useState();

  const sortType = useAppSelector((state) => state.Object.sortType);
  const sortValue = useAppSelector((state) => state.Object.sortValue);
  const [perPage, setPerPage] = useState<{ label: string; value: number }>({
    label: "Show 50",
    value: 50,
  });

  const [showDeleteForm, setDeleteForm] = useState(false);
  const [id, setid] = useState("0");
  const [details, setDetails] = useState<ObjectItem | null>(null);
  const [previewObject, setPreviewObject] = useState<ObjectItem | undefined>();

  const dispatch = useAppDispatch();
  const userList = useAppSelector((state) => state.Object.objectlist);

  const ObjectDeleteAPIIdle = useAppSelector(
    (state) => state.Object.ObjectDeleteAPIIdle
  );
  useEffect(() => {
    document.title = "FISIO | List of Object";
  }, []);

  const [preview, setPreview] = useState(false);
  const handlePreview = (i: ObjectItem) => {
    setPreview(true);
    setPreviewObject(i);
  };

  const activePage = useAppSelector((state) => state.Object.activePage);
  const IsMount = useIsMount();
  useEffect(() => {
    dispatch(
      ObjectListAPI({
        sortType: sortType,
        sortValue: sortValue,
        searchValue: adminListValue,
        page: 1,
        per_page: perPage.value,
      })
    );
    return () => {
      dispatch(clearObject());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const handleDeleteClick = (i: ObjectItem) => {
    setDeleteForm(true);
    setid(i._id);
    setDetails(i);
  };
  const handleEditClick = (object?: any) => {
    navigate("/contentrepository/editobjects", {
      state: object,
    });
  };

  const handleDuplicateClick = (data: ObjectItem) => {
    console.log(data);

    navigate("/contentrepository/addobjects", {
      state: {
        categoryType: data.category_type_id,
        description: data.textarea,
        equipment: data.equipment,
        Headline: data.headline,
        object_reps: data.reps,
        objectName: data.object_name,
        objectTime: data.object_time,
        objectType: data.object_type_id,
        subtitles: data.subtitle,
        tag: data.tag,
        video_id: data.video_id,
        is_drill: data.is_drill,
        link_items: data.link_items,
      },
    });
  };

  const ConfirmDelete = () => {
    dispatch(ObjectDeleteAPI(id)).then(() => {
      setDeleteForm(false);
      dispatch(
        ObjectListAPI({
          sortType: sortType,
          sortValue: sortValue,
          searchValue: adminListValue,
          page: activePage,
          per_page: perPage.value,
        })
      );
    });
  };

  const handlePage = (page: number) => {
    dispatch(
      ObjectListAPI({
        sortType: sortType,
        sortValue: sortValue,
        searchValue: adminListValue,
        page: page,
        per_page: perPage.value,
      })
    );
  };

  const totalPage = useAppSelector((state) => state.Object.totalPages);
  const navigate = useNavigate();

  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
  };
  const loading = useAppSelector((state) => state.Object.objectListAPIIdle);

  useEffect(() => {
    if (!IsMount) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          ObjectListAPI({
            sortType: sortType,
            sortValue: sortValue,
            searchValue: adminListValue,
            page: 1,
            per_page: perPage.value,
          })
        );
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [adminListValue]);
  const setPerPageValue = (value: { label: string; value: number }) => {
    setPerPage(value);
    dispatch(
      ObjectListAPI({
        page: 1,
        searchValue: adminListValue,
        sortType: sortType,
        sortValue: sortValue,
        per_page: value.value,
      })
    );
  };
  const getSearchValue = (d: any) => {
    setAdminListValue(d);
  };

  const getSortValue = (d: any) => {
    dispatch(ObjectListSort());
  };

  return (
    <>
      <BoxLayout HeaderName="Content Repository" sectionName="Object List">
        <FilterSortPanel
          sortType={sortType}
          exportButton={<AlignIcon />}
          getSortValue={getSortValue}
          getSearchValue={getSearchValue}
          Function={() => navigate("/contentrepository/addobjects")}
        ></FilterSortPanel>
        <TableComponent
          loader={loading}
          sortType={sortType}
          sortIcon
          sortValue={sortValue}
          onClick={manageSorting}
          handleSortAction={handleSort}
          TableHeaderData={ObjectTypeListTableHeader}
        >
          {loading === Status.SUCCESS && (
            <tbody>
              {userList.map((i) => (
                <tr key={i._id} className="border-y border-y-[#EAF0F3] ">
                  <td className="py-1 text-start text-xs text-[#212121] font-medium w-[300px] whitespace-nowrap block text-ellipsis overflow-hidden">
                    {i.object_name}
                  </td>
                  <td className="py-1 text-start  text-xs text-[#212121] font-medium">
                    {i.category_type_name}
                  </td>
                  <td className="py-1 text-start tracking-[3%] text-xs text-[#212121] font-medium ">
                    <ObjectIcon objectType={i.object_type_name} />
                  </td>
                  <td className="py-1 text-center">
                    <div className="flex gap-1">
                      {i.tag_name === "" ? (
                        <></>
                      ) : (
                        <>
                          {i.tag_name.split(",").map((j) => (
                            <div className="flex flex-row text-[#1F3161] font-medium justify-center items-center text-xs py-1 px-4 gap-1 w-fit bg-[#C6D6DD] rounded-full ">
                              {j}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </td>
                  <td className="text-[#1F3161] text-xs text-ellipsis font-semibold">
                    {i.reps}
                  </td>
                  <td className="py-1 text-start text-gray-600 text-xs font-normal font-roboto ">
                    {formatTime(Number(i.object_time))}
                  </td>
                  <td className="py-1 text-start">
                    <div className="flex h-full items-center gap-4">
                      <button onClick={() => handlePreview(i)}>
                        <EyeIcon />
                      </button>
                      <button onClick={() => handleDuplicateClick(i)}>
                        <CopyIcon />
                      </button>
                      <button onClick={() => handleEditClick(i)}>
                        <EditIcon />
                      </button>
                      <button onClick={() => handleDeleteClick(i)}>
                        <DeleteIcon />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {loading === Status.LOADING && <TableSkeleton tableColumns={7} />}
        </TableComponent>
        <PaginationComponent
          paginationFunction={handlePage}
          active={activePage}
          total={totalPage}
          setPerPageValue={setPerPageValue}
          perPageValue={perPage}
        />
      </BoxLayout>

      <ModalComponent
        confirmAction={ConfirmDelete}
        message={
          <p className="text-[#212121] font-medium text-lg">
            Are you sure you want to delete{" "}
            <span className="text-[#684C70]">
              {details && details?.object_name}
            </span>{" "}
            from Object List?
          </p>
        }
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
        loading={ObjectDeleteAPIIdle}
      />

      <CenterPopUpComponent
        isOpen={preview}
        onClose={() => setPreview(false)}
        message="Object Preview"
      >
        {" "}
        <PreviewPanel data={previewObject} />
      </CenterPopUpComponent>
    </>
  );
};
export default ObjectPage;

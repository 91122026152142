import React, { useRef } from "react";
import { useOnClickOutside } from "../CustomHooks/useOnClickOutside";

type Props = {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  width?: boolean;
};

const Sidedrawer = ({ onClose, children, width }: Props) => {
  const ModalRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(ModalRef, () => onClose((prev) => !prev));

  return (
    <div
      ref={ModalRef}
      className={
        width
          ? "fixed top-0 right-0 h-full w-[800px] bg-white shadow-lg"
          : "fixed top-0 right-0 h-full w-96 bg-white shadow-lg"
      }
    >
      {children}
    </div>
  );
};

export default Sidedrawer;

import classNames from "classnames";

type Props = {
  // Icon?: string,
  number: number | string;
  text: string;
  bg: string;
  filterIcon: string;
  onClick?: () => void;
  cardName?:
    | "fourtosevenDayLoginCount"
    | "withinthreeDayLoginCount"
    | "morethansevenDayLoginCount"
    | "weekly_rating_count"
    | "Pending"
    | "In Progress"
    | "Completed"
    | "session_rating_count"
    | "scc_rating_count"
    | ""
    | "free_plan_count"
    | "basic_plan_count"
    | "pro_plan_count"
    | "premium_plan_count";
  filterValue?:
    | "fourtosevenDayLoginCount"
    | "withinthreeDayLoginCount"
    | "morethansevenDayLoginCount"
    | "weekly_rating_count"
    | "Pending"
    | "In Progress"
    | "Completed"
    | "session_rating_count"
    | "scc_rating_count"
    | ""
    | "free_plan_count"
    | "basic_plan_count"
    | "pro_plan_count"
    | "premium_plan_count";
};

const ProgressCard = ({
  filterIcon,
  bg,
  number,
  text,
  onClick = () => {},
  cardName,
  filterValue,
}: Props) => {
  return (
    <div
      className={classNames(
        "w-full relative text-[#503C5B] bg-white rounded-[0.75rem] h-[200px]",
        {
          "shadow-[4px_4px_10px_0px_#00000040,inset_4px_4px_1px_0px_#80E3A9,inset_-4px_-4px_1px_0px_#80E3A9,-4px_-4px_10px_0px_#00000040]":
            filterValue === cardName && cardName === "Completed",
          "shadow-[4px_4px_10px_0px_#00000040,inset_4px_4px_1px_0px_#acd0d6,inset_-4px_-4px_1px_0px_#acd0d6,-4px_-4px_10px_0px_#00000040]":
            filterValue === cardName && cardName === "In Progress",
          "shadow-[4px_4px_10px_0px_#00000040,inset_4px_4px_1px_0px_#fff9e2,inset_-4px_-4px_1px_0px_#fff9e2,-4px_-4px_10px_0px_#00000040]":
            filterValue === cardName && cardName === "Pending",
          "shadow-[4px_4px_10px_0px_#00000040,inset_4px_4px_1px_0px_#fffaf3,inset_-4px_-4px_1px_0px_#fffaf3,-4px_-4px_10px_0px_#00000040]":
            filterValue === cardName && cardName === "free_plan_count",
          "shadow-[4px_4px_10px_0px_#00000040,inset_4px_4px_1px_0px_#d1d7f6,inset_-4px_-4px_1px_0px_#d1d7f6,-4px_-4px_10px_0px_#00000040]":
            filterValue === cardName &&
            (cardName === "fourtosevenDayLoginCount" ||
              cardName === "premium_plan_count"),
          "shadow-[4px_4px_10px_0px_#00000040,inset_4px_4px_1px_0px_#a4b1ef,inset_-4px_-4px_1px_0px_#a4b1ef,-4px_-4px_10px_0px_#00000040]":
            filterValue === cardName &&
            (cardName === "morethansevenDayLoginCount" ||
              cardName === "pro_plan_count"),
          "shadow-[4px_4px_10px_0px_#00000040,inset_4px_4px_1px_0px_#feddb6,inset_-4px_-4px_1px_0px_#feddb6,-4px_-4px_10px_0px_#00000040]":
            filterValue === cardName &&
            (cardName === "withinthreeDayLoginCount" ||
              cardName === "basic_plan_count"),
        }
      )}
    >
      <span className="absolute top-0  right-0 w-full p-7 z-10">
        {filterIcon !== "" ? (
          <img
            onClick={() => onClick()}
            className="h-10 w-10 cursor-pointer ml-auto"
            src={`/images/${filterIcon}.svg`}
          />
        ) : (
          <></>
        )}
      </span>

      <div className="absolute top-0  rounded-[0.75rem] w-full left-0 h-[200px]">
        <img
          className="h-[200px]  rounded-[0.75rem] float-right object-cover"
          src={`/images/${bg}.svg`}
        />
      </div>

      <button
        type="button"
        className="flex  flex-col active:scale-95 active:shadow-md transition-transform px-2 justify-end h-full gap-2"
      >
        {/* <img className='h-8 w-8' src={`/images/${Icon}.svg`} /> */}
        <span className="text-3xl font-semibold">{number}</span>
        <span className="font-bold text-xl pb-5 text-[#212121]">{text}</span>
      </button>
    </div>
  );
};

export default ProgressCard;

import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputComponent from "../../Components/Inputs/InputComponent";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { TagFormValues } from "../../Pages/TagPage";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddTagAPI, TagListAPI } from "../../Redux/features/TagSlice";

type Props = {
  handleCloseUpdateModel: Function;
  tagName: string;
  id: string;
};

const UpdateTagForm = ({ handleCloseUpdateModel, tagName, id }: Props) => {
  const dispatch = useAppDispatch();
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("TagName is required")
      .min(2, "TagName must be at least 2 characters"),
  });
  const sortType = useAppSelector((state) => state.Tag.sortType);
  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: tagName,
    },
  });
  const formValues = watch();
  const ConfirmUpdate = (data: TagFormValues) => {
    dispatch(AddTagAPI({ id: id, name: data.name })).then(() => {
      handleCloseUpdateModel();
      dispatch(TagListAPI({ sortType: sortType }));
    });
  };

  const loading = useAppSelector((state) => state.Tag.AddTagAPIIdle);

  return (
    <>
      <p className="text-lg p-4">Update Tag</p>
      <form
        className="p-4 flex  h-full flex-col justify-between gap-4"
        onSubmit={handleSubmit(ConfirmUpdate)}
      >
        <div className="h-full flex flex-col gap-4">
          <InputComponent
            register={register}
            inputRef="name"
            label="Tag Name"
            name="email"
            // errorname={errors.name?.message}
            value={formValues.name}
          />
        </div>
        <div className="flex h-[150px] gap-6">
          <ButtonComponent
            buttonType="submit"
            CTA="Update"
            varient={"blue"}
            loading={loading}
          />
          <ButtonComponent
            buttonType="button"
            varient="outline"
            CTA="cancel"
            onClick={handleCloseUpdateModel}
          />
        </div>
      </form>
    </>
  );
};

export default UpdateTagForm;

import { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import BoxSection from "../../Components/BoxSection/BoxSection";
import InputComponent from "../../Components/Inputs/InputComponent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import TableComponent from "../../Components/TableComponent";
import {
  AppControlTableHeader,
  FundamentalsTableHeader,
} from "../../utils/TableColumns";
import { Status } from "../../Redux/features/UserManagementSlice";
import { useNavigate } from "react-router-dom";
import CopyIcon from "../../Components/Icons/CopyIcon";
import EditIcon from "../../Components/Icons/EditIcon";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import Sidedrawer from "../../Components/SideDrawer";
import TextEditor from "../../Components/Inputs/TextEditor";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import {
  AppUpdateInfo,
  deleteAppControl,
  getAppControlList,
  postAppControlList,
} from "../../Redux/features/AppControlSlice";
import moment from "moment";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import { notifySuccess } from "../../helper";

const appControlFormSchema = yup.object().shape({
  version: yup.string().required("Version is required"),
  device_type: yup
    .mixed()
    .oneOf(["ios", "android"], "Device type must be either ios or android")
    .required("Device type is required"),
  force_update: yup
    .mixed()
    .oneOf(["1", "0"], "Force Update Required")
    .required("Device type is required"),
  description: yup.string().required("Description is required"),
  version_code: yup
    .number()
    .integer("Version code must be an integer")
    .required("Version code is required"),
});

type Props = {};

type AppControlForm = {
  version: string;
  device_type: "ios" | "android";
  force_update: "0" | "1";
  description: string;
  version_code: number;
};

const AppControl = (props: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAppControlList());
  }, []);

  const appControl = useAppSelector((state) => state.AppControl.versionList);

  const [id, setId] = useState("");
  const { register, handleSubmit, control, setValue, watch, reset } =
    useForm<AppControlForm>({
      resolver: yupResolver(appControlFormSchema),
    });

  const formVal = watch();
  console.log(formVal);
  const navigate = useNavigate();

  const handleEdit = (i: AppUpdateInfo) => {
    setId(i._id);
    setValue("description", i.description);
    setValue("device_type", i.device_type);
    setValue("version", i.version);
    setValue("version_code", i.version_code);
    setValue("force_update", i.force_update ? "1" : "0");
    setShowForm(true);
  };

  const [showForm, setShowForm] = useState(false);
  const handleNewVersion = () => {
    setShowForm(true);
  };

  const options = [
    { label: "iOS", value: "ios" },
    { label: "Android", value: "android" },
  ];

  const onSubmit = async (data: AppControlForm) => {
    console.log(data);
    try {
      const res = await dispatch(
        postAppControlList({
          id: id,
          description: data.description,
          device_type: data.device_type,
          force_update: data.force_update === "1" ? true : false,
          version: data.version,
          version_code: data.version_code,
        })
      );
      if (postAppControlList.fulfilled.match(res)) {
        dispatch(getAppControlList());
        notifySuccess("Version updated successfully");
        setShowForm(false);
        setId("");
      }
    } catch (error) {}
  };
  const loading = useAppSelector(
    (state) => state.AppControl.deleteAppControlAPIIdle
  );
  const Editloading = useAppSelector(
    (state) => state.AppControl.postAppControlAPIIdle
  );
  const getloading = useAppSelector(
    (state) => state.AppControl.getAppControlAPIIdle
  );
  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
    setId("");
  };
  const [showDeleteForm, setDeleteForm] = useState(false);
  const handleDeleteClick = (id: string) => {
    setDeleteForm(true);
    setId(id);
  };
  const ConfirmDelete = () => {
    dispatch(deleteAppControl(id)).then(() => {
      setDeleteForm(false);
      dispatch(getAppControlList());
    });
  };
  return (
    <BoxLayout HeaderName="App Control" sectionName="App Control">
      <ModalComponent
        confirmAction={ConfirmDelete}
        message={"Are you sure to delete this Version ?"}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
        loading={loading}
      />
      <div className="rounded-lg">
        <FilterSortPanel showAddButton={false} />
        <TableComponent
          loader={getloading}
          TableHeaderData={AppControlTableHeader}
        >
          <tbody>
            {appControl.map((i, index) => (
              <tr
                key={index}
                className="font-roboto font-medium border-y border-y-[#EAF0F3] leading-16 text-gray-700 tracking-tighter"
              >
                <td className="py-1 text-start  ">{i.version_code}</td>
                <td className="py-1 text-start">{i.device_type}</td>
                <td className="py-1 text-start">
                  {moment(i.created_at).format("DD-MM-YYYY hh:mm:a")}
                </td>
                <td className="py-1 text-start">{i.version}</td>
                <td className="py-1 text-start">
                  {i.force_update ? "True" : "False"}
                </td>
                <td>
                  <div
                    className="py-1 text-start whitespace-nowrap block text-ellipsis overflow-hidden"
                    dangerouslySetInnerHTML={{ __html: i.description }}
                  />
                </td>

                <td className="py-1 text-start flex h-full items-center gap-4">
                  <button onClick={() => handleEdit(i)}>
                    <EditIcon />
                  </button>

                  {/* <button onClick={() => handleDeleteClick(i._id)}>
                    <DeleteIcon />
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
      </div>
      {showForm && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <Sidedrawer
            width
            onClose={() => {
              setShowForm(false);
              reset();
            }}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex h-full flex-col justify-between"
            >
              <div className="flex flex-col gap-5 p-4">
                <h3 className="text-lg font-medium">Add New Version</h3>
                <InputComponent
                  inputRef="version"
                  register={register}
                  name=""
                  value=""
                  label="Version"
                  // disabled
                />
                <InputComponent
                  inputRef="version_code"
                  register={register}
                  name=""
                  value=""
                  label="Version Code"
                  // disabled
                />
                <TextEditor
                  control={control}
                  inputRef="description"
                  register={register}
                  className=""
                  label="Description"
                  name=""
                  values=""
                />
                <SelectComponent<AppControlForm>
                  control={control}
                  inputRef="device_type"
                  placeHolder="Select Device Type"
                  options={options}
                  label="Device Type"
                />
                <div className="flex text-sm font-medium flex-wrap gap-5 items-center">
                  <span>Force Update</span>
                  <label className="flex gap-1 items-center ">
                    <input
                      type="radio"
                      {...register("force_update")}
                      value={0}
                    />
                    <span className="custom-radio">false</span>
                  </label>

                  <label className="flex gap-1 items-center">
                    <input
                      type="radio"
                      {...register("force_update")}
                      value={1}
                    />
                    <span className="custom-radio">true</span>
                  </label>
                </div>
                <span className="text-sm font-semibold text-[#9e9e9e]">
                  Enforcing a force update will require users to upgrade to the
                  latest version.
                </span>
              </div>
              <div className="flex gap-5 p-4">
                <ButtonComponent
                  CTA="Cancel"
                  buttonType="button"
                  onClick={() => {
                    setShowForm(false);
                    reset();
                  }}
                  varient="outline"
                />
                <ButtonComponent
                  CTA="Save"
                  buttonType="submit"
                  varient="blue"
                  loading={Editloading}
                />
              </div>
            </form>
          </Sidedrawer>
        </div>
      )}
    </BoxLayout>
  );
};

export default AppControl;

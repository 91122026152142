import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";

type GroupListPayLoadType = {
  searchValue?: string;
  sortType?: sortValues;
  sortValue?: string;
  page?: number;
};

// Async thunks
export const GetGroupListAPI = createAsyncThunk<
  GroupListResponse,
  GroupListPayLoadType
>("group/list", async (GroupListPayLoad) => {
  const res = await axiosClient.get<GroupListResponse>(
    `auth/group?per_page=10${
      GroupListPayLoad.searchValue
        ? `&filteredVal=${GroupListPayLoad.searchValue}`
        : ""
    }${GroupListPayLoad.page ? `&page=${GroupListPayLoad.page}` : ""}${
      GroupListPayLoad.sortType === "ascending"
        ? `&sort=-${GroupListPayLoad.sortValue}`
        : ``
    }${
      GroupListPayLoad.sortType === "descending"
        ? `&sort=${GroupListPayLoad.sortValue}`
        : ``
    }`
  );
  return res.data;
});

export const PostGroupListAPI = createAsyncThunk<any, GroupListRequest>(
  "group/addGroup",
  async (data) => {
    const res = await axiosClient.post("auth/group", data);
    return res.data;
  }
);

export const DeleteGroupAPI = createAsyncThunk<PayloadType, string>(
  "group/deleteGroup",
  async (id) => {
    const res = await axiosClient.delete(`auth/group/${id}`);
    return res.data;
  }
);

type AssignGroupAPIRequest = {
  group_id: string;
  user_list: string[];
};

export const AssignGroupAPI = createAsyncThunk<any, AssignGroupAPIRequest>(
  "group/assignID",
  async (data) => {
    const res = await axiosClient.post(`auth/assign-group-user`, data);
    return res.data;
  }
);

// State interface
interface IinitialState {
  getGroupListAPIStatus: Status;
  postGroupListAPIStatus: Status;
  deleteGroupListAPIStatus: Status;
  assignGroupListAPIStatus: Status;
  groupList: Array<Group>;
  error?: string | null;
  sortType: sortValues;
  sortValue: string;
  activePage: number;
  totalPages: number;
}

// Initial state
const initialState: IinitialState = {
  getGroupListAPIStatus: Status.IDLE,
  postGroupListAPIStatus: Status.IDLE,
  deleteGroupListAPIStatus: Status.IDLE,
  assignGroupListAPIStatus: Status.IDLE,
  groupList: [],
  error: null,
  activePage: 1,
  totalPages: 0,
  sortType: "descending",
  sortValue: "group_name",
};

const GroupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    handleSort: (state, action) => {
      return {
        ...state,
        sortType: action.payload.sortType,
        sortValue: action.payload.sortValue,
      };
    },
    handlePagination: (state, action) => {
      return {
        ...state,
        activePage: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetGroupListAPI.pending, (state) => {
        state.getGroupListAPIStatus = Status.LOADING;
      })
      .addCase(
        GetGroupListAPI.fulfilled,
        (state, action: PayloadAction<GroupListResponse>) => {
          state.getGroupListAPIStatus = Status.SUCCESS;
          state.groupList = action.payload.data;
        }
      )
      .addCase(GetGroupListAPI.rejected, (state, action) => {
        state.getGroupListAPIStatus = Status.ERROR;
        state.error = action.error.message;
      })

      .addCase(PostGroupListAPI.pending, (state) => {
        state.postGroupListAPIStatus = Status.LOADING;
      })
      .addCase(PostGroupListAPI.fulfilled, (state) => {
        state.postGroupListAPIStatus = Status.SUCCESS;
      })
      .addCase(PostGroupListAPI.rejected, (state, action) => {
        state.postGroupListAPIStatus = Status.ERROR;
        state.error = action.error.message;
      })

      .addCase(DeleteGroupAPI.pending, (state) => {
        state.deleteGroupListAPIStatus = Status.LOADING;
      })
      .addCase(DeleteGroupAPI.fulfilled, (state) => {
        state.deleteGroupListAPIStatus = Status.SUCCESS;
      })
      .addCase(DeleteGroupAPI.rejected, (state, action) => {
        state.deleteGroupListAPIStatus = Status.ERROR;
        state.error = action.error.message;
      })

      .addCase(AssignGroupAPI.pending, (state) => {
        state.assignGroupListAPIStatus = Status.LOADING;
      })
      .addCase(AssignGroupAPI.fulfilled, (state) => {
        state.assignGroupListAPIStatus = Status.SUCCESS;
      })
      .addCase(AssignGroupAPI.rejected, (state) => {
        state.assignGroupListAPIStatus = Status.ERROR;
      });
  },
});
export const { handlePagination, handleSort } = GroupSlice.actions;

export default GroupSlice.reducer;

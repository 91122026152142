import { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import TableComponent from "../../Components/TableComponent";
import EditIcon from "../../Components/Icons/EditIcon";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import {
  TrophyListTableHeader,
} from "../../utils/TableColumns";
import Sidedrawer from "../../Components/SideDrawer";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import moment from "moment";
import {
  deleteTrophiesListAPI,
  getTrophiesListAPI,
} from "../../Redux/features/TrophiesSlice";
import AddTrophyForm from "../../Containers/Trophy/AddTrophyForm";

type Props = {};

const TrophiesList = (props: Props) => {
  const [showAddForm, setAddFormModal] = useState(false);
  const [showDeleteForm, setDeleteForm] = useState(false);
  const [showUpdateForm, setUpdateForm] = useState(false);

  const [cardId, setCardId] = useState("");
  const [trophyName, setTrophyName] = useState("");
  const [trophyGroup, setTrophyGroup] = useState("");
  const [description, setDescription] = useState("");
  const [trophieFront, setTrophieFront] = useState("");
  const [trophieBack, setTrophieBack] = useState("");

  const handleDeleteClick = (id: string) => {
    setDeleteForm(true);
    setCardId(id);
  };

  const handleEditClick = (Trophies: TrophiesData) => {
    setUpdateForm(true);
    setCardId(Trophies.id);
    setDescription(Trophies.description);
    setTrophyName(Trophies.name);
    setTrophyGroup(Trophies.type);
    setTrophieBack("");
    setTrophieFront("");
  };

  const handleCloseAddFormModal = () => {
    setAddFormModal(false);
  };

  const handleCloseEditFormModal = () => {
    setUpdateForm(false);
  };
  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
  };

  const ConfirmDelete = () => {
    dispatch(deleteTrophiesListAPI(cardId)).then((res) => {
      dispatch(getTrophiesListAPI());
      setDeleteForm(false);
    });
  };

  const listLoader = useAppSelector((state) => state.Tag.TagListAPIIdle);
  const deleteTrophiesListAPIIdle = useAppSelector(
    (state) => state.Trophies.deleteTrophiesListAPIIdle
  );
  useEffect(() => {
    document.title = "FISIO | Recall Cards";
  }, []);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTrophiesListAPI());
  }, []);

  const TrophiesList = useAppSelector((state) => state.Trophies.Trophies);
  return (
    <>
      <BoxLayout HeaderName="Trophies" sectionName="Trophy List">
        <FilterSortPanel
          Function={() => setAddFormModal((prev) => !prev)}
          showAddForm={showAddForm}
        >
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10">
            <Sidedrawer onClose={setAddFormModal}>
              <AddTrophyForm
                Heading="Add Trophy"
                setAddFormModal={handleCloseAddFormModal}
              />
            </Sidedrawer>
          </div>
        </FilterSortPanel>
        <TableComponent
          loader={listLoader}
          TableHeaderData={TrophyListTableHeader}
        // TableClassName="grid grid-cols-4 w-[750px]"
        >
          <tbody>
            {TrophiesList?.map((data) => (
              <tr
                key={data.id}
                className="py-1 border-y border-y-[#EAF0F3] text-start font-weight: 500 font-size: 0.75rem"
              >
                <td className="py-1 text-start">{data.name}</td>
                <td className="py-1 text-start">{data.description}</td>
                <td className="py-1 text-start">
                  {moment(data.retrieved_on).format("DD-MM-yyyy  hh:mm")}
                </td>
                <td className="py-1 text-start items-center flex gap-4">
                  <button onClick={() => handleEditClick(data)}>
                    <EditIcon />
                  </button>
                  <button onClick={() => handleDeleteClick(data.id)}>
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
      </BoxLayout>
      <ModalComponent
        confirmAction={ConfirmDelete}
        message={"Are you sure to delete this Trophy ?"}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
        loading={deleteTrophiesListAPIIdle}
      />
      {showUpdateForm && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-10">
          <Sidedrawer onClose={setUpdateForm}>
            <AddTrophyForm
              Heading="Edit Trophy"
              trophie_back={trophieBack}
              trophie_front={trophieFront}
              name={trophyName}
              trophie_type={trophyGroup}
              id={cardId}
              description={description}
              setAddFormModal={handleCloseEditFormModal}
            />
          </Sidedrawer>
        </div>
      )}
    </>
  );
};

export default TrophiesList;

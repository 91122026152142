import React from 'react'

type Props = {}

const ExcelIcon = (props: Props) => {
    return (
        <div><img src="/images/cards/excel.svg" /></div>
    )
}

export default ExcelIcon
import ReportList from "../../Pages/Report/ReportList";
import AdminRoutes from "../../auth/AdminRoutes";

export const reportRoutes = [
  {
    id: "report1",
    name: "Report",
    module: "Report",
    path: "/report",
    component: ReportList,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "ReportList",
        path: "#",
      },
    ],
  },
  {
    id: "report2",
    name: "Report Details",
    module: "Report",
    path: "/reportdetails",
    component: ReportList,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "ReportList",
        path: "#",
      },
    ],
  },
];

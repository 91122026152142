import React, {
    useRef,
    useCallback,
} from "react";
import SessionSelectorArray from "../../Containers/Session/SessionSelectorArray";

// Define the shape of item


// Define the props for DraggableList component
interface DraggableListProps {
    sessionList: any,
    UnitObjectList: any,
    handleInputChange: any,
    handlePermissionChange: any,
    handleRemoveInput: any
    formValues: any
    setObjectList: any
}

export const UnitDraggableList: React.FC<DraggableListProps> = ({ setObjectList, sessionList, UnitObjectList, handleInputChange, handlePermissionChange, handleRemoveInput, formValues }) => {
    //   const [items, setItems] = useState<any[]>(UnitObjectList);
    const dragItem = useRef<number | null>(null);

    const handleDragStart = useCallback((index: number) => {
        dragItem.current = index;
    }, []);

    const handleDragEnter = useCallback(
        (index: number) => {
            if (dragItem.current !== null && dragItem.current !== index) {
                const newItems = [...UnitObjectList];
                const removedItem = newItems.splice(dragItem.current, 1)[0];
                newItems.splice(index, 0, removedItem);
                dragItem.current = index;
                setObjectList(newItems);
            }
        },
        [UnitObjectList]
    );


    const handleDragEnd = useCallback(() => {
        dragItem.current = null;
    }, []);

    const ListItems = () => (
        <>
            {UnitObjectList.map((item: any, index: any) => (
                <li
                    key={item.id}
                    className="flex w-full justify-center items-center"
                    style={{ userSelect: "none", cursor: "pointer" }}
                >
                    <div className="w-full ">
                        <SessionSelectorArray
                            UnitObjectList={UnitObjectList}
                            formValues={formValues}
                            handleInputChange={handleInputChange}
                            handlePermissionChange={handlePermissionChange}
                            handleRemoveInput={handleRemoveInput}
                            index={index}
                            select={item}
                            sessionList={sessionList}
                        />
                    </div>
                    <div draggable
                        onDragStart={() => handleDragStart(index)}
                        onDragEnter={() => handleDragEnter(index)}
                        onDragEnd={handleDragEnd} className="flex flex-col h-fit py-[10px] mx-10 px-[8px] border  rounded-md  gap-1">
                        <span className="block  w-5 h-[2px] bg-orange-600"></span>
                        <span className="block  w-5 h-[2px] bg-orange-600"></span>
                        <span className="block  w-5 h-[2px] bg-orange-600"></span>
                    </div>
                </li>
            ))}
        </>
    );

    return <ul className="gap-3 flex flex-col ">{ListItems()}</ul>;
};

export default UnitDraggableList;

type Props = {};

const SelfReportIcon = (props: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10.75C1 10.75 1.75 10 4 10C6.25 10 7.75 11.5 10 11.5C12.25 11.5 13 10.75 13 10.75V1.75C13 1.75 12.25 2.5 10 2.5C7.75 2.5 6.25 1 4 1C1.75 1 1 1.75 1 1.75V10.75ZM1 10.75V16"
        stroke="white"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default SelfReportIcon;

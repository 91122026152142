import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Status } from "./UserManagementSlice";
import axiosClient from "../../api/api";
import {
  ApiResponse,
  guideList,
  postGuideAPIRequest,
  postGuideAPIResponse,
} from "../../types/apiresponse";

type deleteGuideAPIResponse = {};
type deleteGuideAPIRequest = {};

export interface MicroMoveStep {
  title: string;
  description: string;
  steps: StepDetail[];
}

interface StepDetail {
  detail: string;
  vimeo_link: string;
  image: FileList | string;
}
type postFundamentalAPIResponse = {
  guide_id: string;
  title: string;
  description: string;
  main_image: string;
  secondary_image: string;
  micro_move_steps: MicroMoveStep[];
  updated_at?: string;
  created_at?: string;
  _id?: string;
  main_image_url?: string;
  secondary_image_url?: string;
};
type postFundamentalAPIRequest = {
  guide_id: string;
  name: string;
  description: string;
  main_image: string | File;
  secondary_image: string | File;
  micro_move_steps: MicroMoveStep[];
  id?: string;
};
type deleteFundamentalAPIResponse = {};
type deleteFundamentalAPIRequest = {};
type listFundamentalAPIResponse = {};
type listFundamentalAPIRequest = {};

export const deleteFundamentalAPI = createAsyncThunk<
  ApiResponse<postFundamentalAPIResponse>,
  { id: string; guideId: string }
>("guide/postFundamentaldelete", async ({ id, guideId }) => {
  const res = await axiosClient.delete<ApiResponse<postFundamentalAPIResponse>>(
    `auth/guide_fundamentals/${id}/${guideId}`
  );
  console.log(res.data);
  return res.data;
});
export const postFundamentalAPI = createAsyncThunk<
  ApiResponse<postFundamentalAPIResponse>,
  FormData
>("guide/postFundamental", async (data) => {
  const res = await axiosClient.post<ApiResponse<postFundamentalAPIResponse>>(
    "auth/guide_fundamentals",
    data
  );
  console.log(res.data);
  return res.data;
});
export const postGuideAPI = createAsyncThunk<
  ApiResponse<postGuideAPIResponse>,
  postGuideAPIRequest
>("guide/postGuide", async (data) => {
  const res = await axiosClient.post<ApiResponse<postGuideAPIResponse>>(
    "auth/guide",
    data
  );
  console.log(res.data);
  return res.data;
});

export const listGuideAPI = createAsyncThunk<ApiResponse<guideList[]>>(
  "guide/listGuide",
  async () => {
    const res = await axiosClient.get<ApiResponse<guideList[]>>("auth/guide");
    return res.data;
  }
);

interface GuideDrillSlice {
  postGuideAPIIdle: Status;
  deleteGuideAPIIdle: Status;
  listGuideAPIIdle: Status;
  postFundamentalAPIIdle: Status;
  deleteFundamentalAPIIdle: Status;
  listFundamentalAPIIdle: Status;
  guideList: guideList[];
}

const initialState: GuideDrillSlice = {
  deleteFundamentalAPIIdle: Status.SUCCESS,
  deleteGuideAPIIdle: Status.SUCCESS,
  listFundamentalAPIIdle: Status.SUCCESS,
  listGuideAPIIdle: Status.SUCCESS,
  postFundamentalAPIIdle: Status.SUCCESS,
  postGuideAPIIdle: Status.SUCCESS,
  guideList: [],
};

const GuideDrillSlice = createSlice({
  name: "guideDrill",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(listGuideAPI.pending, (state) => {
        state.listGuideAPIIdle = Status.LOADING;
      })
      .addCase(listGuideAPI.fulfilled, (state, action) => {
        state.listGuideAPIIdle = Status.SUCCESS;
        state.guideList = action.payload.data;
      })
      .addCase(listGuideAPI.rejected, (state) => {
        state.listGuideAPIIdle = Status.ERROR;
      })
      .addCase(postGuideAPI.pending, (state) => {
        state.postGuideAPIIdle = Status.LOADING;
      })
      .addCase(postGuideAPI.fulfilled, (state, action) => {
        state.postGuideAPIIdle = Status.SUCCESS;
        // state.postGuideAPIIdle = action.payload.data
      })
      .addCase(postGuideAPI.rejected, (state) => {
        state.postGuideAPIIdle = Status.ERROR;
      })
      .addCase(postFundamentalAPI.pending, (state) => {
        state.postFundamentalAPIIdle = Status.LOADING;
      })
      .addCase(postFundamentalAPI.fulfilled, (state, action) => {
        state.postFundamentalAPIIdle = Status.SUCCESS;
        // state.postGuideAPIIdle = action.payload.data
      })
      .addCase(postFundamentalAPI.rejected, (state) => {
        state.postFundamentalAPIIdle = Status.ERROR;
      })
      .addCase(deleteFundamentalAPI.pending, (state) => {
        state.deleteFundamentalAPIIdle = Status.LOADING;
      })
      .addCase(deleteFundamentalAPI.fulfilled, (state, action) => {
        state.deleteFundamentalAPIIdle = Status.SUCCESS;
        // state.postGuideAPIIdle = action.payload.data
      })
      .addCase(deleteFundamentalAPI.rejected, (state) => {
        state.deleteFundamentalAPIIdle = Status.ERROR;
      });
  },
});

export const {} = GuideDrillSlice.actions;

export default GuideDrillSlice.reducer;

import React, { ReactNode, useRef } from "react";
import ButtonComponent from "../Buttons/ButtonComponent";
import { useOnClickOutside } from "../../CustomHooks/useOnClickOutside";
import { Status } from "../../Redux/features/UserManagementSlice";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: React.ReactNode;
  confirmAction?: (data?: any) => void;
  children?: ReactNode;
  loading?: Status;
  actionCTA?: string;
}

const ModalComponent: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  message,
  confirmAction,
  loading = Status.SUCCESS,
  children,
  actionCTA = "Disable",
}) => {
  const ModalRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(ModalRef, () => {
    onClose();
  });
  return (
    <>
      {isOpen && (
        <div className="w-full flex items-center z-[99] justify-center h-screen bg-black bg-opacity-30 fixed top-0 left-0">
          <div
            ref={ModalRef}
            className="bg-white m-auto w-1/3  rounded-md fixed"
          >
            <div className="p-10 flex flex-col justify-between">
              <p className="text-lg font-medium">{message}</p>
              <div className="grid grid-cols-8 gap-2 pt-6">
                {children}
                <ButtonComponent
                  CTA="Cancel"
                  className="col-span-2 col-start-5"
                  onClick={onClose}
                  varient="simple"
                  buttonType="button"
                />
                <ButtonComponent
                  className=" col-span-2"
                  varient={"blue"}
                  CTA={actionCTA}
                  onClick={confirmAction}
                  loading={loading}
                  buttonType="button"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalComponent;

import { UnitAddFormValues, UnitSession } from "../../Pages/Units/AddUnitsPage";
import { useAppSelector } from "../../Redux/app/hooks";
import {
  Control,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form/dist/types";
import { UnitDraggableList } from "../../Components/DraggableList/SessionDraggableList";

type Props = {
  register: UseFormRegister<UnitAddFormValues>;
  errors: FieldErrors<UnitAddFormValues>;
  formValues: UnitAddFormValues;
  control: Control<UnitAddFormValues>;
  UnitObjectList: UnitSession[];
  setUnitObjectList: React.Dispatch<React.SetStateAction<UnitSession[]>>;
};

function UnitObjects({
  UnitObjectList = [],
  setUnitObjectList,
  formValues,
}: Props) {
  const handleInputChange = (index: number, event: string, time: number) => {
    const values = [...UnitObjectList];
    values[index] = {
      ...values[index],
      object_id: event,
      time: time,
    };
    setUnitObjectList(values);
  };
  const handlePermissionChange = (index: number, event: "" | 0 | 1) => {
    const values = [...UnitObjectList];
    values[index] = {
      ...values[index],
      permission: event,
    };
    setUnitObjectList(values);
  };

  const handleAddInput = () => {
    setUnitObjectList([
      ...UnitObjectList,
      {
        object_id: "",
        permission: 1,
        time: 0,
      },
    ]);
  };

  const sessionList = useAppSelector((state) => state.Session.sessionList);

  const handleRemoveInput = (index: number) => {
    const values = [...UnitObjectList];
    values.splice(index, 1);
    setUnitObjectList(values);
  };

  return (
    <div className="border rounded-[12px] ">
      <div className="px-5 py-5 flex gap-5 ">
        <span className="text-base text-[#212121] font-medium ">
          Unit Objects :
        </span>
        <span className="text-base text-[#9E9E9E]">
          {UnitObjectList.length} Session
        </span>
        <span className="flex justify-center gap-2 items-center">
          <img src="/images/plusButton.svg" alt="" />
          <button
            type="button"
            className="text-base text-[#212121] font-medium"
            onClick={handleAddInput}
          >
            Add New Session
          </button>{" "}
        </span>
      </div>
      <div className="grid grid-cols-1 gap-4 py-4">
        {sessionList.length !== 0 && (
          <>
            <UnitDraggableList
              UnitObjectList={UnitObjectList}
              formValues={formValues}
              handleInputChange={handleInputChange}
              handlePermissionChange={handlePermissionChange}
              handleRemoveInput={handleRemoveInput}
              sessionList={sessionList}
              setObjectList={setUnitObjectList}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default UnitObjects;

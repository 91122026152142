import React from "react";

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const CloseEye: React.FC<IconProps> = ({ width = 16, height = 16 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M9.15586 9.4395C8.98184 9.62879 8.77207 9.78053 8.53907 9.88569C8.30607 9.99084 8.0546 10.0472 7.79967 10.0515C7.54474 10.0558 7.29158 10.0079 7.05527 9.91065C6.81897 9.81339 6.60437 9.66878 6.42428 9.48545C6.24419 9.30212 6.10229 9.08382 6.00706 8.84357C5.91182 8.60333 5.8652 8.34606 5.86998 8.08711C5.87475 7.82817 5.93082 7.57285 6.03484 7.3364C6.13886 7.09994 6.2887 6.88719 6.47543 6.71084M0.861694 0.996094L14.7696 15.1542M11.5708 11.8979C10.4881 12.7343 9.17028 13.1969 7.81017 13.218C3.38013 13.2132 0.854163 8.06752 0.854163 8.06752C1.64296 6.57817 2.73619 5.2774 4.06051 4.25247L11.5708 11.8979ZM6.49195 3.08514C6.92767 2.98204 7.37373 2.93075 7.82112 2.93231C12.2512 2.93718 14.7771 8.08282 14.7771 8.08282C14.3922 8.81243 13.9333 9.49922 13.408 10.132L6.49195 3.08514Z"
        stroke="#BDBDBD"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CloseEye;

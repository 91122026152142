import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosClient from '../../api/api';
import { ApiResponse } from '../../types/apiresponse';
import { Status } from './UserManagementSlice';

export interface GolfArticle {
    _id: string;
    headline: string;
    subtitle: string;
    text: string;
    image: string; // Assuming this is a reference to an image file name or ID
    vimeo_links: string;
    updated_at: string; // ISO 8601 date string format
    created_at: string; // ISO 8601 date string format
    image_url: string; // URL to the image
}

interface VimeoLink {
    link: string;
    title: string;
    description: string;
}

export const getAboutFisio = createAsyncThunk<ApiResponse<GolfArticle[]>>("aboutFisio/getData", async () => {
    const res = await axiosClient.get<ApiResponse<GolfArticle[]>>(`auth/about_fisio`)
    return res.data
})

export const postAboutFisio = createAsyncThunk<ApiResponse<GolfArticle>, FormData>("aboutFisio/postData", async (data) => {
    const res = await axiosClient.post<ApiResponse<GolfArticle>>(`auth/about_fisio`, data)
    return res.data
})
export const deleteAboutFisio = createAsyncThunk<ApiResponse<GolfArticle>, string>("aboutFisio/deleteData", async (data) => {
    const res = await axiosClient.delete<ApiResponse<GolfArticle>>(`auth/about_fisio/${data}`,)
    return res.data
})

type FisioDetailsOrder = {
    about_list: string
}

export const FisioDetailsOrder = createAsyncThunk<ApiResponse<GolfArticle>, FisioDetailsOrder>("aboutFisio/order", async (data) => {
    const res = await axiosClient.post<ApiResponse<GolfArticle>>(`auth/order_about_fisio`, data)
    return res.data
})


interface AboutFisioSlice {
    postAboutFisio: Status;
    getAboutFisioIdle: Status;
    deleteAboutFisioIdle: Status;
    FisioDetailsOrderIdle: Status;
    golfArticles: GolfArticle[];

}

const initialState: AboutFisioSlice = {
    deleteAboutFisioIdle: Status.SUCCESS,
    postAboutFisio: Status.SUCCESS,
    getAboutFisioIdle: Status.SUCCESS,
    FisioDetailsOrderIdle: Status.SUCCESS,
    golfArticles: []
}

const AboutFisioSlice = createSlice({
    name: "About Fisio",
    initialState,
    reducers: {
        setItems: (state, action) => {
            state.golfArticles = action.payload;
        },
        reorderItems: (state, action) => {
            const { startIndex, endIndex } = action.payload;
            const result = Array.from(state.golfArticles);
            const [removed] = result.splice(startIndex, 1);
            result.splice(endIndex, 0, removed);
            state.golfArticles = result;
        },
    },
    extraReducers(builder) {
        builder
            .addCase(getAboutFisio.pending, (state, action) => {
                state.getAboutFisioIdle = Status.LOADING
            })
            .addCase(getAboutFisio.fulfilled, (state, action) => {
                state.golfArticles = action.payload.data
                state.getAboutFisioIdle = Status.SUCCESS
            })
            .addCase(getAboutFisio.rejected, (state, action) => {
                state.getAboutFisioIdle = Status.ERROR
            })
            .addCase(FisioDetailsOrder.pending, (state, action) => {
                state.FisioDetailsOrderIdle = Status.LOADING
            })
            .addCase(FisioDetailsOrder.fulfilled, (state, action) => {
                state.FisioDetailsOrderIdle = Status.SUCCESS
            })
            .addCase(FisioDetailsOrder.rejected, (state, action) => {
                state.FisioDetailsOrderIdle = Status.ERROR
            })
            .addCase(deleteAboutFisio.pending, (state, action) => {
                state.deleteAboutFisioIdle = Status.LOADING
            })
            .addCase(deleteAboutFisio.fulfilled, (state, action) => {
                state.deleteAboutFisioIdle = Status.SUCCESS
            })
            .addCase(deleteAboutFisio.rejected, (state, action) => {
                state.deleteAboutFisioIdle = Status.ERROR
            })
            .addCase(postAboutFisio.pending, (state, action) => {
                state.postAboutFisio = Status.LOADING
            })
            .addCase(postAboutFisio.fulfilled, (state, action) => {
                state.postAboutFisio = Status.SUCCESS
            })
            .addCase(postAboutFisio.rejected, (state, action) => {
                state.postAboutFisio = Status.ERROR
            })
    },
});

export const { reorderItems, setItems } = AboutFisioSlice.actions

export default AboutFisioSlice.reducer
import React, { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import InputComponent from "../../Components/Inputs/InputComponent";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import { useNavigate } from "react-router-dom";
import TableComponent from "../../Components/TableComponent";
import { Status } from "../../Redux/features/UserManagementSlice";
import CopyIcon from "../../Components/Icons/CopyIcon";
import EditIcon from "../../Components/Icons/EditIcon";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import { FundamentalsTableHeader } from "../../utils/TableColumns";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  deleteFundamentalAPI,
  listGuideAPI,
  postGuideAPI,
} from "../../Redux/features/GuideDrillSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import { ApiResponse } from "../../types/apiresponse";
import { notifyError, notifySuccess } from "../../helper";
import moment from "moment";

type Props = {};

type GuideForm = {
  description: string;
};

const GuidePage = (props: Props) => {
  const Guide = useAppSelector((state) => state.Guide.guideList);
  const guideSchema = Yup.object().shape({
    description: Yup.string().required("Guide Description Required "),
  });

  const {
    register,
    setValue,
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm<GuideForm>({
    resolver: yupResolver(guideSchema),
    // defaultValues: {
    //     description: Guide[0]?.description
    // }
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setValue("description", Guide[0]?.description);
  }, [Guide]);

  useEffect(() => {
    dispatch(listGuideAPI());
  }, []);

  const onSubmit = (data: GuideForm) => {
    dispatch(
      postGuideAPI({
        description: data.description,
        title: Guide[0]?.title,
        id: Guide[0]?.id,
      })
    );
  };
  const ConfirmDelete = () => {
    dispatch(deleteFundamentalAPI({ id: id, guideId: Guide[0]?.id })).then(
      (res: any) => {
        if (res.payload?.success) {
          dispatch(listGuideAPI());
          setid("0");
          notifySuccess("Fundamental Removed");
          setDeleteForm(false);
        } else {
          setid("0");
          setDeleteForm(false);
        }
      }
    );
  };

  const deleteFundamentalAPIIdle = useAppSelector(
    (state) => state.Guide.deleteFundamentalAPIIdle
  );

  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
  };
  const handleDeleteClick = (id: string) => {
    setDeleteForm(true);
    setid(id);
  };
  const [id, setid] = useState("0");
  const [showDeleteForm, setDeleteForm] = useState(false);
  console.log(Guide[0]);
  return (
    <>
      <BoxLayout HeaderName="Content Repository" sectionName="Guide">
        <div className="border-t">
          <div className="mx-4 my-3 border-2 rounded-lg">
            <div className="py-2 font-bold text-[0.75rem] px-4 border-b">
              Guide
            </div>
            <div className="p-5 bg-[#EAF0F3]">
              <div className="p-5 flex flex-col gap-6 rounded-lg drop-shadow-xl bg-[#ffffff]">
                <p className="text-[#757575] font-medium ">{Guide[0]?.title}</p>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className=" gap-3 items-center grid grid-cols-6"
                >
                  <InputComponent
                    label="Description"
                    inputRef="description"
                    name=""
                    register={register}
                    value=""
                    className="col-span-3"
                  />
                  <ButtonComponent CTA="Submit" varient="blue" />
                </form>
              </div>
            </div>
          </div>

          <div className=" my-3  rounded-lg">
            <div className="py-2  font-bold  px-6 ">List of Fundamentals</div>
            <FilterSortPanel
              Function={() => navigate("/contentrepository/addfundamentals")}
            />
            <TableComponent
              loader={Status.SUCCESS}
              TableHeaderData={FundamentalsTableHeader}
            >
              <tbody>
                {Guide[0]?.fundamentals?.map((i, index) => (
                  <tr
                    key={index}
                    className="font-roboto font-medium border-y border-y-[#EAF0F3] leading-16 text-gray-700 tracking-tighter"
                  >
                    <td className="py-1 text-start  w-[300px] whitespace-nowrap block text-ellipsis overflow-hidden">
                      {i.name}
                    </td>
                    <td className="py-1 text-start">
                      {moment(i.updated_at).format("DD.MM.YYYY HH:MM")}
                    </td>

                    <td className="py-1 text-start flex h-full items-center gap-4">
                      <button
                        onClick={() => {
                          navigate("/contentrepository/addfundamentals", {
                            state: {
                              name: i.name,
                              description: i.description,
                              micromoves: i.micro_moves,
                              main_image: i.main_image,
                              secondary_image: i.secondary_image,
                              secondary_vimeo_link: i.secondary_vimeo_link,
                            },
                          });
                        }}
                      >
                        <CopyIcon />
                      </button>
                      <button
                        onClick={() => {
                          navigate("/contentrepository/editfundamentals", {
                            state: {
                              id: i.id,
                              name: i.name,
                              description: i.description,
                              micromoves: i.micro_moves,
                              main_image: i.main_image,
                              secondary_image: i.secondary_image,
                              secondary_vimeo_link: i.secondary_vimeo_link,
                            },
                          });
                        }}
                      >
                        <EditIcon />
                      </button>
                      <button onClick={() => handleDeleteClick(i.id)}>
                        <DeleteIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableComponent>
          </div>
        </div>
      </BoxLayout>

      <ModalComponent
        confirmAction={ConfirmDelete}
        message={"Are you sure to delete this Fundamental ?"}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
        loading={deleteFundamentalAPIIdle}
      />
    </>
  );
};

export default GuidePage;

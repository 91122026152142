import { useEffect, useState } from "react";
import InputComponent from "../../Components/Inputs/InputComponent";
import RecallCardTextEditor from "../../Components/Inputs/RecallCardTextEditor";
import CenterPopUpComponent from "../../Components/PopUp/CenterPopUpComponent";
import FilterSortPanel from "../Layout/FilterSortPanel";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import TableComponent from "../../Components/TableComponent";
import { AddDrillTableHeader } from "../../utils/TableColumns";
import {
  userListHandleSort,
  Status,
} from "../../Redux/features/UserManagementSlice";
import { manageSorting } from "../../utils/helper";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  drillListHandleSort,
  getDrillList,
  submitCoachReview,
} from "../../Redux/features/CoachReviewSlice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { notifyError, notifySuccess } from "../../helper";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import classNames from "classnames";
import { useCookies } from "react-cookie";
import TableSkeleton from "../../Skeleton/TableSkeleton";

const CoachDetails = ({}: any) => {
  const drills = useAppSelector((state) => state.Coach.drillList);
  const drillsSelected = useAppSelector(
    (state) => state.Coach.reviewDetails.drills
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleAddDrillClick = () => {
    setIsOpen(true); // Open the popup when "Add New Drill" is clicked
  };

  const handlePopupClose = () => {
    setIsOpen(false);
  };
  const reorder = (
    list: ObjectItem[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const location = useLocation();
  const [coachValue, setcoachValue] = useState("");
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        getDrillList({
          searchValue: coachValue,
          sortType: sortType,
          sortValue: sortValue,
        })
      );
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coachValue]);

  const listLoading = useAppSelector(
    (state) => state.Coach.getDrillListAPIIdle
  );

  const getSearchValue = (d: string) => {
    setcoachValue(d);
    // dispatch(UserListAPI({ page: 1, userType: "admin", searchValue: d }));
  };
  const [selectedCoach, setSelectedCoach] = useState<any[]>(
    drillsSelected ? drillsSelected : []
  );

  useEffect(() => {
    function orderObjectsByFirstArray(
      firstArray: Array<{ object_id: string }>,
      secondArray: ObjectItem[]
    ): ObjectItem[] {
      return (
        (firstArray
          ?.map((fItem) =>
            secondArray?.find((sItem) => sItem._id === fItem.object_id)
          )
          ?.filter((item) => item !== undefined) as ObjectItem[]) || []
      );
    }
    console.log(drillsSelected);
    const filteredResults = orderObjectsByFirstArray(drillsSelected, drills);
    setSelectedCoach(filteredResults);
  }, [drillsSelected, drills]);

  const handleCheckBoxChange = (coach: ObjectItem) => {
    // Assuming coach is an object like { id: string }
    const index = selectedCoach?.findIndex((c) => c._id === coach._id);

    if (index === -1) {
      // Coach not found, add the coach object to the array
      setSelectedCoach([...selectedCoach, coach]);
    } else {
      // Coach found, remove it from the array
      setSelectedCoach(selectedCoach?.filter((c) => c._id !== coach._id));
    }
  };
  console.log(location.state);
  const dispatch = useAppDispatch();
  // getDrillList
  const sortType = useAppSelector((state) => state.Coach.drillSortType);
  const sortValue = useAppSelector((state) => state.Coach.drillSortValue);
  useEffect(() => {
    console.log("S");
    dispatch(
      getDrillList({
        sortType: sortType,
        sortValue: sortValue,
      })
    );
  }, [sortType]);

  const onDragEnd = (result: any) => {
    // Dropped outside the list
    if (!result.destination) {
      return;
    }

    const newSelectedDrill = reorder(
      selectedCoach,
      result.source.index,
      result.destination.index
    );

    setSelectedCoach(newSelectedDrill);
  };

  // Submit Function
  interface coachReviewForm {
    description: string;
    vimeoLink: string;
  }
  const { ticketid } = useParams();

  const formValidation = yup.object().shape({
    description: yup.string().required(),
    vimeoLink: yup
      .string()
      .test("VimeoID", "invalid vimeo URL", (value: any) => {
        try {
          const url = new URL(value);
          if (url) {
            if (
              url.origin === "https://vimeo.com" &&
              Number(url.pathname.split("/")[1])
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        } catch (e) {
          return false;
        }
      }),
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<coachReviewForm>({
    resolver: yupResolver(formValidation),
    defaultValues: {
      description: location?.state?.coach_review.description,
      vimeoLink: location?.state?.coach_review.vimeo_link,
    },
  });
  const [Reviewid, setReviewid] = useState();
  useEffect(() => {
    console.log(location?.state?.status);
    if (!ticketid) {
      if (location?.state?.status === "Pending") {
        dispatch(
          submitCoachReview({
            id: location.state?.coach_review.id,
            swing_id: location.state._id,
            description: " ",
            drills: "[]",
            status: "In Progress",
            vimeo_link: " ",
          })
        ).then((res) => {
          console.log(res);
          if (res.payload.success) {
            setReviewid(res.payload.data._id);
          }
        });
      }
    }
  }, [location?.state?.status]);

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1)?.map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const navigate = useNavigate();
  const submit = (data: coachReviewForm) => {
    console.log(data, selectedCoach);
    dispatch(
      submitCoachReview({
        id: location.state?.coach_review.id || Reviewid,
        swing_id: location.state._id,
        description: data.description,
        drills: JSON.stringify(
          selectedCoach.map((drill) => {
            return {
              object_id: drill._id,
            };
          })
        ),
        status: "Completed",
        vimeo_link: data.vimeoLink,
      })
    ).then((res: any) => {
      if (res.payload.success) {
        navigate("/coachreview/list");
        notifySuccess("Coach Review Submited Successfully");
      }
    });
  };
  console.log(selectedCoach);

  const loading = useAppSelector((state) => state.Coach.submitCoachReview);

  const formValues = watch();

  const [cookies, setCookies] = useCookies();

  return (
    <form
      className="grid p-4 gap-4 grid-cols-12"
      onSubmit={handleSubmit(submit)}
    >
      <div className="rounded-[10px] font-semibold col-span-12 md:col-span-8 border">
        <p className="py-4 px-4">Coach Review</p>
        <div className="grid grid-cols-8 border-t p-4 gap-4">
          <RecallCardTextEditor
            control={control}
            className="col-span-12 "
            register={register}
            inputRef="description"
            name="subtitles"
            values={formValues.description}
            label="Text"
          />
          <InputComponent
            className="col-span-12"
            register={register}
            inputRef="vimeoLink"
            label="Video Link"
            name="question"
            value={formValues.vimeoLink}
          />
        </div>
      </div>
      <div className="rounded-[10px] col-span-12  md:col-span-4  border">
        <div className="py-2 text-base px-4 font-semibold border-b justify-between items-center w-full flex">
          <p className="py-2">Drills</p>
          <label htmlFor="file-input" className="cursor-pointer text-[#1F3161]">
            {!ticketid && (
              <button
                type="button"
                className="flex justify-center gap-2 items-center"
                onClick={handleAddDrillClick} // Invoke handleAddDrillClick when button is clicked
              >
                <img className="mx-3" src="/images/plusButton.svg" alt="" />
                Add New Drill
              </button>
            )}
          </label>
        </div>

        <div>
          {selectedCoach.length === 0 ? (
            <div
              className={classNames("place-items-center py-20 text-xs", {
                // "opacity-40": cookies.ic === "0"
              })}
            >
              <p className="text-center text-[#9e9e9e] font-semibold text-base">
                You haven't Added Drills yet
              </p>
              <p className="text-center text-[#9e9e9e] text-sm ">
                You can add by Clicking "Add New Drill"
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="flex flex-col  max-h-[250px] overflow-auto example">
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="parent5">
                {(provided) => (
                  <ul ref={provided.innerRef} {...provided.droppableProps}>
                    {selectedCoach?.map((item, index) => (
                      <Draggable
                        key={item._id}
                        draggableId={String(item._id)}
                        index={index}
                      >
                        {(provided) => (
                          <li
                            key={item._id}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div className="px-4 flex items-center justify-between py-2 w-full">
                              <div className="flex items-center px-4">
                                <div className="flex ">
                                  {" "}
                                  <div
                                    className="flex my-auto flex-col items-center justify-center h-full py-[10px] bg-[] px-[8px] border bg-[#EAF0F3] rounded-md  gap-1"
                                    {...provided.dragHandleProps}
                                  >
                                    <span className="block  w-6 h-[2px] bg-[#285A9F]"></span>
                                    <span className="block  w-6 h-[2px] bg-[#285A9F]"></span>
                                    <span className="block  w-6 h-[2px] bg-[#285A9F]"></span>
                                  </div>
                                </div>

                                <div className="px-3 text-center">
                                  {index + 1} .
                                </div>
                                <div
                                  className={classNames(
                                    " text-ellipsis overflow-hidden whitespace-nowrap ",
                                    {
                                      "w-[90px] md:w-[150px]":
                                        ticketid === undefined,
                                      "w-[10px] md:w-[250px]":
                                        ticketid !== undefined,
                                    }
                                  )}
                                >
                                  {item.object_name}
                                </div>
                              </div>

                              <div className=" flex  border-l-2 px-3 gap-4">
                                {ticketid === undefined ? (
                                  <button
                                    disabled={ticketid !== undefined}
                                    className="text-[#9E9E9E] font-medium text-sm flex justify-center items-center gap-2"
                                    type="button"
                                    onClick={handleAddDrillClick}
                                  >
                                    <img
                                      className="w-6"
                                      src="/images/editicon.svg"
                                      alt=""
                                    />
                                  </button>
                                ) : (
                                  <></>
                                )}
                                {ticketid === undefined ? (
                                  <button
                                    disabled={ticketid !== undefined}
                                    className="text-[#9E9E9E] font-medium text-sm flex justify-center items-center gap-2"
                                    type="button"
                                    onClick={() => handleCheckBoxChange(item)}
                                  >
                                    <img
                                      className="w-6"
                                      src="/images/remove.svg"
                                      alt=""
                                    />
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </li>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
        <CenterPopUpComponent
          isOpen={isOpen}
          message="Add Drills"
          onClose={handlePopupClose}
          customStyle={{ width: "700px", height: "600px" }}
        >
          <div className=" flex overflow-y-auto flex-col h-full w-full justify-between overflow-x-hidden gap-5">
            <div>
              <FilterSortPanel
                Function={() => {}}
                showAddButton={false}
                customClassName="border-none"
                getSearchValue={getSearchValue}
              />
              <TableComponent
                TableHeaderData={AddDrillTableHeader}
                loader={listLoading}
                sortType={sortType}
                sortValue={sortValue}
                onClick={manageSorting}
                handleSortAction={drillListHandleSort}
                sortIcon
                heightClass="border-none h-auto"
                checkBox={selectedCoach.length === drills.length}
                checkBoxFunction={(e) => {
                  if (e.target.checked) {
                    setSelectedCoach(drills.map((coach) => coach));
                  } else {
                    setSelectedCoach([]);
                  }
                }}
              >
                {listLoading === Status.SUCCESS && (
                  <tbody className="overflow-x-hidden">
                    {drills.map((drill, index) => (
                      <tr className="cursor-pointer py-1" key={index}>
                        <td className="py-3 text-start pl-5 ">
                          <input
                            checked={
                              selectedCoach.findIndex(
                                (c) => c._id === drill._id
                              ) !== -1
                            }
                            onChange={() => handleCheckBoxChange(drill)}
                            type="checkbox"
                            className="accent-orange-600 mr-6"
                          />
                        </td>
                        <td className="py-3 text-start pr-1 ">
                          <p className="w-[280px] block text-ellipsis overflow-hidden whitespace-nowrap">
                            {drill.object_name}
                          </p>
                        </td>
                        <td className="py-3 text-start pr-1">
                          {drill.category_type_name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
                {listLoading === Status.LOADING && (
                  <TableSkeleton tableColumns={3} />
                )}
              </TableComponent>
            </div>

            <div className="grid grid-cols-6 gap-4">
              <ButtonComponent
                CTA="Close"
                className="col-start-6"
                varient="white"
                buttonType="button"
                onClick={handlePopupClose}
              />
              {/* <ButtonComponent CTA="Add" varient="blue" buttonType="button" /> */}
            </div>
          </div>
        </CenterPopUpComponent>
      </div>
      {!ticketid && (
        <div className="p-4  col-span-12 grid grid-cols-10">
          <div className="col-span-3 gap-5">
            <button
              type="button"
              className="flex items-center text-[#191919] font-semibold gap-2"
              onClick={() => {
                navigate("/coachreview/tickets");
              }}
            >
              <img src="/images/arrow-left.svg" alt="" />
              Back
            </button>
          </div>
          <div className="col-span-3 md:col-span-1 col-start-8 md:col-start-10 ">
            <ButtonComponent
              key={1}
              CTA="Save"
              varient="blue"
              loading={loading}
            />
          </div>
        </div>
      )}
    </form>
  );
};

export default CoachDetails;

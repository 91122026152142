import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";
import { notificationresponse } from "../../types/apiresponse";

type RecallCardPayloadType = {
  searchValue?: string;
  sortType?: sortValues;
  sortValue?: string;
  page?: number;
  perPage?: number;
};

export const getRecallCards = createAsyncThunk<
  GetRecallCards,
  RecallCardPayloadType
>("recallcards/getRecallCards", async ({ perPage, ...RecallCardPayload }) => {
  const res = await axiosClient.get(
    `auth/recall-cards?$&page=${RecallCardPayload.page || 1}${
      RecallCardPayload.searchValue
        ? `&filteredVal=${RecallCardPayload.searchValue}`
        : ""
    }${
      RecallCardPayload.sortType === "ascending"
        ? `&sort=-${RecallCardPayload.sortValue}`
        : ``
    }${
      RecallCardPayload.sortType === "descending"
        ? `&sort=${RecallCardPayload.sortValue}`
        : ``
    }${perPage ? `&per_page=${perPage}` : ``}`
  );
  return {
    data: res.data.data,
    page: RecallCardPayload.page || 1,
  };
});

export const postRecallCardsAPI = createAsyncThunk<
  PostRecallCardsResponse,
  FormData
>("recallcards/postRecallCards", async (data) => {
  const res = await axiosClient.post("auth/recall-cards", data);
  return res.data;
});

export const deleteRecallCardsAPI = createAsyncThunk<PayloadType, string>(
  "recallcards/deleteRecallCards",
  async (id) => {
    const res = await axiosClient.delete(`auth/recall-cards/${id}`);
    return res.data;
  }
);

export const RearrangeRecallCardsAPI = createAsyncThunk<
  notificationresponse,
  { recall_card_ids: [{ id: string }]; show: boolean }
>("recallcards/RearrangeRecallCardsAPI", async ({ recall_card_ids, show }) => {
  const res = await axiosClient.post("auth/recall-card-setting-update", {
    recall_card_ids,
    show,
  });
  return res.data;
});

interface initialStateTypes {
  getRecallCardsAPIIdle: Status;
  postRecallCardsAPIIdle: Status;
  deleteRecallCardsAPIIdle: Status;
  recallCards: RecallCard[];
  sortType: sortValues;
  sortValue: string;
  totalPages: number;
  activePage: number;
  selectedRecallCard: any[];
  RearrangeRecallCardStatus: Status;
  RearrangeRecallCardResponse: notificationresponse;
}

const initialState: initialStateTypes = {
  getRecallCardsAPIIdle: Status.SUCCESS,
  postRecallCardsAPIIdle: Status.SUCCESS,
  deleteRecallCardsAPIIdle: Status.SUCCESS,
  RearrangeRecallCardStatus: Status.SUCCESS,
  recallCards: [],
  sortType: "ascending",
  sortValue: "order",
  activePage: 1,
  totalPages: 0,
  selectedRecallCard: [],
  RearrangeRecallCardResponse: {
    message: "",
    success: false,
  },
};

const RecallCardsSlice = createSlice({
  name: "Recall Cards",
  initialState,
  reducers: {
    handleSort: (state, action) => {
      return {
        ...state,
        sortType: action.payload.sortType,
        sortValue: action.payload.sortValue,
      };
    },
    checkAllRecallBoxes: (state) => {
      return {
        ...state,
        selectedRecallCard: state.recallCards,
      };
    },
    unCheckAllRecallBoxes: (state) => {
      return {
        ...state,
        selectedRecallCard: [],
      };
    },
    RecalladdCheckbox: (state, action) => {
      state.selectedRecallCard.push(action.payload);
    },
    RecallremoveCheckbox: (state, action) => {
      state.selectedRecallCard = state.selectedRecallCard.filter(
        (item) => item._id !== action.payload._id
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getRecallCards.pending, (state) => {
        return {
          ...state,
          getRecallCardsAPIIdle: Status.LOADING,
        };
      })
      .addCase(getRecallCards.fulfilled, (state, action) => {
        return {
          ...state,
          totalPages: action.payload.data.total_page,
          recallCards: action.payload.data.recall_cards,
          getRecallCardsAPIIdle: Status.SUCCESS,
          activePage: action.payload.page,
        };
      })
      .addCase(getRecallCards.rejected, (state, action) => {
        return {
          ...state,
          getRecallCardsAPIIdle: Status.ERROR,
        };
      })
      .addCase(postRecallCardsAPI.pending, (state) => {
        return {
          ...state,
          postRecallCardsAPIIdle: Status.LOADING,
        };
      })
      .addCase(postRecallCardsAPI.fulfilled, (state, action) => {
        return {
          ...state,
          postRecallCardsAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(postRecallCardsAPI.rejected, (state, action) => {
        return {
          ...state,
          postRecallCardsAPIIdle: Status.ERROR,
        };
      })
      .addCase(deleteRecallCardsAPI.pending, (state) => {
        return {
          ...state,
          deleteRecallCardsAPIIdle: Status.LOADING,
        };
      })
      .addCase(deleteRecallCardsAPI.fulfilled, (state, action) => {
        return {
          ...state,
          deleteRecallCardsAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(deleteRecallCardsAPI.rejected, (state, action) => {
        return {
          ...state,
          deleteRecallCardsAPIIdle: Status.ERROR,
        };
      })
      .addCase(RearrangeRecallCardsAPI.pending, (state) => {
        state.RearrangeRecallCardStatus = Status.LOADING;
      })
      .addCase(RearrangeRecallCardsAPI.fulfilled, (state, action) => {
        console.log(action);

        state.RearrangeRecallCardStatus = Status.SUCCESS;
        state.recallCards = state.recallCards.map((item) => {
          const isIdPresent = action.meta.arg.recall_card_ids.some(
            (idObj) => idObj.id === item._id
          );
          if (isIdPresent) {
            item.show = action.meta.arg.show;
          }

          return item;
        });

        state.RearrangeRecallCardResponse = action.payload;
      })
      .addCase(RearrangeRecallCardsAPI.rejected, (state) => {
        state.RearrangeRecallCardStatus = Status.ERROR;
      });
  },
});

export const {
  handleSort,
  RecalladdCheckbox,
  RecallremoveCheckbox,
  checkAllRecallBoxes,
  unCheckAllRecallBoxes,
} = RecallCardsSlice.actions;

export default RecallCardsSlice.reducer;

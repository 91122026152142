import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import TableComponent from "../../Components/TableComponent";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import {
  DeleteQuestionAPI,
  QuestionListAPI,
  clearQuestionList,
  questionListSort,
  updateQuestionList,
} from "../../Redux/features/OnboardingQuestionsSlice";
import { QuestionTypeListTableHeader } from "../../utils/TableColumns";
import { useNavigate } from "react-router";
import UpdateQuestionForm from "../../Components/UpdateQuestionForm";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import QuestionDraggableList from "../../Components/DraggableList/QuestionDraggableList";
import { Status } from "../../Redux/features/UserManagementSlice";

type Props = {};

const OnboardingQuestionsPage = (props: Props) => {
  const [questionId, setQuestionId] = useState("0");
  const [showUpdateForm, setUpdateForm] = useState(false);
  const [showDeleteForm, setDeleteForm] = useState(false);
  const [question, setQuestion] = useState("");
  const [questionType] = useState("");
  const [selectAnswerList, setSelectAnswerList] = useState<Array<string>>([]);
  const QuestionList = useAppSelector(
    (state) => state.OnboardingQuestions.QuestionList.data
  );
  const dispatch = useAppDispatch();
  const sortType = useAppSelector(
    (state) => state.OnboardingQuestions.sortType
  );

  const setQuestionList = (array: any[]) => {
    dispatch(updateQuestionList(array))
  }

  useEffect(() => {
    dispatch(QuestionListAPI({ sortType }));
    return () => {
      dispatch(clearQuestionList());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const handleDeleteClick = (id: string, questionType: string) => {
    setDeleteForm(true);
    setQuestionId(id);
    setQuestion(questionType);
  };

  const navigate = useNavigate();
  const deleteAPIIdle = useAppSelector(
    (state) => state.OnboardingQuestions.DeleteQuestionAPIIdle
  );

  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
  };
  const handleCloseUpdateModel = () => {
    setUpdateForm(false);
  };
  const getSortValue = () => {
    dispatch(questionListSort());
  };

  const getSearchValue = (d: any) => {
    dispatch(QuestionListAPI({ value: d }));
  };

  useEffect(() => {
    document.title = "FISIO | On Boarding Question List";
  }, []);

  const listLoader = useAppSelector(
    (state) => state.OnboardingQuestions.QuestionListAPIIdle
  );

  const handleConfirmDelete = () => {
    dispatch(DeleteQuestionAPI(questionId)).then(() => {
      handleCloseDeleteModel();
      dispatch(QuestionListAPI({ sortType }));
    });
  };

  return (
    <>
      <BoxLayout HeaderName="On-Boarding Question" sectionName="Question List">
        <FilterSortPanel
          getSearchValue={getSearchValue}
          getSortValue={getSortValue}
          Function={() =>
            navigate("/onboardingquestionsrepository/addonboardquestions")
          }
        ></FilterSortPanel>
        <TableComponent
          loader={listLoader}
          TableHeaderData={QuestionTypeListTableHeader}
        >
          {listLoader === Status.SUCCESS && (
            <tbody>
              <QuestionDraggableList handleDelete={handleDeleteClick} UnitObjectList={QuestionList} formValues={""} handleInputChange={() => { }} handlePermissionChange={() => { }} handleRemoveInput={() => { }} sessionList={[]} setObjectList={setQuestionList} />
            </tbody>
          )}
          {listLoader === Status.LOADING && <TableSkeleton tableColumns={6} />}
        </TableComponent>
      </BoxLayout>
      <ModalComponent
        confirmAction={handleConfirmDelete}
        message={"Are you sure to delete this Question ?"}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModel}
        loading={deleteAPIIdle}
      />

      <ModalComponent
        message={"Are you sure to Update this Question ?"}
        isOpen={showUpdateForm}
        onClose={handleCloseUpdateModel}
      >
        <UpdateQuestionForm
          question_id={questionId}
          answerList={selectAnswerList}
          setAnswerList={setSelectAnswerList}
          question={question}
          questionType={questionType}
          closeForm={handleCloseUpdateModel}
        />
      </ModalComponent>
    </>
  );
};

export default OnboardingQuestionsPage;

import React, {
    useRef,
    useCallback,
} from "react";
import UnitSelectorArray from "../../Containers/Group/UnitSelectorArray";

interface DraggableListProps {
    setObjectList: any;
    initialItems: any[];
    ObjectList: any;
    formValues: any;
    handleInputChange: any;
    handleRemoveInput: any;
    handlePermissionChange: any;
    objectList: any;
    moveDrills?: number[];
    setMoveDrills?: React.Dispatch<React.SetStateAction<number[]>>;
}
export const DraggableList: React.FC<DraggableListProps> = ({
    initialItems,
    setObjectList,
    handleInputChange,
    handleRemoveInput,
    handlePermissionChange,
    objectList,
}) => {
    console.log(initialItems, "initialItems")
    const dragItem = useRef<number | null>(null);

    const handleDragStart = useCallback((index: number) => {
        dragItem.current = index;
    }, []);

    const handleDragEnter = useCallback(
        (index: number) => {
            if (dragItem.current !== null && dragItem.current !== index) {
                const newItems = [...initialItems];
                const removedItem = newItems.splice(dragItem.current, 1)[0];
                newItems.splice(index, 0, removedItem);
                dragItem.current = index;
                setObjectList(newItems);
            }
        },
        [initialItems]
    );

    const handleDragEnd = useCallback(() => {
        dragItem.current = null;
    }, []);

    const ListItems = () => (
        <>
            {initialItems.map((item, index) => (
                <li
                    key={item.id}
                    className="flex w-full justify-center items-center"
                    style={{ userSelect: "none", cursor: "pointer" }}
                >
                    <div className="w-full ">
                        <UnitSelectorArray
                            ObjectList={initialItems}
                            handleInputChange={handleInputChange}
                            handlePermissionChange={handlePermissionChange}
                            handleRemoveInput={handleRemoveInput}
                            objectList={objectList}
                            index={index}
                            select={item}
                        />
                    </div>
                    <div draggable
                        onDragStart={() => handleDragStart(index)}
                        onDragEnter={() => handleDragEnter(index)}
                        onDragEnd={handleDragEnd} className="flex flex-col h-full py-[10px] mx-10 px-[8px] border  rounded-md  gap-1">
                        <span className="block  w-6 h-[2px] bg-orange-600"></span>
                        <span className="block  w-6 h-[2px] bg-orange-600"></span>
                        <span className="block  w-6 h-[2px] bg-orange-600"></span>
                    </div>
                </li>
            ))}
        </>
    );

    return <ul className="gap-3 flex flex-col ">{ListItems()}</ul>;
};

export default DraggableList;

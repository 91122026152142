import React, { useState } from "react";
import classNames from "classnames";

type Props = {
  summaryFragment: React.ReactNode;
  detailsFragment: React.ReactNode;
  title?: string;
  disabled?: boolean;
};

const Collapsible = ({
  summaryFragment,
  detailsFragment,
  title = "Hello",
  disabled = false,
}: Props) => {
  const [show, setShow] = useState(true);
  return (
    <>
      <div
        className={classNames(
          "bg-white rounded-xl flex flex-col gap-3 h-full p-5",
          {
            "opacity-25 select-none cursor-not-allowed": disabled,
          }
        )}
      >
        <div className="flex items-center justify-between w-full">
          <div className="text-[#212121] font-bold text-sm">{title}</div>
          <div onClick={() => setShow((show) => !show)}>
            <img
              className={classNames("transition-all", {
                "rotate-180": show,
              })}
              src={"/images/Chevrons.svg"}
            />
          </div>
        </div>
        {show ? <>{summaryFragment}</> : <>{detailsFragment}</>}
      </div>
    </>
  );
};

export default Collapsible;

import React from 'react'

type Props = {
    category: string;
    number: number;
}

const StatusCard = ({ category, number }: Props) => {
    return (
        <div className='border p-8 flex justify-between rounded-lg'>
            <span className='text-lg text-[#1F3161] font-bold'>{category}</span>
            <span className='text-4xl text-[#F58124] font-bold'>{number}</span>
        </div>
    )
}

export default StatusCard
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Controller } from "react-hook-form";
import { FieldValues, UseFormRegister, Control, Path } from "react-hook-form";
import { IconLeft } from "react-day-picker";

type Props<TFieldValues extends FieldValues> = {
  inputRef: Path<TFieldValues>;
  name: string;
  className: string;
  register: UseFormRegister<TFieldValues>;
  values: string;
  label: string;
  control: Control<TFieldValues>;
};

const RecallCardTextEditor = <TFieldValues extends FieldValues>({
  className,
  inputRef,
  control,
  label,
  name,
  register,
  values,
}: Props<TFieldValues>) => {
  const modules = {
    toolbar: [
      ["link"],
      [{ 'align': [] }],    
      [{ direction: "rtl" }],
      [{ list: "ordered" }, { list: "bullet" }],
    
,      ["bold", "italic", "underline"],
      [{ size: ["small", false, "large", "huge"] }],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block",
  ];

  return (
    <div className={`${className} relative`}>
      <label
        className="px-2 text-xs font-medium absolute left-4 text-[#8C8C8C] bg-[#ffffff] py-2 -top-3"
        htmlFor="quill-editor"
      >
        {label}
      </label>
      <Controller
        name={inputRef}
        control={control}
        render={({ field }) => (
          <ReactQuill
            className="right-align-toolbar"
            style={{ background: "#FAFAFA" }}
            formats={formats}
            modules={modules}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </div>
  );
};

export default RecallCardTextEditor;


import { useEffect, useState } from "react";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import TableComponent from "../../Components/TableComponent";
import { SessionTypeListTableHeader } from "../../utils/TableColumns";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import EditIcon from "../../Components/Icons/EditIcon";
import { useNavigate } from "react-router";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  handleSort,
  sessionDeleteAPI,
  sessionListAPI,
  sessionListSort,
} from "../../Redux/features/SessionSlice";
import moment from "moment";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { formatTime, manageSorting } from "../../utils/helper";
import {
  ObjectListAPI,
  SimpleObjectListAPI,
} from "../../Redux/features/ObjectSlice";
import CopyIcon from "../../Components/Icons/CopyIcon";
import { Status } from "../../Redux/features/UserManagementSlice";
import PaginationComponent from "../../Components/PaginationComponent";
import { useIsMount } from "../../CustomHooks/useIsMount";
import AlignIcon from "../../Components/Icons/AlignIcon";

type Props = {};

const SessionListPage = (props: Props) => {
  const navigate = useNavigate();
  const IsMount = useIsMount();
  const sortType = useAppSelector((state) => state.Session.sortType);
  const sortValue = useAppSelector((state) => state.Session.sortValue);
  const [perPage, setPerPage] = useState<{ label: string; value: number }>({
    label: "Show 50",
    value: 50,
  });
  const setPerPageValue = (value: { label: string; value: number }) => {
    setPerPage(value);
    dispatch(
      sessionListAPI({
        page: 1,
        searchValue: adminListValue,
        sortType: sortType,
        sortValue: sortValue,
        per_page: value.value,
      })
    );
  };
  const handleDuplicateClick = (i: SessionItem) => {
    console.log(i);
    navigate("/contentrepository/addsession", {
      state: {
        session_type_id: i.session_type_id,
        category_type_id: i.category_type_id,
        session_name: i.session,
        internal_session_name: i.internal_session_name,
        //  headline: i.headline,
        description: i.description,
        text: i.text,
        object_list: i.object_list,
        overview: {
          text: i.overview.text,
          headline: i.overview.headline,
          time: 0,
          is_equipments_needed: i.overview.equipments,
          equipments: i.overview.equipments,
          recall_cards_count: i.recall_cards?.length || 0,
          move_drills_count: 0,
          list_items: i.overview.list_items,
        },
        recall_cards: i.recall_cards,
      },
    });
  };

  const [showDeleteForm, setDeleteForm] = useState(false);
  const [id, setId] = useState("");
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      sessionListAPI({
        sortType: sortType,
        sortValue: sortValue,
        page: 1,
        per_page: perPage.value,
      })
    );
    dispatch(SimpleObjectListAPI());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const handleDeleteClick = (id: string) => {
    setId(id);
    setDeleteForm(true);
  };

  const handleEditClick = (session: any) => {
    navigate("/contentrepository/editsession", { state: { session: session } });
  };

  useEffect(() => {
    document.title = "FISIO | List Session";
  }, []);

  const ConfirmDelete = () => {
    dispatch(sessionDeleteAPI(id)).then(() => {
      setDeleteForm(false);
      dispatch(sessionListAPI({ per_page: perPage.value }));
    });
  };

  const handlePageChange = (page: any) => {
    dispatch(
      sessionListAPI({
        page: page,
        searchValue: adminListValue,
        sortType: sortType,
        sortValue: sortValue,
        per_page: perPage.value,
      })
    );
  };

  const activePage = useAppSelector((state) => state.Session.activePage);

  const handleCloseDeleteModal = () => {
    setDeleteForm(false);
  };

  const [adminListValue, setAdminListValue] = useState();

  useEffect(() => {
    if (!IsMount) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          sessionListAPI({
            per_page: perPage.value,
            page: 1,
            searchValue: adminListValue,
          })
        );
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminListValue]);

  const getSearchValue = (d: any) => {
    setAdminListValue(d);
  };

  const getSortValue = (d: any) => {
    dispatch(sessionListSort());
  };
  const sessionList = useAppSelector((state) => state.Session.sessionList);
  const loading = useAppSelector((state) => state.Session.sessionDeleleAPIIdle);
  const listLoading = useAppSelector(
    (state) => state.Session.sessionListAPIIdle
  );
  const total_page = useAppSelector((state) => state.Session.totalPages);
  return (
    <>
      <BoxLayout HeaderName="Content Repository" sectionName="Session List">
        <FilterSortPanel
          getSortValue={getSortValue}
          exportButton={<AlignIcon />}
          getSearchValue={getSearchValue}
          Function={() => navigate("/contentrepository/addsession")}
        ></FilterSortPanel>
        <TableComponent
          loader={listLoading}
          sortType={sortType}
          sortValue={sortValue}
          onClick={manageSorting}
          sortIcon
          handleSortAction={handleSort}
          TableHeaderData={SessionTypeListTableHeader}
        >
          {listLoading === Status.SUCCESS && (
            <tbody>
              {sessionList.map((i) => (
                <tr className="border-y border-y-[#EAF0F3]" key={i.id}>
                  <td className="py-1 text-start  text-xs font-medium text-[#212121]">
                    {i.internal_session_name === ""
                      ? "-"
                      : i.internal_session_name}
                  </td>
                  <td className="py-1 text-start w-[300px] block whitespace-nowrap overflow-hidden text-ellipsis text-xs font-medium text-[#212121]">
                    {i.session}
                  </td>
                  <td className="py-1 text-start text-xs font-medium text-[#212121]">
                    {i.session_type}
                  </td>
                  <td className="py-1 text-start text-xs text-[#767576]">
                    {i.category_type}
                  </td>
                  <td className="py-1 text-start  text-xs text-[#767576]">
                    {formatTime(Number(i.time))}
                  </td>
                  <td className="py-1 text-start  text-xs text-[#767576]">
                    {moment(i.last_update).format("DD-MM-YYYY hh:mm")}
                  </td>
                  <td className="py-1 text-start">
                    <span className="py-1 gap-3 w-[15%] flex items-center text-start">
                      <button onClick={() => handleDuplicateClick(i)}>
                        <CopyIcon />
                      </button>
                      <button onClick={() => handleEditClick(i)}>
                        <EditIcon />
                      </button>
                      <button onClick={() => handleDeleteClick(i.id)}>
                        <DeleteIcon />
                      </button>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {listLoading === Status.LOADING && <TableSkeleton tableColumns={7} />}
        </TableComponent>
        <PaginationComponent
          active={activePage}
          setPerPageValue={setPerPageValue}
          perPageValue={perPage}
          total={total_page}
          paginationFunction={handlePageChange}
        />
      </BoxLayout>
      <ModalComponent
        message={"Are you sure to delete this session?"}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModal}
        confirmAction={ConfirmDelete}
        loading={loading}
      />
    </>
  );
};

export default SessionListPage;

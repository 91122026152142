import InputComponent from "../Inputs/InputComponent";
import { useForm } from "react-hook-form";
import ModalComponent from "../PopUp/ModalComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { NotificationSend } from "../../Redux/features/UserDashboardSlice";
import { notificationresponse } from "../../types/apiresponse";
import { notifySuccess } from "../../helper";
import TextAreaComponent from "../Inputs/TextAreaComponent";

type Props = {
  onclose: () => void;
  onopen: boolean;
};

const NotificationComponent = ({ onclose, onopen }: Props) => {
  const { register, control, watch, reset } = useForm();
  const formValues = watch();
  const dispatch = useAppDispatch();
  const selectedUser = useAppSelector(
    (state) => state.UserManagement.selectedUser
  );
  const handelNotificationSend = () => {
    console.log(watch());
    const selectedUserid = selectedUser.map((user) => {
      return { userid: user._id };
    });
    dispatch(
      NotificationSend({
        body_text: formValues.body_text,
        title_text: formValues.title_text,
        user_ids: selectedUserid,
      })
    ).then((res) => {
      const data = res.payload as notificationresponse;
      if (data.success) {
        reset();
        onclose();
        notifySuccess(data.message);
      } else {
        notifySuccess(data.message);
      }
    });
  };
  return (
    <ModalComponent
      isOpen={onopen}
      message={"Notification"}
      onClose={() => onclose()}
      actionCTA="Send"
      confirmAction={handelNotificationSend}
    >
      <div className="col-span-8 flex flex-col gap-3">
        <InputComponent
          inputRef="title_text"
          register={register}
          value={formValues.title}
          placeholder="Enter title"
          label="Title"
        />
        <TextAreaComponent
          name="body_text"
          inputRef="body_text"
          register={register}
          value={formValues.title}
          placeholder="Enter Text"
          label="Notification"
        />
        {/* <TextEditor
          className="col-span-8 pb-5"
          control={control}
          inputRef="body_text"
          label="Text"
          name="notification"
          register={register}
          values=""
        /> */}
      </div>
    </ModalComponent>
  );
};

export default NotificationComponent;

import { useEffect, useState } from 'react'
import BoxLayout from '../../Containers/Layout/BoxLayout'
import InputComponent from '../../Components/Inputs/InputComponent'
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import MultiSelectComponent from '../../Components/Inputs/MultiSelectComponent'
import ButtonComponent from '../../Components/Buttons/ButtonComponent'
import { useAppDispatch, useAppSelector } from '../../Redux/app/hooks'
import { unitListAPI } from '../../Redux/features/UnitSlice'
import { TagListAPI } from '../../Redux/features/TagSlice'
import { PostGroupListAPI } from '../../Redux/features/GroupSlice'
import { useNavigate } from 'react-router-dom'
import { notifyError } from '../../helper';
import SelectUnit from '../../Containers/Group/SelectUnit';

type Props = {}

type GroupForm = {
    groupName: string,
    Units: Array<{ object_id: string, time: string }>,
    tags: Array<any>
}

const AddGroupPage = (props: Props) => {
    const [objectList, setObjectList] = useState<
        Array<{ object_id: string; permission: 0 | 1 | ""; time: number, title: string, description: string }>
    >([]);
    const validationSchema = Yup.object().shape({
        groupName: Yup.string().required("Session Type Required"),
        // Units: Yup.array().required("Category Type Required"),
        tags: Yup.array().required("Tag is Required"),
    });


    const { handleSubmit, formState: { errors }, register, control, watch } = useForm<any>({
        resolver: yupResolver(validationSchema)
    })

    const onSubmit = (data: any) => {
        console.log(objectList.length, data)
        let hasNoEmptyString = objectList.every((item) => item.object_id !== "");

        if (hasNoEmptyString && objectList.length > 0) {
            dispatch(PostGroupListAPI({
                tag: data.tags.map((i: any) => i.value).toString(),
                group_name: data.groupName,
                group_object: objectList.map((unit) => {
                    return {
                        object_id: unit.object_id,
                        time: String(unit.time)
                    }
                }),
            })).then(() => navigate("/contentrepository/group")).then((err) => console.log(err))
        } else {
            notifyError("Add Unit Data")
        }

    }

    const unitList = useAppSelector(state => state.Unit.unitList)
    const tagList = useAppSelector(state => state.Tag.TagList)
    const loading = useAppSelector(state => state.Group.postGroupListAPIStatus)

    const dispatch = useAppDispatch()

    useEffect(() => {
        const arrayOfError = Object.values(errors);
        if (Object.keys(errors).length !== 0) {
            arrayOfError.slice(0, 1).map((error) => notifyError(typeof error?.message === "string" ? error?.message : "Something went wrong"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors]);

    useEffect(() => {
        dispatch(unitListAPI({}))
    }, [])

    useEffect(() => {
        dispatch(TagListAPI({}))
    }, [])

    const navigate = useNavigate()


    const formValues = watch()
    return (
        <BoxLayout HeaderName='Program' sectionName='Add Program'>
            <form onSubmit={handleSubmit(onSubmit)} className='p-4 flex flex-col gap-4  border-t'>
                <div className='grid items-center grid-cols-2 gap-5'>

                    <InputComponent value='' register={register} inputRef='groupName' name='group' label='Program Name' />
                    <MultiSelectComponent options={tagList.data.tags.map((i) => {
                        return {
                            value: i._id,
                            label: i.name,
                        }
                    })} label='choose Tag' control={control} inputRef='tags' />
                </div>

                <SelectUnit ObjectList={objectList} formValues={formValues} setObjectList={setObjectList} />

                <div className='grid grid-cols-10 gap-3'>
                    <ButtonComponent className='w-[200px] col-start-9' CTA='back' varient='outline' loading={loading} buttonType='button' onClick={() => navigate("/contentrepository/group")} />
                    <ButtonComponent className='w-[200px] col-start-10' CTA='Save' varient='blue' loading={loading} buttonType='submit' />
                </div>
            </form>
        </BoxLayout >
    )
}

export default AddGroupPage
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";

export const QuestionListAPI = createAsyncThunk<
  QuestionListAPIResponse,
  any,
  {}
>("/On-BoardingQuestions/QuestionsList", async (data) => {
  const res = await axiosClient.get<QuestionListAPIResponse>(
    `auth/questions?${data.value ? `&filteredVal=${data.value}` : ""}`
  );
  return res.data;
});

export const AddQuestionAPI = createAsyncThunk<
  AddQuestionAPIResponse,
  AddQuestionAPIRequest,
  {}
>("/On-BoardingQuestions/AddQuestion", async (data) => {
  const res = await axiosClient.post(`auth/questions`, data);
  return res.data;
});

export const UpdateQuestionAPI = createAsyncThunk<
  any,
  UpdateQuestionAPIRequest,
  {}
>("/On-BoardingQuestions/UpdateQuestion", async (data) => {
  const res = await axiosClient.put(`auth/questions/`, data);
  return res.data;
});

export const DeleteQuestionAPI = createAsyncThunk<
  DeleteQuestionAPIResponse,
  string,
  {}
>("/On-BoardingQuestions/deleteQuestion", async (id) => {
  const res = await axiosClient.delete(`auth/questions/${id}`);
  return res.data;
});

export const getWeclomePageInfo = createAsyncThunk<GetWeclomePageInfo>(
  "/On-BoardingQuestions/getWelcomePage",
  async () => {
    const res = await axiosClient.get("/auth/getwelcomeimages");
    return res.data;
  }
);

export const postWelcomePage = createAsyncThunk<any, any>(
  "/On-BoardingQuestions/postWelcomePage",
  async (data: any) => {
    const res = await axiosClient.post("/auth/display-images", data);
    return res.data;
  }
);

export const setQuestionOrder = createAsyncThunk<any, any>(
  "/On-BoardingQuestions/postWelcomePage",
  async (data) => {
    const res = await axiosClient.post("auth/drag-questions", data);
  }
);

interface OnboardingQuestionsState {
  QuestionList: { data: Question[] };
  QuestionListAPIIdle: Status;
  sortType: sortValues;
  AddQuestionAPIIdle: Status;
  DeleteQuestionAPIIdle: Status;
  UpdateQuestionAPIIdle: Status;
  getWeclomePageInfoIdle: Status;
  getWeclomePageInfo: GetWeclomePageInfo;
  postWelcomePageIdle: Status;
}

const initialState: OnboardingQuestionsState = {
  QuestionListAPIIdle: Status.SUCCESS,
  sortType: "ascending",
  AddQuestionAPIIdle: Status.SUCCESS,
  UpdateQuestionAPIIdle: Status.SUCCESS,
  DeleteQuestionAPIIdle: Status.SUCCESS,
  getWeclomePageInfoIdle: Status.SUCCESS,
  postWelcomePageIdle: Status.SUCCESS,
  getWeclomePageInfo: {
    data: {
      login: [],
      Marketing: [],
      Register: [],
      welcome: [],
    },
  },
  QuestionList: {
    data: [],
  },
};

const OnboardingQuestionsSlice = createSlice({
  name: "onboardingquestionsrepository",
  initialState,
  reducers: {
    clearQuestionList: (state) => {
      return {
        ...state,
        QuestionList: {
          data: [],
          links: [],
          path: "",
          per_page: 0,
          from: 0,
          to: 0,
          current_page: 0,
          last_page: 0,
          total: 0,
        },
      };
    },
    questionListSort: (state) => {
      return {
        ...state,
        sortType: state.sortType === "ascending" ? "descending" : "ascending",
      };
    },
    updateQuestionList: (state, { payload }) => {
      return {
        ...state,
        QuestionList: {
          data: payload,
        },
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(QuestionListAPI.pending, (state, action) => {
        return {
          ...state,
          QuestionListAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        QuestionListAPI.fulfilled,
        (state, action: PayloadAction<QuestionListAPIResponse>) => {
          return {
            ...state,
            QuestionListAPIIdle: Status.SUCCESS,
            QuestionList: {
              data: action.payload.data,
            },
          };
        }
      )
      .addCase(QuestionListAPI.rejected, (state, action) => {
        return {
          ...state,
          QuestionListAPIIdle: Status.ERROR,
        };
      })
      .addCase(AddQuestionAPI.pending, (state, action) => {
        return {
          ...state,
          AddQuestionAPIIdle: Status.LOADING,
        };
      })
      .addCase(AddQuestionAPI.fulfilled, (state, action) => {
        return {
          ...state,
          AddQuestionAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(AddQuestionAPI.rejected, (state, action) => {
        return {
          ...state,
          AddQuestionAPIIdle: Status.ERROR,
        };
      })
      .addCase(UpdateQuestionAPI.pending, (state, action) => {
        return {
          ...state,
          UpdateQuestionAPIIdle: Status.LOADING,
        };
      })
      .addCase(UpdateQuestionAPI.fulfilled, (state, action) => {
        return {
          ...state,
          UpdateQuestionAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(UpdateQuestionAPI.rejected, (state, action) => {
        return {
          ...state,
          UpdateQuestionAPIIdle: Status.ERROR,
        };
      })
      .addCase(DeleteQuestionAPI.pending, (state, action) => {
        return {
          ...state,
          DeleteQuestionAPIIdle: Status.LOADING,
        };
      })
      .addCase(DeleteQuestionAPI.fulfilled, (state, action) => {
        return {
          ...state,
          DeleteQuestionAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(DeleteQuestionAPI.rejected, (state, action) => {
        return {
          ...state,
          DeleteQuestionAPIIdle: Status.ERROR,
        };
      })
      .addCase(getWeclomePageInfo.pending, (state, action) => {
        return {
          ...state,
          getWeclomePageInfoIdle: Status.LOADING,
        };
      })
      .addCase(getWeclomePageInfo.fulfilled, (state, action) => {
        return {
          ...state,
          getWeclomePageInfoIdle: Status.SUCCESS,
          getWeclomePageInfo: {
            data: action.payload.data,
          },
        };
      })
      .addCase(getWeclomePageInfo.rejected, (state, action) => {
        return {
          ...state,
          getWeclomePageInfoIdle: Status.ERROR,
        };
      })
      .addCase(postWelcomePage.pending, (state, action) => {
        return {
          ...state,
          postWelcomePageIdle: Status.LOADING,
        };
      })
      .addCase(postWelcomePage.fulfilled, (state, action) => {
        return {
          ...state,
          postWelcomePageIdle: Status.SUCCESS,
        };
      })
      .addCase(postWelcomePage.rejected, (state, action) => {
        return {
          ...state,
          postWelcomePageIdle: Status.ERROR,
        };
      });
  },
});

export const { clearQuestionList, questionListSort, updateQuestionList } =
  OnboardingQuestionsSlice.actions;

export default OnboardingQuestionsSlice.reducer;

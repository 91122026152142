import { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
// import { formatSecondsToHMS } from '../utils/helper/formatSecondsToHMS';
import PlayButton from '../assets/PlayButton.svg';
import PauseButton from '../assets/pauseButton.svg';
import FullScreenOn from '../assets/FullscreenOn.svg';
import FullScreenOff from '../assets/FullscreenOff.svg';
import { formatSecondsToHMS } from '../../utils/helper';

const PreviewPlayer = ({ url }: any) => {
    const [playing, setPlaying] = useState(true);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [duration, setDuration] = useState(0); // To store video duration
    const playerRef = useRef<any>(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const playerWrapperRef = useRef<any>(null);
    const playPauseHandler = () => {
        setPlaying((prev) => !prev);
    };

    // const forward15Sec = () => {
    //   const newTime = playedSeconds + 15;
    //   playerRef.current.seekTo(newTime, 'seconds');
    // };

    // const backward15Sec = () => {
    //   const newTime = playedSeconds - 15;
    //   playerRef.current.seekTo(newTime, 'seconds');
    // };

    const handleProgressChange = (e: any) => {
        const newTime = (e.target.value / 100) * duration;
        setPlayedSeconds(newTime);
        playerRef.current.seekTo(newTime, 'seconds');
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        // Add the event listener
        document.addEventListener('fullscreenchange', handleFullscreenChange);

        // Cleanup the event listener on component unmount
        return () => {
            document?.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const [showControls, setShowControls] = useState(false);

    useEffect(() => {
        let movementTimeout: NodeJS.Timeout; // declare this to hold the reference to the timeout

        const handleUserInteraction = () => {
            setShowControls(true); // show controls on movement

            clearTimeout(movementTimeout);

            // hide controls after 2 seconds of no movement
            movementTimeout = setTimeout(() => {
                setShowControls(false);
            }, 2000);
        };

        const handleVideoClick = (event: MouseEvent | TouchEvent) => {
            const clickTarget = event.target as HTMLElement;

            // Check if the click target is within the player area
            if (playerWrapperRef.current?.contains(clickTarget)) {
                setShowControls(true);
            }
        };

        // Add the event listeners for click and touchend events
        playerWrapperRef.current.addEventListener('click', handleVideoClick);
        playerWrapperRef.current.addEventListener('touchend', handleVideoClick);
        playerWrapperRef.current.addEventListener(
            'mousemove',
            handleUserInteraction,
        );

        // Cleanup the event listeners and timeout on component unmount
        return () => {
            playerWrapperRef.current?.removeEventListener('click', handleVideoClick);
            playerWrapperRef.current?.removeEventListener(
                'touchend',
                handleVideoClick,
            );
            playerWrapperRef.current?.removeEventListener(
                'mousemove',
                handleUserInteraction,
            );
            clearTimeout(movementTimeout);
        };
    }, []);

    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            if (playerWrapperRef.current.requestFullscreen) {
                playerWrapperRef.current.requestFullscreen();
            } else if (playerWrapperRef.current.mozRequestFullScreen) {
                playerWrapperRef.current.mozRequestFullScreen();
            } else if (playerWrapperRef.current.webkitRequestFullscreen) {
                playerWrapperRef.current.webkitRequestFullscreen();
            } else if (playerWrapperRef.current.msRequestFullscreen) {
                playerWrapperRef.current.msRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if ((document as any).mozCancelFullScreen) {
                (document as any).mozCancelFullScreen();
            } else if ((document as any).webkitExitFullscreen) {
                (document as any).webkitExitFullscreen();
            } else if ((document as any).msExitFullscreen) {
                (document as any).msExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };

    const [playbackRate, setPlaybackRate] = useState<number>(1);

    // ... existing handlers ...

    const togglePlaybackRate = () => {
        if (playbackRate === 1) {
            setPlaybackRate(2);
        } else {
            setPlaybackRate(1);
        }
    };

    return (
        <div
            ref={playerWrapperRef}
            className="rounded-2xl  my-2 w-[85%] m-auto relative "
        >
            <ReactPlayer
                // url='http://185.253.72.151/storage/storage/swing/aHjBWizP7lU4EYW5iVM3pOEizkAJeXPDfxkH6ewP.mp4'
                url={url}
                config={{
                    file: {
                        attributes: {
                            className: 'aspect-ratio',
                        },
                    },
                }}
                // style=
                className="rounded-2xl aspect-square  object-fill"
                height={isFullscreen ? '100vh' : '100%'}
                // controls
                playbackRate={playbackRate}
                ref={playerRef}
                playing={playing}
                width={'100%'}
                onProgress={({ playedSeconds }) => setPlayedSeconds(playedSeconds)}
                onDuration={(duration) => setDuration(duration)}
            />
            {/* {showControls && (
        <button
          type="button"
          onClick={playPauseHandler}
          className="absolute top-[45%] left-[50%]"
        >
          {playing ? (
            <img src={PauseButton} alt="pause" />
          ) : (
            <img src={PlayButton} alt="" />
          )}
        </button>
      )} */}
            {showControls && (
                <>
                    <input
                        className={`w-full text-red-200 absolute -bottom- left-0 h-[3px] z-20 ${isFullscreen ? 'absolute bottom-2' : 'absolute bottom-0'
                            }`}
                        type="range"
                        min={0}
                        max={100}
                        value={(playedSeconds / duration) * 100}
                        onChange={handleProgressChange}
                    />
                    <div className="flex justify-center items-center w-full absolute top-[45%] h-[2rem] left-[0%] z-10">
                        <button type="button" onClick={playPauseHandler} className="">
                            {playing ? (
                                <img src={"/images/pauseButton.svg"} alt="pause" />
                            ) : (
                                <img src={"/images/PlayButton.svg"} alt="play" />
                            )}
                        </button>
                    </div>
                </>
            )}
            {/* {<canvas ref={canvasRef} width="800" height="450">} */}
            {showControls && (
                <div
                    style={{
                        boxShadow: '0px 12px 32px 0px #0000000D',
                    }}
                    className={`custom-controls     flex flex-col justify-center items-center w-full text-opacity-100  `}
                >
                    <div
                        className={` py-1 px-1 bg-[rgba(0,0,0,0.7)] flex flex-col gap-1 opacity-90  w-[100%] aspect-square mx-4 ${isFullscreen ? 'absolute bottom-0 ' : 'absolute bottom-0'
                            }`}
                    >
                        <div
                            className={`flex  items-end aspect-square w-full justify-between gap-2 h-100% ${isFullscreen ? 'fixed bottom-4 px-5 ' : ''
                                } `}
                        >
                            {/* <div className="flex justify-evenly gap-4">
                <button type="button" onClick={backward15Sec}>
                  <img src={'/images/previousIcon.svg'} alt="" />
                </button>

                <button type="button" onClick={forward15Sec}>
                  <img src={'/images/forwardIcon.svg'} alt="" />
                </button>
              </div> */}

                            <div className="text-right flex  text-sm text-white py-0 ">
                                {formatSecondsToHMS(Math.floor(playedSeconds))} |{' '}
                                {formatSecondsToHMS(Math.floor(duration))}
                            </div>
                            <button
                                type="button"
                                onClick={toggleFullscreen}
                                className="absolute right-0 bottom-[2px]"
                            >
                                <img src={isFullscreen ? "/images/FullScreenOff.svg" : "/images/FullScreenOn.svg"} alt="" />
                            </button>
                        </div>
                        <div className="flex justify-evenly gap-6">
                            <button type="button" onClick={togglePlaybackRate}>
                                {playbackRate === 1 ? (
                                    <img src={'/images/playbackIcon.svg'} alt={''} />
                                ) : (
                                    <img src={'/images/playbackIcon.svg'} alt={''} />
                                )}
                            </button>
                        </div>
                        {/* <input
              className="w-full text-red-200"
              type="range"
              min={0}
              max={100}
              value={(playedSeconds / duration) * 100}
              onChange={handleProgressChange}
            /> */}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PreviewPlayer;

import UserPieChartComponent from "../../Components/PieChart/UserPieChartComponent";
// import Switch from "../Inputs/SwitchComponent";
import { UseFormRegister, FieldValues } from "react-hook-form";
// import UserPieChartComponent from "../PieChart/UserPieChartComponent";

type Props = {
    data: Array<{ name: string; value: number; color?: string }>;
    register: UseFormRegister<FieldValues>;
    checked: boolean;
    responseTime: number
};

const CoachDashboardCard = ({ data, register, checked, responseTime }: Props) => {
    const colors = ["#FFE57F", "#58BBCF", "#00C853"];
    return (
        <div className="w-full flex flex-col  justify-center p-2  bg-white rounded-lg ">
            <div className="flex justify-between  ">
                <p className="text-md font-medium">Total Processes</p>

                <div>
                    <p className="text-sm text-[#9e9e9e] flex flex-col">Average Response Time
                    </p>
                    <span className="px-2 ml-auto flex rounded-full text-xs py-[2px] bg-[#EAF0F3] w-fit text-[#1F3161]">{responseTime}</span>
                </div>
            </div>
            <div className="w-full h-full grid grid-cols-2 ">
                <div className=" m-auto">
                    <UserPieChartComponent className="w-36 h-36" data={data} colors={colors} />
                </div>
                <div className="flex flex-col px-3 justify-center items-start">
                    {data.map((item, index) => (
                        <span className={`flex gap-2 h-5 ${!checked ? "pb-10" : ""}  justify-center items-baseline`}>
                            <div
                                style={{ background: `${colors[index % colors.length]}` }}
                                className={`w-2 h-2 bg-[${colors[index % colors.length]
                                    }] rounded-full`}
                            ></div>
                            <div className="text-[#8B8D97] text-xs ">{item.name}</div>
                        </span>
                    ))}
                </div>
            </div>
        </div >
    );
};

export default CoachDashboardCard;

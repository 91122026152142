import React, { useState } from "react";
import classNames from "classnames";
import SelectComponent from "../Inputs/SelectComponent";
import { useForm } from "react-hook-form";
import { DropDownStylesV2 } from "../../helper";

type Props = {
  disabled?: boolean;
  data: Array<{
    id: string;
    summaryFragment: React.ReactNode;
    detailsFragment: React.ReactNode;
  }>;
  defaultFragment: React.ReactNode;
  title?: string;
  inputRef: string;
  options: Array<{ value: string; label: string }>;
  defaultValues: any;
};

const CollapsibleWithDropDown = ({
  defaultValues,
  disabled = false,
  inputRef,
  options,
  data,
  title = "Hello",
  defaultFragment,
}: Props) => {
  const [show, setShow] = useState(true);
  const { control, watch } = useForm({
    defaultValues: {
      ...defaultValues,
    },
  });

  const type = watch(inputRef);
  console.log(type, "rating");
  return (
    <>
      <div className="bg-white rounded-xl flex flex-col justify-between gap-3 h-full p-5">
        <div className="flex justify-between items-center w-full ">
          <div className="text-[#212121] font-bold text-sm flex gap-5">
            <span className="my-auto">{title}</span>
            <span>
              <SelectComponent
                selectStyle={DropDownStylesV2}
                options={options}
                inputRef={inputRef}
                control={control}
              />
            </span>
          </div>
          <button disabled={disabled} onClick={() => setShow((show) => !show)}>
            <img
              className={classNames("transition-all", {
                "rotate-180": show,
              })}
              src={"/images/Chevrons.svg"}
            />
          </button>
        </div>
        {data.map((i) => (
          <>
            {i.id === type ? (
              <>{show ? <>{i.summaryFragment}</> : <>{i.detailsFragment}</>}</>
            ) : null}
          </>
        ))}
        {!type ? (
          <>
            <>{defaultFragment}</>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default CollapsibleWithDropDown;

import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputComponent from "../../Components/Inputs/InputComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { AddTagAPI, TagListAPI } from "../../Redux/features/TagSlice";
import { Status } from "../../Redux/features/UserManagementSlice";

type Props = {
  setAddFormModal: React.Dispatch<React.SetStateAction<boolean>>;
};

type FormValues = {
  name: string;
};

const AddNewTagForm = ({ setAddFormModal }: Props) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("TagName is required")
      .min(2, "TagName must be at least 2 characters"),
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useAppDispatch();
  const tagSortType = useAppSelector((state) => state.Tag.sortType);
  const onSubmit = async (data: FormValues) => {
    dispatch(
      AddTagAPI({
        name: data.name,
      })
    ).then((res: any) => {
      setAddFormModal(false);
      dispatch(TagListAPI({ sortType: tagSortType }));
    });
  };

  const formValues = watch();

  const loading = useAppSelector(
    (state) => state.UserManagement.UserPostAPIIdle
  );

  return (
    <div className="p-4 flex flex-col h-full">
      <h2 className="text-xl font-bold mb-4">Add New Tag</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
        <div className="mt-auto">
          <div className="flex flex-col gap-3 justify-end">
            <InputComponent
              errorname={errors.name?.message}
              inputRef="name"
              label="Tag Name"
              name="name"
              register={register}
              value={formValues.name}
            />
            <div className="flex rounded-none col-end-3 ">
              <button
                disabled={loading === Status.LOADING}
                className="bg-blue-950 text-white rounded-md px-4 py-2 mr-2 w-1/2"
              >
                {loading === Status.LOADING ? "Submitting" : "Save"}
              </button>
              <button
                className="border-2 border-indigo-900/80 text-gray-700 px-4 py-2 rounded w-1/2"
                onClick={() => setAddFormModal((prev) => !prev)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewTagForm;

import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { formatSecondsToHMS } from '../../utils/helper';

const CustomPlayer = ({ url }: any) => {
    const [playing, setPlaying] = useState(false);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const [duration, setDuration] = useState(0);  // To store video duration
    const playerRef = useRef<any>(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const playerWrapperRef = useRef<any>(null);
    const playPauseHandler = () => {
        setPlaying(prev => !prev);
    };

    const forward15Sec = () => {
        const newTime = playedSeconds + 15;
        playerRef.current.seekTo(newTime, 'seconds');
    };

    const backward15Sec = () => {
        const newTime = playedSeconds - 15;
        playerRef.current.seekTo(newTime, 'seconds');
    };

    const handleProgressChange = (e: any) => {
        const newTime = (e.target.value / 100) * duration;
        setPlayedSeconds(newTime);
        playerRef.current.seekTo(newTime, 'seconds');
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        // Add the event listener
        document.addEventListener('fullscreenchange', handleFullscreenChange);

        // Cleanup the event listener on component unmount
        return () => {
            document?.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const [showControls, setShowControls] = useState(true);

    useEffect(() => {
        let movementTimeout: NodeJS.Timeout;  // declare this to hold the reference to the timeout

        const handleMouseMove = () => {
            setShowControls(true);  // show controls on movement

            clearTimeout(movementTimeout);

            // hide controls after 2 seconds of no movement
            movementTimeout = setTimeout(() => {
                setShowControls(false);
            }, 2000);
        };

        // Add the event listener on the playerWrapperRef to detect mouse movement
        playerWrapperRef.current.addEventListener('mousemove', handleMouseMove);

        // Cleanup the event listener and timeout on component unmount
        return () => {
            playerWrapperRef.current?.removeEventListener('mousemove', handleMouseMove);
            clearTimeout(movementTimeout);
        };
    }, []);


    const toggleFullscreen = () => {
        if (!document.fullscreenElement) {
            if (playerWrapperRef.current.requestFullscreen) {
                playerWrapperRef.current.requestFullscreen();
            } else if (playerWrapperRef.current.mozRequestFullScreen) { /* Firefox */
                playerWrapperRef.current.mozRequestFullScreen();
            } else if (playerWrapperRef.current.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
                playerWrapperRef.current.webkitRequestFullscreen();
            } else if (playerWrapperRef.current.msRequestFullscreen) { /* IE/Edge */
                playerWrapperRef.current.msRequestFullscreen();
            }
            setIsFullscreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if ((document as any).mozCancelFullScreen) { /* Firefox */
                (document as any).mozCancelFullScreen();
            } else if ((document as any).webkitExitFullscreen) { /* Chrome, Safari & Opera */
                (document as any).webkitExitFullscreen();
            } else if ((document as any).msExitFullscreen) { /* IE/Edge */
                (document as any).msExitFullscreen();
            }
            setIsFullscreen(false);
        }
    };

    const [playbackRate, setPlaybackRate] = useState<number>(1);

    // ... existing handlers ...

    const togglePlaybackRate = () => {
        if (playbackRate === 1) {
            setPlaybackRate(2);
        } else {
            setPlaybackRate(1);
        }
    };

    return (
        <div ref={playerWrapperRef} className='rounded-2xl  my-2 w-[70%] m-auto relative '>

            <ReactPlayer
                // url='http://185.253.72.151/storage/storage/swing/aHjBWizP7lU4EYW5iVM3pOEizkAJeXPDfxkH6ewP.mp4'
                url={url}
                config={{
                    file: {
                        attributes: {
                            className: 'aspect-ratio'
                        }
                    }


                }}
                // style=
                className='rounded-2xl aspect-square  object-fill'
                height={isFullscreen ? "100vh" : "100%"}
                // controls
                playbackRate={playbackRate}

                ref={playerRef}
                playing={playing}
                width={"100%"}
                onProgress={({ playedSeconds }) => setPlayedSeconds(playedSeconds)}
                onDuration={(duration) => setDuration(duration)}

            />
            {/* <canvas ref={canvasRef} width="800" height="450"></canvas> */}
            {showControls && (
                <div style={{
                    boxShadow: "0px 12px 32px 0px #0000000D"

                }} className={`custom-controls     flex flex-col justify-center items-center w-full text-opacity-100  `}>
                    <div className={`timeline py-4 px-16 bg-[#f3f5f6] opacity-90  w-[66%] rounded-full mx-4 ${isFullscreen ? "absolute bottom-1 " : "absolute"}`}>
                        <div >
                            <input
                                className='w-full text-red-200'
                                type="range"
                                min={0}
                                max={100}

                                value={(playedSeconds / duration) * 100}
                                onChange={handleProgressChange}
                            />
                            <div className='text-right text-sm py-0'>
                                {formatSecondsToHMS(Math.floor(playedSeconds))} | {formatSecondsToHMS(Math.floor(duration))}
                            </div>
                        </div>
                        <div className='flex justify-evenly gap-6'>

                            <button type='button' onClick={togglePlaybackRate}>
                                {playbackRate === 1 ?
                                    <img src={"/images/playbackIcon.svg"} alt={""} />
                                    :
                                    <img src={"/images/playbackIcon.svg"} alt={""} />
                                }
                            </button>
                            <div className='flex justify-evenly gap-4'>

                                <button type='button' onClick={backward15Sec}>
                                    <img src={"/images/previousIcon.svg"} alt='' />
                                </button>
                                <button type='button' onClick={playPauseHandler}>
                                    {playing ? <img src={"/images/pauseIcon.svg"} alt='pause' /> : <img src={"/images/playIcon.svg"} alt='' />}
                                </button>
                                <button type='button' onClick={forward15Sec}>
                                    <img src={"/images/forwardIcon.svg"} alt='' />
                                </button>
                            </div>
                            <button type='button' onClick={toggleFullscreen}>
                                <img src={"/images/fullscreen.svg"} alt='' />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CustomPlayer;

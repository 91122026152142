import React, { useState, useRef, useEffect } from "react";

type TooltipProps = {
  children: React.ReactNode;
  text: React.ReactNode | string; // This prop will be used to pass the tooltip content
  className?: string;
};

const Tooltip: React.FC<TooltipProps> = ({
  children,
  text,
  className = "bg-white text-brandPrimary text-[14px]",
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const childRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node) &&
        childRef.current &&
        !childRef.current.contains(event.target as Node)
      ) {
        setShowTooltip(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (childRef.current) {
      setTopOffset(childRef.current.offsetHeight + 8);
    }
  }, [showTooltip]);

  // const toggleTooltip = () => {
  //   setShowTooltip((prev) => !prev);
  // };
  const handleMouseEnter = () => {
    setShowTooltip(true);
  };
  const handleMouseLeave = () => {
    setShowTooltip(false);
  };
  return (
    <div className="relative inline-block ">
      <div
        // onClick={toggleTooltip}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={childRef}
        className="cursor-pointer hover:bg-white rounded-[10px] duration-500 hover:ease-linear hover:transform-cpu"
      >
        {children}
      </div>
      {showTooltip && (
        <div
          ref={tooltipRef}
          className={`${className} absolute z-[9999] bg-white w-auto h-auto py-[5px] px-[10px] rounded-md top-4 right-0 transform shadow-section font-medium flex-nowrap`}
          style={{
            top: `${topOffset}px`,
            right: "6px",
            whiteSpace: "nowrap",
          }}
        >
          {text}
          <div className="absolute right-[6px] -top-[2px] -z-10">
            <div className={`${className} w-3 h-3   rotate-45`}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;

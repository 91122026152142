import React, { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import InputComponent from "../../Components/Inputs/InputComponent";
import {
  Controller,
  FieldError,
  SubmitErrorHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import AccordianComp from "../../Components/AccordianTable/AccordianTable";
import CenterPopUpComponent from "../../Components/PopUp/CenterPopUpComponent";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FundamentalForm from "./FundamentalForm";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { notifyError, notifySuccess } from "../../helper";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  MicroMoveStep,
  listGuideAPI,
  postFundamentalAPI,
  postGuideAPI,
} from "../../Redux/features/GuideDrillSlice";
import { useLocation, useNavigate } from "react-router-dom";
import UploadFileComponents from "../../Components/Inputs/UploadFileComponents";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import FundamentalArray from "./FundamentalArray";
// import { error } from 'console'

type Props = {};

type microMoveForm = {
  title: string;
  description: string;
  step: Array<{ detail: string; vimeo_link: string; image: string | FileList }>;
};

export type AddFundamentalForm = {
  fundamentalName: string;
  description: string;
  mainImage: FileList | string;
  secondaryImage: FileList | string;
  secondaryVimeoLink: string;
  micromoves: MicroMoveStep[];
};

const AddFundamentalPage = (props: Props) => {
  const [fileType, setFileType] = useState(false);

  const location = useLocation();

  // const { micromoves, main_image, secondary_image, description, name } = location.state
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(listGuideAPI());
  }, []);

  const handleChange = (event: any) => {
    console.log(event?.target?.value);
    if (event?.target?.value === "true") {
      setFileType(true);
      setValue("secondaryVimeoLink", "");
    } else {
      setFileType(false);
      setValue("secondaryImage", "");
    }
  };

  // Local States
  const [mimeType, setMimeType] = useState([
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ]);
  // Validation for a single step in microMoveForm
  const stepSchema = yup
    .object()
    .shape({
      detail: yup.string().required("Step description is required"),
      image: yup.string().nullable(),
      vimeo_link: yup.string().url("Link must be a valid URL").nullable(),
    })
    .test(
      "imageOrVimeoLink",
      "Either an image or a vimeo link is required",
      function (value) {
        const val = value as {
          detail: string;
          vimeo_link: string;
          image: string | FileList;
        }; // Type assertion
        return !!val.image || !!val.vimeo_link;
      }
    );

  // Validation for a single microMoveForm
  const microMoveFormSchema = yup.object().shape({
    title: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
    steps: yup.array().of(stepSchema).required("At least one step is required"),
  });

  // Validation for the AddFundamentalForm
  const AddFundamentalFormSchema = yup
    .object()
    .shape({
      fundamentalName: yup.string().required("Fundamental name is required"),
      description: yup.string().required("Description is required"),
      secondaryVimeoLink: yup.string().nullable(),
      mainImage: yup
        .mixed()
        .test("fileType", "Unsupported File Format", (value) => {
          const file = value as FileList | string;
          if (typeof file === "string") {
            return true;
          } else {
            return [
              "image/jpeg",
              "image/png",
              "image/bmp",
              "image/gif",
              "image/webp",
              "image/svg+xml",
              "image/heic",
            ].includes(file[0].type);
          }
        }),
      secondaryImage: yup
        .mixed()
        .test("fileType", "Unsupported File Format", (value) => {
          const file = value as FileList | string;
          if (typeof file === "string") {
            return true;
          } else {
            return [
              "image/jpeg",
              "image/png",
              "image/bmp",
              "image/gif",
              "image/webp",
              "image/svg+xml",
              "image/heic",
            ].includes(file[0].type);
          }
        }),
      micromoves: yup
        .array()
        .of(microMoveFormSchema)
        .required("At least one micromove is required"),
    })
    .test(
      "secondaryImageOrVimeoLink",
      "Either a secondary image or a secondary vimeo link is required",
      function (value) {
        const val = value as AddFundamentalForm; // Type assertion
        return !!val.secondaryImage || !!val.secondaryVimeoLink;
      }
    );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<AddFundamentalForm>({
    resolver: yupResolver(AddFundamentalFormSchema),
    defaultValues: {
      micromoves: location?.state?.micromoves?.map((moveStep: any) => ({
        ...moveStep,
        steps: moveStep.steps.map((step: any) => ({
          detail: step.details, // Rename 'details' to 'detail'
          ...step,
          details: undefined, // This will ensure 'details' key is removed
        })),
      })),
      description: location.state?.description,
      fundamentalName: location.state?.name,
      secondaryImage: "",
      mainImage: "",
      secondaryVimeoLink: location.state?.secondary_vimeo_link,
    },
  });
  const fieldArray = useFieldArray({
    control,
    name: "micromoves",
  });
  const Guide = useAppSelector((state) => state.Guide.guideList);
  const fileToBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  console.log(errors);
  const submit = async (data: AddFundamentalForm) => {
    console.log(data);
    const Form = new FormData();
    if (typeof data.secondaryImage === "string" && data.secondaryImage !== "") {
      Form.append("secondary_image", data.secondaryImage);
      Form.append("secondary_vimeo_link", "");
    } else if (typeof data.secondaryImage === "object") {
      Form.append("secondary_image", data.secondaryImage[0]);
      Form.append("secondary_vimeo_link", "");
    }
    if (data.secondaryVimeoLink !== "") {
      Form.append("secondary_vimeo_link", data.secondaryVimeoLink);
      Form.append("secondary_image", "");
    }
    Form.append("guide_id", Guide[0]?.id);
    Form.append("name", data.fundamentalName);
    Form.append("description", data.description);
    // console.log(convertImagesToBase64(data.micromoves))
    const res = formValues.micromoves.map((move) => {
      const moveList = move.steps.map((step) => {
        return { ...step };
      });

      return { ...moveList };
    });

    const processedData = await Promise.all(
      formValues.micromoves.map(async (move) => {
        // Use `Promise.all` to wait for all promises from mapping over `move.steps` to resolve
        const moves = await Promise.all(
          move.steps.map(async (step) => {
            const file = step.image[0]; // Assuming this is a File object
            if (typeof file !== "string" && file?.name) {
              const base64 = await fileToBase64(file);
              return { ...step, image: base64 }; // Replace the file object with its base64 string
            }
            return step; // Return the step unchanged if no image file was found
          })
        );
        console.log(move, "move");
        return { ...move, steps: moves }; // Ensure to spread `move` and update its `steps` with the processed ones
      })
    );
    Form.append("main_image", data.mainImage[0]);
    Form.append("micro_move_steps", JSON.stringify(processedData));

    // At this point, `processedData` contains your steps with images converted to base64 strings
    // Proceed with your submission logic, e.g., sending this data to an API
    // console.log(processedData);
    console.log(processedData, "movie");
    // fileToBase64(formValues.micromoves[0].steps[1].image, (res: any) => console.log(res))
    // Form.append("micro_move_steps", JSON.stringify(moveList))
    dispatch(postFundamentalAPI(Form)).then((res: any) => {
      if (res.payload.success) {
        navigate("/contentrepository/fundamentals");
        notifySuccess("Fundamental Added");
      }
    });
    console.log(data);
  };

  console.log(errors);
  const formValues = watch();
  console.log(formValues);
  console.log(fieldArray.fields);

  const loader = useAppSelector((state) => state.Guide.postFundamentalAPIIdle);
  useEffect(() => {
    if (location.state?.secondary_image === "") {
      setFileType(false);
    } else {
      setFileType(true);
    }
  }, [location.state?.secondary_image]);
  const onError: SubmitErrorHandler<AddFundamentalForm> = (errors) => {
    Object.keys(errors).forEach((key) => {
      const error = errors[key as keyof AddFundamentalForm];
      if (Array.isArray(error)) {
        error.forEach((err: any | undefined, index) => {
          console.log(Object.values(err), "err");
          Object.values(err)
            .slice(0, 1)
            .map((msg: any, moveIndex: number) => {
              if (Array.isArray(msg)) {
                Object.values(msg)
                  .slice(0, 1)
                  .map((stepError: any) => {
                    console.log(Object.values(stepError), "stepError");
                    Object.values(stepError)
                      .slice(0, 1)
                      .map((stepErrorMss: any) => {
                        console.log(stepErrorMss);
                        notifyError(
                          `${stepErrorMss} in Micro Moves row ${
                            index + 1
                          } , step row ${moveIndex + 1}`
                        );
                      });
                  });
              } else {
                notifyError(`${msg.message} in Micro Moves row ${index + 1}`);
              }
            });
          if (err?.message) {
            notifyError(err.message);
          }
        });
      } else if (error && "message" in error) {
        notifyError((error as FieldError).message);
      }
    });
  };

  const navigate = useNavigate();
  const childrenRef = React.useRef<any>(null);
  const reorder = (result: any) => {
    console.log(result);
    const { source, destination, type } = result;
    if (!destination) {
      return;
    }
    const sourceIndex = source.index;
    const destIndex = destination.index;

    if (type === "parentContainer2") {
      fieldArray.move(sourceIndex, destIndex);
    } else if (type === "childContainer" && source.droppableId) {
      const reorderChild = childrenRef.current[source.droppableId];
      if (reorderChild) {
        reorderChild(sourceIndex, destIndex);
      }
    }
  };

  return (
    <BoxLayout HeaderName="Content Repository" sectionName="Add Fundamaentals">
      <form
        onSubmit={handleSubmit(submit, onError)}
        className="grid grid-cols-3 gap-4 mx-4 my-3"
      >
        <div className="  border-2 col-span-2 rounded-lg">
          <div className="py-2 font-bold text-[0.75rem] px-4 border-b">
            Fundamentals Details
          </div>

          <div className="p-5 flex flex-col gap-6 rounded-lg ">
            <div className=" gap-3 items-center grid grid-cols-3">
              <InputComponent
                label="Fundamental Name"
                inputRef="fundamentalName"
                name=""
                register={register}
                value=""
                className="col-span-2"
              />
              <InputComponent
                label="Description"
                inputRef="description"
                name=""
                register={register}
                value=""
                className="col-span-3"
              />
            </div>
          </div>
        </div>
        <div className=" border-2 h-fit rounded-lg">
          <div className="py-2 font-bold text-[0.75rem] px-4 border-b">
            Images
          </div>

          <div className="py-2 text-base px-4 font-medium border-b justify-between items-center w-full flex">
            <p className="py-2 w-[180px] block overflow-hidden text-ellipsis whitespace-nowrap">
              {typeof formValues?.mainImage !== "string"
                ? formValues?.mainImage[0]?.name
                : formValues?.mainImage === ""
                ? "Main Image"
                : formValues?.mainImage.split("/")[3]}
            </p>
            <label htmlFor="file-input" className="cursor-pointer">
              <span className={`flex justify-center gap-2 items-center`}>
                <img src="/images/plusButton.svg" alt="" />
                {"Add File"}
              </span>
            </label>
            <Controller
              control={control}
              name="mainImage"
              render={({ field: { onChange, ref } }) => (
                <input
                  id="file-input"
                  ref={ref}
                  type="file"
                  className="hidden"
                  onChange={(e) => onChange(e.target.files)} // When the file changes, update it in the RHF store
                />
              )}
            />
          </div>

          <div className="grid px-3 py-2 items-center grid-cols-2 gap-1">
            <div
              className="font-semibold text-[#3c4c76]
          font-blue-950 text-sm col-span-2 flex gap-3"
            >
              Choose Secondary Media :
              <div className="">
                <label
                  className="flex font-semibold text-[#3c4c76]
          font-blue-950 text-sm items-center gap-2"
                >
                  Image
                  <input
                    type="radio"
                    name="toggle"
                    value="true"
                    checked={fileType}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div>
                <label
                  className="flex font-semibold text-[#3c4c76]
          font-blue-950 text-sm items-center gap-2"
                >
                  Video
                  <input
                    type="radio"
                    name="toggle"
                    value="false"
                    checked={!fileType}
                    onChange={handleChange}
                  />
                </label>
              </div>
            </div>
            <div className="py-3 ">
              {fileType ? (
                <UploadFileComponents
                  onClick={() => setValue("secondaryImage", "")}
                  control={control}
                  id="img"
                  inputRef="secondaryImage"
                  label={
                    typeof formValues?.secondaryImage !== "string"
                      ? formValues?.secondaryImage[0]?.name
                      : formValues?.secondaryImage === ""
                      ? "Upload Image"
                      : formValues?.secondaryImage.split("/")[3]
                  }
                  value={formValues?.secondaryImage}
                />
              ) : (
                <>
                  <InputComponent
                    inputRef="secondaryVimeoLink"
                    className="col-span-2"
                    label="Vimeo Link"
                    name="s"
                    register={register}
                    value=""
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="border-2 rounded-lg col-span-3">
          <div className="py-2 font-bold flex justify-between text-[0.75rem] px-4 border-b">
            <p> Micro Moves and Steps</p>
            <button
              type="button"
              className="flex gap-2 items-center"
              onClick={() => {
                fieldArray.append({
                  description: "",
                  title: "",
                  steps: [{ detail: "", vimeo_link: "", image: "" }],
                });
              }}
            >
              {" "}
              <img src="/images/plusButton.svg" alt="" /> Add Micro Moves
            </button>
          </div>
          <div className="">
            <div className=" flex flex-col min-h-[30vh] max-h-[30vh] overflow-auto font-medium gap-6">
              <DragDropContext onDragEnd={reorder}>
                <Droppable droppableId="parent2w" type="parentContainer2">
                  {(provided) => (
                    <ul ref={provided.innerRef} {...provided.droppableProps}>
                      {fieldArray.fields.map((field, index) => (
                        <Draggable
                          key={field.id}
                          draggableId={field.id}
                          index={index}
                        >
                          {(provided) => (
                            <li
                              key={field.id}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <FundamentalArray
                                setValue={setValue}
                                formValues={formValues}
                                control={control}
                                field={field}
                                fieldArray={fieldArray}
                                index={index}
                                provided={provided}
                                register={register}
                                reorder={reorder}
                              />
                            </li>
                          )}
                        </Draggable>
                      ))}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
              {fieldArray.fields.length ? (
                <> </>
              ) : (
                <div className="m-auto flex flex-col text-center text-[#9E9E9E]">
                  <span className="text-base font-semibold">
                    You haven't Added “Micro Move”
                  </span>
                  <span className="text-sm font-normal">
                    {" "}
                    You can add by clicking the “Add Micro Move“ Button
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-span-3 grid grid-cols-10 gap-5">
          <button
            type="button"
            className="flex gap-4"
            onClick={() => {
              navigate("/contentrepository/fundamentals");
            }}
          >
            <img src="/images/arrow-left.svg" alt="" />
            Back
          </button>
          {/* <ButtonComponent CTA='Back' buttonType='button' varient='e' className='' /> */}
          <ButtonComponent
            CTA="Submit"
            loading={loader}
            buttonType="submit"
            varient="blue"
            className="col-start-10"
          />
        </div>
      </form>
    </BoxLayout>
  );
};

export default AddFundamentalPage;

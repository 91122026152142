import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiResponse } from "../../types/apiresponse";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";

export interface faqresponse {
  question: string;
  answer: string;
  image: string;
  vimeo_link: string;
  updated_at: string;
  created_at: string;
  _id: string;
  image_url: string;
}

export const postFaq = createAsyncThunk<ApiResponse<faqresponse>, FormData>(
  "FAQ/postFaq",
  async (data) => {
    const res = await axiosClient.post<ApiResponse<faqresponse>>(
      `auth/faq`,
      data
    );
    return res.data;
  }
);

export const getfaq = createAsyncThunk<ApiResponse<faqresponse[]>>(
  "FAQ/getfaq",
  async () => {
    const res = await axiosClient.get<ApiResponse<faqresponse[]>>(`auth/faq`);
    return res.data;
  }
);

type faqfileOrder = {
  faq_list: string;
};

export const orderfaq = createAsyncThunk<
  ApiResponse<faqresponse>,
  faqfileOrder
>("faq/orderfaq", async (data) => {
  const res = await axiosClient.post<ApiResponse<faqresponse>>(
    `auth/order_faq`,
    data
  );
  return res.data;
});

export const deleteFaq = createAsyncThunk<ApiResponse<faqresponse>, string>(
  "faq/deleteFaq",
  async (data) => {
    const res = await axiosClient.delete<ApiResponse<faqresponse>>(
      `auth/faq/${data}`
    );
    return res.data;
  }
);

interface faqslice {
  postFaqStatus: Status;
  postFaqResponse: faqresponse[];
  getfaqStatus: Status;
  orderStatus: Status;
  deleteFaqStatus: Status;
}

const initialState: faqslice = {
  postFaqStatus: Status.SUCCESS,
  postFaqResponse: [],
  getfaqStatus: Status.SUCCESS,
  orderStatus: Status.SUCCESS,
  deleteFaqStatus: Status.SUCCESS,
};

const FaqSlice = createSlice({
  name: "FAQ",
  initialState,
  reducers: {
    faqsetItems: (state, action) => {
      state.postFaqResponse = action.payload;
    },
    faqreorderItems: (state, action) => {
      const { startIndex, endIndex } = action.payload;
      const result = Array.from(state.postFaqResponse);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      state.postFaqResponse = result;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(postFaq.pending, (state, action) => {
        state.postFaqStatus = Status.LOADING;
      })
      .addCase(postFaq.fulfilled, (state, action) => {
        state.postFaqStatus = Status.SUCCESS;
      })
      .addCase(postFaq.rejected, (state, action) => {
        state.postFaqStatus = Status.ERROR;
      })
      .addCase(getfaq.pending, (state, action) => {
        state.getfaqStatus = Status.LOADING;
      })
      .addCase(getfaq.fulfilled, (state, action) => {
        state.postFaqResponse = action.payload.data;
        state.getfaqStatus = Status.SUCCESS;
      })
      .addCase(getfaq.rejected, (state, action) => {
        state.getfaqStatus = Status.ERROR;
      })
      .addCase(orderfaq.pending, (state, action) => {
        state.orderStatus = Status.LOADING;
      })
      .addCase(orderfaq.fulfilled, (state, action) => {
        state.orderStatus = Status.SUCCESS;
      })
      .addCase(orderfaq.rejected, (state, action) => {
        state.orderStatus = Status.ERROR;
      })
      .addCase(deleteFaq.pending, (state, action) => {
        state.deleteFaqStatus = Status.LOADING;
      })
      .addCase(deleteFaq.fulfilled, (state, action) => {
        state.deleteFaqStatus = Status.SUCCESS;
      })
      .addCase(deleteFaq.rejected, (state, action) => {
        state.deleteFaqStatus = Status.ERROR;
      });
  },
});

export const { faqreorderItems, faqsetItems } = FaqSlice.actions;

export default FaqSlice.reducer;

import { useEffect } from "react";
import { useAppSelector } from "../../../Redux/app/hooks";
import BoxLayout from "../../../Containers/Layout/BoxLayout";
import Tabs from "../../../Components/Tab/Tab";
import History from "./History/History";
import ProfilePage from "./Profile/ProfilePage";
import RatingPage from "./Rating/RatingPage";
import ActivityPage from "./Activity/ActivityPage";

type Props = {};

const UserIdDetail = (props: Props) => {
  const userData = useAppSelector((state) => state.UserManagement.userData);

  useEffect(() => {
    document.title = "FISIO | User Details";
  }, []);
  const tabItems: TabItem[] = [
    { label: "Profile", content: <ProfilePage /> },
    { label: "History", content: <History /> },
    { label: "Rating", content: <RatingPage /> },
    { label: "Activity", content: <ActivityPage /> },
  ];
  return (
    <>
      <BoxLayout HeaderName="User Management" sectionName="">
        <div>
          <Tabs items={tabItems} />
        </div>
      </BoxLayout>
    </>
  );
};
export default UserIdDetail;

import BreadCrumbComponent from "../../Components/BreadCrumbComponent";
import classNames from "classnames";

type Props = {
  sectionName: string;
  bgColor?: string;
  headerSection?: React.ReactNode;
};

const HeaderPanel = ({
  sectionName,
  bgColor = "FFFFFF",
  headerSection,
}: Props) => {
  const isProd = process.env.REACT_APP_ISPROD;
  return (
    <div
      className={classNames(
        "font-semibold items-center flex flex-wrap-reverse bg-sec justify-between gap-4 py-4 px-4 rounded-[10px] bg-white"
      )}
    >
      <span>
        {sectionName} <span>{headerSection}</span>
      </span>
      <BreadCrumbComponent />
    </div>
  );
};

export default HeaderPanel;

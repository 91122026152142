import { useRef } from "react";
import { PreviewHeader } from "./Preivew/PreviewHeader";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import classNames from "classnames";
import SmallSavedIcon from "../../Components/Icons/SmallSavedIcon";
import SmallFlagIcon from "../../Components/Icons/SmallFlagIcon";
import QuoteIcon from "../../Components/Icons/QuoteIcon";
import CustomPlayer from "../../Components/VideoPlayer/VideoPlayer";
import PreviewPlayer from "../../Components/VideoPlayer/PreviewPlayer";

type Props = {
  data: ObjectItem | undefined;
};

const PreviewPanel = ({ data }: Props) => {
  const audioRef = useRef<HTMLAudioElement>(null);

  const renderComponent = () => {
    switch (data?.object_type_name) {
      case "Audio":
        return (
          <div className="px-4">
            <img
              className="w-[390px] h-[292px] object-cover"
              src={
                data?.thumbnail !== ""
                  ? data.thumbnail
                  : "/images/AudioBlueImg.svg"
              }
              alt=""
            />
            <div className="flex flex-col py-5 px-3">
              <p className={classNames("text-start")}>{data.headline}</p>
              <p
                className={classNames("text-start")}
                dangerouslySetInnerHTML={{
                  __html: data?.textarea?.replace(/<p>/g, "<br />"),
                }}
              ></p>
            </div>
          </div>
        );
        break;
      case "Video":
        return (
          <div>
            <PreviewPlayer url={`https://vimeo.com/${data.video_id}`} />
            <div
              style={{ padding: "10px" }}
              className="text-start text-primaryBlue 
              list-inside list-disc text-[1.125rem]"
              dangerouslySetInnerHTML={{
                __html: data.textarea,
              }}
            ></div>
          </div>
        );
        break;
      case "Image":
        return (
          <div>
            {" "}
            <div className="flex flex-col justify-center items-center gap-2"></div>{" "}
            <img src={data.file} alt="" className="aspect-square" />
            <p
              style={{ padding: "10px" }}
              className="text-start text-primaryBlue text-[1.125rem]"
              dangerouslySetInnerHTML={{
                __html: data.textarea.replace(/<p>/g, "<br />"),
              }}
            ></p>
          </div>
        );
        break;
      case "Quote":
        return (
          <>
            {" "}
            <div>
              <div className="flex flex-col justify-center items-center gap-2">
                <p className="text-[#F58124] text-[1.625rem] font-bold">
                  {data.headline}
                </p>
                <p className="text-[#F58124] text-[1.125rem] ">
                  {data.subtitle}
                </p>
              </div>
              <div className="bg-white w-full h-fit rounded-[0.625rem] shadow-[0px_4px_4px_0px_rgba(50,50,71,0.05)] px-3 py-2 relative">
                <div className="absolute top-8 left-5">
                  <QuoteIcon />
                </div>
                <p
                  style={{ padding: "10px", color: "#1F3161" }}
                  className="text-center text-primaryBlue text-[1.125rem]"
                  dangerouslySetInnerHTML={{
                    __html: data.textarea.replace(/<p>/g, "<br />"),
                  }}
                ></p>
                <div className="flex justify-end w-full">
                  <QuoteIcon />
                </div>

                <div className="w-full flex justify-end py-2">
                  <button></button>
                </div>
              </div>
            </div>
          </>
        );
        break;
      case "Self Check Check":
        return (
          <div>
            <PreviewPlayer url={`https://vimeo.com/${data.video_id}`} />
            <p
              style={{ padding: "10px" }}
              className="text-start text-primaryBlue text-[1.125rem]"
              dangerouslySetInnerHTML={{
                __html: data.textarea.replace(/<p>/g, "<br />"),
              }}
            ></p>
          </div>
        );
        break;
      case "Self Report":
        return (
          <div>
            <PreviewPlayer url={`https://vimeo.com/${data.video_id}`} />
            <p
              style={{ padding: "10px" }}
              className="text-start text-primaryBlue text-[1.125rem]"
              dangerouslySetInnerHTML={{
                __html: data.textarea.replace(/<p>/g, "<br />"),
              }}
            ></p>
          </div>
        );
        break;
      case "Text":
        return (
          <>
            <div className="h-full flex flex-col overflow-auto example justify-start items-center">
              <div className="flex flex-col justify-center items-center gap-2">
                <p className="text-[#F58124] text-[1.625rem] font-bold">
                  {data.headline}
                </p>
                <p className="text-[#F58124] text-[1.125rem] ">
                  {data.subtitle}
                </p>
              </div>
              <div className="bg-white w-full h-fit rounded-[0.625rem] shadow-[0px_4px_4px_0px_rgba(50,50,71,0.05)] px-3 py-2">
                <p
                  style={{ padding: "10px" }}
                  className="text-start text-primaryBlue text-[1.125rem]"
                  dangerouslySetInnerHTML={{
                    __html: data.textarea.replace(/<p>/g, "<br />"),
                  }}
                ></p>
                <div className="w-full flex justify-end">
                  <button>
                    <img src={"/images/SmallAudioOff.svg"} alt="AudioOn" />
                  </button>
                </div>
              </div>
            </div>
          </>
        );
        break;

      default:
        return <></>;
        break;
    }
  };
  return (
    <>
      <div className="w-[21.375rem] rounded-lg pt-4  h-[40.75rem] overflow-auto example m-auto  shadow-[3px_3px_20px_20px_rgba(172,172,172,0.25)]">
        <PreviewHeader moduleName={data?.category_type_name} />
        {renderComponent()}
        <div className="bg-white h-fit min-h-[10rem] w-[100%] sticky bottom-0 flex flex-col items-center   rounded-[1.25rem_1.25rem_0_0] shadow-[0px_-4px_10px_0px_rgba(172,172,172,0.25)]">
          <div className="flex justify-end items-center gap-6 w-[100%] px-8 pt-2">
            <button>
              <SmallSavedIcon />
            </button>
            <button>
              <SmallFlagIcon />
            </button>
          </div>
          {data?.object_type_name === "Audio" && (
            <div className="w-full flex my-4 justify-center items-center">
              <audio ref={audioRef} controls autoPlay>
                <source src={data?.file} type="audio/mp3" />
                <source src={data?.file} type="audio/wav" />
              </audio>
            </div>
          )}
          <ButtonComponent
            CTA={"Next"}
            buttonType="button"
            className={classNames("px-4   m-auto w-[15.9375rem]", {
              "my-2": data?.object_type_name === "Audio",
              "my-8": data?.object_type_name !== "Audio",
            })}
            varient="orange"
          />
        </div>
      </div>
    </>
  );
};

export default PreviewPanel;

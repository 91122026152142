import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";

export const getTrophiesListAPI = createAsyncThunk<GetTrophiesListAPIResponse>(
  "Trophies/GetTrophies",
  async () => {
    const res = await axiosClient.get("auth/trophies-list");
    return res.data;
  }
);

export const postTrophiesListAPI = createAsyncThunk<
  PostTrophiesResponse,
  FormData
>("Trophies/postTrophies", async (data) => {
  const res = await axiosClient.post("auth/trophies", data);
  return res.data;
});

export const deleteTrophiesListAPI = createAsyncThunk<void, string>(
  "Trophies/deleteTrophies",
  async (id) => {
    const res = await axiosClient.delete(`auth/trophies/${id}`);

  }
);

interface Trophies {
  getTrophiesListAPIIdle: Status;
  postTrophiesListAPIIdle: Status;
  deleteTrophiesListAPIIdle: Status;
  Trophies: TrophiesData[];
}

const initialState: Trophies = {
  deleteTrophiesListAPIIdle: Status.SUCCESS,
  getTrophiesListAPIIdle: Status.SUCCESS,
  postTrophiesListAPIIdle: Status.SUCCESS,
  Trophies: [],
};

const TrophiesSlice = createSlice({
  name: "Trophies",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getTrophiesListAPI.pending, (state) => {
        return {
          ...state,
          getTrophiesListAPIIdle: Status.LOADING,
        };
      })
      .addCase(getTrophiesListAPI.fulfilled, (state, action) => {
        return {
          ...state,
          getTrophiesListAPIIdle: Status.SUCCESS,
          Trophies: action.payload.data,
        };
      })
      .addCase(getTrophiesListAPI.rejected, (state, action) => {
        return {
          ...state,
          getTrophiesListAPIIdle: Status.ERROR,
        };
      })
      .addCase(postTrophiesListAPI.pending, (state) => {
        return {
          ...state,
          postTrophiesListAPIIdle: Status.LOADING,
        };
      })
      .addCase(postTrophiesListAPI.fulfilled, (state, action) => {
        return {
          ...state,
          postTrophiesListAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(postTrophiesListAPI.rejected, (state, action) => {
        return {
          ...state,
          postTrophiesListAPIIdle: Status.ERROR,
        };
      })
      .addCase(deleteTrophiesListAPI.pending, (state) => {
        return {
          ...state,
          deleteTrophiesListAPIIdle: Status.LOADING,
        };
      })
      .addCase(deleteTrophiesListAPI.fulfilled, (state, action) => {
        return {
          ...state,
          deleteTrophiesListAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(deleteTrophiesListAPI.rejected, (state, action) => {
        return {
          ...state,
          deleteTrophiesListAPIIdle: Status.ERROR,
        };
      });
  },
});

export const { } = TrophiesSlice.actions;

export default TrophiesSlice.reducer;

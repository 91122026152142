import InputComponent from "../../Components/Inputs/InputComponent";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import {
  Control,
  FieldErrors,
  SetFieldValue,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form/dist/types";
import { ObjectFormValues } from "../../Pages/Objects/AddObjectsPage";
import { useAppSelector } from "../../Redux/app/hooks";
import CheckBoxComponent from "../../Components/Inputs/CheckBoxComponent";
import { Controller } from "react-hook-form";
import UploadFileComponents from "../../Components/Inputs/UploadFileComponents";

type Props = {
  register: UseFormRegister<ObjectFormValues>;
  errors: FieldErrors<ObjectFormValues>;
  formValues: ObjectFormValues;
  control: Control<ObjectFormValues>;
  objectTypeList: ObjectType[];
  setValues: UseFormSetValue<ObjectFormValues>
};

const ObjectDetails = ({
  register,
  errors,
  formValues,
  control,
  objectTypeList,
  setValues
}: Props) => {
  const objectTypeOptions = useAppSelector(
    (state) => state.Object.objectTypeList
  );
  const categoryTypeOptions = useAppSelector(
    (state) => state.Object.categoryTypeList
  );

  return (
    <>
      <div className="grid py-4 px-4 border-t gap-4 grid-cols-2">
        <SelectComponent<ObjectFormValues>
          control={control}
          inputRef="objectType"
          options={objectTypeOptions.map((i) => {
            return {
              label: i.name,
              value: i._id,
            };
          })}
          label="Object Type"
        />
        <SelectComponent<ObjectFormValues>
          control={control}
          inputRef="categoryType"
          options={categoryTypeOptions.map((i) => {
            return {
              label: i.name,
              value: i._id,
            };
          })}
          label="Catagory Type"
        />
        <InputComponent
          register={register}
          inputRef="objectTime"
          label="Object Time (mm:ss)"
          name="question"
          value={String(formValues.objectTime)}
        />
        <InputComponent
          register={register}
          inputRef="object_reps"
          label="Object Reps"
          name="question"
          value={String(formValues.objectTime)}
        />
        <InputComponent
          register={register}
          inputRef="objectName"
          label="Object Name"
          name="question"
          value={formValues.objectName}
        />
        <CheckBoxComponent
          register={register}
          inputRef="is_drill"
          label="Is Drill"
          name="question"
          value={formValues.objectName}
        />
        {objectTypeList
          .filter((category) => category.name === "Audio")
          .map((category) => category._id)
          .includes(formValues.objectType) ? (
          <div className="flex gap-2 col-span-2 items-center">
            <span className="text-sm">Audio Thumbnail :</span>
            <div className="py-2 text-base px-4 font-medium border-b justify-between items-center  flex">
              <UploadFileComponents
                label={
                  formValues.thumbnail !== "" && typeof formValues.thumbnail === "string" && formValues?.thumbnail.length !== 0
                    ? formValues.thumbnail.split("/")[4]
                    : "Upload Answer Image"
                }
                id="thumbnail"
                control={control}
                onClick={() => setValues("thumbnail", "")}
                inputRef="thumbnail"
                value={formValues.thumbnail}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default ObjectDetails;

type Props = {};

const ImageObjectIcon = (props: Props) => {
  return (
    <>
      <svg
        width="12"
        height="12"
        viewBox="0 0 18 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 12.5556C17 12.9386 16.8468 13.306 16.574 13.5769C16.3012 13.8478 15.9312 14 15.5455 14H2.45455C2.06878 14 1.69881 13.8478 1.42603 13.5769C1.15325 13.306 1 12.9386 1 12.5556V4.61111C1 4.22802 1.15325 3.86062 1.42603 3.58973C1.69881 3.31885 2.06878 3.16667 2.45455 3.16667H5.36364L6.81818 1H11.1818L12.6364 3.16667H15.5455C15.9312 3.16667 16.3012 3.31885 16.574 3.58973C16.8468 3.86062 17 4.22802 17 4.61111V12.5556Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 11.1111C10.6066 11.1111 11.9091 9.81771 11.9091 8.22222C11.9091 6.62673 10.6066 5.33333 9 5.33333C7.39335 5.33333 6.09091 6.62673 6.09091 8.22222C6.09091 9.81771 7.39335 11.1111 9 11.1111Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ImageObjectIcon;

import React from 'react'

type Props = {
    label: string,
    index: number,
    onChange: (index: number, event: string) => void
    value: string;
}

const InputField = ({ label, onChange, index, value }: Props) => {
    return (
        <div
            className={`h-[50px]  w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8] `}
        >
            <input
                defaultValue={value}
                onChange={(e) => onChange(index, e.target.value)}
                className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none`}
                placeholder={`Enter ${label}`}
            />

            <label
                className="absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
                htmlFor={"userName"}
            >
                {label}
            </label>
        </div>
    )
}

export default InputField
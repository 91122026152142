import React from 'react'
import DisableSmallLeftIcon from '../../../Components/Icons/SmallDisableLeftIcon'
import SmallLeftIcon from '../../../Components/Icons/SmallLeftIcon'
import NoteBookIcon from '../../../Components/Icons/NoteBookComp'
import SmallDisableRightIcon from '../../../Components/Icons/SmallDisableRightIcon'
import SmallRightIcon from '../../../Components/Icons/SmallRightIcon'
import classNames from "classnames"
import CompletedSmallCircleIcon from '../../../Components/Icons/CompletedSmallCircle'
import ActiveSmallCircleIcon from '../../../Components/Icons/ActiveSmallCircle'
import NonActiveSmallCircle from '../../../Components/Icons/NonActiveSmallCircle'

type Props = {
    moduleName?: string
}

export const PreviewHeader = ({ moduleName = "Apply" }: Props) => {
    const activeStep = 4
    const lastStep = 6
    return (
        <div className='px-4'><div className="flex   justify-between items-center">
            <button>Exit</button>
            <p className="text-[1.25rem] text-primaryBlue font-medium">
                {moduleName}
            </p>
            <NoteBookIcon />
        </div>
            <div className="flex  justify-center items-center py-5  xs:gap-3 xxs:gap-3">
                <button>
                    {activeStep === 4 ? <DisableSmallLeftIcon /> : <SmallLeftIcon />}
                </button>
                <div className="flex overflow-auto example">
                    {[1, 2, 3, 4, 5, 6]?.map((_, index) => (
                        <div key={index} className="flex items-center flex-col">
                            <div className="flex flex-col">
                                <div className="flex">

                                    <div
                                        // onClick={() => handleStepClick(index)}
                                        className={classNames(
                                            'relative flex items-center justify-center  rounded-full w-8 h-8 border-orange-500',
                                            {
                                                'border-red-500': +index === activeStep,
                                            },
                                        )}
                                    >
                                        {index < activeStep ? (
                                            <CompletedSmallCircleIcon />
                                        ) : (
                                            <>
                                                {index === activeStep ? (
                                                    <ActiveSmallCircleIcon />
                                                ) : (
                                                    <NonActiveSmallCircle />
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>


                        </div>
                    ))}
                </div>
                <button
                    disabled
                // onClick={handleNextStepClick}
                // disabled={lastStep && activeStep == lastStep - 1 ? true : false}
                >
                    {lastStep && activeStep == lastStep - 1 ? (
                        <SmallDisableRightIcon />
                    ) : (
                        <SmallRightIcon />
                    )}
                </button>
            </div></div>
    )
}
import { useEffect, useState } from "react";

type Props = {
  direction: string;
};

const ArrowIcon = ({ direction }: Props) => {
  const [arrowDirection, setArrowDirection] = useState("");
  useEffect(() => {
    switch (direction) {
      case "up":
        setArrowDirection("rotate-90");
        break;
      case "down":
        setArrowDirection("-rotate-90");
        break;
      case "right":
        setArrowDirection("rotate-180");
        break;
      default:
        setArrowDirection("");
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowDirection]);

  return (
    <svg
      className={`${direction} ${arrowDirection} m-auto `}
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.4668 0L0.466797 4L4.4668 8L5.4068 7.06L2.35346 4L5.4068 0.94L4.4668 0Z"
        fill="#757575"
      />
    </svg>
  );
};

export default ArrowIcon;

import Select from "react-select";
import { DropDownStyles, convertToMMSS } from "../../helper";
import { convertToHoursMinutes, formatTime } from "../../utils/helper";

type Props = {
  index: number;
  sessionList: any;
  select: any;
  UnitObjectList: any;
  handleInputChange: Function;
  handlePermissionChange: Function;
  handleRemoveInput: Function;
  formValues: any;
};

const SessionSelectorArray = ({
  index,
  sessionList,
  select,
  UnitObjectList,
  handleInputChange,
  handlePermissionChange,
  handleRemoveInput,
  formValues,
}: Props) => {
  return (
    <div className="grid grid-cols-12 gap-4 items-center" key={index}>
      <p className="col-span-1 m-auto">{index + 1}.</p>
      <div className="col-span-7">
        <div className="flex flex-col w-full">
          <div className="relative">
            <label
              className={`absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 z-10`}
            >
              Session Name
            </label>
            <Select
              value={{
                value: select.object_id,
                label: sessionList?.filter(
                  (value: any) => value.id === select.object_id
                )[0]?.session,
              }}
              options={sessionList
                .filter(
                  (state: any) =>
                    state.category_type_id === formValues.category_type_id
                )
                .map((value: any) => {
                  return { label: value.session, value: value.id };
                })}
              styles={DropDownStyles}
              placeholder={"Session Name"}
              onChange={(e: any) => {
                const time = Number(
                  sessionList.filter(
                    (i: any) => i.id === UnitObjectList[index].object_id
                  )[0]?.time
                );

                handleInputChange(index, e?.value, time);
              }}
            />
          </div>
        </div>
      </div>

      <div className="col-span-2 flex flex-col gap-1 text-xs">
        <p>Permission</p>
        <div className="flex">
          <div className="flex gap-2 items-center">
            <input
              type="radio"
              onChange={(e) => handlePermissionChange(index, e.target.value)}
              name={`val${index}`}
              value={0}
              defaultChecked={Number(select.permission) === 0}
            />
            <label>{"Required"}</label>
            <input
              type="radio"
              name={`val${index}`}
              defaultChecked={Number(select.permission) === 1}
              value={1}
              onChange={(e) => handlePermissionChange(index, e.target.value)}
            />
            <label>{"Optional"}</label>
          </div>
        </div>
      </div>
      <div className="col-span-1 flex items-center justify-end gap-4">
        <span className="text-xs">
          {formatTime(
            Number(
              sessionList.filter(
                (i: any) => i.id === UnitObjectList[index].object_id
              )[0]?.time
            )
          )}
        </span>
        <button type="button" onClick={() => handleRemoveInput(index)}>
          <img className="w-6" src="/images/remove.svg" alt="remove" />
        </button>
      </div>
    </div>
  );
};

export default SessionSelectorArray;

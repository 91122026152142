import InputComponent from "../../Components/Inputs/InputComponent";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import {
  Control,
  FieldErrors,
  UseFormRegister,
} from "react-hook-form/dist/types";
import { SessionAddFormValues } from "../../Pages/Sessions/AddSessionPage";
import { useAppSelector } from "../../Redux/app/hooks";
import MultiSelectComponent from "../../Components/Inputs/MultiSelectComponent";
import TextAreaComponent from "../../Components/Inputs/TextAreaComponent";

type Props = {
  register: UseFormRegister<SessionAddFormValues>;
  errors: FieldErrors<SessionAddFormValues>;
  formValues: SessionAddFormValues;
  control: Control<SessionAddFormValues>;
};

const SessionDetails = ({ register, errors, formValues, control }: Props) => {
  const sessionType = useAppSelector((state) => state.Session.sessionTypeList);
  const CategoryType = useAppSelector((state) => state.Object.categoryTypeList);
  const recallCardList = useAppSelector(
    (state) => state.RecallCards.recallCards
  );
  return (
    <div className="py-3 p-4">
      <form>
        <div className="grid gap-4 grid-cols-3">
          <SelectComponent<SessionAddFormValues>
            control={control}
            inputRef="sessionType"
            options={sessionType.map((value) => {
              return {
                value: value._id,
                label: value.name,
              };
            })}
            label="Session Type"
          />
          <SelectComponent<SessionAddFormValues>
            control={control}
            inputRef="categoryType"
            options={CategoryType.map((value) => {
              return {
                value: value._id,
                label: value.name,
              };
            })}
            label="Category Type"
          />

          <InputComponent
            register={register}
            inputRef="sessionName"
            label="Session Name"
            name="Session Name"
            value={formValues.sessionName}
          />
          {/* <InputComponent
            register={register}
            inputRef="headline"
            label="Headline"
            name="Session Name"
            value={formValues.sessionName}
          /> */}
          <InputComponent
            register={register}
            inputRef="overview.headline"
            label="Overview Headline"
            name="Session Name"
            value={formValues.sessionName}
          />
          <InputComponent
            register={register}
            inputRef="overview.equipments"
            label="Equipments"
            name="Session Name"
            value={formValues.sessionName}
          />
          <TextAreaComponent
            register={register}
            inputRef="overview.text"
            label="Session Text"
            name="Session Name"
            value={formValues.sessionName}
          />
          {/* <TextAreaComponent
            inputRef="description"
            name="description"
            register={register}
            value=""
            label="Description"
          /> */}
          <MultiSelectComponent
            label={"Recall Cards"}
            control={control}
            options={recallCardList?.map((option) => {
              return {
                label: option.question_text,
                value: option._id,
              };
            })}
            inputRef="recall_cards"
            isMulti
          />
          <InputComponent
            register={register}
            inputRef="internal_session_name"
            label="Internal Session Name"
            name="Session Name"
            astric={false}
            value={formValues.sessionName}
          />
        </div>
      </form>
    </div>
  );
};

export default SessionDetails;

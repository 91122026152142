import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axiosClient from '../../api/api';
import { Status } from './UserManagementSlice';

export const getShotCheckOverViewAPI = createAsyncThunk<TrustShotCheckResponse>("trust/getShotCheck", async () => {
    const res = await axiosClient.get("auth/overview/shot-check")
    return res.data
})
export const getTrustOverViewAPI = createAsyncThunk<TrustShotCheckResponse>("trust/getTrust", async () => {
    const res = await axiosClient.get("auth/overview/trust")
    return res.data
})

export const postOverViewAPI = createAsyncThunk("trust/postOverview", async (data: any) => {
    const res = await axiosClient.post("auth/overview", data)
    return res.data
})

export const getQuoteAPI = createAsyncThunk("trust/getquote", async () => {
    const res = await axiosClient.get("auth/quote")
    return res.data
})

export const getTrustKeyAPI = createAsyncThunk("trust/trustkey", async () => {
    const res = await axiosClient.get("auth/trustKey")
    return res.data
})

export const postQuoteAPI = createAsyncThunk("trust/postquote", async (data: any) => {
    const res = await axiosClient.post("auth/quote", data)
    return res.data
})
export const postTrustKeyAPI = createAsyncThunk("trust/postTrustkey", async (data: any) => {
    const res = await axiosClient.post("auth/trustKey", data)
    return res.data
})


interface IinitialState {
    getShotCheckOverViewAPIIdle: Status
    postShotChekckOverViewAPIIdle: Status
    getTrustOverViewAPIIdle: Status
    postTrustOverViewAPIIdle: Status
    postTrustKeyAPIdle: Status
    trustOverView?: TrustShotCheckOverView,
    shotCheckOverView?: TrustShotCheckOverView
    quote: Quote
    trustKey: TrustKeys[]
}

const initialState: IinitialState = {
    postTrustKeyAPIdle: Status.SUCCESS,
    getShotCheckOverViewAPIIdle: Status.SUCCESS,
    postShotChekckOverViewAPIIdle: Status.SUCCESS,
    getTrustOverViewAPIIdle: Status.SUCCESS,
    postTrustOverViewAPIIdle: Status.SUCCESS,
    trustKey: [],
    quote: {
        _id: "",
        created_at: "",
        quote: "",
        quote_by: "",
        shown: false,
        title: "",
        updated_at: ""
    }

}

const TrustShotCheckSlice = createSlice({
    name: "Trust",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(getShotCheckOverViewAPI.pending, (state) => {
                state.getShotCheckOverViewAPIIdle = Status.LOADING
            })
            .addCase(getShotCheckOverViewAPI.fulfilled, (state, action) => {
                state.getShotCheckOverViewAPIIdle = Status.SUCCESS
                state.shotCheckOverView = action.payload?.data[0]
            })
            .addCase(getShotCheckOverViewAPI.rejected, (state) => {
                state.getShotCheckOverViewAPIIdle = Status.ERROR
            })
            .addCase(getTrustOverViewAPI.pending, (state, action) => {
                state.getTrustOverViewAPIIdle = Status.LOADING
            })
            .addCase(getTrustOverViewAPI.fulfilled, (state, action) => {
                state.getTrustOverViewAPIIdle = Status.LOADING
                state.trustOverView = action.payload?.data[0]
            })
            .addCase(getTrustOverViewAPI.rejected, (state) => {
                state.getTrustOverViewAPIIdle = Status.LOADING
            })
            .addCase(getQuoteAPI.pending, (state, action) => {
                state.getTrustOverViewAPIIdle = Status.LOADING
            })
            .addCase(getQuoteAPI.fulfilled, (state, action) => {
                state.getTrustOverViewAPIIdle = Status.LOADING
                state.quote = action.payload?.data[0]
            })
            .addCase(getQuoteAPI.rejected, (state) => {
                state.getTrustOverViewAPIIdle = Status.LOADING
            })
            .addCase(postQuoteAPI.pending, (state, action) => {
            })
            .addCase(postQuoteAPI.fulfilled, (state, action) => {
            })
            .addCase(postQuoteAPI.rejected, (state) => {
            })
            .addCase(getTrustKeyAPI.pending, (state, action) => {
            })
            .addCase(getTrustKeyAPI.fulfilled, (state, action) => {
                state.trustKey = action.payload.data
            })
            .addCase(getTrustKeyAPI.rejected, (state) => {
            })
            .addCase(postTrustKeyAPI.pending, (state, action) => {
                state.postTrustKeyAPIdle = Status.LOADING
            })
            .addCase(postTrustKeyAPI.fulfilled, (state, action) => {

                state.postTrustKeyAPIdle = Status.SUCCESS
            })
            .addCase(postTrustKeyAPI.rejected, (state) => {
                state.postTrustKeyAPIdle = Status.ERROR
            })

    },
});

export const { } = TrustShotCheckSlice.actions

export default TrustShotCheckSlice.reducer
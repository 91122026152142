type Props = {};

const SearchIcon = (props: Props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 11 11 "
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33326 7.33351H7.8066L7.61993 7.15351C8.41993 6.22017 8.83326 4.94684 8.6066 3.59351C8.29326 1.74017 6.7466 0.260173 4.87993 0.0335063C2.05993 -0.31316 -0.313405 2.06017 0.0332621 4.88017C0.259929 6.74684 1.73993 8.29351 3.59326 8.60684C4.9466 8.83351 6.21993 8.42017 7.15326 7.62017L7.33326 7.80684V8.33351L10.1666 11.1668C10.4399 11.4402 10.8866 11.4402 11.1599 11.1668C11.4333 10.8935 11.4333 10.4468 11.1599 10.1735L8.33326 7.33351ZM4.33326 7.33351C2.67326 7.33351 1.33326 5.99351 1.33326 4.33351C1.33326 2.67351 2.67326 1.33351 4.33326 1.33351C5.99326 1.33351 7.33326 2.67351 7.33326 4.33351C7.33326 5.99351 5.99326 7.33351 4.33326 7.33351Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};

export default SearchIcon;

import React, { useEffect, useState } from "react";
import InputComponent from "../../Components/Inputs/InputComponent";
import TextEditor from "../../Components/Inputs/TextEditor";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getShotCheckOverViewAPI,
  getTrustOverViewAPI,
  postOverViewAPI,
} from "../../Redux/features/TrustShotCheckSlice";
import { Status } from "../../Redux/features/UserManagementSlice";
import { notifySuccess } from "../../helper";
type Props = {};

type OverviewForm = {
  headline: string;
  text: string;
  time: number;
  description: string;
};

const AddShotCheckForm = (props: Props) => {
  const overview = useAppSelector(
    (state) => state.TrustShotCheck.shotCheckOverView
  );
  const description = useAppSelector(
    (state) => state.TrustShotCheck.shotCheckOverView?.description
  );
  const text = useAppSelector(
    (state) => state.TrustShotCheck.shotCheckOverView?.text
  );
  const time = useAppSelector(
    (state) => state.TrustShotCheck.shotCheckOverView?.time
  );
  const headline = useAppSelector(
    (state) => state.TrustShotCheck.shotCheckOverView?.headline
  );

  const validationSchema = Yup.object().shape({
    headline: Yup.string().required("headline Need"),
    text: Yup.string().required("text need"),
    // time: Yup.number().required("time"),
    description: Yup.string().required("description"),
  });

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      description: description,
      headline: headline,
      text: text,
      // time: time,
    },
  });

  console.log(description, text, time, headline);

  useEffect(() => {
    if (overview) {
      setValue("description", overview.description);
      setValue("headline", overview.headline);
      setValue("text", overview.text);
      // setValue("time", overview.time)
    }
  }, [overview]);

  const [loading, setLoading] = useState<Status>(Status.SUCCESS);
  const onSubmit = (data: any) => {
    setLoading(Status.LOADING);
    dispatch(
      postOverViewAPI({
        type: "shot-check",
        headline: data.headline,
        description: data.description,
        time: 0,
        text: data.text,
        id: overview?.id,
      })
    ).then((res) => {
      if (res.payload.success) {
        notifySuccess("Text saved successfully");
        setLoading(Status.SUCCESS);
      }
      setLoading(Status.ERROR);
    });
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    // dispatch(getShotCheckOverViewAPI())
    dispatch(getShotCheckOverViewAPI());
  }, []);

  // const

  return (
    <div>
      <div className="pb-2 mx-6 mb-3 gap-3 border rounded-2xl  ">
        <div className="col-span-3  py-3 font-semibold border-b">
          <span className="px-6">Shot Check Overview</span>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" grid-cols-3 grid gap-3 px-4 py-3"
        >
          {overview && (
            <>
              <InputComponent
                value=""
                inputRef="headline"
                name="Headline"
                register={register}
                label="Headline"
              />
              <InputComponent
                inputRef="text"
                label="Text"
                name=""
                register={register}
                value=""
                className=""
                errorname=""
                inputClassName=""
              />
              {/* <InputComponent inputRef='time' name='' label='Time (mm:ss)' register={register} value='' className='' errorname='' inputClassName='' /> */}
              <TextEditor
                className="col-span-3"
                control={control}
                inputRef="description"
                label="Description"
                name=""
                register={register}
                values=""
              />
              <ButtonComponent
                CTA="Save"
                buttonType="submit"
                varient="blue"
                loading={loading}
                className="max-w-[120px]"
              />
            </>
          )}
        </form>
      </div>
      <div></div>
    </div>
  );
};

export default AddShotCheckForm;

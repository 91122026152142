import { yupResolver } from "@hookform/resolvers/yup";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import TextEditor from "../../Components/Inputs/TextEditor";
import UploadFileComponents from "../../Components/Inputs/UploadFileComponents";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import { useFieldArray, useForm } from "react-hook-form";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { useEffect } from "react";
import {
  ObjectListAPI,
  ObjectTypeListAPI,
} from "../../Redux/features/ObjectSlice";
import { postRecallCardsAPI } from "../../Redux/features/RecallCardsSlice";
import { useNavigate } from "react-router-dom";
import MultiSelectComponent from "../../Components/Inputs/MultiSelectComponent";
import { toast } from "react-toastify";
import { TagListAPI } from "../../Redux/features/TagSlice";
import RecallCardForm from "./RecallCardForm";
import {
  extractUrlsAndText,
  hasDuplicateLinks,
  updateFieldArray,
} from "../../utils/helper";
import { notifyError } from "../../helper";
import RecallCardTextEditor from "../../Components/Inputs/RecallCardTextEditor";
import InputComponent from "../../Components/Inputs/InputComponent";

type Props = {};

export type MetaDataType = {
  link: string;
  title: string;
  description: string;
};

export interface AddRecallCardForm {
  tags?: Array<{
    label: string;
    value: string;
  }>;
  object_id?: string;
  object_type_id?: string;
  answer_text: string;
  question_text: string;
  title: string;
  answer_image?: string | FileList;
  question_image?: string | FileList;
  answer_link_items: Array<MetaDataType>;
  question_link_items: Array<MetaDataType>;
  order: number;
}

const AddRecallCardList = (props: Props) => {
  const TagList = useAppSelector((state) => state.Tag.TagList.data.tags);
  const loading = useAppSelector(
    (state) => state.RecallCards.postRecallCardsAPIIdle
  );
  const itemSchema = yup.object().shape({
    link: yup.string().required("Link is required"),
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
  });

  const schema = yup.object().shape({
    tags: yup.array().of(
      yup.object().shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
    ),
    object_id: yup.string(),
    object_type_id: yup.string(),
    title: yup.string().required("Title Required"),
    answer_image: yup
      .mixed<string | FileList>()
      .test(
        "is-string-or-filelist",
        "answer_image must be a string or a FileList",
        (value) => typeof value === "string" || value instanceof FileList
      ),
    question_image: yup
      .mixed<string | FileList>()
      .test(
        "is-string-or-filelist",
        "question_image must be a string or a FileList",
        (value) => typeof value === "string" || value instanceof FileList
      ),
    answer_text: yup.string().required(),
    question_text: yup.string().required(),
    answer_link_items: yup.array().of(itemSchema),
    question_link_items: yup.array().of(itemSchema),
    order: yup.number().required("Order is required"),
  });

  const {
    getValues,
    control,
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<AddRecallCardForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      answer_image: "",
      question_image: "",
      tags: [],
      answer_link_items: [],
      question_link_items: [],
    },
  });
  const answerLinkItems = useFieldArray({
    control,
    name: "answer_link_items",
  });

  const questionLinkItems = useFieldArray({
    control,
    name: "question_link_items",
  });

  const formValues = watch();
  const questionText = watch("question_text");
  const answertext = watch("answer_text");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // SUBMIT ADD RECALLCARD FORM
  const onSubmit = (data: AddRecallCardForm) => {
    console.log(data, JSON.stringify(data.answer_link_items));
    const form = new FormData();

    form.append("title", data.title);
    form.append("order", data.order.toString());
    // if ID is not specified then it will create a new one
    if (data.object_id) {
      form.append("object_id", data.object_id);
    }
    // Adding Images to Form
    if (typeof data.answer_image !== "string" && data.answer_image) {
      form.append("answer_img", data.answer_image[0]);
    }
    if (typeof data.question_image !== "string" && data.question_image) {
      form.append("question_img", data.question_image[0]);
    }

    // Adding Tags
    const tags = data?.tags?.map((tag) => tag.value);
    if (tags?.length) {
      form.append("tag", tags.join(","));
    }
    form.append("answer_text", data.answer_text);
    form.append("question_text", data.question_text);
    form.append("level", "1");
    //
    if (data.object_type_id) {
      form.append("object_type", data.object_type_id);
    }

    form.append(
      "link_items",
      JSON.stringify([...data.answer_link_items, ...data.question_link_items])
    );

    if (hasDuplicateLinks(data.answer_link_items, data.question_link_items)) {
      notifyError("Duplicate Links detected in meta Data");
    } else {
      dispatch(postRecallCardsAPI(form)).then((res) => {
        const response = res?.payload as PostRecallCardsResponse;
        if (response.success) {
          toast.success("New Recall Card Created");
          navigate("/contentrepository/recallcard");
        } else {
          toast.error("Failed to add RecallCard");
        }
      });
    }
  };

  const handleArrow = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
      return;
    }
  };

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1)?.map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    dispatch(ObjectTypeListAPI());
    // dispatch(ObjectListAPI({}));
    dispatch(TagListAPI({}));
  }, []);

  useEffect(() => {
    const currentUrls = extractUrlsAndText(questionText);
    updateFieldArray(
      currentUrls,
      questionLinkItems.append,
      questionLinkItems.fields,
      questionLinkItems.update,
      questionLinkItems.remove
    );
  }, [questionText]);

  useEffect(() => {
    const currentUrls = extractUrlsAndText(answertext);
    updateFieldArray(
      currentUrls,
      answerLinkItems.append,
      answerLinkItems.fields,
      answerLinkItems.update,
      answerLinkItems.remove
    );
  }, [answertext]);

  return (
    <>
      <BoxLayout HeaderName="Recall Card" sectionName="Add Recall Card">
        <div className="border-t p-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="grid grid-cols-6 gap-4"
          >
            <div className="col-span-6  border-2 rounded-lg">
              <p className="col-span-2 py-4 border-b font-semibold px-3">
                Recall Card Details
              </p>
              <div className="bg-[#e9eff2]">
                <div className="">
                  <div className="">
                    <div className="px-3 py-3 flex items-center gap-3  bg-white">
                      <InputComponent
                        className="w-[60%]"
                        inputRef="title"
                        register={register}
                        name="title"
                        value=""
                        label="Name"
                      />
                      <InputComponent
                        className="w-[60%]"
                        inputRef="order"
                        register={register}
                        name="order"
                        value=""
                        label="Order"
                        type="number"
                        onkeydown={(e) => handleArrow(e)}
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-4 px-3 py-4 drop-shadow-md">
                      <div className=" bg-white pb-5 rounded-lg">
                        <div className="flex px-4 pt-5 font-bold  mb-4 text-[#8C8C8C]">
                          Front
                        </div>
                        <div className="grid gap-6 grid-cols-7 ps-3">
                          <RecallCardTextEditor
                            className="col-span-5"
                            name="question_text"
                            control={control}
                            inputRef="question_text"
                            label="Questions"
                            register={register}
                            values={""}
                          />

                          <div className="flex w-full items-center col-span-2 flex-row gap-6">
                            <UploadFileComponents
                              onClick={() => setValue("question_image", "")}
                              label={
                                typeof formValues?.question_image ===
                                  "string" && formValues?.question_image
                                  ? formValues?.question_image.split("/")[4]
                                  : "Add image Background"
                              }
                              id="question_image"
                              control={control}
                              inputRef="question_image"
                              value={
                                formValues?.question_image
                                  ? formValues?.question_image
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" bg-white pb-5 rounded-lg">
                        <div className="flex px-4 pt-5 font-bold  mb-4 text-[#8C8C8C]">
                          Back
                        </div>
                        <div className="grid gap-6 grid-cols-7 ps-3">
                          <RecallCardTextEditor
                            className="col-span-5"
                            name="Back"
                            control={control}
                            inputRef="answer_text"
                            label="Questions"
                            register={register}
                            values={""}
                          />
                          <div className="flex w-full items-center col-span-2 flex-row gap-6">
                            <UploadFileComponents
                              onClick={() => setValue("answer_image", "")}
                              label={
                                typeof formValues?.answer_image === "string" &&
                                formValues?.answer_image
                                  ? formValues?.answer_image.split("/")[4]
                                  : "Add image Background"
                              }
                              id="answer_image"
                              control={control}
                              inputRef="answer_image"
                              value={
                                formValues?.answer_image
                                  ? formValues?.answer_image
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-3">
              <RecallCardForm
                itemSchema={itemSchema}
                getValues={getValues}
                answerFieldItems={answerLinkItems}
                questionFieldItems={questionLinkItems}
                register={register}
                setValue={setValue}
              />
            </div>
            <div className="col-span-3  border-2 rounded-lg">
              <p className="col-span-3 py-4 border-b font-semibold px-3">
                Tags
              </p>
              <div className="grid grid-cols-1 gap-4 px-3 py-4">
                <MultiSelectComponent
                  control={control}
                  inputRef="tags"
                  isMulti
                  label="Tags"
                  placeHolder="Tags"
                  options={TagList.map((value) => {
                    return {
                      value: value._id,
                      label: value.name,
                    };
                  })}
                />
              </div>
            </div>

            <ButtonComponent
              CTA="Cancel"
              onClick={() => navigate("/contentrepository/recallcard")}
              className="col-start-5"
              buttonType="button"
              varient="outline"
            />
            <ButtonComponent
              loading={loading}
              CTA="Save"
              buttonType="submit"
              varient="blue"
            />
          </form>

          <div></div>
        </div>
      </BoxLayout>
    </>
  );
};

export default AddRecallCardList;

import React from "react";
import { ShotCheckData } from "../../types/apiresponse";
import moment from "moment";

type Props = {
  summaryBox: React.RefObject<HTMLDivElement>;
  check: ShotCheckData;
};

const PopUpTable = ({ summaryBox, check }: Props) => {
  const formattedDate = moment(check.date, "DD MMM YY hh:mm A").format("DD MMM YY hh:mm A");

  console.log("PopUpTable Rendering with Check:", check);
  return (
    <>
    {console.log("Shot Checks Length:", check.shot_checks.length)}
      <div
        ref={summaryBox}
        className="bg-white rounded-lg z-[70] top-0 left-0 absolute shadow-2xl max-h-[520px] w-[370px] overflow-auto"
        
      >
        {
          <>
            <span className=" ">
              {" "}
              <img
                className="bg-[#F58124] p-2 rounded-tl-lg rounded-br-lg"
                src="/images/whitecalander.svg"
                alt=""
              />
            </span>
            <h6 className="font-medium pt-2.5  px-5 text-start text-[#212121] text-[16px]">
              Shot Check
            </h6>
            <h6 className="font-medium px-5 text-start text-[#9e9e9e] text-xs mt-1">
            {formattedDate}
            </h6>
            <table className="table-auto shadow-[0px_4px_30px_0px_rgba(186,186,186,0.12)] mt-6 ml-4 mb-4">
              <thead>
                <tr className="text-[#285A9F]  text-[16px] font-semibold">
                  <th className="p-2">Shot</th>
                  <th className="p-2">Direction</th>
                  <th className="p-2">Curve</th>
                  <th className="p-2">Contact</th>
                  <th className="p-2">Face</th>
                </tr>
              </thead>
              <tbody>
              {check.shot_checks.map((checkShot) => {
              
                if (checkShot.shot_no === 11) {
                  return null;
                }

                return (
                  <tr
                    key={`${checkShot.shot_no}-${checkShot.direction}-${checkShot.curve}`}
                    className="text-md text-[#1F3161]"
                  >
                    <td className="p-2 text-[#285A9F]">{checkShot.shot_no}</td>
                    <td className="p-2">{checkShot.direction}</td>
                    <td className="p-2">{checkShot.curve}</td>
                    <td className="p-2">{checkShot.contact}</td>
                    <td className="p-2">{checkShot.face}</td>
                  </tr>
                );
              })}
              </tbody>
            </table>
          </>
        }
      </div>
    </>
  );
};

export default PopUpTable;

import { useState } from "react";

type Props = {
  answerList: string[];
  setAnswerList: React.Dispatch<React.SetStateAction<string[]>>;
};

function QuestionOptions({ answerList, setAnswerList }: Props) {
  const handleInputChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const values = [...answerList];
    values[index] = event.target.value;
    setAnswerList(values);
  };

  const handleAddInput = () => {
    setAnswerList([...answerList, ""]);
  };

  const handleRemoveInput = (index: number) => {
    const values = [...answerList];
    values.splice(index, 1);
    setAnswerList(values);
  };

  return (
    <div className="border rounded-[12px] ">
      <div className="px-5 py-5 flex gap-5">
        <span className="text-base text-[#212121] font-medium">
          Question Option :
        </span>
        <span className="text-base text-[#9E9E9E]">
          {answerList.length} Answers
        </span>
        <span className="flex justify-center gap-2 items-center">
          <img src="/images/plusButton.svg" alt="" />
          <button
            type="button"
            className="text-base text-[#212121] font-medium"
            onClick={handleAddInput}
          >
            Add Input
          </button>{" "}
        </span>
      </div>
      <div className="grid grid-cols-12  py-5">
        {answerList.map((input, index) => (
          <div
            className="col-span-6  px-5 border grid gap-3 items-center grid-cols-12"
            key={index}
          >
            <p className="col-span-1">{index + 1}.</p>
            <input
              className="col-span-9 py-3 my-3 bg-secondary border rounded-[12px] outline-none px-4"
              type="text"
              value={input}
              placeholder="Answer"
              onChange={(event) => handleInputChange(index, event)}
            />
            <button
              type="button"
              className="col-span-2 m-auto"
              onClick={() => handleRemoveInput(index)}
            >
              <img className="w-6" src="/images/remove.svg" alt="remove" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default QuestionOptions;

import React, { useEffect } from "react";
import Select from "react-select";
import { DropDownStyles } from "../../helper";
import {
  convertToHHMMSS,
  convertToHoursMinutes,
  formatTime,
} from "../../utils/helper";

type Props = {
  index: number;
  select: any;
  objectList: any;
  ObjectList: any;
  formValues: any;
  handleInputChange: any;
  handlePermissionChange: any;
  handleRemoveInput: any;
  moveDrills?: number[];
  setMoveDrills?: React.Dispatch<React.SetStateAction<number[]>>;
};

const ObjectSelectorArray = React.memo(
  ({
    index,
    select,
    objectList,
    ObjectList,
    formValues,
    handleInputChange,
    handlePermissionChange,
    handleRemoveInput,
    moveDrills,
    setMoveDrills,
  }: Props) => {
    console.log(select);

    useEffect(() => {
      console.log(
        objectList.filter((i: any) => i.id === ObjectList[index].object_id),
        "Serker"
      );
    }, [select]);
    console.log(select);
    return (
      <div className="grid grid-cols-12 gap-4 items-center" key={index}>
        <p className="col-span-1 m-auto">{index + 1}.</p>
        <div className="col-span-6">
          <div className="flex flex-col w-full">
            <div className="relative">
              <label
                className={`absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 z-10`}
              >
                Object Name
              </label>
              <Select
                value={{
                  value: select.object_id,
                  label: objectList?.filter(
                    (value: any) => value.id === select.object_id
                  )[0]?.object_name,
                }}
                options={objectList
                  .filter(
                    (state: any) =>
                      state.category_type_id === formValues.categoryType
                  )
                  .map((value: any) => {
                    return {
                      label: value.object_name,
                      value: value.id,
                    };
                  })}
                styles={DropDownStyles}
                placeholder={"Object Name"}
                onChange={(e: any) => {
                  const time = objectList.filter(
                    (i: any) => i.id === ObjectList[index].object_id
                  )[0]?.object_time;
                  handleInputChange(index, e.value, Number(time));
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-span-1">
          <div className="text-xs text-center">Object Reps</div>
          <div className="text-center font-semibold text-sm">
            {objectList?.filter(
              (i: any) => i.id === ObjectList[index].object_id
            )[0]?.reps
              ? objectList?.filter(
                  (i: any) => i.id === ObjectList[index].object_id
                )[0]?.reps
              : 0}
          </div>
        </div>
        <div className="col-span-2 justify-center m-auto flex flex-col gap-1 text-xs">
          <p>Permission</p>
          <div className="flex">
            <div className="flex flex-wrap gap-2 items-center">
              <input
                type="radio"
                onChange={(e) => handlePermissionChange(index, e.target.value)}
                name={`val${index}`}
                defaultChecked={Number(select.permission) === 0}
                value={0}
              />
              <label>{"Required"}</label>
              <input
                type="radio"
                name={`val${index}`}
                value={1}
                defaultChecked={Number(select.permission) === 1}
                onChange={(e) => handlePermissionChange(index, e.target.value)}
              />
              <label>{"Optional"}</label>
            </div>
          </div>
        </div>
        <div className="text-xs col-span-1">
          <div>Time</div>
          <div>
            {formatTime(
              Number(
                objectList.filter(
                  (i: any) => i.id === ObjectList[index].object_id
                )[0]?.object_time
              )
            )}
          </div>
        </div>
        <div className="col-span-2  col-start-12 flex gap-4">
          <span className="text-xs">{}</span>
          <button type="button" onClick={() => handleRemoveInput(index)}>
            <img className="w-6" src="/images/remove.svg" alt="remove" />
          </button>
        </div>
      </div>
    );
  }
);

export default ObjectSelectorArray;

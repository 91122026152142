import React from "react";

type Props = {};

const SelfCheckIcon = (props: Props) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 18 17"
        fill="none"
      >
        <path
          d="M0.5 2.23688L1.84211 3.57899L4.52632 0.894775"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.5 8.50006L1.84211 9.84217L4.52632 7.15796"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M0.5 14.7632L1.84211 16.1054L4.52632 13.4211"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.65771 8.5H17.4998"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.65771 14.7632H17.4998"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.65771 2.23694H17.4998"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default SelfCheckIcon;

import React, { useEffect, useState } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import TableComponent from "../../Components/TableComponent";
import PaginationComponent from "../../Components/PaginationComponent";
import { ObjectListSort } from "../../Redux/features/ObjectSlice";
import { useIsMount } from "../../CustomHooks/useIsMount";
import { clearReports, getReports } from "../../Redux/features/ReportSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import moment from "moment";
import { Status, handleSort } from "../../Redux/features/UserManagementSlice";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import {
  AffiliateListTableHeader,
  ReportPageListTableHeader,
} from "../../utils/TableColumns";
import { manageSorting } from "../../utils/helper";
import EditIcon from "../../Components/Icons/EditIcon";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import { useNavigate } from "react-router-dom";

type Props = {};

const AffiliateList = (props: Props) => {
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState<Report>();
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [file, setFile] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [adminListValue, setAdminListValue] = useState();

  const sortType = useAppSelector((state) => state.Report.sortType);
  const sortValue = useAppSelector((state) => state.Report.sortValue);

  const dispatch = useAppDispatch();

  const report = useAppSelector((state) => state.Report.reports);

  useEffect(() => {
    dispatch(
      getReports({
        sortType: sortType,
        sortValue: sortValue,
        searchValue: adminListValue,
        page: 1,
      })
    );
    return () => {
      dispatch(clearReports());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const loading = useAppSelector((state) => state.Report.getReportsIdle);
  const total = useAppSelector((state) => state.Report.totalPages);
  const active = useAppSelector((state) => state.Report.activePage);

  const handleEditClick = (i: Report) => {
    setDetails(i);
    setTitle(i.title);
    setDescription(i.description);
    setFile(i.file);
    setDate(i.created_at);
    setIsOpen(true);
  };
  const handlePageination = (page: number) => {
    dispatch(
      getReports({
        sortType: sortType,
        sortValue: sortValue,
        searchValue: adminListValue,
        page: page,
      })
    );
  };

  const handleImageError = (e: any) => {
    e.target.src = "/images/user.png"; // Set a fallback image
  };

  const totalPage = useAppSelector((state) => state.Object.totalPages);
  const IsMount = useIsMount();

  useEffect(() => {
    if (!IsMount) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getReports({
            sortType: sortType,
            sortValue: sortValue,
            searchValue: adminListValue,
            page: 1,
          })
        );
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [adminListValue]);

  const getSearchValue = (d: any) => {
    setAdminListValue(d);
  };

  const getSortValue = (d: any) => {
    dispatch(ObjectListSort());
  };
  const navigate = useNavigate();
  return (
    <div>
      <BoxLayout HeaderName="Affiliate" sectionName="AffiliateList">
        <FilterSortPanel Function={() => {}}></FilterSortPanel>
        <TableComponent
          sortType={sortType}
          sortValue={sortValue}
          onClick={manageSorting}
          TableHeaderData={AffiliateListTableHeader}
          loader={loading}
          handleSortAction={handleSort}
        >
          {loading === Status.LOADING ? (
            <TableSkeleton tableColumns={5} />
          ) : (
            <></>
          )}
          <tbody className="">
            {loading === Status.SUCCESS ? (
              report.map((i) => (
                <tr className="border-y border-y-[#EAF0F3]" key={i._id}>
                  <td className="py-1 text-start cursor-pointer">{i.title}</td>
                  <td className="py-1 text-start">{i.type}</td>
                  <td className="py-1 text-start">{i.user_name}</td>

                  <td className="py-1 text-start">
                    {moment(i.created_at).format("DD-MM-YYYY HH:mm")}
                  </td>
                  <td className="py-1 flex gap-3 text-start">
                    <button onClick={() => navigate("/affiliate/details")}>
                      <EditIcon />
                    </button>
                    <button onClick={() => {}}>
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </TableComponent>
        <PaginationComponent
          active={active}
          paginationFunction={handlePageination}
          total={total}
        />
      </BoxLayout>
    </div>
  );
};

export default AffiliateList;

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { submitRepReview } from "../../Redux/features/CoachReviewSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "react-day-picker";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { notifySuccess } from "../../helper";
import InputComponent from "../../Components/Inputs/InputComponent";
import UploadFileComponents from "../../Components/Inputs/UploadFileComponents";
import { Cookies, useCookies } from "react-cookie";

type Props = {
  register: any;
  control: any;
  handleSubmit: any;
  formValues: any;
  reset: any;
  repstatus: string;
  setValue: any;
};

const RepReview = ({
  register,
  reset,
  control,
  handleSubmit,
  formValues,
  repstatus,
  setValue,
}: Props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  useEffect(() => {
    if (cookie.ic === "1") {
      if (repstatus === "Pending") {
        let formData = new FormData();
        formData.append("description", "");
        formData.append("status", "In Progress");
        formData.append("swing_id", location?.state._id);
        // formData.append("video", params?.videoForRep[0]);
        dispatch(submitRepReview(formData));
      }
    }
  }, []);
  const navigate = useNavigate();
  const submitFun = async (params: any) => {
    if (cookie.ic === "1") {
      let formData = new FormData();
      formData.append("description", "");
      formData.append("status", "Completed");
      formData.append("swing_id", location?.state._id);

      if (formValues?.vimeo_link && formValues?.vimeo_link !== "") {
        formData.append("vimeo_link", formValues?.vimeo_link);
      } else {
        formData.append("video", params?.videoForRep[0]);
      }

      dispatch(submitRepReview(formData)).then((res) => {
        if (res.payload.success) {
          notifySuccess(res.payload.message);
          navigate("/coachreview/list");
        }
      });
    }
  };

  const [cookie] = useCookies(["ic"]);
  const [fileType, setFileType] = useState(false);
  const handleChange = (event: any) => {
    if (event?.target?.value === "true") {
      setFileType(true);
      setValue("vimeo_link", "");
    } else {
      setFileType(false);
      setValue("videoForRep", "");
    }
  };

  const loading = useAppSelector((state) => state.Coach.submitRepReviewIdle);
  console.log(formValues?.videoForRep, "formValues");
  return (
    <form
      onSubmit={handleSubmit(submitFun)}
      className="flex h-full justify-between flex-col gap-3 font-medium"
    >
      {/* <div className="flex gap-2 col-span-1 md:col-span-3">
        <input
          id={"videoForRep"}
          name="videoForRep"
          type="file"
          className="hidden"
          {...register("videoForRep")}
        />
        <label
          htmlFor="videoForRep"
          className="py-4 border rounded-md w-[190px] md:max-w-[300px] lg:w-[450px] whitespace-nowrap text-ellipsis overflow-hidden "
        >
          <>
            <>
              {typeof formValues?.videoForRep === "object" &&
              formValues?.videoForRep[0]?.name ? (
                formValues?.videoForRep[0]?.name
              ) : (
                <>
                  {formValues?.videoForRep
                    ? formValues?.videoForRep
                    : location?.state?.coach_review.vimeo_link
                    ? location?.state?.coach_review.vimeo_link
                    : "Upload File"}
                </>
              )}
            </>
          </>
        </label>

        <img
          onClick={() => reset()}
          className="w-6"
          src="/images/remove.svg"
          alt=""
        />
      </div> */}

      <div className="flex flex-col  items-start gap-4">
        <div className="font-semibold text-[#3c4c76] font-blue-950 text-sm col-span-2 flex gap-3">
          <div className="">
            <label className="flex font-semibold text-[#3c4c76] font-blue-950 text-sm items-center gap-2">
              Upload Video
              <input
                type="radio"
                name="toggle"
                value="true"
                checked={fileType}
                onChange={handleChange}
                disabled={cookie.ic !== "1"}
              />
            </label>
          </div>
          <div>
            <label className="flex font-semibold text-[#3c4c76] font-blue-950 text-sm items-center gap-2">
              Add Link
              <input
                disabled={cookie.ic !== "1"}
                type="radio"
                name="toggle"
                value="false"
                checked={!fileType}
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
        {fileType ? (
          <UploadFileComponents
            onClick={() => setValue("videoForRep", "")}
            control={control}
            disabled={cookie.ic !== "1"}
            id="videoForRep"
            inputRef="videoForRep"
            label={
              typeof formValues.videoForRep === "object"
                ? formValues.videoForRep[0]?.name
                : "Upload File"
            }
            value={formValues.videoForRep}
          />
        ) : (
          <>
            <InputComponent
              inputRef="vimeo_link"
              className="col-span-2"
              label="Vimeo Link"
              name="s"
              register={register}
              value=""
              disabled={cookie.ic !== "1"}
            />
          </>
        )}
      </div>
      {cookie.ic === "1" && (
        <div className="lg:w-[20%] flex justify-end">
          <ButtonComponent
            CTA="Submit"
            buttonType="submit"
            loading={loading}
            varient="blue"
            className="col-span-1 flex"
          />
        </div>
      )}
    </form>
  );
};

export default RepReview;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";

type UnitPayloadType = {
  searchValue?: string;
  sortType?: sortValues;
  sortValue?: string;
  page?: number;
  per_page?: number;
};

export const unitListAPI = createAsyncThunk<
  UnitAPIListResponse,
  UnitPayloadType
>("unit/SessionAPI", async ({ per_page, ...UnitPayload }) => {
  const res = await axiosClient.get(
    `auth/unit?${
      UnitPayload.searchValue ? `&filteredVal=${UnitPayload.searchValue}` : ""
    }${
      UnitPayload.sortValue !== ""
        ? UnitPayload.sortType === "ascending"
          ? `&sort=-${UnitPayload.sortValue}`
          : ``
        : ""
    }${
      UnitPayload.sortValue !== ""
        ? UnitPayload.sortType === "descending"
          ? `&sort=-${UnitPayload.sortValue}`
          : ``
        : ""
    }${
      UnitPayload.page
        ? UnitPayload.page
          ? `&page=${UnitPayload.page}`
          : ``
        : ""
    }${per_page ? `&per_page=${per_page}` : ``}`
  );
  return {
    data: res.data.data,
    page: UnitPayload.page || 1,
  };
});

export const UnitDeleteAPI = createAsyncThunk<UnitDeleteAPIResponse, string>(
  "unit/delete",
  async (id) => {
    const res = await axiosClient.delete(`auth/unit/${id}`);
    return res.data;
  }
);

export const AddUnitAPI = createAsyncThunk<
  UnitPostAPIResponse,
  UnitAPIRequest,
  {}
>("unit/add", async (data) => {
  const res = await axiosClient.post("auth/unit", data);
  return res.data;
});

interface initialStateType {
  unitList: UnitItem[];
  sortType: sortValues;
  sortValue: string;
  unitListAPIIdle: Status;
  UnitDeleteAPIIdle: Status;
  UnitPostAPIIdle: Status;
  count: number;
  total: number;
  active: number;
}

const initialState: initialStateType = {
  unitList: [],
  sortType: "ascending",
  sortValue: "",
  unitListAPIIdle: Status.SUCCESS,
  UnitDeleteAPIIdle: Status.SUCCESS,
  UnitPostAPIIdle: Status.SUCCESS,
  count: 0,
  total: 0,
  active: 1,
};

const UnitSlice = createSlice({
  name: "unit",
  initialState,
  reducers: {
    unitListSort: (state) => {
      return {
        ...state,
        sortType: state.sortType === "ascending" ? "descending" : "ascending",
      };
    },
    handleSort: (state, action) => {
      return {
        ...state,
        sortType: action.payload.sortType,
        sortValue: action.payload.sortValue,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(unitListAPI.pending, (state, action) => {
        return {
          ...state,
          unitListAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        unitListAPI.fulfilled,
        (state, action: PayloadAction<UnitAPIListResponse>) => {
          return {
            ...state,
            unitListAPIIdle: Status.SUCCESS,
            unitList: action.payload.data.units,
            active: action.payload.page,
            total: action.payload.data.total_page,
          };
        }
      )
      .addCase(unitListAPI.rejected, (state, action) => {
        return {
          ...state,
          unitListAPIIdle: Status.ERROR,
        };
      })
      .addCase(UnitDeleteAPI.pending, (state, action) => {
        return {
          ...state,
          UnitDeleteAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        UnitDeleteAPI.fulfilled,
        (state, action: PayloadAction<UnitDeleteAPIResponse>) => {
          return {
            ...state,
            UnitDeleteAPIIdle: Status.SUCCESS,
          };
        }
      )
      .addCase(UnitDeleteAPI.rejected, (state, action) => {
        return {
          ...state,
          UnitDeleteAPIIdle: Status.ERROR,
        };
      })
      .addCase(AddUnitAPI.pending, (state, action) => {
        return {
          ...state,
          UnitPostAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        AddUnitAPI.fulfilled,
        (state, action: PayloadAction<UnitPostAPIResponse>) => {
          return {
            ...state,
            UnitPostAPIIdle: Status.SUCCESS,
          };
        }
      )
      .addCase(AddUnitAPI.rejected, (state, action) => {
        return {
          ...state,
          UnitPostAPIIdle: Status.ERROR,
        };
      });
  },
});

export const { unitListSort, handleSort } = UnitSlice.actions;

export default UnitSlice.reducer;

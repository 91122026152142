import React from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import InputComponent from "../../Components/Inputs/InputComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import UploadFileComponents from "../../Components/Inputs/UploadFileComponents";
import {
  getRecallCards,
} from "../../Redux/features/RecallCardsSlice";
import moment from "moment";
import { postTrophiesListAPI } from "../../Redux/features/TrophiesSlice";

type Props = {
  setAddFormModal: React.Dispatch<React.SetStateAction<boolean>>;
  name?: string;
  trophie_back?: FileList | string;
  trophie_front?: FileList | string;
  id?: string;
  Heading: string;
  description?: string;
  trophie_type?: string | string;
};

type FormValues = {
  trophie_type: string;
  name: string;
  trophie_back: FileList | string;
  trophie_front: FileList | string;
  description: string;
};

const AddTrophyForm = ({
  setAddFormModal,
  trophie_type = "",
  name = "",
  trophie_back = "",
  trophie_front = "",
  id = "",
  Heading,
  description,
}: Props) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Subscription Type is required"),
    description: Yup.string().required("Subscription Type is required"),
    trophie_type: Yup.string().required("Subscription Type is required"),
  });

  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      trophie_type: trophie_type,
      name: name,
      description: description,
      trophie_back: trophie_back,
      trophie_front: trophie_front,
    },
  });

  const dispatch = useAppDispatch();

  const formValues = watch();
  const handleAddCard = (data: FormValues) => {
    let formData = new FormData();
    let date: string = moment().format("YYYY-MM-DD");
    formData.append("name", data.name);
    formData.append("trophie_type", data.trophie_type);
    formData.append("description", data.description);
    formData.append("received_date", date);
    if (id !== "") {
      formData.append("id", id);
    }
    if (typeof data.trophie_back === "object") {
      formData.append("trophie_back", data.trophie_back[0]);
    }
    if (typeof data.trophie_front === "object") {
      formData.append("trophie_front", data.trophie_front[0]);
    }
    dispatch(postTrophiesListAPI(formData)).then(() => {
      dispatch(getRecallCards({}));
      setAddFormModal(false);
    });
  };
  const loading = useAppSelector(
    (state) => state.RecallCards.postRecallCardsAPIIdle
  );
  return (
    <div className="p-4 flex flex-col  h-full">
      <h2 className="text-xl font-bold mb-4">{Heading}</h2>
      <form onSubmit={handleSubmit(handleAddCard)} className="mb-4 h-full">
        <div className="mt-auto flex flex-col h-full w-full justify-between">
          <div className="flex flex-col gap-3 ">
            <InputComponent
              // errorname={errors.name?.message}
              inputRef="name"
              label="Trophy Name"
              name="Trophy Name"
              register={register}
              value={formValues.name}
            />
            <InputComponent
              // errorname={errors.trophie_type?.message}
              inputRef="trophie_type"
              label="Trophy Group"
              name="Answer Text"
              register={register}
              value={formValues.trophie_type}
            />
            <InputComponent
              // errorname={errors.description?.message}
              inputRef="description"
              label="Description Of Trophy"
              name="Answer Text"
              register={register}
              value={formValues.description}
            />
            <UploadFileComponents
              label="Upload Trophy Image Front"
              id="trophie_front"
              control={control}
              inputRef="trophie_front"
              value={formValues.trophie_front}
            />
            <UploadFileComponents
              label="Upload Trophy Image Back"
              id="trophie_back"
              control={control}
              inputRef="trophie_back"
              value={formValues.trophie_back}
            />
          </div>
          <div className="flex gap-5 rounded-none col-end-3 ">
            <ButtonComponent
              CTA="Cancel"
              buttonType="button"
              varient="outline"
              onClick={() => setAddFormModal(false)}
            />
            <ButtonComponent
              CTA="Save"
              buttonType="submit"
              loading={loading}
              varient="blue"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddTrophyForm;

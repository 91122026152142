import Select from "react-select";
import { DropDownStyles } from "../../helper";
import { Controller } from "react-hook-form";
import { useAppDispatch } from "../../Redux/app/hooks";
import { useId } from "react";

type Props = {
  asterisk?: boolean;
  placeHolder?: string;
  label?: string;
  options?: any[];
  setValue?: Function;
  control: any;
  inputRef: string;
  isDisabled?: boolean;
  className?: string;
  selectclassName?: string;
  errorname?: string;
  isMulti?: boolean;
};
const MultiSelectComponent = ({
  placeHolder,
  asterisk,
  options,
  isDisabled = false,
  setValue = () => { },
  control,
  inputRef,
  className = "",
  selectclassName = "",
  label = "",
  errorname = "",
}: // isMulti = false,
  Props) => {
  const dispatch = useAppDispatch();
  const uid = useId()
  return (
    <div className="flex flex-col w-full">
      <Controller
        name={inputRef}
        control={control}
        render={({ field }) => {
          return (
            <div className="relative">
              <label
                className={`absolute left-4 -top-2 bg-gradient-to-b text-xs 
                font-medium text-[#9E9E9E]  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 z-10${className}`}
                htmlFor={inputRef}
              >
                {label}
              </label>
              <Select
                className={`${selectclassName}`}
                options={options}
                isMulti={true}
                styles={DropDownStyles}
                placeholder={placeHolder}
                formatOptionLabel={function (data: any) {
                  return (
                    <span key={uid} dangerouslySetInnerHTML={{ __html: data.label }} />
                  );
                }}
                // value={value}
                // value={options?.filter((i) => i.value === value)}
                {...field}
                isDisabled={isDisabled}
              />
            </div>
          );
        }}
        rules={{ required: true }}
      />
      {errorname !== "" && (
        <span className="text-red-500 text-sm px-2 text-left">{errorname}</span>
      )}
    </div>
  );
};

export default MultiSelectComponent;

import { useEffect } from "react";
import LoginForm from "../../Containers/Auth/LoginForm";

type Props = {};

const LoginPage = (props: Props) => {
  useEffect(() => {
    document.title = "FISIO | Login to FISIO";
  }, []);

  return (
    <div className="box-border h-screen   bg-secondary">
      <div className="grid grid-cols-1 lg:grid-cols-2 w-full h-screen  justify-between">
        <div className="col-span-1">
          <div className="flex justify-start p-6 ">
            <img src="/images/logo.svg" className="h-[40px]" alt="" />
          </div>
          <LoginForm />
        </div>
        <div className="col-span-1 hidden lg:flex ">
          <img
            className="object-cover w-full"
            src="/images/background.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

import { useEffect, useState } from "react";
import { Status } from "../../Redux/features/UserManagementSlice";

type Props = {
  CTA: string;
  onClick?: Function;
  varient?: string;
  loading?: Status;
  className?: string;
  buttonType?: "button" | "submit" | "reset";
};

const ButtonComponent = ({
  CTA,
  onClick = () => { },
  varient = "",
  loading = Status.SUCCESS,
  buttonType = "submit",
  className,
}: Props) => {
  const [buttonStyle, setButtonStyle] = useState("");

  useEffect(() => {
    switch (varient) {
      case "blue":
        setButtonStyle("bg-bluegradient text-white");
        break;
      case "orange":
        setButtonStyle(
          "bg-gradient-to-l text-white from-gradientPairs-orangeStart from-[-4.22%] to-gradientPairs-orangeEnd to-[120.23%]"
        );
        break;
      case "white":
        setButtonStyle(
          "bg-gradient-to-l text-[#D84315] border"
        );
        break;
      case "outline":
        setButtonStyle("bg-white border h-[44px] text-black");
        break;
      case "simple":
        setButtonStyle("text-red-700");
        break;
      default:
        setButtonStyle("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varient]);


  const handleRipple = (e: React.MouseEvent<HTMLButtonElement>) => {
    const ripple = document.createElement("span");
    ripple.className = "ripple";
    const rect = e.currentTarget.getBoundingClientRect();
    const size = Math.max(rect.width, rect.height);
    const x = e.clientX - rect.left - size / 2;
    const y = e.clientY - rect.top - size / 2;
    ripple.style.width = ripple.style.height = `${size}px`;
    ripple.style.left = `${x}px`;
    ripple.style.top = `${y}px`;
    e.currentTarget.appendChild(ripple);
    setTimeout(() => {
      ripple.remove();
    }, 400);
  };

  return (
    <>
      {buttonStyle !== "" && (
        <div className={`w-full h-[44px] relative rounded-[4px]  ${className}`}>
          <button
            type={buttonType}
            onClick={(e) => {
              onClick()
            }}
            disabled={loading === Status.LOADING}
            className={`h-[44px]  w-full rounded-[4px]  ${buttonStyle} active:scale-95 active:shadow-md transition-transform`}
          >
            {loading !== Status.LOADING ? (
              CTA
            ) : (
              <img className="m-auto" src="/images/loader.gif" alt="loader" />
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default ButtonComponent;

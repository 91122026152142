// Admin Coach
import CoachDashboard from "../../Pages/Coach/CoachDashboard";
import CoachReviewPage from "../../Pages/Coach/CoachListPage";

// Coach Review
import TicketReviewList from "../../Pages/Coach/TicketReviewList";

// Layouts
import AdminRoutes from "../../auth/AdminRoutes";
import CoachRoutes from "../../auth/CoachRoutes";

export const coachRoutes = [
  {
    id: "coach3",
    name: "Tickets",
    module: "Tickets",
    path: "/coachreview/tickets/:ticketid",
    component: TicketReviewList,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Coach Review",
        path: "/coachreview/tickets",
      },
      {
        name: "Coach Review Detail",
        path: "#",
      },
    ],
  },
  {
    id: "coach1",
    name: "Tickets",
    module: "Tickets",
    path: "/coachreview/tickets",
    component: CoachDashboard,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Coach Review",
        path: "/coachreview/tickets",
      },
      {
        name: "Coach Review List",
        path: "#",
      },
    ],
  },

  {
    id: "coach2",
    name: "Coaches",
    module: "Coaches",
    path: "/coachreview/coaches",
    component: CoachReviewPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Coach Review",
        path: "/coachreview/tickets",
      },
      {
        name: "Coaches List",
        path: "#",
      },
    ],
  },

  {
    id: "coach5",
    name: "Coach",
    module: "Coach Review",
    // path: "/coachreview/swingcheck",
    path: "/coachreview/list",
    component: CoachDashboard,
    auth: CoachRoutes,
    breadCrumbRoutes: [
      {
        name: "Coach Review",
        path: "#",
      },
    ],
  },
  {
    id: "coach6",
    name: "Coach",
    module: "Review Details",
    path: "/coachreview/list/details",
    component: TicketReviewList,
    auth: CoachRoutes,
    breadCrumbRoutes: [
      {
        name: "Coach Review",
        path: "/coachreview/list",
      },
      {
        name: "Coach Review Detail",
        path: "#",
      },
    ],
  },
];

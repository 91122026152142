import AppControl from "../../Pages/AppControl/AppControl";
import ForgetPasswordPage from "../../Pages/Auth/ForgetPasswordPage";
import LoginPage from "../../Pages/Auth/LoginPage";
import ResetPasswordPage from "../../Pages/Auth/ResetPasswordPage";
import AdminRoutes from "../../auth/AdminRoutes";
import OpenRoutes from "../../auth/OpenRoutes";
import PublicRoutes from "../../auth/PublicRoutes";

export const appControlRoutes = [
  {
    id: "ac1",
    path: "/appcontrol",
    name: "App Control",
    module: "AppControl",
    component: AppControl,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "App Control",
        path: "#",
      },
    ],
  },
];

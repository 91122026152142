import React, { useEffect, useState } from "react";
import AssignGroupID from "../../../AssignGroupID";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/app/hooks";
import moment from "moment";
import {
  categorizeDate,
  exportToExcel,
  formatUserData,
} from "../../../../../utils/helper";
import ExcelIcon from "../../../../../Components/Icons/ExcelIcon";
import {
  getUserAnalytics,
  UserResetPassword,
  UserSendResetPasswordLink,
} from "../../../../../Redux/features/UserDashboardSlice";
import { Status } from "../../../../../Redux/features/UserManagementSlice";
import ButtonComponent from "../../../../../Components/Buttons/ButtonComponent";
import Sidedrawer from "../../../../../Components/SideDrawer";
import InputComponent from "../../../../../Components/Inputs/InputComponent";
import { useForm } from "react-hook-form";
import { notificationresponse } from "../../../../../types/apiresponse";
import { notifyError, notifySuccess } from "../../../../../helper";
import mailsend from "../../../../../assets/mailsendIcon.svg";
import resetPass from "../../../../../assets/resetpassword.svg";
import Tooltip from "../../../../../Components/ToolTipComponent/ToolTipComponent";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

type Props = {
  fullName: string;
  groupID: string;
  imgSrc: string;
  registrationDate: string;
};

type resetPassword = {
  newpassword: string;
  comfirmpass: string;
};
const validationSchema = Yup.object().shape({
  newpassword: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  comfirmpass: Yup.string()
    .required("Confirm Password is required")
    .min(6, "Confirm Password must be at least 6 characters"),
});
const ProfileBox = ({ fullName, groupID, imgSrc, registrationDate }: Props) => {
  const { id } = useParams();
  const [showAddForm, setAddFormModal] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<resetPassword>({
    resolver: yupResolver(validationSchema),
    defaultValues: { newpassword: "", comfirmpass: "" },
  });
  const formValues = watch();
  const userdashboard = useAppSelector(
    (state) => state?.UserDashboard?.dashBoardData
  );

  useEffect(() => {
    const errorMsg = Object.values(errors).map((item) => item.message);
    errorMsg.slice(0, 1).forEach((errorMessage?: string) => {
      notifyError(errorMessage ?? "");
    });
  }, [errors]);

  const dispatch = useAppDispatch();
  const downloadExcell = () => {
    if (id) {
      dispatch(getUserAnalytics(id)).then((res) => {
        if (getUserAnalytics.fulfilled.match(res)) {
          const value = formatUserData(res.payload.data);
          exportToExcel(
            [
              {
                Name: value.Name,
                Program: value.Program,
                "Available Time": value.availableTime,
                "First Object": value.first_object,
                ...value,
              },
            ],
            value?.Name ? value.Name : "File",
            [
              "Name",
              "Importance",
              "Available Time",
              "Program",
              "Reg Date",
              "First Object",
              ...Array.from({ length: 90 }, (_, i) => String(i + 1)),
            ]
          );
        }
      });
    }
  };

  const getUserAnalyticsAPI = useAppSelector(
    (state) => state.UserDashboard.getUserProgressTrackingAPIStatus
  );

  const onSubmit = (data: resetPassword) => {
    if (id) {
      dispatch(
        UserResetPassword({
          confirm_password: data.comfirmpass,
          password: data.newpassword,
          user_id: id,
        })
      )
        .then((res) => {
          const data = res.payload as notificationresponse;
          if (data && data.success) {
            reset();
            notifySuccess(data.message);
            setAddFormModal(false);
          } else {
            notifyError(data.message);
          }
        })
        .catch((err) => {
          notifyError(err);
        });
    }
  };

  const handleSendrestetpasswordLink = () => {
    if (id) {
      dispatch(
        UserSendResetPasswordLink({
          user_id: id,
        })
      )
        .then((res) => {
          const data = res.payload as notificationresponse;
          if (data && data.success) {
            reset();
            notifySuccess(data.message);
            setAddFormModal(false);
          } else {
            notifyError(data.message);
          }
        })
        .catch((err) => {
          notifyError(err);
        });
    }
  };
  const loading = useAppSelector(
    (state) => state.UserDashboard.getUserResetPasswordStatus
  );
  return (
    <>
      <div className="flex flex-row items-center">
        {groupID !== "" && (
          <AssignGroupID handleClose={() => {}} userID={id} groupID={groupID} />
        )}
        {getUserAnalyticsAPI === Status.IDLE ||
        getUserAnalyticsAPI === Status.SUCCESS ? (
          <div
            role="button"
            onClick={downloadExcell}
            className=" cursor-pointer pr-4"
          >
            <ExcelIcon />
          </div>
        ) : (
          <>
            <img className="m-auto" src="/images/loader.gif" alt="loader" />
          </>
        )}
      </div>

      <div className="relative h-fit pt-3 rounded-[12px] text-center flex flex-col gap-2 mb-6">
        <div className="rounded-full m-auto">
          <img
            src={imgSrc !== "" ? imgSrc : "/images/avatar.jpg"}
            className="object-cover w-20 h-20 bg-[#FC8A2E] rounded-full"
            alt=""
          />
        </div>
        <h3 className="text-[#908b83] font-bold">{fullName}</h3>
        <div className="text-[#9E9E9E] text-[14px]">
          <span className="w-2 h-2 mx-1 rounded-full inline-block bg-[#FFCC91]"></span>{" "}
          {categorizeDate(userdashboard.user.last_login_at)}
          <p className="m-auto pl-[15px] pr-[15px] py-[3px] text-xs px-4 w-56 border-b-[2px] pb-7 border-[#FFF5E5]">
            {moment(registrationDate).format("YYYY.MM.DD")}
          </p>
        </div>
        <div className="w-full flex justify-center gap-2 items-center px-5">
          <Tooltip text="Send/reset email" className=" text-[12px]">
            <button
              className="px-2 py-2 rounded-full bg-orangegradient"
              onClick={handleSendrestetpasswordLink}
            >
              <img src={mailsend} alt="" className="w-[20px] h-[20px]" />
            </button>
          </Tooltip>
          <Tooltip text="Reset password" className=" text-[12px]">
            <button
              className="px-2 py-2 rounded-full bg-orangegradient"
              onClick={() => setAddFormModal(true)}
            >
              <img src={resetPass} alt="" className="w-[20px] h-[20px]" />
            </button>
          </Tooltip>
        </div>
        {showAddForm && (
          <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
            <Sidedrawer onClose={setAddFormModal}>
              <div className="p-4 flex flex-col h-full ">
                <h2 className="text-xl font-bold mb-4">Reset Password</h2>
                <form onSubmit={handleSubmit(onSubmit)} className=" mb-4">
                  <div className="flex flex-col justify-between h-screen">
                    <div className="flex flex-col  gap-3 justify-between">
                      <InputComponent
                        // errorname={errors.email?.message}
                        inputRef="newpassword"
                        label="Password"
                        name="newpassword"
                        register={register}
                        value={formValues.newpassword}
                        type="password"
                      />
                      <InputComponent
                        // errorname={errors.password?.message}
                        inputRef="comfirmpass"
                        label="Confirm Password"
                        name="comfirmpass"
                        register={register}
                        value={formValues.comfirmpass}
                        type="password"
                      />

                      <div className="flex h-[150px] gap-4">
                        <ButtonComponent
                          CTA="Save"
                          loading={loading}
                          buttonType="submit"
                          varient="blue"
                        />
                        <ButtonComponent
                          CTA="Cancel"
                          onClick={() => setAddFormModal(false)}
                          buttonType="button"
                          varient="outline"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </Sidedrawer>
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileBox;

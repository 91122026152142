import TagListPage from "../../Pages/TagPage";
import AdminRoutes from "../../auth/AdminRoutes";

export const tagRoutes = [
  {
    id: "21",
    name: "Tags List ",
    module: "Tags",
    path: "/TagsRepository/Tags",
    component: TagListPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Tags",
        path: "#",
      },
      {
        name: "Tags List",
        path: "#",
      },
    ],
  },
];

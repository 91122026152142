import { colors } from "react-select/dist/declarations/src/theme";
import { PieChart, Pie, Cell, Legend, ResponsiveContainer } from "recharts";
import { useAppSelector } from "../../Redux/app/hooks";

type Props = {
  data: Array<{ name: string; value: number }>;
  className: string;
  colors?: string[];
  checked?: boolean;
};

// const COLORS = ['#FFCC91', '#97A5EB', '#5570F1'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const UserPieChartComponent = ({
  data,
  className,
  checked,
  colors = ["#FFCC91", "#97A5EB", "#5570F1"],
}: Props) => {
  const showSideBar = useAppSelector((state) => state.UIController.showSideBar);
  return (
    <div className={`relative ${className} m-auto `}>
      <ResponsiveContainer width={"100%"} className={""} height="100%">
        <PieChart style={{ display: "flex", width: "100%" }} height={150}>
          {/* <Legend style={{ display: "flex", width: "20%", alignItems: "flex-start", textAlign: "initial" }} wrapperStyle={{ display: "flex", textAlign: "initial", fontSize: "12px", flexDirection: "column", position: "unset", width: "50%", height: "100%", margin: "auto" }} verticalAlign="middle" height={36} /> */}
          <Pie
            data={data}
            cx={"50 %"}
            cy={"50 %"}
            // labelLine={false}
            // label={renderCustomizedLabel}
            innerRadius={showSideBar && !checked ? 35 : 40}
            outerRadius={showSideBar && !checked ? 55 : 60}
            fill="#8884d8"
            paddingAngle={0}
            stroke="#EEF0FA"
            strokeLinejoin="bevel"
            strokeLinecap={"round"}
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                style={{ outline: "none" }}
                stroke="#EEF0FA"
                strokeWidth={5}
                strokeLinecap="round"
                strokeDashoffset={0}
                fill={colors[index % colors.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      {/* <p className='text-3xl left-[26%] top-[39%] font-semibold absolute'>50%</p> */}
    </div>
  );
};

export default UserPieChartComponent;

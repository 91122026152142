import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputComponent from "../../Components/Inputs/InputComponent";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  getQuoteAPI,
  getTrustKeyAPI,
  postQuoteAPI,
  postTrustKeyAPI,
} from "../../Redux/features/TrustShotCheckSlice";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextEditor from "../../Components/Inputs/TextEditor";
import moment from "moment";
import { Status } from "../../Redux/features/UserManagementSlice";
import { notifySuccess } from "../../helper";

type Props = {};

interface TrustkeyForm {
  trustKey: string;
}

const AddTrustKey = (props: Props) => {
  const trustKey = useAppSelector((state) => state.TrustShotCheck.trustKey);

  const validationSchema = Yup.object().shape({
    trustKey: Yup.string().required("Trust Key Required"),
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<TrustkeyForm>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (trustKey.length) {
      setValue("trustKey", trustKey[0]?.trust_key);
    }
  }, [trustKey]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getTrustKeyAPI());
  }, []);

  const [loading, setLoading] = useState<Status>(Status.IDLE);
  const OnSubmit = (data: any) => {
    setLoading(Status.LOADING);
    dispatch(
      postTrustKeyAPI({
        id: trustKey[0]?._id,
        trust_key: data.trustKey,
        date: String(moment().format("DD-MM-yyyy")),
      })
    ).then((res) => {
      if (res.payload.success) {
        notifySuccess("Text saved successfully");
        setLoading(Status.SUCCESS);
      }
      setLoading(Status.ERROR);
    });
  };

  return (
    <div className="mx-6 gap-3 rounded-2xl border m-2 ">
      <div className="py-2 col-span-12 font-semibold border-b p-4">
        Edit Trust Key
      </div>
      <form
        onSubmit={handleSubmit(OnSubmit)}
        className="grid grid-cols-2 gap-3 px-4 py-2"
      >
        <InputComponent
          inputRef="trustKey"
          name="trustKey"
          register={register}
          label="Trust Key"
          value=""
        />
        <ButtonComponent
          buttonType="submit"
          loading={loading}
          CTA="Save"
          varient="blue"
          className="max-w-[120px]   "
        />
      </form>
    </div>
  );
};

export default AddTrustKey;

import TableComponent from "../../../../Components/TableComponent";
import { Status } from "../../../../Redux/features/UserManagementSlice";
import { SubscriptionTableHeader } from "../../../../utils/TableColumns";
import SearchBoxComponent from "../../../../Components/Inputs/SearchBoxComponent";
import ExcelIcon from "../../../../Components/Icons/ExcelIcon";
import BoxLayout from "../../../../Containers/Layout/BoxLayout";
import BoxSection from "../../../../Components/BoxSection/BoxSection";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import { useEffect, useState } from "react";
import {
  billingListHandleSort,
  getPaymentMethods,
  getSubscription,
} from "../../../../Redux/features/UserDashboardSlice";
import { useParams } from "react-router-dom";
import { manageSorting } from "../../../../utils/helper";
import TableSkeleton from "../../../../Skeleton/TableSkeleton";

type Props = {};

const Subscription = (props: Props) => {
  const billingHistory = useAppSelector(
    (state) => state.UserDashboard.billingData.billing_history
  );
  const listLoading = useAppSelector(
    (state) => state.UserDashboard.getSubscriptionAPIStatus
  );
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const sortType = useAppSelector(
    (state) => state.UserDashboard.billingSortType
  );
  const sortValue = useAppSelector(
    (state) => state.UserDashboard.billingSortValue
  );

  useEffect(() => {
    if (id) {
      dispatch(
        getSubscription({ id: id, sortType: sortType, sortValue: sortValue })
      );
      dispatch(getPaymentMethods(id));
    }
  }, [sortType]);

  const [billingListValue, setbillingListValue] = useState("");

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (id) {
        dispatch(
          getSubscription({
            id: id,
            sortType: sortType,
            sortValue: sortValue,
            filteredValue: billingListValue,
          })
        );
      }
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingListValue]);

  const getSearchValue = (d: string) => {
    setbillingListValue(d);
    // dispatch(UserListAPI({ page: 1, userType: "admin", searchValue: d }));
  };

  const paymentmethods = useAppSelector(
    (state) => state.UserDashboard.paymentmethods
  );
  const userData = useAppSelector(
    (state) => state.UserDashboard.dashBoardData.user.active_plan_name
  );
  return (
    <>
      <BoxLayout HeaderName="User Profile" sectionName="">
        <div className="grid gap-4 p-4 grid-cols-1">
          <div className="grid grid-cols-1 gap-2">
            <div className="flex gap-4 px-4 py-2  items-center justify-between">
              <div className="font-semibold">
                Subscription History
                <span
                  className={classNames("mx-3 rounded-full px-2 py-1 text-xs", {
                    "bg-[#5570F1] text-white": userData === "Ultra",
                    "bg-[#97A5EB] text-white": userData === "Premium",
                    "bg-[#F9B884] text-white": userData === "Basic",
                    "bg-[#FFF5E5] text-[#F58124]": userData === "7-Day Trial",
                  })}
                >
                  {userData}
                </span>
              </div>
              <div className="flex gap-3 items-center justify-center">
                <SearchBoxComponent getSearchValue={getSearchValue} />
                <ExcelIcon />
              </div>
            </div>
          </div>
          <BoxSection headerSection={<></>} name="Payment Methods">
            <>
              {paymentmethods.length ? (
                <>
                  {paymentmethods.map((method) => (
                    <div className="flex justify-between items-center">
                      <div className="flex items-center">
                        {method.brand === "mastercard" ? (
                          <img src="/images/mastercard.png" alt="" />
                        ) : (
                          <img src="/images/visa.png" alt="" />
                        )}
                        <div className="flex flex-col">
                          <div className="capitalize text-[#212121] text-sm">
                            {method.brand}
                          </div>
                          <div className="text-[#9E9E9E] text-xs">
                            Ending in XXXX XXXX XXXX {method.last4}
                          </div>
                        </div>
                      </div>
                      {method.is_default ? (
                        <div className="px-3 py-2  text-sm text-[#F58124] rounded-[100px] bg-[#FFF3E8]">
                          Default
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
              {!paymentmethods.length && (
                <div className="h-36 m-auto flex justify-center flex-col items-center">
                  <p className="text-[#9e9e9e] font-medium text-center">
                    User has not yet started the Fisio Foundation plan
                  </p>
                  <p className="text-[#9e9e9e] text-center">
                    You can see payment methods only after the user save the
                    payment methods
                  </p>
                </div>
              )}
            </>
          </BoxSection>
          <BoxSection
            defaultPadding={false}
            name="Billing History"
            headerSection={<></>}
          >
            <div className="px-2">
              <TableComponent
                headerPadding="py-3"
                TableClassName=""
                onClick={manageSorting}
                sortType={sortType}
                sortValue={sortValue}
                handleSortAction={billingListHandleSort}
                loader={Status.SUCCESS}
                TableHeaderData={SubscriptionTableHeader}
                sortIcon
                staticHeight
              >
                {listLoading === Status.SUCCESS && (
                  <tbody>
                    {billingHistory.map((i, index) => (
                      <tr
                        className="cursor-pointer text-sm border-t  text-[#212121] border-t-[#EAF0F3] py-1"
                        key={i._id}
                      >
                        <td className="py-3 text-start font-medium ">
                          {i.transaction_no}
                        </td>
                        <td className=" text-start">
                          {i.plan === "Pro" ? "Ultra" : i.plan}
                        </td>
                        <td className=" text-start">{i.expiry_date}</td>
                        <td className=" text-start">$ {i.amount}</td>
                        <td className="my-2">
                          <span
                            className={classNames(
                              "text-start text-[#00C853] py-1 px-2  rounded-full",
                              {
                                "bg-[#B9F6CA]": index === 0,
                                "bg-[#B9F6CA] opacity-50": index !== 0,
                              }
                            )}
                          >
                            {i.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
                {listLoading === Status.LOADING && (
                  <>
                    <TableSkeleton tableColumns={5} />
                  </>
                )}
              </TableComponent>
              {!billingHistory.length && (
                <div className="h-36 m-auto flex justify-center flex-col items-center">
                  <p className="text-[#9e9e9e] font-medium text-center">
                    User has not yet started the Fisio Foundation plan
                  </p>
                  <p className="text-[#9e9e9e] text-center">
                    You can see payment history only after the user starts the
                    program
                  </p>
                </div>
              )}
            </div>
          </BoxSection>
        </div>
      </BoxLayout>
    </>
  );
};

export default Subscription;

import React, { useRef, useCallback } from "react";
import ObjectSelectorArray from "../../Containers/Session/ObjectSelectorArray";
import { Status } from "../../Redux/features/UserManagementSlice";
import { useAppSelector } from "../../Redux/app/hooks";

// Define the shape of item

// Define the props for DraggableList component
interface DraggableListProps {
  setObjectList: any;
  initialItems: any[];
  ObjectList: any;
  formValues: any;
  handleInputChange: any;
  handleRemoveInput: any;
  handlePermissionChange: any;
  objectList: any;
  moveDrills?: number[];
  setMoveDrills?: React.Dispatch<React.SetStateAction<number[]>>;
}
export const DraggableList: React.FC<DraggableListProps> = ({
  initialItems,
  formValues,
  setObjectList,
  handleInputChange,
  handleRemoveInput,
  handlePermissionChange,
  objectList,
  moveDrills,
  setMoveDrills,
}) => {
  const dragItem = useRef<number | null>(null);

  const handleDragStart = useCallback((index: number) => {
    dragItem.current = index;
  }, []);

  const handleDragEnter = useCallback(
    (index: number) => {
      if (dragItem.current !== null && dragItem.current !== index) {
        const newItems = [...initialItems];
        const removedItem = newItems.splice(dragItem.current, 1)[0];
        newItems.splice(index, 0, removedItem);
        dragItem.current = index;
        setObjectList(newItems);
      }
    },
    [initialItems]
  );

  const handleDragEnd = useCallback(() => {
    dragItem.current = null;
  }, []);

  const loading = useAppSelector((state) => state.Object.objectListAPIIdle);

  const ListItems = () => (
    <>
      {loading !== Status.LOADING ? (
        <>
          {initialItems.map((item, index) => (
            <li
              key={item.id}
              className="flex w-full justify-center items-center"
              style={{ userSelect: "none", cursor: "pointer" }}
            >
              <div className="w-full ">
                <ObjectSelectorArray
                  ObjectList={initialItems}
                  formValues={formValues}
                  handleInputChange={handleInputChange}
                  handlePermissionChange={handlePermissionChange}
                  handleRemoveInput={handleRemoveInput}
                  objectList={objectList}
                  index={index}
                  select={item}
                  moveDrills={moveDrills}
                  setMoveDrills={setMoveDrills}
                />
              </div>
              <div
                draggable
                onDragStart={() => handleDragStart(index)}
                onDragEnter={() => handleDragEnter(index)}
                onDragEnd={handleDragEnd}
                className="flex flex-col h-full py-[10px] mx-10 px-[8px] border  rounded-md  gap-1"
              >
                <span className="block  w-6 h-[2px] bg-orange-600"></span>
                <span className="block  w-6 h-[2px] bg-orange-600"></span>
                <span className="block  w-6 h-[2px] bg-orange-600"></span>
              </div>
            </li>
          ))}
        </>
      ) : (
        <>
          <span className="m-auto loader my-4 w-full flex justify-center"></span>
        </>
      )}
    </>
  );

  return <ul className="gap-3 flex flex-col ">{ListItems()}</ul>;
};

export default DraggableList;

import { useEffect } from "react";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { getWeclomePageInfo } from "../../Redux/features/OnboardingQuestionsSlice";
import WelcomeInfoForm from "./WelcomeSection/WelcomeInfoForm";
import SignInSignUp from "./SignInSignUpSection/SignInSignUp";
import MarketingPageContainer from "./MarketingSection/MarketingPageContainer";
type Props = {};

const WelcomeMarketing = (props: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getWeclomePageInfo());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const Logindata = useAppSelector(
    (state) => state.OnboardingQuestions.getWeclomePageInfo.data?.login
  );
  const Registerdata = useAppSelector(
    (state) => state.OnboardingQuestions.getWeclomePageInfo.data?.Register
  );

  return (
    <div>
      <BoxLayout
        HeaderName="Welcome Pages"
        sectionName="Edit Images and Text on Home Screen"
      >
        <WelcomeInfoForm />
        {/* <SignInSignUp data={Logindata} />
        <SignInSignUp data={Registerdata} />
        <MarketingPageContainer /> */}
      </BoxLayout>
    </div>
  );
};

export default WelcomeMarketing;

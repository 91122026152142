import React, { useState } from "react";
// import { useAppDispatch } from "../../hooks/useAppDispatch";
// import { updateSideBar } from "../../../redux/slices/UIControlSlice";
// import logo from "../../../assets/global/logo.svg";
import { useAppDispatch } from "../Redux/app/hooks";
import { updateSideBar } from "../Redux/features/UIControllerSlice";
import PopUpComponent from "./PopUp/PopUpComponent";
import { navbarImages } from "../assets/navbarasset";
// import { navbarImages } from "../../../assets/navbarasset";

type Props = {};

const NavBarComponent = React.memo(({}: Props) => {
  const dispatch = useAppDispatch();
  const [showPopUp, setshowPopUp] = useState(false);
  return (
    <>
      <div className="flex flex-row justify-between items-center bg-white h-[70px]">
        <div className="flex flex-row  justify-between items-center bg-white bg-wheit w-full  md:w-[270px]">
          <div
            className={`mx-6 h-[70px] items-center  flex justify-between w-full md:w-[270px] bg-white "`}
          >
            <div className="">
              <img src="/images/logo.svg" alt="" />
            </div>
            <div
              className="cursor-pointer"
              onClick={() => dispatch(updateSideBar())}
            >
              <img className="" src="/images/HamburgerMenu.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="hidden md:flex flex-row justify-between gap-12 mx-3  items-center">
          <div className="grid grid-cols-2 gap-7">
            <img src={navbarImages.chat} alt="" />
            <img src={navbarImages.notification} alt="" />
          </div>
          <div
            onClick={() => setshowPopUp((prev) => !prev)}
            className="relative bg-[#ffffff] flex justify-between border border-[#F58124] w-[110px] rounded-full"
          >
            <img className="rounded-full" src={navbarImages.avatar} alt="" />
            <PopUpComponent setPopUp={setshowPopUp} showPopUp={showPopUp} />
            <img className=" p-2" src={navbarImages.gear} alt="" />
          </div>
        </div>
      </div>
    </>
  );
});

export default NavBarComponent;

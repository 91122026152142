import AddFundamentalPage from "../../Pages/Guide/AddFundamentalPage";
import EditFundamentalPage from "../../Pages/Guide/EditFundamentalPage";
import GuidePage from "../../Pages/Guide/GuidePage";
import AdminRoutes from "../../auth/AdminRoutes";

export const guideRoutes = [
  {
    id: "guide1",
    name: "Fundamentals",
    module: "Content Repository",
    path: "/contentrepository/fundamentals",
    component: GuidePage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Content Repository",
        path: "#",
      },
      {
        name: "Guide for drill down ",
        path: "#",
      },
    ],
  },
  {
    id: "guide2",
    name: "Add Fundamentals",
    module: "Content Repository",
    path: "/contentrepository/addfundamentals",
    component: AddFundamentalPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Content Repository",
        path: "#",
      },
      {
        name: "Add Fundamental",
        path: "#",
      },
    ],
  },
  {
    id: "guide3",
    name: "Edit Fundamentals",
    module: "Content Repository",
    path: "/contentrepository/editfundamentals",
    component: EditFundamentalPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Content Repository",
        path: "#",
      },
      {
        name: "Edit Fundamental",
        path: "#",
      },
    ],
  },
];

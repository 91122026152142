import React, { useEffect, useState } from "react";
import BoxLayout from "../../../Containers/Layout/BoxLayout";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  FisioDetailsOrder,
  GolfArticle,
  deleteAboutFisio,
  getAboutFisio,
  postAboutFisio,
  reorderItems,
} from "../../../Redux/features/AboutFisioSlice";
import InputComponent from "../../../Components/Inputs/InputComponent";
import {
  FieldArrayWithId,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import TextAreaComponent from "../../../Components/Inputs/TextAreaComponent";
import TextEditor from "../../../Components/Inputs/TextEditor";
import UploadFileComponents from "../../../Components/Inputs/UploadFileComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import EditIcon from "../../../Components/Icons/EditIcon";
import DeleteIcon from "../../../Components/Icons/DeleteIcon";
import {
  extractUrlsAndText,
  removeUrlAndUpdateHtml,
  updateFieldArray,
} from "../../../utils/helper";
import ModalComponent from "../../../Components/PopUp/ModalComponent";
import { notifyError, notifySuccess } from "../../../helper";
import ButtonComponent from "../../../Components/Buttons/ButtonComponent";
import { Status } from "../../../Redux/features/UserManagementSlice";
import className from "classnames";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";

type Props = {};
type ModalData = { link: string; title: string; description: string };

type AddAboutFisioForm = {
  headline: string;
  subtitle: string;
  text?: string;
  img: string | FileList;
  vimeo_links: string;
};

const AboutFisio = (props: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAboutFisio());
  }, []);

  const [id, setID] = useState<string | undefined>("");
  const [order, setOrder] = useState<string | undefined>();
  const onSubmit = (data: AddAboutFisioForm) => {
    console.log(data.img);
    const formData = new FormData();
    if (id && order) {
      formData.append("id", id);
      formData.append("order", order);
    }
    formData.append("headline", data.headline);
    formData.append("subtitle", data.subtitle);
    if (data.text) {
      formData.append("text", data.text);
    }
    if (data.img !== "" && data.img) {
      if (typeof data.img === "object") {
        formData.append("image", data.img[0]);
        formData.append("vimeo_links", "");
      } else {
        formData.append("image", data.img);
        formData.append("vimeo_links", "");
      }
    } else {
      formData.append("vimeo_links", data.vimeo_links);
      formData.append("image", "");
    }
    console.log(data);
    dispatch(postAboutFisio(formData)).then((res: any) => {
      if (res.payload.success) {
        if (id) {
          notifySuccess("Screen Updated Successfully");
          reset();
          setID(undefined);
        } else {
          // if (id) {
          notifySuccess("Screen Created Successfully");
          reset();
        }
        // setID(id)
        // }
        dispatch(getAboutFisio());
      }
    });
  };

  const [showDeleteForm, setDeleteForm] = useState(false);
  // const [id, setid] = useState("0");
  const handleDeleteClick = (id: string) => {
    setDeleteForm(true);
    setID(id);
  };

  const confirmDeleteAction = () => {
    if (id) {
      dispatch(deleteAboutFisio(id)).then((res: any) => {
        if (res.payload?.success) setDeleteForm(false);
        notifySuccess("Screen Delete SuccessFully!");
        dispatch(getAboutFisio());
      });
    }
  };

  const golfArticles = useAppSelector((state) => state.AboutFisio.golfArticles);
  const itemSchema = yup.object().shape({
    link: yup.string().required("Link is required"),
    title: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
  });
  const AddAboutFisioFormSchema = yup
    .object()
    .shape({
      headline: yup.string().required("Headline is required"),
      subtitle: yup.string().required("Subtitle is required"),
      text: yup.string(),
      vimeo_links: yup.string().nullable(),
      img: yup.mixed().nullable(),
    })
    .test(
      "vimeo_links_or_img",
      "Either a Vimeo link or an image is required",
      function (object) {
        const { vimeo_links, img } = object;
        // Check if at least one of the fields is not empty
        return !!(vimeo_links || img);
      }
    );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    getValues,
    setValue,
  } = useForm<AddAboutFisioForm>({
    resolver: yupResolver(AddAboutFisioFormSchema),
    defaultValues: {
      img: "",
    },
  });

  const formValues = watch();

  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const handleEditAboutFisio = (data: GolfArticle, index: number) => {
    setID(data._id);
    setOrder(String(index));
    setValue("headline", data.headline);
    // if (typeof data.image !== "string") {
    setValue("img", data.image_url);
    // }
    setValue("subtitle", data.subtitle);
    setValue("text", data.text);
    if (data.vimeo_links) {
      setValue("vimeo_links", data.vimeo_links);
    }
    if (data.vimeo_links && data.vimeo_links !== "") {
      setFileType(false);
    } else {
      setFileType(true);
    }
  };
  const handleCloseDeleteModel = () => {
    setDeleteForm(false);
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    dispatch(
      reorderItems({
        startIndex: result.source.index,
        endIndex: result.destination.index,
      })
    );
  };

  // useEffect(() => {
  //     setValue("vimeo_links", "")

  // }, [formValues.img])

  console.log(formValues.img);

  const [fileType, setFileType] = useState(false);

  const reOrderDetails = () => {
    dispatch(
      FisioDetailsOrder({
        about_list: JSON.stringify(
          golfArticles.map((article, index) => {
            return {
              id: article._id,
              order: String(index),
            };
          })
        ),
      })
    ).then((res: any) => {
      if (res.payload?.success) {
        notifySuccess("Reordererd SuccessFully");
      }
    });
  };

  const reOrderAPIIdle = useAppSelector(
    (state) => state.AboutFisio.FisioDetailsOrderIdle
  );
  const deleteAPIIdle = useAppSelector(
    (state) => state.AboutFisio.deleteAboutFisioIdle
  );
  const postAPIIdle = useAppSelector(
    (state) => state.AboutFisio.postAboutFisio
  );

  // useEffect(() => {
  //     if (formValues?.vimeo_links !== "" && formValues?.vimeo_links) {
  //         setFileType(false)
  //     }
  // }, [formValues.vimeo_links])
  // Handler to change the state
  const handleChange = (event: any) => {
    console.log(event?.target?.value);
    if (event?.target?.value === "true") {
      setFileType(true);
      setValue("vimeo_links", "");
    } else {
      setFileType(false);
      setValue("img", "");
    }
  };

  return (
    <>
      <BoxLayout HeaderName="FTUE" sectionName="About Fisio">
        <div className="  border-t gap-4">
          <div className=" my-4 mx-4 gap-4  grid grid-cols-12">
            <div className="col-span-8 border rounded-lg">
              <div className="py-2 px-2 text-base font-medium flex justify-between ">
                <p>
                  {id !== ""
                    ? "Edit About Fisio Details"
                    : "Add About Fisio Details"}
                </p>
                {id !== "" ? (
                  <button
                    className="text-base font-medium flex items-center gap-1"
                    onClick={() => {
                      reset();
                      setID("");
                    }}
                  >
                    {" "}
                    <img src="/images/plusButton.svg" alt="" />
                    Add new
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <div className="border-t">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="flex flex-col p-4 gap-4"
                >
                  <InputComponent
                    inputRef="headline"
                    label="Headline"
                    register={register}
                    value=""
                  />
                  <InputComponent
                    inputRef="subtitle"
                    label="Subtitle"
                    register={register}
                    value=""
                  />
                  <TextEditor
                    name="text"
                    control={control}
                    className=""
                    values=""
                    inputRef="text"
                    label="Text"
                    astric={false}
                    register={register}
                  />
                  <div className="grid items-center grid-cols-4 gap-1">
                    <div
                      className="font-semibold text-[#3c4c76]
          font-blue-950 text-sm col-span-2 flex gap-3"
                    >
                      Choose Cover Media :
                      <div className="">
                        <label
                          className="flex font-semibold text-[#3c4c76]
          font-blue-950 text-sm items-center gap-2"
                        >
                          Image
                          <input
                            type="radio"
                            name="toggle"
                            value="true"
                            checked={fileType}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                      <div>
                        <label
                          className="flex font-semibold text-[#3c4c76]
          font-blue-950 text-sm items-center gap-2"
                        >
                          Video
                          <input
                            type="radio"
                            name="toggle"
                            value="false"
                            checked={!fileType}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>
                    {/* <ButtonComponent
                                            CTA={fileType ? "Add Video" : " Add Image "}
                                            buttonType='button'
                                            className="gap-5 max-w-[200px] overflow-clip flex items-center font-semibold text-[#3c4c76] font-blue-950 text-sm text-ellipsis  whitespace-nowrap"
                                            onClick={() => {
                                                setFileType(prev => !prev);
                                                fileType ? setValue("img", "") : setValue("vimeo_links", "")

                                            }}
                                            varient='blue'
                                        /> */}

                    {fileType ? (
                      <UploadFileComponents
                        onClick={() => setValue("img", "")}
                        control={control}
                        id="img"
                        inputRef="img"
                        label={
                          typeof formValues.img !== "string"
                            ? formValues?.img[0].name
                            : formValues.img === ""
                            ? "Upload Image"
                            : formValues.img.split("/")[4]
                        }
                        value={formValues.img}
                      />
                    ) : (
                      <>
                        <InputComponent
                          inputRef="vimeo_links"
                          className="col-span-2"
                          label="Vimeo Link"
                          name="s"
                          register={register}
                          value=""
                        />
                      </>
                    )}
                  </div>
                  <span className="w-[200px] ml-auto">
                    <ButtonComponent
                      className=""
                      loading={postAPIIdle}
                      CTA="Submit"
                      buttonType="submit"
                      varient="blue"
                    />
                  </span>
                </form>
              </div>
            </div>
            <div className="col-span-4 border rounded-lg">
              <div className="py-2 px-2 text-base font-medium">
                About Fisio Details
              </div>
              <div className="border-t w-full h-full">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="droppableArticleas">
                    {(provided, snapshot) => (
                      <ul
                        // key={article._id}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        // {...provided.}
                      >
                        {golfArticles.map((article, index) => (
                          <Draggable
                            draggableId={article._id}
                            key={article._id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="list-none flex items-center py-2 justify-between"
                              >
                                <div
                                  draggable
                                  className="flex flex-col bg-[#EAF0F3]  h-full py-[10px] mx-10 px-[8px] border  rounded-md  gap-1"
                                >
                                  <span className="block  w-6 h-[2px] bg-[#285A9f]"></span>
                                  <span className="block  w-6 h-[2px] bg-[#285A9f]"></span>
                                  <span className="block  w-6 h-[2px] bg-[#285A9f]"></span>
                                </div>
                                <div>{index + 1}</div>
                                <div className="w-[150px] text-ellipsis whitespace-nowrap overflow-hidden block">
                                  {article.headline}
                                </div>
                                <div className="flex gap-3 p-2 items-center">
                                  <span className="block  h-4 w-[1px] bg-[#285A9f]"></span>
                                  <button
                                    className={className("", {
                                      "cursor-not-allowed": id === article._id,
                                    })}
                                    disabled={id === article._id}
                                    onClick={() =>
                                      handleEditAboutFisio(article, index)
                                    }
                                  >
                                    <EditIcon />
                                  </button>
                                  <button
                                    disabled={golfArticles.length === 1}
                                    onClick={() =>
                                      handleDeleteClick(article._id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </button>
                                </div>
                              </li>
                            )}
                          </Draggable>
                        ))}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <ButtonComponent
                CTA="Save Screen Order"
                buttonType="button"
                varient="blue"
                onClick={() => reOrderDetails()}
                loading={reOrderAPIIdle}
              />
            </div>
          </div>
        </div>
        {/* <ModalComponent
                    actionCTA='Save'
                    isOpen={isModalOpen}
                    message='Edit Link Data'
                    onClose={() => { setModalOpen(false); setModalData({ link: '', title: '', description: '' }) }}
                    confirmAction={() => handleModalSave(fields)}
                >
                    <div className='flex flex-col col-span-8 gap-4'>

                        <div
                            className={`h-[50px]  w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8] `}
                        >
                            <input
                                className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none`}
                                name="link"
                                value={modalData.link}
                                onChange={handleChange}
                            />

                            <label
                                className="absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
                                htmlFor={"userName"}
                            >
                                Link
                            </label>
                        </div>
                        <div
                            className={`h-[50px]  w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8] `}
                        >
                            <input
                                className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none `}
                                name="title"
                                value={modalData.title}
                                onChange={handleChange}
                            />

                            <label
                                className="absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
                                htmlFor={"title"}
                            >
                                Title
                            </label>
                        </div>
                        <div
                            className={`h-[50px]  w-full rounded-[12px] justify-end flex  relative border border-[#B8B8B8] `}
                        >
                            <textarea
                                name="description"
                                className={`w-full h-full peer bg-[#fafafa] rounded-[10px] outline-none px-3 pt-2 animate-none`}
                                placeholder={"Description"}
                                value={modalData.description}
                                onChange={handleChange}
                            />

                            <label
                                className="absolute left-4 -top-2 bg-gradient-to-b text-xs font-medium  from-[#ffffff] from-[-4.22%] to-[#Fafafa] to-[120.23%] px-2 "
                                htmlFor={"title"}
                            >
                                Description
                            </label>
                        </div>


                    </div>
                </ModalComponent> */}
        <ModalComponent
          confirmAction={confirmDeleteAction}
          message={"Are you sure to delete this Screen ?"}
          isOpen={showDeleteForm}
          onClose={handleCloseDeleteModel}
          loading={deleteAPIIdle}
        />
      </BoxLayout>
    </>
  );
};

export default AboutFisio;

import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../api/api";
import { Status } from "./UserManagementSlice";

type ObjectPayloadType = {
  searchValue?: string;
  sortType?: sortValues;
  sortValue?: string;
  page?: number;
  per_page?: number;
};

export const ObjectListAPI = createAsyncThunk<
  ObjectListAPIResponse,
  ObjectPayloadType
>("Object/ObjectList", async ({ per_page, ...ObjectPayload }) => {
  const res = await axiosClient.get(
    `auth/user-object-type?${per_page ? `&per_page=${per_page}` : ``}${
      ObjectPayload.searchValue
        ? `&filteredVal=${ObjectPayload.searchValue}`
        : ""
    }${ObjectPayload.page ? `&page=${ObjectPayload.page}` : ""}${
      ObjectPayload.sortType === "ascending"
        ? `&sort=-${ObjectPayload.sortValue}`
        : ``
    }${
      ObjectPayload.sortType === "descending"
        ? `&sort=${ObjectPayload.sortValue}`
        : ``
    }
  `
  );
  return {
    data: res.data,
    page: ObjectPayload.page || 1,
  };
});
export const SimpleObjectListAPI = createAsyncThunk<any, void>(
  "Object/SimpleObjectList",
  async () => {
    const res = await axiosClient.get<any>(`auth/get-user-objects`);
    return res.data;
  }
);

export const ObjectDeleteAPI = createAsyncThunk<
  DeleteObjectAPIResponse,
  string,
  {}
>("object/delete", async (id) => {
  const res = await axiosClient.delete(`auth/user-object-type/${id}`);
  return res.data.data;
});

export const AddObjectAPI = createAsyncThunk<ObjectListAPIResponse, FormData>(
  "Object/AddObject",
  async (data) => {
    const res = await axiosClient.post("auth/user-object-type", data);
    return res.data;
  }
);

export const ObjectTypeListAPI = createAsyncThunk<ObjectTypeListAPIResponse>(
  "Object/ObjectTypeList",
  async () => {
    const res = await axiosClient.get("auth/object-type");
    return res.data;
  }
);

export const CategoryTypeListAPI =
  createAsyncThunk<CategoryTypeListAPIResponse>(
    "Object/CategoryTypeList",
    async () => {
      const res = await axiosClient.get("auth/category-type");
      return res.data;
    }
  );

interface initialStateType {
  objectlist: ObjectItem[];
  objectTypeList: ObjectType[];
  categoryTypeList: CategoryType[];
  objectListAPIIdle: Status;
  objectTypeListAPI: Status;
  categoryTypeListAPI: Status;
  AddobjectAPIIdle: Status;
  ObjectDeleteAPIIdle: Status;
  SimpleObjectListAPIIdle: Status;
  sortType: sortValues;
  sortValue: string;
  activePage: number;
  totalPages: number;
  simpleObjectList: Array<{
    id: string;
    object_name: string;
    object_reps: string;
    time: string;
    category_type_id: string;
  }>;
}
const initialState: initialStateType = {
  objectlist: [],
  sortType: "descending",
  sortValue: "object_name",
  objectTypeList: [],
  categoryTypeList: [],
  objectListAPIIdle: Status.SUCCESS,
  objectTypeListAPI: Status.SUCCESS,
  categoryTypeListAPI: Status.SUCCESS,
  AddobjectAPIIdle: Status.SUCCESS,
  ObjectDeleteAPIIdle: Status.SUCCESS,
  SimpleObjectListAPIIdle: Status.SUCCESS,
  simpleObjectList: [],
  activePage: 1,
  totalPages: 0,
};

const ObjectSlice = createSlice({
  name: "Object",
  initialState,
  reducers: {
    clearObject: (state) => {
      return {
        ...state,
        objectlist: [],
        objectTypeList: [],
        categoryTypeList: [],
      };
    },
    ObjectListSort: (state) => {
      return {
        ...state,
        sortType: state.sortType === "ascending" ? "descending" : "ascending",
      };
    },
    handleSort: (state, action) => {
      return {
        ...state,
        sortType: action.payload.sortType,
        sortValue: action.payload.sortValue,
      };
    },
    handlePagination: (state, action) => {
      return {
        ...state,
        activePage: action.payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(ObjectListAPI.pending, (state, action) => {
        return {
          ...state,
          objectListAPIIdle: Status.LOADING,
        };
      })
      .addCase(ObjectListAPI.fulfilled, (state, action) => {
        console.log(action, "action");
        return {
          ...state,
          objectlist: action.payload.data.data.objects,
          activePage: action.payload.page,
          totalPages: action.payload.data.data.total_page,
          objectListAPIIdle: Status.SUCCESS,
        };
      })
      .addCase(ObjectListAPI.rejected, (state, action) => {
        return {
          ...state,
          objectListAPIIdle: Status.ERROR,
        };
      })
      .addCase(ObjectTypeListAPI.pending, (state, action) => {
        return {
          ...state,
          objectTypeListAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        ObjectTypeListAPI.fulfilled,
        (state, action: PayloadAction<ObjectTypeListAPIResponse>) => {
          return {
            ...state,
            objectTypeListAPIIdle: Status.SUCCESS,
            objectTypeList: action.payload.data,
          };
        }
      )
      .addCase(ObjectTypeListAPI.rejected, (state, action) => {
        return {
          ...state,
          objectTypeListAPIIdle: Status.ERROR,
        };
      })
      .addCase(CategoryTypeListAPI.pending, (state, action) => {
        return {
          ...state,
          categoryTypeListAPI: Status.LOADING,
        };
      })
      .addCase(
        CategoryTypeListAPI.fulfilled,
        (state, action: PayloadAction<CategoryTypeListAPIResponse>) => {
          return {
            ...state,
            categoryTypeListAPI: Status.SUCCESS,
            categoryTypeList: action.payload.data,
          };
        }
      )
      .addCase(CategoryTypeListAPI.rejected, (state, action) => {
        return {
          ...state,
          categoryTypeListAPI: Status.ERROR,
        };
      })
      .addCase(AddObjectAPI.pending, (state, action) => {
        return {
          ...state,
          AddobjectAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        AddObjectAPI.fulfilled,
        (state, action: PayloadAction<AddObjectAPIResponse>) => {
          return {
            ...state,
            AddobjectAPIIdle: Status.SUCCESS,
          };
        }
      )
      .addCase(AddObjectAPI.rejected, (state, action) => {
        return {
          ...state,
          AddobjectAPIIdle: Status.ERROR,
        };
      })
      .addCase(ObjectDeleteAPI.pending, (state, action) => {
        return {
          ...state,
          ObjectDeleteAPIIdle: Status.LOADING,
        };
      })
      .addCase(
        ObjectDeleteAPI.fulfilled,
        (state, action: PayloadAction<AddObjectAPIResponse>) => {
          return {
            ...state,
            ObjectDeleteAPIIdle: Status.SUCCESS,
          };
        }
      )
      .addCase(ObjectDeleteAPI.rejected, (state, action) => {
        return {
          ...state,
          ObjectDeleteAPIIdle: Status.ERROR,
        };
      })
      .addCase(SimpleObjectListAPI.pending, (state, action) => {
        return {
          ...state,
          SimpleListAPIIdle: Status.ERROR,
        };
      })
      .addCase(SimpleObjectListAPI.fulfilled, (state, action) => {
        return {
          ...state,
          SimpleListAPIIdle: Status.ERROR,
          simpleObjectList: action.payload.data,
        };
      })
      .addCase(SimpleObjectListAPI.rejected, (state, action) => {
        return {
          ...state,
          SimpleListAPIIdle: Status.ERROR,
        };
      });
  },
});

export const { clearObject, handlePagination, ObjectListSort, handleSort } =
  ObjectSlice.actions;

export default ObjectSlice.reducer;

import React, { useEffect, useState } from "react";
import { Badge } from "../../../../Sandbox/Sandbox";
import SelectComponent from "../../../../../Components/Inputs/SelectComponent";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/app/hooks";
import Sidedrawer from "../../../../../Components/SideDrawer";
import InputComponent from "../../../../../Components/Inputs/InputComponent";
import ButtonComponent from "../../../../../Components/Buttons/ButtonComponent";
import AssignGroupID from "../../../AssignGroupID";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  UserListAPI,
  UserPostAPI,
} from "../../../../../Redux/features/UserManagementSlice";
import { useParams } from "react-router-dom";
import { getProfile } from "../../../../../Redux/features/UserDashboardSlice";
type Props = {
  registrationDate: string;
  address: string;
  number: string;
  email: string;
  countryZone: string;
  BirthDay: string;
  Height: string;
  Weight: string;
  countryFlag: string;
  timezoneField: string;
  activePlanName: string;
  fullName: string;
  userid: number;
};

const ProfileInfo = ({
  registrationDate = "-",
  timezoneField,
  address = "-",
  countryFlag,
  number,
  email,
  countryZone,
  BirthDay,
  Height,
  activePlanName,
  fullName,
  userid,
  Weight,
}: Props) => {
  const [userId, setuserId] = useState<string>("");

  const handleEditClick = (
    id: number,
    name: string,
    email: string,
    userID?: string
  ) => {
    setUpdateForm(true);
    setValue("email", email);
    setValue("name", name);
    setid(id);
    if (userID) {
      setuserId(userID);
    }
  };
  const { id } = useParams();

  const [plan, setPlan] = useState("");
  const [groupID, setGroupID] = useState<string>("");
  const [uid, setid] = useState(0);
  const [planName, setPlanName] = useState("");
  const [badge, setBadgeName] = useState("");
  const ActiveplanName = useAppSelector(
    (state) => state.UserDashboard.dashBoardData.user.active_plan_name
  );
  useEffect(() => {
    switch (ActiveplanName) {
      case "Basic":
        setPlan("Basic");
        setPlanName("Basic");
        setBadgeName("Basic");
        break;
      case "Premium":
        setPlan("Premium");
        setPlanName("Premium");
        setBadgeName("Premium");
        break;
      case "Ultra":
        setPlan("Ultra");
        setPlanName("Ultra");
        setBadgeName("Ultra");
        break;

      case "7 day Trial":
        setPlan("7 day Trial");
        setPlanName("Free");
        setBadgeName("Trial");
        break;

      default:
        setPlan("Not Purchased");
        setPlanName("N/A");
        setBadgeName("N/A");
        break;
    }
  }, [ActiveplanName]);
  type FormValues = {
    email: string;
    name: string;
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Invalid email"),
    name: Yup.string().required("Name is required"),
  });
  const [showUpdateForm, setUpdateForm] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: "", name: "" },
  });
  const formValues = watch();

  const perPage = useAppSelector((state) => state.UserManagement.perPage);
  const pageNumber = useAppSelector(
    (state) => state.UserManagement.userList.current_page
  );
  const dispatch = useAppDispatch();
  const ConfirmUpdate = (data: FormValues) => {
    dispatch(UserPostAPI({ data: data, id: uid })).then((res: any) => {
      if (res.payload?.success) {
        setUpdateForm(false);
        if (id) {
          dispatch(getProfile(id));
        }
      }
    });
  };
  const handleCloseUpdateModel = () => {
    setUpdateForm(false);
  };
  const loading = useAppSelector(
    (state) => state.UserManagement.UserPostAPIIdle
  );
  return (
    <>
      <div className="flex flex-col gap-5 justify-between">
        <span className="m-auto border border-[#F58124] text-center rounded-full text-[#F58124] bg-[#FFF5E5] pl-[15px] pr-[15px] py-[3px] text-xs px-4 w-56">
          Registration Date: {moment(registrationDate).format("YYYY.MM.DD")}
        </span>

        <h3 className="font-medium text-sm mx-[1.88rem] px-2 text-black">
          Personal Info
        </h3>
        <ul className="px-2 text-[0.75rem] flex font-normal justify-center mx-[1.88rem] text-[#9e9e9e] flex-col gap-2">
          <li className="flex items-center gap-2 ">
            <span>
              <img src="/images/Popular.svg" className="h-6" alt="" />
            </span>
            {address}
          </li>
          <li className="flex items-center gap-2 ">
            <span>
              <img src="/images/Devices.svg" className="h-6" alt="" />
            </span>
            {number}
          </li>
          <li
            className="flex justify-between items-center gap-2 cursor-pointer "
            onClick={() => handleEditClick(Number(userid), fullName, email)}
          >
            <span>
              <img src="/images/Messaging.svg" className="w-6 h-6" alt="" />
            </span>
            <span className="w-[120px] overflow-hidden text-ellipsis">
              {email}
            </span>
            <img
              src="/images/editicon.svg"
              onClick={() => handleEditClick(Number(userid), fullName, email)}
              className="h-5 w-5"
              alt=""
            />
          </li>
          <li className="flex items-center gap-2 ">
            <span>
              <img src={countryFlag} className="h-4" alt="" />
            </span>
            {timezoneField}
          </li>
        </ul>
      </div>
      <div className="mx-[1.88rem] text-[0.75rem] font-normal justify-center  text-[#9e9e9e] py-5 flex flex-col gap-3">
        <div className="flex flex-row justify-between ">
          <span className="">Birthday</span>
          <span>{BirthDay}</span>
        </div>
        {/* <div className='flex flex-row justify-between '>
                    <span className=''>Height</span>
                    <span>{Height}</span>

                </div>
                <div className='flex flex-row justify-between '>
                    <span className=''>Weight</span>
                    <span>{Weight}</span>
                </div> */}
      </div>
      <div className="mx-[1.88rem] px-[0.62rem] py-[0.62rem] mb-[1.28rem] bg-[#FFF5E5] shadow-[0px_4px_10px_0px_rgba(137,137,137,0.20)] rounded-2xl">
        <div className="mx-[0.62rem] flex flex-row justify-between items-center">
          <span className="text-[#FC8A2E]">Active Plan</span>{" "}
          <Badge CTA={badge} />{" "}
        </div>
        <div className="mx-[0.62rem] flex flex-col gap-[0.62rem]">
          <p className="text-base font-medium text-[#1f3161]">{plan}</p>
          <p className="text-xs text-[#767576]">{planName}</p>
        </div>
      </div>

      {showUpdateForm && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[999]">
          <Sidedrawer onClose={handleCloseUpdateModel}>
            <p className="text-lg p-4">Update User</p>
            <form
              className="p-4 flex  flex-col justify-between gap-4"
              onSubmit={handleSubmit(ConfirmUpdate)}
            >
              <div className="h-full flex flex-col gap-4">
                <InputComponent
                  register={register}
                  inputRef="email"
                  label="email"
                  name="email"
                  errorname={errors.email?.message}
                  value={formValues.email}
                />
                <InputComponent
                  register={register}
                  inputRef="name"
                  label="name"
                  name="name"
                  errorname={errors.name?.message}
                  value={formValues.name}
                />
              </div>
              <div className="flex h-[150px] gap-6">
                <ButtonComponent
                  buttonType="submit"
                  CTA="Update"
                  varient={"blue"}
                  loading={loading}
                />
                <ButtonComponent
                  buttonType="button"
                  CTA="cancel"
                  onClick={handleCloseUpdateModel}
                />
              </div>
            </form>
          </Sidedrawer>
        </div>
      )}
    </>
  );
};

export default ProfileInfo;

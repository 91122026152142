import React from "react";

type Props = {};

const EyeIcon = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 23 15"
      fill="none"
    >
      <path
        d="M0.780748 7.19625C0.812195 7.12219 1.57317 5.36062 3.26493 3.59531C5.51911 1.24312 8.36626 0 11.5 0C14.6338 0 17.4809 1.24312 19.7351 3.59531C21.4268 5.36062 22.1914 7.125 22.2193 7.19625C22.2601 7.29217 22.2812 7.39597 22.2812 7.50094C22.2812 7.60591 22.2601 7.70971 22.2193 7.80562C22.1878 7.87969 21.4268 9.64031 19.7351 11.4056C17.4809 13.7569 14.6338 15 11.5 15C8.36626 15 5.51911 13.7569 3.26493 11.4056C1.57317 9.64031 0.812195 7.87969 0.780748 7.80562C0.739882 7.70971 0.718765 7.60591 0.718765 7.50094C0.718765 7.39597 0.739882 7.29217 0.780748 7.19625ZM11.5 13.5C14.2654 13.5 16.6813 12.4509 18.6812 10.3828C19.5018 9.53128 20.1999 8.56027 20.7539 7.5C20.2001 6.43963 19.5019 5.4686 18.6812 4.61719C16.6813 2.54906 14.2654 1.5 11.5 1.5C8.73462 1.5 6.31872 2.54906 4.3188 4.61719C3.4966 5.4684 2.79694 6.43942 2.24161 7.5C2.88938 8.76188 5.71137 13.5 11.5 13.5ZM11.5 3C12.3529 3 13.1867 3.26392 13.8959 3.75839C14.6051 4.25285 15.1578 4.95566 15.4842 5.77792C15.8106 6.60019 15.896 7.50499 15.7296 8.37791C15.5632 9.25082 15.1525 10.0526 14.5494 10.682C13.9463 11.3113 13.1779 11.7399 12.3413 11.9135C11.5048 12.0872 10.6377 11.9981 9.84968 11.6575C9.06168 11.3169 8.38816 10.7401 7.91429 10.0001C7.44043 9.26005 7.18751 8.39002 7.18751 7.5C7.1887 6.30691 7.64343 5.16303 8.45192 4.31939C9.26042 3.47575 10.3566 3.00124 11.5 3ZM11.5 10.5C12.0686 10.5 12.6245 10.3241 13.0973 9.99441C13.5701 9.66476 13.9386 9.19623 14.1562 8.64805C14.3738 8.09987 14.4307 7.49667 14.3198 6.91473C14.2088 6.33279 13.935 5.79824 13.5329 5.37868C13.1309 4.95912 12.6186 4.6734 12.0609 4.55764C11.5032 4.44189 10.9251 4.5013 10.3998 4.72836C9.87446 4.95542 9.42544 5.33994 9.10953 5.83329C8.79362 6.32664 8.62501 6.90666 8.62501 7.5C8.62501 8.29565 8.92791 9.05871 9.46708 9.62132C10.0062 10.1839 10.7375 10.5 11.5 10.5Z"
        fill="#1F3161"
      />
    </svg>
  );
};

export default EyeIcon;

import React from "react";
import classNames from "classnames";

type Props = {
  streak: number;
  maxStreak: number;
  recallCards: number;
  moveDrills: number;
  trustShots: number;
  swingDrills: number;
  showBlue: number;
};

const ProfileStats = ({
  maxStreak,
  moveDrills,
  recallCards,
  streak,
  trustShots,
  swingDrills,
  showBlue,
}: Props) => {
  return (
    <>
      <div className="shadow-section py-[15px] border border-[#C6D6DD] bg-white rounded-2xl justify-center items-center flex flex-col ">
        <div className="px-[30px] ">
          <span className="relative text-center">
            {showBlue ? (
              <img className="w-10" src={"/images/calenderblue.svg"} alt="" />
            ) : (
              <img className="w-10" src={"/images/calender.svg"} alt="" />
            )}

            <svg
              className="text-[1.125rem] absolute -top-[3.7rem] left-1 w-8"
              style={{ color: "#F58124", zIndex: "1" }}
            >
              <text
                className="absolute fontnunito right-0"
                x="49.7%"
                y="55%"
                dominantBaseline="middle"
                textAnchor="middle"
                fill="#FFF"
                fontSize="1.80rem"
                fontWeight="bold"
                strokeWidth={2}
                stroke="#FFF"
              >
                {streak}
              </text>
              <text
                className="absolute fontnunito "
                x="49.7%"
                y="55%"
                dominantBaseline="middle"
                textAnchor="middle"
                fill={showBlue ? "#3d6cac" : "#F58124"}
                fontSize="1.60rem"
                fontWeight="bold"
              >
                {streak}
              </text>
            </svg>
          </span>
        </div>
        <p
          className={classNames("text-[#F58124] fontnunito font-medium  ", {
            "text-[#3d6cac]": showBlue,
            "text-[#F58124]": !showBlue,
          })}
        >
          Day Streak
        </p>
      </div>
      <div className="shadow-section py-[15px] border border-[#C6D6DD] bg-white rounded-2xl  justify-center items-center flex flex-col gap-1">
        <div className="  px-[30px] ">
          <span className="relative ">
            <img className="w-10 " src={"/images/max.svg"} alt="" />
            <svg
              className="text-[1.125rem] absolute fontnunito -top-[1.5rem] left-1 w-8"
              style={{ color: "#F58124", zIndex: "1" }}
            >
              <text
                className="absolute fontnunito  right-0"
                x="49.7%"
                y="55%"
                dominantBaseline="middle"
                fontFamily="Nunito"
                textAnchor="middle"
                fill="#FFF"
                fontSize="1.80rem"
                fontWeight="bold"
                strokeWidth={2}
                stroke="#FFF"
              >
                {maxStreak}
              </text>
              <text
                className="absolute fontnunito "
                x="49.7%"
                y="55%"
                dominantBaseline="middle"
                textAnchor="middle"
                fill={"#F58124"}
                fontSize="1.60rem"
                fontWeight="bold"
                fontFamily="Nunito"
              >
                {maxStreak}
              </text>
            </svg>
          </span>
        </div>
        <p className="text-[#F58124] fontnunito  font-medium ">MAX Streak</p>
      </div>
      <div className="shadow-section p-5 border border-[#C6D6DD] rounded-xl h-full flex flex-col gap-[24px] bg-white">
        <p className="font-bold text-sm ">Reps</p>
        <div className="text-center grid h-full text-[#FC8A2E] font-bold text-2xl justify-center place-content-center items-center grid-cols-1">
          <div className="py-[15px]">
            <div>{recallCards}</div>
            <div className="text-xs font-normal text-[#9e9e9e]">
              Recall cards
            </div>
          </div>
          <div className="border-t py-[15px] border-t-[#FFF5E5]">
            <div>{moveDrills}</div>
            <div className="text-xs font-normal text-[#9e9e9e]">
              Move Drills
            </div>
          </div>
          <div className="border-t py-[15px] border-t-[#FFF5E5]">
            <div>{swingDrills}</div>
            <div className="text-xs font-normal text-[#9e9e9e]">
              Swing Drill
            </div>
          </div>
          <div className="border-t py-[15px] border-t-[#FFF5E5]">
            <div>{trustShots}</div>
            <div className="text-xs font-normal text-[#9e9e9e]">
              Trust Shots
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileStats;

import { addDays, differenceInCalendarDays, format, isBefore } from "date-fns";
import { Calendar } from "react-date-range";
import classNames from "classnames";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// import { StreakCalander } from '../types/APIResponsetypes';
import parseISO from "date-fns/parseISO";
import { StreakCalander } from "../../types/apiresponse";

type Props = {
  calenderStreak: StreakCalander[];
};
const isDateStreakEnd = (date: string, calenderStreak: StreakCalander[]) => {
  // Ensure that the date string is in the correct format
  if (!date || !Date.parse(date)) {
    console.error("Invalid date string:", date);
    return false;
  }

  const dateIndex = calenderStreak?.findIndex((d: { date: string }) => {
    // Ensure that d.date is a valid date string
    const parsedDate = parseISO(d.date);
    return (
      !isNaN(parsedDate.getTime()) && format(parsedDate, "yyyy-MM-dd") === date
    );
  });

  if (dateIndex === -1) return false;
  if (dateIndex === calenderStreak.length - 1) return true;

  const currentDate = parseISO(calenderStreak[dateIndex].date);
  if (isNaN(currentDate.getTime())) {
    console.error("Invalid currentDate:", calenderStreak[dateIndex].date);
    return false;
  }

  const nextDateEntry = calenderStreak[dateIndex + 1];
  if (!nextDateEntry) return true; // If there's no next entry, this must be the end

  const nextDate = parseISO(nextDateEntry.date);
  if (isNaN(nextDate.getTime())) {
    console.error("Invalid nextDate:", nextDateEntry.date);
    return false;
  }

  return differenceInCalendarDays(nextDate, currentDate) > 1;
};

const CalenderComponent = ({ calenderStreak }: Props) => {
  const sortedData = [...calenderStreak].sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  );

  // Identify the first and last dates and the start of new ranges
  const specialDates: Set<string> = new Set();

  sortedData.forEach((item, index) => {
    if (index === 0 || index === sortedData.length - 1) {
      // Add the first and last dates
      specialDates.add(item.date);
    } else {
      const prevDate = parseISO(sortedData[index - 1].date);
      const currentDate = parseISO(item.date);
      if (isBefore(addDays(prevDate, 1), currentDate)) {
        // If there's a gap of more than 1 day, add the current date to specialDates
        specialDates.add(item.date);
      }
    }
  });

  console.log(calenderStreak, "calenderStreak");
  console.log(sortedData, "calenderStreak sortDate");
  console.log(specialDates, "calenderStreak specialDates");
  const gradientStyle = {
    background: "#FDDFC3",
    color: "white",
    borderRadius: "0 100% 100% 0 ",
    width: "40px",
    fontSize: 14,
    fontFamily: `"Poppins", sans-serif`,
  };

  const dayContentRenderer = (day: Date) => {
    const dateString = format(day, "yyyy-MM-dd");
    let style: any = {
      backgroundColor: "white",
      fontSize: 14,
      color: "black",
      fontFamily: `"Poppins", sans-serif`,
      borderRadius: "0",
      fontWeight: "400",
      width: "40px",
    };
    const dateEntry = calenderStreak.find((d) => d.date === dateString);
    if (dateEntry) {
      style = {
        background: "#FDDFC3",
        fontSize: 14,
        width: "40px",
        color: dateEntry.mulligan === 1 ? "#ffffff" : "rgba(15, 37, 82, 1)",
        fontFamily: `"Poppins", sans-serif`,
        fontWeight: "400",
        borderRadius: "0",
      };
    }
    const prevDate = parseISO(dateString);
    const nextDate = parseISO(dateString);
    const isStreakStart = !calenderStreak.some(
      (d) => format(addDays(prevDate, -1), "yyyy-MM-dd") === d.date
    );
    const isStreakEnd = !calenderStreak.some(
      (d) => format(addDays(nextDate, 1), "yyyy-MM-dd") === d.date
    );

    if (isStreakStart && isStreakEnd) {
      style.borderRadius = "100%";
    } else if (isStreakStart) {
      style.borderRadius = "100% 0 0 100%";
    } else if (isStreakEnd) {
      style.borderRadius = "0 100% 100% 0";
    } else {
      style.borderRadius = "0";
    }
    if (
      specialDates.has(dateString) &&
      Array.from(specialDates).findIndex((date) => date === dateString) === -1
    ) {
      style = {
        ...gradientStyle,
        borderRadius: "0% 100% 100% 0%",
      };
    }
    if (
      specialDates.has(dateString) &&
      Array.from(specialDates).findIndex((date) => date === dateString) === -1
    ) {
      style = {
        ...gradientStyle,
        borderRadius: " 100% 0 0 100% ",
      };
    }
    const isStreakEnds = isDateStreakEnd(dateString, sortedData);

    return (
      <div style={style}>
        <div
          style={{ fontFamily: "Poppins", fontWeight: "400" }}
          className={classNames(
            "w-[40px] h-[40px] flex justify-center items-center text-primaryBlue",
            {
              "rounded-[50%] bg-orangegradient text-white ":
                (isStreakEnds && !dateEntry?.mulligan) ||
                (specialDates.has(dateString) && !dateEntry?.mulligan),
              "rounded-[50%] bg-bluegradient text-white": dateEntry?.mulligan,
            }
          )}
        >
          {format(day, "d")}
        </div>
      </div>
    );
  };
  return (
    <Calendar
      className="justify-center flex"
      dayContentRenderer={dayContentRenderer}
    />
  );
};

export default CalenderComponent;

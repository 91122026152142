import { useEffect, useState } from "react";
import TableComponent from "../../Components/TableComponent";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  userListHandleSort,
  Status,
  checkAllBoxes,
  unCheckAllBoxes,
} from "../../Redux/features/UserManagementSlice";
import {
  TicketListCoachTableHeader,
  TicketListTableHeader,
} from "../../utils/TableColumns";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { useLocation, useNavigate } from "react-router-dom";
import CoachCardSection from "../../Containers/Coach/CoachCardSection";
import ExcelIcon from "../../Components/Icons/ExcelIcon";
import { exportToExcel, manageSorting } from "../../utils/helper";
import {
  changeFilterValue,
  getSwingCheckList,
  ticketListHandleSort,
} from "../../Redux/features/CoachReviewSlice";
import PaginationComponent from "../../Components/PaginationComponent";
import { useIsMount } from "../../CustomHooks/useIsMount";
import classNames from "classnames";
import moment from "moment";
import { useCookies } from "react-cookie";

type Props = {};

const CoachDashboard = (props: Props) => {
  const dispatch = useAppDispatch();
  const userList = useAppSelector(
    (state) => state.UserManagement.userList.data
  );
  const filter_dashboard = useAppSelector((state) => state.Coach.filterValue);

  const sortType = useAppSelector((state) => state.Coach.ticketListsortType);
  const sortValue = useAppSelector((state) => state.Coach.ticketListsortValue);
  useEffect(() => {
    dispatch(
      getSwingCheckList({
        page: 1,
        sortType: sortType,
        sortValue: sortValue,
        filterValue: filterValue,
      })
    ).then((response) => {
      dispatch(unCheckAllBoxes());
    });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const listLoading = useAppSelector((state) => state.Coach.getSwingCheckList);

  const navigate = useNavigate();

  const selectedUser = useAppSelector(
    (state) => state.UserManagement.selectedUser
  );

  const [searchValue, setsearchValue] = useState("");
  const getSearchValue = (d: string) => {
    setsearchValue(d);
    // dispatch(UserListAPI({ page: 1, userType: "admin", searchValue: d }));
  };

  useEffect(() => {
    if (!IsMount) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          getSwingCheckList({
            page: 1,
            userType: "user",
            searchValue: searchValue,
            sortType: sortType,
            sortValue: sortValue,
            filterValue: filterValue,
          })
        ).then((response) => {
          dispatch(unCheckAllBoxes());
        });
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);
  const IsMount = useIsMount();

  const pageChange = (pageNumber: number) => {
    console.log(pageNumber);
    dispatch(
      getSwingCheckList({
        page: pageNumber,
        searchValue: searchValue,
        sortType: sortType,
        sortValue: sortValue,
        filterValue: filterValue,
      })
    ).then((response) => {
      dispatch(unCheckAllBoxes());
    });
  };
  const swingTicket = useAppSelector((state) => state.Coach.swingList);
  const active = useAppSelector((state) => state.Coach.active);
  const total = useAppSelector((state) => state.Coach.total);
  const location = useLocation();
  console.log(location);
  const [cookies] = useCookies(["ic"]);
  const filterValue = useAppSelector((state) => state.Coach.filterValue);
  return (
    <>
      <BoxLayout
        HeaderName="Coach Review"
        sectionName="Ticket List"
        cards={
          <>
            <CoachCardSection />
          </>
        }
      >
        <FilterSortPanel
          getSearchValue={getSearchValue}
          Function={() => {}}
          exportButton={
            <>
              <button
                onClick={() =>
                  exportToExcel(
                    swingTicket.map((i) => {
                      return {
                        ticket: i._id,
                        title: i.title,
                        username: i.user_name,
                        coachName: i.coach_name,
                        createdAt: i.created_at,
                        status: i.status,
                      };
                    }),
                    `ticket_list_page=${active}`
                  )
                }
                className="border rounded"
              >
                <ExcelIcon />
              </button>
              {filterValue !== "" && filterValue ? (
                <button
                  onClick={() => {
                    dispatch(changeFilterValue(""));
                    dispatch(
                      getSwingCheckList({
                        page: 1,
                        sortType: sortType,
                        sortValue: sortValue,
                      })
                    ).then((response) => {
                      dispatch(unCheckAllBoxes());
                    });
                  }}
                  className="border px-2 rounded py-1 text-xs"
                >
                  Remove Filter
                </button>
              ) : (
                <></>
              )}
            </>
          }
          showAddButton={false}
          customClassName=""
        ></FilterSortPanel>
        <TableComponent
          TableHeaderData={
            cookies.ic ? TicketListCoachTableHeader : TicketListTableHeader
          }
          loader={listLoading}
          onClick={manageSorting}
          sortType={sortType}
          sortValue={sortValue}
          handleSortAction={ticketListHandleSort}
          sortIcon
          checkBox={selectedUser.length === userList.length}
          checkBoxFunction={(e) => {
            if (e.target.checked) {
              dispatch(checkAllBoxes());
            } else {
              dispatch(unCheckAllBoxes());
            }
          }}
        >
          {listLoading === Status.SUCCESS && (
            <tbody>
              {swingTicket.map((dataa, index) => (
                <tr
                  className="cursor-pointer whitespace-nowrap overflow-hidden  border-y border-y-[#EAF0F3] py-1"
                  key={index}
                >
                  <td className="py-3 text-xs text-start pr-1 font-medium text-[#212121] ">
                    {dataa.title}
                  </td>
                  <td className="py-3 text-xs text-start pr-1 font-medium text-[#212121] ">
                    {dataa.type}
                  </td>
                  <td className="py-3 text-xs text-[#767576] pr-1">
                    {dataa.user_name}
                  </td>
                  <td className="py-3 text-xs text-[#767576] pr-1 ">
                    {dataa.remaining_hours}
                  </td>
                  <td className="py-3 text-xs text-[#767576] pr-1">
                    {moment(dataa.created_at).format("YYYY-MM-DD hh:mm:ss a")}
                  </td>
                  <td className="py-3 text-xs text-[#767576] pr-1">
                    <span
                      className={classNames(
                        " px-2 font-medium text-xs py-1 rounded-full",
                        {
                          "bg-[#FFF8E1] text-[#FFC107]":
                            dataa.status === "Pending",
                          "bg-[#D7F1F4] text-[#58BBCF] ":
                            dataa.status === "In Progress",
                          "bg-[#B9F6CA] text-[#00C853]":
                            dataa.status === "Completed",
                        }
                      )}
                    >
                      {dataa.status}
                    </span>
                  </td>

                  <td className="py-1 text-xs flex gap-3 text-center">
                    <button
                      onClick={() =>
                        navigate(
                          location.pathname === "/coachreview/list"
                            ? `/coachreview/list/details`
                            : `/coachreview/tickets/${dataa._id} `,
                          { state: dataa }
                        )
                      }
                    >
                      <img className="h-8" src={"/images/actionbutton.svg"} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {listLoading === Status.LOADING && <TableSkeleton tableColumns={7} />}
        </TableComponent>
        <PaginationComponent
          paginationFunction={pageChange}
          active={active}
          total={total}
        />
      </BoxLayout>
    </>
  );
};

export default CoachDashboard;

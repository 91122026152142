import AddObjectsPage from "../../Pages/Objects/AddObjectsPage";
import EditObjectPage from "../../Pages/Objects/EditObjectPage";
import ObjectPage from "../../Pages/Objects/ObjectListPage";
import AdminRoutes from "../../auth/AdminRoutes";

export const objectRoutes = [
  {
    id: "object1",
    name: "Objects List",
    module: "Content Repository",
    path: "/contentrepository/objects",
    component: ObjectPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Content Repository",
        path: "/contentrepository/objects",
      },
      {
        name: "Objects List",
        path: "#",
      },
    ],
  },
  {
    id: "object2",
    name: "Add Objects",
    module: "Content Repository",
    path: "/contentrepository/addobjects",
    component: AddObjectsPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Content Repository",
        path: "/contentrepository/objects",
      },
      {
        name: "Add Object",
        path: "#",
      },
    ],
  },
  {
    id: "object3",
    name: "Edit Objects",
    module: "Content Repository",
    path: "/contentrepository/editobjects",
    component: EditObjectPage,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Content Repository",
        path: "/contentrepository/objects",
      },
      {
        name: "Edit Object",
        path: "#",
      },
    ],
  },
];

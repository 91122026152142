import { useEffect } from 'react'
import BoxLayout from '../../Containers/Layout/BoxLayout'
import AddTrustQuote from '../../Containers/TrustShotCheck/AddTrustQuote'
import { useAppDispatch } from '../../Redux/app/hooks'
import { getShotCheckOverViewAPI, getTrustOverViewAPI } from '../../Redux/features/TrustShotCheckSlice'
import AddShotCheckOverviewForm from '../../Containers/TrustShotCheck/AddShotCheckForm'
import AddTrustOverviewForm from '../../Containers/TrustShotCheck/AddTrustOverviewForm'
import AddTrustKey from '../../Containers/TrustShotCheck/AddTrustKey'

type Props = {}

const TrustPage = (props: Props) => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(getShotCheckOverViewAPI())
        dispatch(getTrustOverViewAPI())
    }, [])

    return (
        <div><BoxLayout HeaderName='Manage Trust' sectionName='Trust'>
            <div className='border-t py-2'>
                <AddTrustOverviewForm />
                <AddShotCheckOverviewForm />
                <AddTrustQuote />
                <AddTrustKey />
            </div>
        </BoxLayout ></div >
    )
}

export default TrustPage
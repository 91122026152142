import React, { KeyboardEvent } from "react";
import { AddRecallCardForm } from "../../Pages/RecallCards/AddRecallCard";
import { hasDuplicateLinks } from "../../utils/helper";
import { notifyError } from "../../helper";
import { useAppDispatch } from "../../Redux/app/hooks";
import { toast } from "react-toastify";
import {
  getRecallCards,
  postRecallCardsAPI,
} from "../../Redux/features/RecallCardsSlice";
import { useNavigate } from "react-router-dom";

type Props = {
  order: number;
  data: any;
};

const TableInput = ({ order, data }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      if (String(event.currentTarget.value) !== String(data.order)) {
        const form = new FormData();
        form.append("id", data._id);
        form.append("object_id", data.object_id);
        form.append("title", data.title);
        form.append("order", event.currentTarget.value);
        form.append("question_img", data.question_img);
        form.append("answer_img", data.answer_img);
        form.append("answer_text", data.answer_text);
        form.append("question_text", data.question_text);
        form.append("tag", data.tag);
        form.append("level", data.level);
        form.append("link_items", JSON.stringify(data.link_items));

        dispatch(postRecallCardsAPI(form)).then((res) => {
          const response = res?.payload as PostRecallCardsResponse;
          if (response.success) {
            toast.success("Recall Card Edited");
            dispatch(
              getRecallCards({
                page: 1,
                sortType: "ascending",
                sortValue: "order",
                perPage: 50,
              })
            ).then((res) => {});
          } else {
            toast.error("Failed to add RecallCard");
          }
        });
      }
    }
  };

  return (
    <div className="py-[5px] w-min rounded-[8px] px-[17.5px] border border-[#EAF0F3] shadow-[0px_4px_10px_0px_#5B5B5B0D]">
      <input
        defaultValue={order}
        type="number"
        onKeyPress={(e) => handleKeyPress(e)}
        className="text-[12px] text-[#1F3161] w-5 text-center outline-none flex "
      />
    </div>
  );
};

export default TableInput;

import { useEffect, useMemo, useState } from "react";
import SessionDetails from "../../Containers/Session/SessionDetails";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { placeholder } from "@babel/types";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  sessionPostAPI,
  sessionTypeAPI,
} from "../../Redux/features/SessionSlice";
import {
  CategoryTypeListAPI,
  ObjectListAPI,
  SimpleObjectListAPI,
} from "../../Redux/features/ObjectSlice";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import SessionObject from "../../Containers/Session/SessionObject";
import { SessionAddFormValues } from "./AddSessionPage";
import { getRecallCards } from "../../Redux/features/RecallCardsSlice";
import OverviewDetails from "../../Containers/Session/OverviewDetails";
import ArrowIcon from "../../Components/Icons/ArrowIcon";

type Props = {};

const EditSessionPage = (props: Props) => {
  const location = useLocation();
  console.log(location.state);
  const [objectList, setObjectList] = useState<
    Array<{
      object_id: string;
      permission: 0 | 1 | "";
      time: number;
      title: string;
      description: string;
    }>
  >([]);
  const allObjects = useAppSelector((state) => state.Object.objectlist);

  useEffect(() => {
    setObjectList(
      location.state.session.object_list.map((item: any, index: any) => {
        if (Array.isArray(location?.state?.session?.overview?.list_items)) {
          return {
            ...item,
            ...location?.state?.session?.overview?.list_items[index],
          };
        } else {
          return { ...item };
        }
      })
    );
  }, [location.state.session]);

  const validationSchema = Yup.object().shape({
    sessionType: Yup.string().required("Session Type Required"),
    categoryType: Yup.string().required("Category Type Required"),
    sessionName: Yup.string().required("Session Name Required"),
    internalSessionName: Yup.string(),
    description: Yup.string(),
    //headline: Yup.string().required("Headline Required"),
    overview: Yup.object().shape({
      headline: Yup.string().required("Overview Headline Required"),
      text: Yup.string().required("Description Required"),
      equipments: Yup.string(),
    }),
    recall_cards: Yup.array(),
  });

  useEffect(() => {
    document.title = "FISIO | Edit Session";
  }, []);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(sessionTypeAPI());
    dispatch(CategoryTypeListAPI());
    dispatch(SimpleObjectListAPI());
    dispatch(getRecallCards({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      sessionType: location.state.session.session_type_id,
      categoryType: location.state.session.category_type_id,
      sessionName: location.state.session.session,
      //headline: location.state.session.headline,
      description: location.state.session.description,
      order: location.state.session.order,
      text: location.state.session.text,
      overview: location.state.session.overview,
      internal_session_name: location.state.session.internal_session_name,
      // recall_cards: location.state.session.recall_cards
      // recall_cards: location.state?.session?.recall_cards,
    },
  });
  console.log(errors);

  const moveDrills = useMemo(() => {
    return allObjects
      .map((item1) => {
        const matchedItem = objectList?.find(
          (item2) => item2?.object_id === item1?._id
        );
        if (matchedItem) {
          return {
            ...item1,
            ...matchedItem,
          };
        }
        return null;
      })
      ?.filter((item) => item)
      .reduce((acc: any, item: any) => acc + Number(item?.reps), 0);
  }, [objectList]);

  console.log(moveDrills, "ssssss");

  const formValues = watch();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.Session.sessionPostAPIIdle);
  const onSubmit = (data: SessionAddFormValues) => {
    let hasNoEmptyString = objectList.every(
      (item) => item.object_id !== "" && item.permission !== ""
    );
    console.log("out");
    if (hasNoEmptyString && objectList.length > 0) {
      console.log("in");
      dispatch(
        sessionPostAPI({
          id: location.state.session.id,
          session_type_id: data.sessionType,
          category_type_id: data.categoryType,
          session_name: data.sessionName,
          internal_session_name: data.internal_session_name,
          // headline: data.headline,
          description: data.description,
          text: data.text,
          overview: {
            text: data.overview.text,
            headline: data.overview.headline,
            time: 0,
            is_equipments_needed:
              data.overview.equipments !== "" ? true : false,
            equipments: data.overview.equipments,
            recall_cards_count: data.recall_cards?.length || 0,
            move_drills_count: moveDrills,
            list_items: objectList.map((items) => {
              return {
                title: items.title,
                description: items.description,
                time: items.time,
              };
            }),
          },
          session_object: objectList.map((items) => {
            return {
              time: items.time,
              permission: items.permission,
              object_id: items.object_id,
            };
          }),
          recall_cards: data.recall_cards?.map((i) => {
            return { recall_card_id: i.value };
          }),
        })
      ).then((res) => {
        navigate("/contentrepository/session");
      });
    }
  };
  const AllRecallCards = useAppSelector(
    (state) => state.RecallCards.recallCards
  );

  console.log(
    allObjects
      .map((item1) => {
        const matchedItem = objectList?.find(
          (item2) => item2?.object_id === item1?._id
        );
        if (matchedItem) {
          return {
            ...item1,
            ...matchedItem,
          };
        }
        return null;
      })
      ?.filter((item) => item)
      .reduce((acc: any, item: any) => acc + Number(item?.reps), 0),
    "ssssss"
  );

  useEffect(() => {
    setValue(
      "recall_cards",
      AllRecallCards.map((item) => {
        const recallCardId = item._id;
        const recallCard = location.state?.session?.recall_cards.find(
          (card: any) => card.recall_card_id === recallCardId
        );

        if (recallCard) {
          return {
            value: recallCard.recall_card_id,
            label: item.question_text,
          };
        }

        return null;
      }).filter((item) => item !== null)
    );
  }, [AllRecallCards]);

  // useEffect(() => {
  //   setObjectList(location.state.session.object_list);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location.state.session.object_list]);

  return (
    <BoxLayout sectionName="Edit Session" HeaderName="Content Repository">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 border-t p-4">
          <div className="rounded-[10px] w-full  border">
            <p className=" p-4 text-base font-semibold border-b">
              Session Details
            </p>
            <SessionDetails
              register={register}
              errors={errors}
              formValues={formValues}
              control={control}
            />
          </div>

          <SessionObject
            ObjectList={objectList}
            setObjectList={setObjectList}
            register={register}
            control={control}
            errors={errors}
            formValues={formValues}
            placeholder={placeholder}
          />

          <OverviewDetails
            ObjectList={objectList}
            control={control}
            errors={errors}
            formValues={formValues}
            placeholder={placeholder}
            register={register}
            setObjectList={setObjectList}
          />
        </div>
        <div className="px-4 py-3 col-span-12 grid grid-cols-10">
          <button
            type="button"
            onClick={() => navigate("/contentrepository/session")}
            className="w-fit gap-4 flex items-center col-start-1 col-span-1 text-[#1F3161]"
          >
            <img src="/images/arrow-left.svg" alt="" /> Back
          </button>
          <ButtonComponent
            key={1}
            buttonType="submit"
            className="col-span-1  col-end-11"
            CTA="Save"
            varient="blue"
            loading={loading}
          />
        </div>
      </form>
    </BoxLayout>
  );
};

export default EditSessionPage;

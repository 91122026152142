import { ActionCreatorWithPayload, ThunkDispatch } from "@reduxjs/toolkit";
import { FieldArrayMethodProps, FieldArrayWithId } from "react-hook-form";
import { AddRecallCardForm } from "../Pages/RecallCards/AddRecallCard";
import moment from "moment";
import { getUserProgressTracking, userProgress } from "../types/apiresponse";
import * as XLSX from "xlsx";

// Load the workbook
const loadWorkbook = (path: string): XLSX.WorkBook => {
  return XLSX.readFile(path);
};

const reorderColumns = (data: any[], order: string[]): any[] => {
  const headers: string[] = data[0];
  const headerIndexMap: { [key: string]: number } = headers.reduce(
    (acc: any, header: any, index) => {
      acc[header] = index;
      return acc;
    },
    {}
  );

  return data.map((row) => {
    return order.map((key) => row[headerIndexMap[key]] || "");
  });
};

export function translateMinutes(totalMinutes: number) {
  // Calculate the hours by dividing the total minutes by 60 and flooring the result
  let hours = Math.floor(totalMinutes / 60);

  // Calculate the remaining minutes by taking the modulus of the total minutes and 60
  let remainingMinutes = totalMinutes % 60;
  if (hours !== 0) {
    return (
      <>
        {hours} hours {remainingMinutes ? `${remainingMinutes} minutes` : ""}
      </>
    );
  } else {
    return <>{totalMinutes} minutes</>;
  }
}

export function exportToExcel(
  array: any[],
  fileName: string,
  headers?: string[]
) {
  // Create a new workbook
  const wb = XLSX.utils.book_new();

  // Convert the array to a worksheet with a specified header order
  const ws = XLSX.utils.json_to_sheet(array, {
    header: headers ? headers : [],
  });

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  // Write the workbook and export it
  XLSX.writeFile(wb, `${fileName}.xlsx`);
}

export function formatTime(minutesInput: number): string {
  // Check if the input is not a number
  if (isNaN(minutesInput)) {
    return "";
  }

  // Convert the total minutes to total seconds
  let totalSeconds = Math.floor(minutesInput * 60);

  // Calculate hours, minutes, and seconds
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds = totalSeconds % 3600;

  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;

  // Construct the formatted string based on the conditions
  if (hours > 0) {
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} hrs`;
  } else {
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")} mins`;
  }
}

export const manageSorting = (
  type: sortValues,
  value: string,
  dispatch: ThunkDispatch<any, any, any>,
  handleSort: ActionCreatorWithPayload<any, string>
) => {
  switch (type) {
    case "ascending":
      dispatch(handleSort({ sortType: "descending", sortValue: value }));
      break;
    default:
      dispatch(handleSort({ sortType: "ascending", sortValue: value }));
      break;
  }
};

export const convertToHHMMSS = (decimalMinutes: number): string => {
  let minutes = Math.floor(decimalMinutes);
  let seconds = Math.round((decimalMinutes - minutes) * 60);

  return `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
};

export const convertToHoursMinutes = (timeString: string): string => {
  const timeComponents = timeString.split(":");

  if (timeComponents.length !== 2) {
    // throw new Error("Incorrect time format. Expected format is 'mm:ss'");
    return `0 minutes`;
  }

  const minutes = parseInt(timeComponents[0]);
  const seconds = parseInt(timeComponents[1]);

  if (isNaN(minutes) || isNaN(seconds)) {
    return `0 minutes`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  // Check if hours equals 0
  if (hours === 0) {
    return `${remainingMinutes} minutes`;
  }

  return `${hours} hours ${remainingMinutes} minutes`;
};

export function formatSecondsToHMS(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);
  const secs = seconds - hours * 3600 - minutes * 60;

  let hoursStr = hours.toString();
  let minutesStr = minutes.toString();
  let secondsStr = secs.toString();

  if (hours < 10) {
    hoursStr = "0" + hours;
  }
  if (minutes < 10) {
    minutesStr = "0" + minutes;
  }
  if (secs < 10) {
    secondsStr = "0" + secs.toFixed(0);
  }

  return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

type MetaLinkFormType = {
  link: string;
  title: string;
  description: string;
};

export const updateFieldArray = (
  newUrls: {
    url: string;
    text: string;
  }[],
  append: (
    value: MetaLinkFormType | MetaLinkFormType[],
    options?: FieldArrayMethodProps | undefined
  ) => void,
  fields:
    | FieldArrayWithId<AddRecallCardForm, "answer_link_items", "id">[]
    | FieldArrayWithId<AddRecallCardForm, "question_link_items", "id">[],
  update: (index: number, value: MetaLinkFormType) => void,
  remove: (index?: number | number[] | undefined) => void,
  description?: string,
  title?: string
) => {
  // Update existing items and remove items that are no longer in the new URLs

  fields.forEach((field, index) => {
    if (newUrls[index] && field.link !== newUrls[index].url) {
      update(index, {
        link: newUrls[index].url,
        description: field.description,
        title: newUrls[index].text,
      });
    } else if (!newUrls[index]) {
      remove(index);
    }
  });

  // Add new items that are not already in the fields
  newUrls.slice(fields.length).forEach((url) => {
    append({
      link: url.url,
      description: description || "",
      title: title || url.text,
    });
  });
};

export const extractUrlsAndText = (htmlString: string) => {
  console.log(htmlString, "HTML String");
  const urlRegex = /<a href="(.*?)".*?>(.*?)<\/a>/gi;
  let match;
  const urlsAndText = [];

  while ((match = urlRegex.exec(htmlString)) !== null) {
    urlsAndText.push({
      url: match[1],
      text: extractTextFromHTML(match[2]),
    });
  }

  return urlsAndText;
};

export function extractTextFromHTML(htmlString: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  return doc.body.textContent || "";
}

export function hasDuplicateLinks(array1: any[], array2?: any[]) {
  // Combine both arrays
  let combinedArray = array1.concat(array2);

  // Create a Set to track unique links
  let linksSet = new Set();

  // Iterate through the combined array
  for (let item of combinedArray) {
    if (linksSet.has(item.link)) {
      // Duplicate link found
      return true;
    }
    linksSet.add(item.link);
  }

  // No duplicates found
  return false;
}

export const removeUrlAndUpdateHtml = (
  urlToRemove: string,
  htmlString: string,
  objectArrayFields: any,
  setValue: any,
  remove: any
) => {
  // Find the index of the URL to remove
  console.log(urlToRemove, htmlString, "Hello");
  const indexToRemove = objectArrayFields.findIndex(
    (field: any) => field.link === urlToRemove
  );

  if (indexToRemove > -1) {
    // Remove the URL from the field array
    remove(indexToRemove);

    // Remove the anchor tag from the HTML string
    const updatedHtmlString = htmlString.replace(
      new RegExp(`<a href="${urlToRemove}".*?>(.*?)</a>`, "gi"),
      "$1"
    );
    // Update the form value
    setValue("text", updatedHtmlString);
  }
};

export function categorizeDate(dateStr: string): string {
  const inputDate = moment(dateStr, "YYYY-MM-DD HH:mm");
  const currentDate = moment(); // Set current date to start of the day

  // Calculate the difference in days and hours
  const differenceInDays = currentDate.diff(inputDate, "days");
  const differenceInHours = currentDate.diff(inputDate, "hours");

  // Categorize the difference
  let category;
  if (
    differenceInDays < 3 ||
    (differenceInDays === 3 && differenceInHours < 24)
  ) {
    category = "within 3 days";
  } else if (differenceInDays <= 7) {
    category = "4-7 days";
  } else {
    category = "7+ days";
  }
  return category;
}

// Function to format the user data
// Function to format the user data
export function formatUserData(newdata: getUserProgressTracking) {
  let data = newdata;
  const user = data.user;
 
  const formattedData: { [key: string]: string | undefined } = {
    Name: user.full_name,
    Program: user.group_name,
    "Reg Date": moment(user.registered_at).format('MMM D, YYYY'),
    first_object: moment(data.first_object).format('MMM D, YYYY')
  };

  // Extracting answers from user_question_answer
  const answers: { [key: string]: string } = {};
  data.user_question_answer.forEach((item) => {
    answers[item.question] = item.answer;
  });

  // Add specific answers to formattedData
  formattedData.Importance =
    answers["How important is it to you to get better at golf?"];
  formattedData.availableTime =
    answers[
      "Are you able to find 10-15 minutes on most days for guided at-home golf training sessions?"
    ];

  // Sort and filter unique sessions by date
  const sortedTrainingSessions = [...data?.user_progress_train].sort(
    (a, b) => new Date(b?.date).getTime() - new Date(a?.date).getTime()
  );
  const uniqueSessions: { [key: string]: userProgress } = {};
  sortedTrainingSessions.forEach((session) => {
    const day = session.date.split("T")[0]; // Extract date without time
    if (
      !uniqueSessions[day] ||
      new Date(session.date) > new Date(uniqueSessions[day].date)
    ) {
      uniqueSessions[day] = session;
    }
  });

  // Add sorted and unique sessions to formattedData
  const sessionKeys = Object.keys(uniqueSessions).sort(
    (a, b) => new Date(b).getTime() - new Date(a).getTime()
  );
  sessionKeys.forEach((key, index) => {
    formattedData[
      index + 1
    ] = `${uniqueSessions[key].unit_name}-${uniqueSessions[key].session_name}`;
  });

  return formattedData;
}

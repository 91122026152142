import { useEffect, useState } from "react";
import BoxLayout from "../../../../Containers/Layout/BoxLayout";
import SearchBoxComponent from "../../../../Components/Inputs/SearchBoxComponent";
import ExcelIcon from "../../../../Components/Icons/ExcelIcon";
import TableComponent from "../../../../Components/TableComponent";
import { RatingTableHeader } from "../../../../utils/TableColumns";
import PaginationComponent from "../../../../Components/PaginationComponent";
import { useAppDispatch, useAppSelector } from "../../../../Redux/app/hooks";
import {
  getRatings,
  ratingListHandleSort,
} from "../../../../Redux/features/UserDashboardSlice";
import { useParams } from "react-router-dom";
import SelectComponent from "../../../../Components/Inputs/SelectComponent";
import { useForm } from "react-hook-form";
import { exportToExcel } from "../../../../utils/helper";
import { DropDownStylesV2 } from "../../../../helper";
import { Status } from "../../../../Redux/features/UserManagementSlice";
import TableSkeleton from "../../../../Skeleton/TableSkeleton";

type Props = {};

const RatingPage = (props: Props) => {
  const pageChange = (pageNumber: number) => {
    console.log(pageNumber);
    dispatch(
      getRatings({
        page: pageNumber,
        type: ratingType.ratingType,
        filteredValue: userListValue,
        perPage: 10,
        userId: id || "",
      })
    ).then((response) => {});
  };

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { control, watch } = useForm({
    defaultValues: {
      ratingType: "",
    },
  });
  const ratingType = watch();
  console.log(ratingType);

  const [userListValue, setUserListValue] = useState("");
  const getSearchValue = (d: string) => {
    setUserListValue(d);
    // dispatch(UserListAPI({ page: 1, userType: "admin", searchValue: d }));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(
        getRatings({
          page: 1,
          type: ratingType.ratingType,
          filteredValue: userListValue,
          perPage: 10,
          userId: id || "",
        })
      ).then((response) => {});
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userListValue, ratingType.ratingType]);

  const currentPage = useAppSelector(
    (state) => state.UserDashboard.currentRatingPage
  );
  const totalPages = useAppSelector(
    (state) => state.UserDashboard.ratingListPage
  );
  const ratings = useAppSelector((state) => state.UserDashboard.userRatingData);
  const getRatingsAPI = useAppSelector(
    (state) => state.UserDashboard.getRatingAPIStatus
  );
  const sortType = useAppSelector(
    (state) => state.UserDashboard.ratingSortType
  );
  const sortValue = useAppSelector(
    (state) => state.UserDashboard.ratingSortValue
  );
  useEffect(() => {
    if (id) {
      dispatch(
        getRatings({
          filteredValue: "",
          page: 1,
          perPage: 10,
          type: ratingType.ratingType,
          sortType: sortType,
          sortValue: sortValue,
          userId: id,
        })
      );
    }
  }, [sortType]);

  const manageSorting = (type: sortValues, value: string) => {
    switch (type) {
      case "ascending":
        dispatch(
          ratingListHandleSort({ sortType: "descending", sortValue: value })
        );
        break;
      case "descending":
        dispatch(ratingListHandleSort({ sortType: null, sortValue: "" }));
        break;
      default:
        dispatch(
          ratingListHandleSort({ sortType: "ascending", sortValue: value })
        );
        break;
    }
  };

  return (
    <>
      <BoxLayout HeaderName="User Profile" sectionName="">
        <div className="h-[calc(100vh-87.6px-8rem)] rounded-md border mx-5 my-3">
          <div className="flex gap-4 px-4 py-2 border-b justify-between">
            <div className="font-semibold items-center h-[3.5rem] flex gap-2">
              <p className="w-36">Rating List</p>
              <div className="w-full">
                <SelectComponent
                  selectStyle={DropDownStylesV2}
                  control={control}
                  inputRef="ratingType"
                  options={[
                    { label: "All", value: "" },
                    {
                      label: "Weekly Rating",
                      value: "weekly_rating",
                    },
                    {
                      label: "Session Rating",
                      value: "session_rating",
                    },
                    {
                      label: "Self Check Rating",
                      value: "scc_rating",
                    },
                  ]}
                />
              </div>
            </div>
            <div className="flex gap-3 items-center justify-center">
              <SearchBoxComponent getSearchValue={getSearchValue} />{" "}
              <button
                onClick={() =>
                  exportToExcel(
                    ratings.map((i) => {
                      return {
                        ratingType: i.type,
                        date: i.date,
                        result: i.rating,
                        text: i.text,
                      };
                    }),
                    `rating_pageno=${currentPage}`
                  )
                }
              >
                {" "}
                <ExcelIcon />
              </button>
            </div>
          </div>
          <TableComponent
            headerPadding="px-4"
            sortType={sortType}
            sortValue={sortValue}
            handleSortAction={ratingListHandleSort}
            onClick={manageSorting}
            loader={getRatingsAPI}
            TableHeaderData={RatingTableHeader}
            sortIcon
            staticHeight
            heightClass={
              getRatingsAPI === Status.SUCCESS && ratings.length !== 0
                ? "h-[calc(100vh-87.6px-15.75rem)]"
                : ""
            }
          >
            {getRatingsAPI === Status.SUCCESS ? (
              <tbody className=" overflow-clip">
                {ratings.map((i) => (
                  <tr className="text-[#767576] text-xs px-2 border-t">
                    <td className="font-medium px-4 text-[#212121]">
                      {i.type}
                    </td>
                    <td className=" py-4  px-4 text-[#212121]">{i.date}</td>
                    <td className=" gap-3 mr-auto  px-4 my-auto">
                      <span className="flex items-center gap-3">
                        {typeof i.rating === "number" ? (
                          <img src={`/images/cards/${i.rating}.svg`} alt="" />
                        ) : (
                          ""
                        )}
                        <span>{i.rating}</span>
                      </span>
                    </td>
                    <td className=" w-1/2 px-2">{i.text}</td>
                  </tr>
                ))}
              </tbody>
            ) : (
              <></>
            )}
            {getRatingsAPI === Status.LOADING ? (
              <TableSkeleton tableColumns={4} />
            ) : (
              <></>
            )}
          </TableComponent>
          {getRatingsAPI === Status.SUCCESS && ratings?.length === 0 ? (
            <div className="h-[calc(100vh-87.6px-17.75rem)] flex flex-col justify-center">
              <p className=" flex justify-center text-[1.25rem]  font-black text-[#757575]">
                No Data Available
              </p>
              <p className="font-medium flex justify-center text-[0.875rem] text-[#757575]">
                You can choose Category by clicking the “choose Category “
                Dropdown at the left side of the screen
              </p>
            </div>
          ) : (
            <></>
          )}
          <PaginationComponent
            active={Number(currentPage)}
            total={totalPages}
            paginationFunction={pageChange}
          />
        </div>
      </BoxLayout>
    </>
  );
};

export default RatingPage;

import React from 'react'
import ContactUsSection from './ContactUsSection'
import ResetPasswordSection from './ResetPasswordSection'
import DeleteAccount from './DeleteAccount'
import SupportSection from './SupportSection'

type Props = {}

const Support = (props: Props) => {
    return (
        <>
            {/* <div className='grid grid-cols-4 gap-6'>
                <div className='border rounded-lg'>
                    <ContactUsSection />
                </div>
                <div className='col-span-2  rounded-lg'>
                    <div className='border rounded-lg'>
                        <ResetPasswordSection />
                    </div>
                </div>
                <div className='border rounded-lg'>
                    <div className=' rounded-lg h-full'>
                        <DeleteAccount />
                    </div>
                </div>
                <div className='col-span-4 border rounded-lg'>
                    <SupportSection />
                </div>
            </div> */}
            <p className='text-center font-bold'>Yet to develop </p>
        </>
    )
}

export default Support
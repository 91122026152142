import { useEffect, useState } from "react";
import FilterSortPanel from "../../Containers/Layout/FilterSortPanel";
import TableComponent from "../../Components/TableComponent";
import { UnitTypeListTableHeader } from "../../utils/TableColumns";
import moment from "moment";
import DeleteIcon from "../../Components/Icons/DeleteIcon";
import EditIcon from "../../Components/Icons/EditIcon";
import ModalComponent from "../../Components/PopUp/ModalComponent";
import { useNavigate } from "react-router";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import {
  UnitDeleteAPI,
  handleSort,
  unitListAPI,
  unitListSort,
} from "../../Redux/features/UnitSlice";
import TableSkeleton from "../../Skeleton/TableSkeleton";
import { formatTime, manageSorting } from "../../utils/helper";
import { sessionListAPI } from "../../Redux/features/SessionSlice";
import CopyIcon from "../../Components/Icons/CopyIcon";
import { Status } from "../../Redux/features/UserManagementSlice";
import PaginationComponent from "../../Components/PaginationComponent";
import { useIsMount } from "../../CustomHooks/useIsMount";

const UnitListPage = () => {
  const [id, setId] = useState("0");
  const [showDeleteForm, setDeleteForm] = useState(false);
  const UnitList = useAppSelector((state) => state.Unit.unitList);
  const navigate = useNavigate();
  const [perPage, setPerPage] = useState<{ label: string; value: number }>({
    label: "Show 50",
    value: 50,
  });
  const setPerPageValue = (value: { label: string; value: number }) => {
    setPerPage(value);
    dispatch(
      unitListAPI({
        page: 1,
        searchValue: adminListValue,
        sortType: sortType,
        sortValue: sortValue,
        per_page: value.value,
      })
    );
  };
  const handleDeleteClick = (id: string) => {
    setDeleteForm(true);
    setId(id);
  };
  const sortType = useAppSelector((state) => state.Unit.sortType);
  const sortValue = useAppSelector((state) => state.Unit.sortValue);
  const handleEditClick = (data: UnitItem) => {
    navigate("/contentrepository/editunit", {
      state: {
        id: data.id,
        unitname: data.unit,
        category: data.category_type,
        session: data.session,
        category_type_id: data.category_type_id,
        order: data.order,
      },
    });
  };

  const ConfirmDelete = () => {
    dispatch(UnitDeleteAPI(id)).then(() => {
      setDeleteForm(false);
      dispatch(unitListAPI({ per_page: perPage.value }));
    });
  };
  const handleCloseDeleteModal = () => {
    setDeleteForm(false);
  };

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(sessionListAPI({ sortType }));
    dispatch(
      unitListAPI({
        page: 1,
        per_page: perPage.value,

        sortType: sortType,
        sortValue: sortValue,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortType]);

  const loading = useAppSelector((state) => state.Unit.UnitDeleteAPIIdle);
  const listLoader = useAppSelector((state) => state.Unit.unitListAPIIdle);

  const getSortValue = (d: any) => {
    dispatch(unitListSort());
  };

  const [adminListValue, setAdminListValue] = useState();
  const isMount = useIsMount();
  useEffect(() => {
    if (adminListValue) {
      const delayDebounceFn = setTimeout(() => {
        dispatch(
          unitListAPI({
            per_page: perPage.value,
            searchValue: adminListValue,
            sortType: sortType,
          })
        );
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminListValue]);

  const getSearchValue = (d: any) => {
    setAdminListValue(d);
  };

  useEffect(() => {
    document.title = "FISIO | List Units";
  }, []);

  const handleDuplicateClick = (data: UnitItem) => {
    console.log(data);
    navigate("/contentrepository/AddUnits", {
      state: {
        unit_name: data.unit,
        category_type_id: data.category_type_id,
        order: data.order,
        session: data.session,
      },
    });
  };
  const handlePage = (page: number) => {
    dispatch(
      unitListAPI({
        per_page: perPage.value,
        sortType: sortType,
        sortValue: sortValue,
        searchValue: adminListValue,
        page: page,
      })
    );
  };

  const activePage = useAppSelector((state) => state.Unit.active);
  const total = useAppSelector((state) => state.Unit.total);

  return (
    <>
      <BoxLayout HeaderName="Content Repository" sectionName="Unit List">
        <FilterSortPanel
          getSortValue={getSortValue}
          getSearchValue={getSearchValue}
          Function={() => navigate("/contentrepository/AddUnits")}
        ></FilterSortPanel>

        <TableComponent
          loader={listLoader}
          handleSortAction={handleSort}
          onClick={manageSorting}
          sortType={sortType}
          sortValue={sortValue}
          TableHeaderData={UnitTypeListTableHeader}
          sortIcon
        >
          {listLoader === Status.SUCCESS && (
            <tbody>
              {UnitList.map((data) => (
                <tr key={data.id} className="border-y border-y-[#EAF0F3] py-1 ">
                  <td className=" py-1 text-start text-[#212121] text-xs font-medium">
                    {data.unit}
                  </td>
                  {/* <td className=" py-1 text-start">{data.order}</td> */}
                  <td className="py-1 text-start text-gray-500">
                    {data.category_type}
                  </td>
                  <td className="py-1 text-start text-gray-500">
                    {formatTime(Number(data.time))}
                  </td>
                  <td className="py-1 text-start text-gray-500 font-weight: 500 font-size: 0.75rem">
                    {moment(data.last_update).format("DD-MM-YYYY hh:mm")}
                  </td>
                  <td className="py-1 flex gap-3 text-start">
                    <button onClick={() => handleDuplicateClick(data)}>
                      <CopyIcon />
                    </button>
                    <button onClick={() => handleEditClick(data)}>
                      <EditIcon />
                    </button>
                    <button onClick={() => handleDeleteClick(data.id)}>
                      <DeleteIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {listLoader === Status.LOADING && <TableSkeleton tableColumns={6} />}
        </TableComponent>
        <PaginationComponent
          paginationFunction={handlePage}
          active={activePage}
          setPerPageValue={setPerPageValue}
          perPageValue={perPage}
          total={total}
        />
      </BoxLayout>

      <ModalComponent
        message={"Are you sure to delete this units?"}
        isOpen={showDeleteForm}
        onClose={handleCloseDeleteModal}
        confirmAction={ConfirmDelete}
        loading={loading}
      />
    </>
  );
};

export default UnitListPage;

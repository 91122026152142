import AffiliateDetails from "../../Pages/Affiliate/AffiliateDetails";
import AffiliateList from "../../Pages/Affiliate/AffiliateList";
import AddFundamentalPage from "../../Pages/Guide/AddFundamentalPage";
import AdminRoutes from "../../auth/AdminRoutes";

export const affiliateRoutes = [
  {
    id: "affiliate1",
    name: "Affiliate List",
    module: "Affiliate",
    path: "/affiliate",
    component: AffiliateList,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Affiliate",
        path: "#",
      },
      {
        name: "Affiliate List",
        path: "#",
      },
    ],
  },
  {
    id: "affiliate2",
    name: "Affiliates",
    module: "Affiliate Details",
    path: "/affiliate/details",
    component: AffiliateDetails,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Affiliate List",
        path: "/affiliate",
      },
      {
        name: "Affiliate Details",
        path: "#",
      },
    ],
  },
];

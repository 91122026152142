import React, { useEffect } from "react";
import InputComponent from "../../../Components/Inputs/InputComponent";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import ButtonComponent from "../../../Components/Buttons/ButtonComponent";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import { postWelcomePage } from "../../../Redux/features/OnboardingQuestionsSlice";
import TextEditor from "../../../Components/Inputs/TextEditor";
import { notifySuccess } from "../../../helper";

type Props = {
  //   initialData: WelcomeInfo;
};

type FormValues = {
  text: string;
  image: FileList | string;
  subtext: string;
  type: string;
};

const WelcomeInfoForm = (props: Props) => {
  const data = useAppSelector(
    (state) => state.OnboardingQuestions.getWeclomePageInfo.data.welcome
  );
  const validationSchema = Yup.object().shape({
    text: Yup.string(),
    subtext: Yup.string(),
    type: Yup.string(),
  });

  const { register, setValue, watch, control, handleSubmit } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      subtext: data[0]?.subtext,
      text: data[0]?.text,
      type: data[0]?.type,
      image: data[0]?.image,
    },
  });

  useEffect(() => {
    setValue("subtext", data[0]?.subtext);
    setValue("text", data[0]?.text);
    setValue("type", data[0]?.type);
    setValue("image", data[0]?.image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const formValues = watch();

  const dispatch = useAppDispatch();
  const handleWelcomePage = (datas: FormValues) => {
    if (typeof datas.image !== "string") {
      let formData = new FormData();
      formData.append("id", data[0]._id);
      formData.append("subtext", datas.subtext);
      formData.append("type", data[0].type);
      formData.append("image", datas.image[0]);
      formData.append("text", datas.text);
      dispatch(postWelcomePage(formData)).then((res) => {
        if (res.payload.success) {
          notifySuccess("Text saved successfully");
        }
      });
    } else {
      let formData = new FormData();
      formData.append("id", data[0]._id);
      formData.append("subtext", datas.subtext);
      formData.append("type", data[0].type);
      formData.append("text", datas.text);
      dispatch(postWelcomePage(formData)).then((res) => {
        if (res.payload.success) {
          notifySuccess("Text saved successfully");
        }
      });
    }
  };
  const loading = useAppSelector(
    (state) => state.OnboardingQuestions.postWelcomePageIdle
  );
  return (
    <form
      onSubmit={handleSubmit(handleWelcomePage)}
      className="rounded-[10px] border-2 m-4"
    >
      <p className="text-md font-medium px-4 py-4 ">Welcome screen</p>
      <div className="grid grid-cols-12 gap-3 p-5 border-t-2">
        <TextEditor
          control={control}
          inputRef="text"
          name="subtext"
          className="w-full col-span-6 pb-8 rounded-lg h-fit"
          register={register}
          values={formValues.subtext}
          label="Text"
        />
        <TextEditor
          control={control}
          inputRef="subtext"
          name="subtext"
          className="w-full col-span-6 pb-8 rounded-lg h-fit"
          register={register}
          values={formValues.subtext}
          label="Subtext"
        />
      </div>
      <div className="grid px-2 grid-cols-12 items-center mx-4 mb-2">
        <label htmlFor="file-input" className="flex gap-2 text-sm ">
          <img src="/images/plusButton.svg" alt="" />
          <span className="">Add File</span>
        </label>
        <div className="text-start text-orange-400 items-center justify-between gap-4 text-sm  col-span-2">
          <div className="">
            <span className="text-blue-950">File Name : </span>
            {typeof formValues?.image !== "string" ? (
              <span className="w-[150px] whitespace-nowrap text-ellipsis ">
                {formValues.image && formValues?.image[0]?.name}
              </span>
            ) : (
              <span className="w-[150px] whitespace-nowrap text-ellipsis ">
                {formValues?.image?.split("/")[4]}
              </span>
            )}
          </div>
        </div>
        <Controller
          control={control}
          name="image"
          render={({ field: { onChange, ref } }) => (
            <input
              id="file-input"
              ref={ref}
              type="file"
              className="hidden"
              onChange={(e) => onChange(e.target.files)} // When the file changes, update it in the RHF store
            />
          )}
        />

        <ButtonComponent
          CTA="Save"
          className="col-span-1 col-start-12"
          buttonType="submit"
          varient="blue"
          loading={loading}
        />
      </div>
    </form>
  );
};

export default WelcomeInfoForm;



import { useEffect, useState } from "react";
import BreadCrumbComponent from "../../Components/BreadCrumbComponent";
import QuestionDetails from "../../Components/QuestionDetails";
import QuestionsOptions from "../../Components/QuestionsOptions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { AddQuestionAPI } from "../../Redux/features/OnboardingQuestionsSlice";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import classNames from "classnames";
import BoxLayout from "../../Containers/Layout/BoxLayout";
import SelectComponent from "../../Components/Inputs/SelectComponent";
import InputComponent from "../../Components/Inputs/InputComponent";
import { notifyError } from "../../helper";

type Props = {};

export type QuestionFormValues = {
  question: string;
  question_type: string;
  can_skip: string;
  subtitle: string;
};

const AddOnBoardingQuestionPage = (props: Props) => {
  const [selectAnswerList, setSelectanswerList] = useState<Array<string>>([
    "",
    "",
    "",
    "",
  ]);
  useEffect(() => {
    document.title = "FISIO | Add On Boarding Question";
  }, []);
  const validationSchema = Yup.object().shape({
    can_skip: Yup.string().required("A selection is required"),
    question_type: Yup.string(),
    question: Yup.string().required("Question is required"),
  });
  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      can_skip: "0",
    },
  });
  const options = [
    { label: "Radio", value: "radio" },
    { label: "Multi Choice", value: "multiple_choice" },
    { label: "OpenText Box", value: "opentext_box" },
  ];
  const formValues = watch();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(
    (state) => state.OnboardingQuestions.AddQuestionAPIIdle
  );
  const onSubmit = (data: QuestionFormValues) => {
    let hasNoEmptyString = selectAnswerList.every((item) => item !== "");
    console.log(data);
    if (formValues.question_type !== "opentext_box") {
      if (hasNoEmptyString && selectAnswerList.length >= 2) {
        dispatch(
          AddQuestionAPI({
            text: data.question,
            answers: selectAnswerList,
            question_type: data.question_type,
            can_skip: data.can_skip !== "0" ? true : false,
          })
        ).then((res) => navigate("/onboardingquestionsrepository"));
      } else {
        if (selectAnswerList.length >= 2) {
          notifyError("Please enter the value in all options.");
        } else {
          notifyError("Please add at least 2 options");
        }
      }
    } else {
      dispatch(
        AddQuestionAPI({
          text: data.question,
          answers: ["opentextbox"],
          question_type: data.question_type,
          subtitle: data.subtitle,
          can_skip: data.can_skip !== "0" ? true : false,
        })
      ).then((res) => navigate("/onboardingquestionsrepository"));
    }
  };
  useEffect(() => {
    const arrayOfError = Object.values(errors);
    if (Object.keys(errors).length !== 0) {
      arrayOfError.slice(0, 1).map((error: any) => notifyError(error.message));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  console.log(formValues, errors);
  return (
    <BoxLayout
      HeaderName="On Boarding questions"
      sectionName="Add On Boarding Question"
    >
      <div className="p-4 h-full">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex justify-between h-[90%] flex-col gap-4"
        >
          <div>
            <div className="grid grid-cols-3 gap-4 items-center">
              <SelectComponent<QuestionFormValues>
                control={control}
                inputRef="question_type"
                placeHolder="Select Question Type"
                options={options}
                label="Question Type"
              />

              <InputComponent
                // errorname={errors.question?.message}
                inputRef="question"
                label="Question"
                name="Quiestion"
                register={register}
                value={formValues.question}
              />
              <div className="col-span-1 justify-start flex flex-col gap-2 text-xs ml-4">
                <p className="mt-[-10px] text-[#757575]">Permission</p>
                <div className="flex">
                  <div className="flex flex-wrap gap-5 items-center">
                    <label className="flex gap-1 items-center ">
                      <input type="radio" {...register("can_skip")} value={0} />
                      <span className="custom-radio"></span>{" "}
                    
                      {"Required"}
                    </label>

                    <label className="flex gap-1 items-center">
                      <input type="radio" {...register("can_skip")} value={1} />
                      <span className="custom-radio"></span>{" "}
                   
                      {"Optional"}
                    </label>
                  </div>
                </div>
              </div>

              <div
                className={classNames("col-span-2", {
                  hidden: formValues.question_type !== "opentext_box",
                })}
              >
                <InputComponent
                  // errorname={errors.question?.message}
                  inputRef="subtitle"
                  label="Subtitle"
                  name="Quiestion"
                  register={register}
                  value={formValues.question}
                />
              </div>
            </div>
            <div
              className={classNames("py-3  ", {
                invisible: formValues.question_type === "opentext_box",
              })}
            >
              <QuestionsOptions
                answerList={selectAnswerList}
                setAnswerList={setSelectanswerList}
              />
            </div>
          </div>

          <div className="grid grid-cols-10 gap-4">
            <ButtonComponent
              CTA="Back"
              className="col-start-9"
              varient="outline"
              buttonType={"button"}
              onClick={() => navigate("/onboardingquestionsrepository")}
            />
            <ButtonComponent
              loading={loading}
              CTA="Save"
              varient="blue"
              buttonType={"submit"}
            />
          </div>
        </form>
      </div>
    </BoxLayout>
  );
};

export default AddOnBoardingQuestionPage;
import avatar from "../assets/navbar/avatar.svg";
import chat from "../assets/navbar/chat.svg";
import gear from "../assets/navbar/gear.svg";
import hamburger from "../assets/navbar/hamburger.svg";
import notification from "../assets/navbar/notification.svg";

export const navbarImages = {
  avatar,
  chat,
  gear,
  hamburger,
  notification,
};

import React from 'react';

interface IconProps {
  width?: number;
  height?: number;
  color?: string;
}

const CompletedSmallCircleIcon: React.FC<IconProps> = ({
  width = 16,
  height = 15,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 15"
      fill="none"
    >
      <circle cx="8" cy="7.5" r="7.5" fill="url(#paint0_linear_7494_74885)" />
      <path
        d="M4 7.5L6.66353 10L12 5"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7494_74885"
          x1="11.7079"
          y1="13.8281"
          x2="-1.69249"
          y2="-3.75382"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#285A9F" />
          <stop offset="1" stop-color="#C0DAFE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default CompletedSmallCircleIcon;

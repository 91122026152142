type Props = {};

const AddIcon = (props: Props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8332 6.83341H6.83317V11.8334H5.1665V6.83341H0.166504V5.16675H5.1665V0.166748H6.83317V5.16675H11.8332V6.83341Z"
        fill="white"
      />
    </svg>
  );
};

export default AddIcon;

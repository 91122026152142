import DiscountCodes from "../../Pages/DiscountCodes/DiscountCodesList";
import ReportList from "../../Pages/Report/ReportList";
import AdminRoutes from "../../auth/AdminRoutes";

export const discountCodes = [
  {
    id: "dc1",
    name: "Report",
    module: "Report",
    path: "/discountcodes",
    component: DiscountCodes,
    auth: AdminRoutes,
    breadCrumbRoutes: [
      {
        name: "Discount Codes",
        path: "#",
      },
    ],
  },
];

import React, { useState } from "react";
import ButtonComponent from "../../Components/Buttons/ButtonComponent";
import CenterPopUpComponent from "../../Components/PopUp/CenterPopUpComponent";
import ReactPlayer from "react-player";

type File = { name: string; size: string; link: string; extension: string };

const formatBytes = (bytes: number) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

const getFileExtension = (fileName: string) =>
  fileName.split(".").pop()?.toLowerCase();

const isVideoFile = (fileName: string) => {
  const videoExtensions = ["mp4", "avi", "mov"];
  const extension = getFileExtension(fileName);
  return extension ? videoExtensions.includes(extension) : false;
};

const formatFileWithIcon = (file: File) => (
  <div className="flex items-center">
    <img
      src="/images/videofileicon.svg"
      alt="File Icon"
      className="w-6 h-6 mr-2"
    />
    <div className="text-[#1F3161]">
      <div>{file.name}</div>
      {/* <div className="ml-2 text-[#9E9E9E]">10.24 MB</div> */}
    </div>
  </div>
);

const WatchVideoComp: React.FC<{ files: File[] }> = ({ files }) => {
  const videoFiles = files.filter((file) => isVideoFile(file.name));
  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl1, setVideoUrl1] = useState<string>("");
  const [videoUrl2, setVideoUrl2] = useState<string>("");

  const handleWatchVideosClick = () => {
    setIsOpen(true);
    if (videoFiles.length > 0) {
      setVideoUrl1(videoFiles[0].link);
    }
    if (videoFiles.length > 1) {
      setVideoUrl2(videoFiles[1].link);
    }
  };

  const handlePopupClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="rounded-[10px] w-full md:w-[375px] border border-[#C6D6DD] p-3 md:p-5 bg-[#EAF0F3]">
      <div className="grid gap-5 pr-2">
        <div className="grid grid-cols-2 items-center gap-20">
          <div>
            <div>Down the line</div>
            <div>{""}</div>
          </div>

          <div className="text-sm flex flex-col items-center text-gray-500">
            {videoFiles.length > 0 ? formatFileWithIcon(videoFiles[0]) : ""}
            <div className="text-[#757575]">
              {files[0].size === "" ? "N/A" : `${files[0].size}`}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 justify-between items-center gap-20">
          <div>
            Face On
            <div></div>
          </div>
          <div className="text-sm flex flex-col items-center text-gray-500">
            {videoFiles.length > 1 ? formatFileWithIcon(videoFiles[1]) : ""}
            <div className="text-[#757575]">
              {files[1].size === "" ? "N/A" : `${files[1].size}`}
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center gap-20"></div>
        {videoFiles.slice(2).map((file, index) => (
          <div key={index} className="flex justify-between gap-20">
            <div className="text-sm text-gray-500">
              {formatFileWithIcon(file)}
            </div>
            <div></div>
          </div>
        ))}
      </div>
      <div className="">
        <ButtonComponent
          CTA="Watch Videos"
          className="col-start-3"
          buttonType="button"
          varient="blue"
          onClick={handleWatchVideosClick}
        />

        <CenterPopUpComponent
          isOpen={isOpen}
          message="Watch Videos"
          onClose={handlePopupClose}
          // customStyle={{ width: "1600px", height: "500px" }}
        >
          <div className="grid grid-cols-2 gap-4  rounded-none ">
            <div className="max-h-[70vh] w-[]">
              <ReactPlayer
                width="100%"
                height="100%"
                url={videoUrl1}
                controls
              />
            </div>
            <div className="max-h-[70vh] w-[]">
              <ReactPlayer
                width="100%"
                height="100%"
                url={videoUrl2}
                controls
              />
            </div>
          </div>
        </CenterPopUpComponent>
      </div>
    </div>
  );
};

export default WatchVideoComp;

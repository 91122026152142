import React, { useEffect } from "react";
import DatePicker from "../../../../../Components/Calander/Calander";
import Collapsible from "../../../../../Components/Collapsible/Collapsible";
import RangeSlider from "../../../../../Components/Inputs/RangeSliderComponent";
import { useAppDispatch, useAppSelector } from "../../../../../Redux/app/hooks";
import { getProfile } from "../../../../../Redux/features/UserDashboardSlice";
import { useParams } from "react-router-dom";
import {
  QuestionAnswer,
  StreakCalander,
} from "../../../../../types/apiresponse";

interface Props {
  summaryData: QuestionAnswer[];
  detailsData: QuestionAnswer[];
  trainningPlan: {
    label: string;
    value: string;
  };
  calenderStreak: StreakCalander[];
  DailyRemainder: string;
}

const ProfileTimeline = ({
  summaryData,
  detailsData,
  trainningPlan,
  calenderStreak,
  DailyRemainder,
}: Props) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getProfile(id));
    }
  }, []);

  const ProfileData = useAppSelector(
    (state) => state.UserDashboard.dashBoardData
  );
  const valuePercentage =
    ((Number(ProfileData.training_intention.length) - 0) * 100) / (7 - 0);

  return (
    <>
      <div className="bg-white w-full shadow-section justify-center flex rounded-xl border border-[#C6D6DD]">
        <DatePicker calenderStreak={calenderStreak} />
      </div>
      <div className="h-full w-full border shadow-section border-[#C6D6DD] rounded-xl">
        <Collapsible
          title="On-boarding Questions"
          summaryFragment={
            <div className="max-h-[300px] overflow-auto ">
              {summaryData.map((summary) => (
                <div className="p-[0.62rem] w-full  flex flex-col justify-center rounded-lg h-full m-auto text-[0.75rem] text-[#212121] bg-[#f6f6f6] ">
                  <p className="font-medium">{summary.question}</p>
                  <p className="text-[#767576]">{summary.answer}</p>
                </div>
              ))}
            </div>
          }
          detailsFragment={
            <div className="max-h-[300px] flex flex-col gap-5 overflow-auto">
              {detailsData.map((detail) => (
                <div className="p-[0.62rem] w-full flex flex-col justify-center rounded-lg  m-auto text-[0.75rem] text-[#212121] bg-[#f6f6f6]">
                  <p className="font-medium">{detail.question}</p>
                  <p className="text-[#767576]">{detail.answer}</p>
                </div>
              ))}
            </div>
          }
        />
      </div>
      <div className="h-full bg-white shadow-section w-full rounded-xl border border-[#C6D6DD]">
        <div className="m-4 flex items-center justify-between">
          <div className="text-sm font-bold">Daily Reminder</div>
          {DailyRemainder ? (
            <span
              style={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              className="bg-[#f9f9f9] rounded-[12px] flex gap-2  font-medium border-[#1F3161] text-[#1F3161] px-1 py-2"
            >
              <img src="/images/alert.svg" alt="s" className="w-4" />
              {DailyRemainder}
            </span>
          ) : (
            <></>
          )}
        </div>
        {DailyRemainder ? (
          <div className="flex items-center justify-start px-4 gap-2 text-[#00C853] py-2">
            <img src="/images/sucess.svg" alt="s" className="w-4" />
            The user has set his daily reminder
          </div>
        ) : (
          <div className="flex  items-center justify-start px-4 gap-2 text-[#FFC107] py-2">
            <img src="/images/caution.svg" alt="s" className="w-4" />
            The user hasn’t set his daily reminder
          </div>
        )}
      </div>
    </>
  );
};

export default ProfileTimeline;
